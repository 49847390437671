import React from 'react'

// eslint-disable-next-line react/display-name
export const AddCircle = React.memo(() => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="9.5" stroke="#979797" />
    <line x1="5" y1="10" x2="15" y2="10" stroke="black" strokeWidth="2" />
    <line x1="10" y1="5" x2="10" y2="15" stroke="black" strokeWidth="2" />
  </svg>
))
