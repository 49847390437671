import { withFormik } from 'formik'
import * as selfSignUpActions from 'modules/selfSignUp/actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'
import { getFormattedMobileNumber } from 'utils/formatting'
import { isValidMobileNumberForRegion } from 'utils/validation'
import * as Yup from 'yup'

Yup.addMethod(Yup.string, 'phone', function (region) {
  return this.test({
    name: 'name',
    message: 'Phone Number is not valid',
    test: (number) => {
      if (number) {
        return isValidMobileNumberForRegion(number, region)
      }
      return number
    },
  })
})

export default compose(
  withRouter,
  connect(
    // MapStateToProps
    (state) => ({
      getBranchDataProgress: state.progress.getBranchData,
      sendSMSProgress: state.progress.sendSms,
      branchData: state.branchData,
    }),
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...selfSignUpActions,
      }
      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  withFormik({
    mapPropsToValues: () => ({ phone: '', region: 'NZ' }),
    validationSchema: () =>
      Yup.lazy((values) =>
        Yup.object({
          phone: Yup.string()
            .required('Please enter your phone number')
            .phone(values.region),
        }),
      ),
    handleSubmit: (values, { setSubmitting, props }) => {
      setSubmitting(true)
      const formatedNumber = getFormattedMobileNumber(
        values.phone,
        values.region,
      )
      props.actions.sendSMS(formatedNumber, props.branchData.entities)
    },
  }),
  lifecycle({
    componentDidMount() {
      const { actions } = this.props
      actions.initBranch()
    },
  }),
)
