import { FeatureKeys } from 'constants/features'

import { useFeature } from '@optimizely/react-sdk'
import { Switch } from '@weareroam/cake-ui-v1'
import Button from '@weareroam/cake-ui-v1/Button'
import TextField from '@weareroam/cake-ui-v1/TextField'
import Typography from '@weareroam/cake-ui-v1/Typography'
import EmptyState from 'components/molecules/EmptyState'
import { FormikProps } from 'formik'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import VideoUrlTextField from '../../molecules/VideoUrlTextField'

import { FormValues, InnerFormProps } from './BoosterItemForm.enhancer'

export const StyledEmail = styled(TextField)``

export const StyledFields = styled.div`
  margin: ${({ theme }) => theme.spacing.lg}px 0
    ${({ theme }) => theme.spacing.xl}px;

  && {
    > * {
      margin-bottom: ${({ theme }) => theme.spacing.md}px;
    }
  }
`

export const ContactNumber = styled.div`
  display: flex;
`

export const StyledConfigItem = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  padding-bottom: ${({ theme }) => theme.spacing.lg}px;
`

export const Extension = styled(TextField)`
  && {
    width: 140px;
    margin-left: ${({ theme }) => theme.spacing.md}px;
  }
`

export const Actions = styled.footer`
  display: flex;
  width: 320px;
  margin: 0 auto;

  && {
    > * {
      margin: 0 ${({ theme }) => theme.spacing.sm}px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

type BoosterItemFormProps = InnerFormProps & FormikProps<FormValues>

export function BoosterItemForm({
  className,
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  onCancel,
  inEditMode,
  showEscalationOption,
  hasError,
  themes,
}: BoosterItemFormProps): React.ReactElement {
  const onFormSubmit = useCallback((e) => {
    e.stopPropagation()
    handleSubmit(e)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [themeToggleEnabled] = useFeature(FeatureKeys.BoosterThemeToggle)

  if (themeToggleEnabled && hasError) {
    return (
      <EmptyState
        title="Oh no! We’re not able to show this form right now."
        childComponent={
          <span>
            We’ve been notified and will take a look but if the problem persists
            then let us know.
          </span>
        }
      />
    )
  }

  return (
    <form
      onSubmit={onFormSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      <Typography variant="h2">
        {inEditMode ? 'Edit item' : 'Add new item'}
      </Typography>
      <StyledFields>
        <TextField
          id="heading"
          name="heading"
          label="Heading"
          type="text"
          value={values.heading}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched?.heading && !!errors.heading}
          helperText={touched?.heading ? errors.heading : ''}
          fullWidth
        />
        <TextField
          id="subHeading"
          name="subHeading"
          label="Subheading"
          type="text"
          value={values.subHeading}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched?.subHeading && !!errors.subHeading}
          helperText={touched?.subHeading ? errors.subHeading : ''}
          fullWidth
        />
        <StyledEmail
          id="email"
          name="email"
          label="Email Address"
          type="email"
          value={values.email}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched?.email && !!errors.email}
          helperText={touched?.email ? errors.email : ''}
          fullWidth
        />
        <ContactNumber>
          <TextField
            id="contact"
            name="contact"
            label="Contact number"
            type="text"
            value={values.contact}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched?.contact && !!errors.contact}
            helperText={touched?.contact ? errors.contact : ''}
            fullWidth
          />
          <Extension
            id="extension"
            name="extension"
            label="Extension"
            type="text"
            value={values.extension}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched?.extension && !!errors.extension}
            helperText={touched?.extension ? errors.extension : ''}
            fullWidth
          />
        </ContactNumber>
        <TextField
          id="website"
          name="website"
          label="Website"
          type="text"
          value={values.website}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched?.website && !!errors.website}
          helperText={touched?.website ? errors.website : ''}
          fullWidth
        />
        <VideoUrlTextField
          id="videoUrl"
          name="videoUrl"
          type="text"
          value={values.videoUrl}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched?.videoUrl && !!errors.videoUrl}
          helperText={touched?.videoUrl ? errors.videoUrl : ''}
          fullWidth
        />
        <TextField
          id="description"
          name="description"
          label="Description"
          type="text"
          value={values.description}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched?.description && !!errors.description}
          helperText={touched?.description ? errors.description : ''}
          fullWidth
        />
        <TextField
          id="intermediateScreenDescription"
          name="intermediateScreenDescription"
          label="Intermediate Screen Description"
          type="text"
          value={values.intermediateScreenDescription}
          onBlur={handleBlur}
          onChange={handleChange}
          error={
            touched?.intermediateScreenDescription &&
            !!errors.intermediateScreenDescription
          }
          helperText={
            touched?.intermediateScreenDescription
              ? errors.intermediateScreenDescription
              : ''
          }
          fullWidth
        />
      </StyledFields>
      <StyledConfigItem>
        Show intermediate screen on click through?
        <Switch
          name="showIntermediateScreen"
          id="showIntermediateScreen"
          checked={values.showIntermediateScreen}
          color="primary"
          onChange={handleChange}
        />
      </StyledConfigItem>
      {showEscalationOption ? (
        <StyledConfigItem>
          Display option for urgent support?
          <Switch
            name="escalationOption"
            id="escalationOption"
            checked={values.escalationOption}
            color="primary"
            onChange={handleChange}
          />
        </StyledConfigItem>
      ) : themeToggleEnabled && themes?.length ? (
        <StyledConfigItem>
          Surface this resource when themes of bullying and harassment are
          detected in the app?
          <Switch
            name="bullyingThemeAssociated"
            id="bullyingThemeAssociated"
            checked={values.bullyingThemeAssociated}
            color="primary"
            onChange={handleChange}
          />
        </StyledConfigItem>
      ) : null}

      <Actions>
        <Button
          variant="outlined"
          type="button"
          color="primary"
          fullWidth
          size="small"
          onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          color="primary"
          size="small">
          {inEditMode ? 'Edit item' : 'Add item'}
        </Button>
      </Actions>
    </form>
  )
}

export default BoosterItemForm
