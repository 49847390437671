import { Typography } from '@weareroam/cake-ui-v1'
import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'

import { ClickArea } from '../../atoms/ClickArea/ClickArea'
import { ApproveCircle } from '../../atoms/Icons/ApproveCircle'
import { DeleteCircle } from '../../atoms/Icons/DeleteCircle'

const StyledActionArea = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 ${({ theme }) => theme.spacing.md}px;
`

const StyledLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

interface Props {
  icon: 'approve' | 'delete'
  label: string
  onClick: MouseEventHandler<HTMLDivElement>
}

export function TableRowAction({
  icon,
  label,
  onClick,
}: Props): React.ReactElement {
  return (
    <StyledActionArea>
      <ClickArea onClick={onClick} />
      {icon === 'approve' ? <ApproveCircle /> : <DeleteCircle />}
      <StyledLabel variant="body2">{label}</StyledLabel>
    </StyledActionArea>
  )
}
