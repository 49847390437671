import React from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function FaceUpset(props) {
  return (
    <svg width="21px" height="21px" viewBox="0 0 21 21" {...props}>
      <g
        id="Chnnl-admin-home"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="_Screens/Super-admin/selected-orgs"
          transform="translate(-268.000000, -338.000000)"
          stroke="currentColor"
          strokeWidth="1.092224">
          <g id="Group-9" transform="translate(269.000000, 339.000000)">
            <path
              d="M9.51607176,18.9347294 C14.7178247,18.9347294 18.93426,14.7174 18.93426,9.51587059 C18.93426,4.31456471 14.7178247,0.0979058824 9.51607176,0.0979058824 C4.31454235,0.0979058824 0.0978835294,4.31456471 0.0978835294,9.51587059 C0.0978835294,14.7174 4.31454235,18.9347294 9.51607176,18.9347294 Z"
              id="Stroke-1"
              fill="transparent"
            />
            <path
              d="M15.2101706,13.8424612 C14.5013588,13.5487435 13.7254882,13.3835553 12.9113941,13.3766259 C10.6054647,13.3565082 8.58565294,14.60872 7.51852353,16.4780965"
              id="Stroke-3"
            />
            <path
              d="M3.35526588,7.85998706 C3.35526588,9.21479882 4.45324235,10.3127753 5.80783059,10.3127753 C7.16197176,10.3127753 8.25994824,9.21479882 8.25994824,7.85998706"
              id="Stroke-5"
            />
            <path
              d="M10.7317365,7.85998706 C10.7317365,9.21479882 11.8297129,10.3127753 13.1843012,10.3127753 C14.5386659,10.3127753 15.6366424,9.21479882 15.6366424,7.85998706"
              id="Stroke-7"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default FaceUpset
