import TextField from '@weareroam/cake-ui-v1/TextField'
import { StandardTextFieldProps } from '@weareroam/cake-ui-v1/TextField/TextField'
import React from 'react'
import styled from 'styled-components'

const StyledAsterisk = styled.span`
  margin-right: 8px;
  padding-top: 3px;
  color: #ff4d4f;
`

const StyledCounter = styled.label`
  position: absolute;
  right: ${({ theme }) => theme.spacing.xs}px;
  bottom: ${({ theme }) => theme.spacing.mdlg + theme.spacing.xs}px;
  font-size: 0.7rem;
  color: #ddddde;
`

const StyledDiv = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md}px;
  position: relative;
`

const StyledLabel = styled.label`
  display: flex;
  color: #828797;
  padding: 0;
  font-size: 1rem;
  font-family: 'Axiforma', 'Helvetica', 'Arial', 'sans-serif';
  line-height: 1;
`

const StyledTextField = styled(TextField)`
  div {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    padding: 0;
  }

  div::before {
    border-bottom: none;
  }

  textarea {
    border: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 5px;
    padding: ${({ theme }) => theme.spacing.md}px;
  }

  && {
    margin-top: ${({ theme }) => theme.spacing.sm}px;
    margin-bottom: ${({ theme }) => theme.spacing.mdlg}px;
  }
`

interface Props extends StandardTextFieldProps {
  value?: string
  maxLength?: number
}

export function LabelledTextarea({
  id,
  name,
  label,
  placeholder,
  disabled,
  required,
  rows,
  maxLength,
  children,
  value,
  error,
  helperText,
  onChange,
  onBlur,
}: Props): React.ReactElement {
  return (
    <StyledDiv>
      {label ? (
        <StyledLabel>
          {required ? <StyledAsterisk>*</StyledAsterisk> : null}
          {label}
        </StyledLabel>
      ) : null}
      <StyledTextField
        id={id}
        name={name}
        type="text"
        placeholder={placeholder}
        fullWidth
        multiline
        rows={rows ?? 5}
        variant="filled"
        disabled={disabled}
        InputProps={{
          disableUnderline: true,
        }}
        value={value}
        error={error}
        helperText={helperText}
        onChange={onChange}
        onBlur={onBlur}
      />
      {maxLength ? (
        <StyledCounter>
          {value?.length || 0}/{maxLength}
        </StyledCounter>
      ) : null}
      {children}
    </StyledDiv>
  )
}
