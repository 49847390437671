import { TextField, Typography } from '@weareroam/cake-ui-v1'
import Button from '@weareroam/cake-ui-v1/Button'
import styled from 'styled-components'

export const ModalFormTitle = styled(Typography)`
  && {
    margin: ${({ theme }) => theme.spacing.sm}px 0
      ${({ theme }) => theme.spacing.lg}px 0;
  }
`

export const ModalFormBody = styled.div`
  display: flex;
  flex-direction: column;
`

export const ModalFormFields = styled.fieldset`
  margin: ${({ theme }) => theme.spacing.md}px 0
    ${({ theme }) => theme.spacing.lg}px 0;

  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.mdlg}px;
  }
`

export const ModalFormTextField = styled(TextField)`
  // Trickery to swap asterisk to the front of label
  > label {
    display: flex;
    flex-direction: row-reverse;
  }
  > label > span {
    margin-right: 8px;
    padding-top: 3px;
    color: #ff4d4f; // Dust Red / 5
  }
`

export const ModalFormActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;

  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.xl}px;
  }
`

export const ModalFormActionButton = styled(Button)`
  width: 157px;

  && {
    padding: 6px;
    font-size: 0.9rem;
    background: ${(props) =>
      props.variant === 'flat' ? 'rgba(41, 133, 252, 0.1)' : ''};
  }
`
