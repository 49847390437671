import { FeatureKeys } from 'constants/features'
import {
  ROLE_SUPER_ADMIN,
  ROLE_ORG_OWNER,
  ROLE_ORG_ADMIN,
  ROLE_PARTNER_ADMIN,
} from 'constants/form'
import {
  ORG_DASHBOARD_MESSAGING_NETWORK,
  ORG_DASHBOARD_MESSAGING_NOTICEBOARD,
  ORG_DASHBOARD_MESSAGING_ORGANISATION,
} from 'constants/routes'

import { useFeature } from '@optimizely/react-sdk'
import authorisedRoles from 'components/hoc/authorisedRoles'
import NavItem from 'components/molecules/NavItem'
import Sidebar from 'components/molecules/Sidebar'
import React from 'react'
import { useParams } from 'react-router-dom'
import { OrganisationRouteParams } from 'types/routes'
import { replaceRouteParams } from 'utils/routes'

import { SideNavigationContainer } from './SideNavigation/SideNavigationContainer'
import { SideNavigationContent } from './SideNavigation/SideNavigationContent'

export interface MessagingLayoutProps {
  children?: React.ReactNode
}

// Don't allow group admins to access the video updates page
const ProtectedNavItem = authorisedRoles(
  [ROLE_PARTNER_ADMIN, ROLE_SUPER_ADMIN, ROLE_ORG_OWNER, ROLE_ORG_ADMIN],
  false,
)(NavItem)

export function MessagingLayout({
  children,
}: MessagingLayoutProps): React.ReactElement {
  const { orgUuid } = useParams<OrganisationRouteParams>()
  const [videoUpdatesEnabled] = useFeature(FeatureKeys.VideoUpdates)
  const [noticeBoardEnabled] = useFeature(FeatureKeys.NoticeBoard)

  return (
    <SideNavigationContainer>
      <Sidebar>
        <NavItem
          // @ts-expect-error NavItem.js is wrapped in withRouter() and TS doesn't see internal props
          direction="vertical"
          to={replaceRouteParams(ORG_DASHBOARD_MESSAGING_ORGANISATION, {
            orgUuid,
          })}
          text="General"
        />
        {noticeBoardEnabled && (
          <ProtectedNavItem
            direction="vertical"
            to={replaceRouteParams(ORG_DASHBOARD_MESSAGING_NOTICEBOARD, {
              orgUuid,
            })}
            text="Notice Board"
          />
        )}
        {videoUpdatesEnabled && (
          <ProtectedNavItem
            direction="vertical"
            to={replaceRouteParams(ORG_DASHBOARD_MESSAGING_NETWORK, {
              orgUuid,
            })}
            text="Network"
          />
        )}
      </Sidebar>
      <SideNavigationContent>{children}</SideNavigationContent>
    </SideNavigationContainer>
  )
}
