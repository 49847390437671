import { ROLE_SUPER_ADMIN } from 'constants/form'
import {
  PARTNER_DASHBOARD_ADMINS,
  PARTNER_DASHBOARD_SETTINGS_DETAILS,
  PARTNER_DASHBOARD_ORGANISATIONS,
  CHNNL_DASHBOARD_PARTNERS,
} from 'constants/routes'

import Typography from '@weareroam/cake-ui-v1/Typography'
import ChevronRight from '@weareroam/cake-ui-v1-icons/ChevronRight'
import authorisedRoles from 'components/hoc/authorisedRoles'
import NavItem from 'components/molecules/NavItem'
import Sidebar from 'components/molecules/Sidebar'
import ChnnlDashboardLayout from 'layouts/ChnnlDashboardLayout'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { replaceRouteParams } from 'utils/routes'

import { SideNavigationContainer } from '../SideNavigation/SideNavigationContainer'
import { SideNavigationContent } from '../SideNavigation/SideNavigationContent'

export const Breadcrumbs = styled.nav`
  background: ${({ theme }) => theme.palette.secondary.main};
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  height: 65px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`

export const Breadcrumb = styled(Link)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
  padding: ${({ theme }) => theme.spacing.md}px 0
    ${({ theme }) => theme.spacing.md}px ${({ theme }) => theme.spacing.lg}px;
  font-size: 14px;
`

export const CurrentPage = styled(Typography)`
  && {
    font-weight: ${({ theme }) => theme.weights.regular};
  }
`
export const StyledChevron = styled(ChevronRight)`
  margin: 0 ${({ theme }) => theme.spacing.sm}px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  position: absolute;
  padding: 0 48px;
  top: 60px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 125vw;
    height: 65px;
    transform: translateX(-25vw);
    background-color: ${({ theme }) => theme.palette.secondary.main};
    border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  }
`

const StyledSideNavigationContainer = styled(SideNavigationContainer)`
  height: 100vh;
  background-color: #fafbfd;
`

const StyledSideNavigationContent = styled(SideNavigationContent)`
  overflow: auto;
`

// Only ROLE_SUPER_ADMIN has access top this nav item
const ProtectedSuperAdminNavItem = authorisedRoles([ROLE_SUPER_ADMIN])(NavItem)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function PartnerInnerLayout({ children, partnerUuid, partner }) {
  return (
    <ChnnlDashboardLayout noSidebar>
      <ContentContainer>
        <Breadcrumbs>
          <Breadcrumb to={replaceRouteParams(CHNNL_DASHBOARD_PARTNERS, {})}>
            Partners
          </Breadcrumb>
          <StyledChevron />
          <CurrentPage variant="subtitle1">
            {partner ? partner.title : 'Loading...'}
          </CurrentPage>
        </Breadcrumbs>
        <StyledSideNavigationContainer>
          <Sidebar>
            <ProtectedSuperAdminNavItem
              direction="vertical"
              to={replaceRouteParams(PARTNER_DASHBOARD_SETTINGS_DETAILS, {
                partnerUuid,
              })}
              text="General"
            />
            <ProtectedSuperAdminNavItem
              direction="vertical"
              to={replaceRouteParams(PARTNER_DASHBOARD_ORGANISATIONS, {
                partnerUuid,
              })}
              text="Organisations"
            />
            <ProtectedSuperAdminNavItem
              direction="vertical"
              to={replaceRouteParams(PARTNER_DASHBOARD_ADMINS, {
                partnerUuid,
              })}
              text="Admins"
            />
          </Sidebar>
          <StyledSideNavigationContent>{children}</StyledSideNavigationContent>
        </StyledSideNavigationContainer>
      </ContentContainer>
    </ChnnlDashboardLayout>
  )
}

PartnerInnerLayout.propTypes = {
  children: PropTypes.node,
  partnerUuid: PropTypes.string,
  partnerTitle: PropTypes.string,
  partner: PropTypes.object,
  getPartnerProgress: PropTypes.object,
}

export default PartnerInnerLayout
