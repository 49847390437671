const dg = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
]
const tn = [
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
  'nineteen',
]
const tw = [
  'twenty',
  'thirty',
  'forty',
  'fifty',
  'sixty',
  'seventy',
  'eighty',
  'ninety',
]
const th = ['', 'thousand', 'million', 'milliard', 'billion']

export const numberToWord = (num: number): string => {
  const s = num.toString()
  if (s.length > 15) return s //'too big'
  const n = s.split('').map((item) => Number(item))
  let str = ''
  let sk = 0
  for (let i = 0; i < s.length; i++) {
    if ((s.length - i) % 3 === 2) {
      if (n[i] === 1) {
        str += tn[Number(n[i + 1])] + ' '
        i++
        sk = 1
      } else if (n[i] !== 0) {
        str += tw[n[i] - 2] + ' '
        sk = 1
      }
    } else if (n[i] !== 0) {
      str += dg[n[i]] + ' '
      if ((s.length - i) % 3 === 0) str += 'hundred '
      sk = 1
    }
    if ((s.length - i) % 3 === 1) {
      if (sk) str += th[(s.length - i - 1) / 3] + ' '
      sk = 0
    }
  }
  return str.replace(/\s+/g, ' ')
}
