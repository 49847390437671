import { useState, useCallback } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useMemberModals() {
  const [isEditMemberModalOpen, setIsEditMemberModalOpen] = useState(false)
  const [isResendModalOpen, setIsResendModalOpen] = useState(false)
  const [isRemoveMemberModalOpen, setIsRemoveMemberModalOpen] = useState(false)
  const [isMultiResendModalOpen, setIsMultiResendModalOpen] = useState(false)
  const [isMoveMembersModalOpen, setIsMoveMembersModalOpen] = useState(false)
  const [memberUuid, setMemberUuid] = useState(null)

  const handleOpenDeleteMemberModal = useCallback((uuid) => {
    setMemberUuid(uuid)
    setIsRemoveMemberModalOpen(true)
  }, [])

  const handleCloseDeleteMemberModal = useCallback(() => {
    setIsRemoveMemberModalOpen(false)
    setMemberUuid(null)
  }, [])

  const handleOpenMultipleResendModal = useCallback(() => {
    setIsMultiResendModalOpen(true)
  }, [])

  const handleCloseMultipleResendModal = useCallback(() => {
    setIsMultiResendModalOpen(false)
  }, [])

  const handleOpenSingleResendModal = useCallback((uuid) => {
    setMemberUuid(uuid)
    setIsResendModalOpen(true)
  }, [])

  const handleCloseSingleResendModal = useCallback(() => {
    setIsResendModalOpen(false)
  }, [])

  const handleOpenMoveMembersModal = useCallback((uuid) => {
    setMemberUuid(uuid)
    setIsMoveMembersModalOpen(true)
  }, [])

  const handleCloseMoveMembersModal = useCallback(() => {
    setIsMoveMembersModalOpen(false)
  }, [])

  const handleOpenEditMemberModal = useCallback((uuid) => {
    setMemberUuid(uuid)
    setIsEditMemberModalOpen(true)
  }, [])

  const handleCloseEditMemberModal = useCallback(() => {
    setIsEditMemberModalOpen(false)
    setMemberUuid(null)
  }, [])

  return {
    handleOpenEditMemberModal,
    handleCloseEditMemberModal,
    handleOpenDeleteMemberModal,
    handleCloseDeleteMemberModal,
    handleOpenSingleResendModal,
    handleCloseSingleResendModal,
    handleOpenMultipleResendModal,
    handleCloseMultipleResendModal,
    handleOpenMoveMembersModal,
    handleCloseMoveMembersModal,
    isEditMemberModalOpen,
    isMultiResendModalOpen,
    isResendModalOpen,
    isMoveMembersModalOpen,
    isRemoveMemberModalOpen,
    memberUuid,
  }
}
