import { ObjectSchema, object, string, array } from 'yup'

import { ApiEntityDefaults } from '../../models/ApiEntityDefaults'
import { Group, GroupValidation } from '../groups/schema'

// GET

export interface InboxMessageLink extends ApiEntityDefaults {
  uuid?: string
  title: string
  url: string
}

export interface InboxMessage extends ApiEntityDefaults {
  uuid?: string
  title: string
  message: string
  links: InboxMessageLink[]
  groupUuid?: string
  // TODO: Link to actual groups?
  // groups: Group[]
}

// User Inputs

export type InboxMessageLinkUserInputs = Pick<InboxMessageLink, 'title' | 'url'>

export type InboxMessageUserInputs = Pick<InboxMessage, 'title' | 'message'> & {
  groups: Group[]
  links: InboxMessageLinkUserInputs[]
}

export const InboxMessageConstrains = {
  title: {
    maxLength: 30,
  },
  message: {
    maxLength: 240,
  },
}

export const InboxMessageLinkValidation: ObjectSchema<InboxMessageLinkUserInputs> =
  object({
    title: string().required('Title is required'),
    url: string().url('Must be a valid URL').required('URL is required'),
  })

export const InboxMessageValidation: ObjectSchema<InboxMessageUserInputs> =
  object({
    title: string()
      .required('Please provide a message title')
      .max(
        InboxMessageConstrains.title.maxLength,
        'Please keep your title within 30 characters',
      ),
    message: string()
      .required('Please enter a message to send to your team')
      .max(
        InboxMessageConstrains.message.maxLength,
        'Please keep your message within 240 characters',
      ),
    groups: array()
      .of(GroupValidation)
      .required()
      .min(1, 'Please select at least one group to send your message to'),
    links: array().of(InboxMessageLinkValidation).required(),
  })

// POST

export type InboxMessagePostBody = Array<
  Pick<InboxMessageUserInputs, 'title' | 'message' | 'links'> & {
    groupUuid: string
  }
>
