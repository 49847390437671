import { IDayType } from 'models/IDayType'
import { IReflection } from 'models/IReflection'
import { RequestProgressState } from 'modules/progress'
import React from 'react'

import { ReflectionItemFormValues } from '../ReflectionItemForm'

import { default as ReflectionsFormElement } from './ReflectionsForm'
import enhance from './ReflectionsForm.enhancer'

// TODO(matej): figure out the drag result structure
interface IDragResult {
  source: { index: number }
  destination: { index: number }
}

export interface FormValues {
  reflections: IReflection[]
}

export interface FormProps {
  reflections: IReflection[]
  disableEditing: boolean
  isAddReflectionModalOpen: boolean
  setIsAddReflectionModalOpen: (isOpen: boolean) => void
  isDeleteModalOpen: boolean
  setIsDeleteModalOpen: (isOpen: boolean) => void
  deleteReflectionIndex: number
  setDeleteReflectionIndex: (reflectionIndex: number) => void
  editReflectionIndex: number
  setEditReflectionIndex: (reflectionIndex: number) => void
  updateProgress: RequestProgressState
  progress: RequestProgressState
  onCreate: (value: { reflection: Partial<IReflection> }) => void
  onUpdate: (value: { reflectionUuid: string; reflection: IReflection }) => void
  onDelete: (value: { reflectionUuid: string }) => void
  typesOfDays: IDayType[]
  description: React.ReactElement
  title: string
  className: string
}

export interface HandlerProps {
  onCloseAddReflectionModal: () => void
  onAddReflection: (newValue: ReflectionItemFormValues) => void
  onEditReflection: (newValue: ReflectionItemFormValues, index: number) => void
  onDeleteReflection: (e: React.FormEvent<HTMLFormElement>) => void
  onDragEnd: (result: IDragResult) => void
}

export default enhance(ReflectionsFormElement)
