import { Typography } from '@weareroam/cake-ui-v1'
import { format } from 'date-fns'
import React from 'react'
import styled from 'styled-components'

import { HumanReadableDatetime } from '../../../constants/date'
import { Group } from '../../../modules/groups/schema'
import { InboxMessage } from '../../../modules/messaging/interfaces'
import { TextLink } from '../../atoms/TextLink/TextLink'
import { ListItem } from '../../molecules/ListItem/ListItem'
import { ListSection } from '../../molecules/ListSection/ListSection'

const SecondaryColumn = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

interface MessagingListSectionProps {
  groups: Group[]
  messages: InboxMessage[]
  handlers: {
    startNew: () => void
  }
}

export function MessagingListSection({
  // TODO: Store groups in redux while using useGroups hook
  //  and source from useSelector in this component instead of props
  groups,
  messages,
  handlers,
}: MessagingListSectionProps): React.ReactElement {
  return (
    <ListSection
      columns={[
        {
          title: 'Message',
          width: 65,
        },
        {
          title: 'Group',
          alignment: 'center',
          width: 20,
        },
        {
          title: 'Date / Time',
          alignment: 'center',
          width: 15,
        },
      ]}>
      {messages.length ? (
        messages.map((item) => (
          <ListItem
            key={item.uuid}
            columns={[
              {
                contents: (
                  <Typography variant="body1">{item.message}</Typography>
                ),
                width: 65,
              },
              {
                contents: (
                  <SecondaryColumn variant="body2">
                    {groups.find((g) => g.uuid === item.groupUuid)?.name}
                  </SecondaryColumn>
                ),
                width: 20,
              },
              {
                contents: (
                  <SecondaryColumn variant="body2">
                    {format(item.createdAt, HumanReadableDatetime)}
                  </SecondaryColumn>
                ),
                width: 15,
              },
            ]}
          />
        ))
      ) : (
        <Typography variant="body1">
          No messages sent yet. Get started by{' '}
          <TextLink onClick={handlers.startNew}>
            sending a message to your team!
          </TextLink>
        </Typography>
      )}
    </ListSection>
  )
}
