import { ReflectionSentimentEnum } from 'constants/ReflectionSentimentEnum'

import { MAXIMUM_REFLECTION_LENGTH } from 'config'
import { FormikProps, withFormik } from 'formik'
import { compose, withHandlers } from 'recompose'
import * as Yup from 'yup'

import {
  FormProps,
  ReflectionItemFormValues,
  HandlerProps,
  InnerFormProps,
} from './index'

export const enhance = compose<
  HandlerProps & InnerFormProps & FormikProps<ReflectionItemFormValues>,
  InnerFormProps & FormProps
>(
  withFormik<FormProps & InnerFormProps, ReflectionItemFormValues>({
    enableReinitialize: true,
    mapPropsToValues: ({
      initialTitle,
      initialSentiment,
      initialDayTypes,
      initialSubQuestions,
    }) => ({
      title: initialTitle || '',
      sentiment: initialSentiment,
      dayTypes: initialDayTypes.map((dt) => dt.type),
      subQuestions: initialSubQuestions,
      textInput: '',
    }),
    validationSchema: Yup.object({
      title: Yup.string()
        .max(
          MAXIMUM_REFLECTION_LENGTH,
          `Reflections need to be less than ${MAXIMUM_REFLECTION_LENGTH} characters`,
        )
        .required('Please enter your reflection title'),
      sentiment: Yup.string().required('Please choose sentiment'),
      subQuestions: Yup.array().of(
        Yup.object({
          title: Yup.string().required('Sub-question title required'),
          option1: Yup.string().required('Sub-question option required'),
        }),
      ),
    }),
    handleSubmit: (values, { props: { onSubmit }, setSubmitting }) => {
      setSubmitting(true)
      onSubmit(values)
      setSubmitting(false)
    },
  }),
  withHandlers<FormikProps<ReflectionItemFormValues>, HandlerProps>({
    setSentiment:
      ({ setFieldValue }) =>
      (value: ReflectionSentimentEnum) => {
        setFieldValue('sentiment', value)
      },
    onAddChip:
      ({ setFieldValue, values }) =>
      (chip) => {
        const alreadyExists = values.dayTypes.some(
          (v) => v.trim().toLowerCase() === chip.trim().toLowerCase(),
        )

        if (alreadyExists) {
          return
        }

        setFieldValue('dayTypes', [...values.dayTypes, chip])
        setFieldValue('textInput', '')
      },
    onDeleteChip:
      ({ values, setFieldValue }) =>
      (chip) => {
        setFieldValue(
          'dayTypes',
          values.dayTypes.filter((value) => value !== chip),
        )
      },
  }),
)
