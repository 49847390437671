import { PAGINATION_LIMIT } from 'constants/pagination'

import { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import { getMessageHistory, messageGroups } from '../modules/messaging/actions'
import {
  InboxMessage,
  InboxMessageUserInputs,
} from '../modules/messaging/interfaces'
import {
  getGetMessagesPagination,
  getGetMessagesProgress,
  getOrganisationMessagesList,
} from '../modules/messaging/selectors'

import { PaginatedHook, SearchCriteria } from './types'

interface UseMessagesProps {
  organisationUuid: string
  groupUuid?: string
  initialFilters: SearchCriteria[]
}

// Handlers in addition to the standard paginated data hook
interface UseMessagesHandlers {
  sendMessage: (messageInput: InboxMessageUserInputs) => void
}

export const useMessages = ({
  organisationUuid,
  groupUuid,
  initialFilters,
}: UseMessagesProps): PaginatedHook<InboxMessage[]> & UseMessagesHandlers => {
  const dispatch = useDispatch()

  const progress = useSelector(getGetMessagesProgress)
  const pagination = useSelector(getGetMessagesPagination)
  const data = useSelector(getOrganisationMessagesList)

  const [searchFilters, setSearchFilters] = useState(initialFilters)

  const fetchMessages = useCallback(
    (page = 0) => {
      dispatch(
        getMessageHistory({
          organisationUuid,
          groupUuid,
          pagination: {
            ...pagination,
            start: page * PAGINATION_LIMIT,
          },
          searchFilters,
        }),
      )
    },
    [dispatch, organisationUuid, groupUuid, pagination, searchFilters],
  )

  // Load messages on mount.
  useEffect(() => {
    fetchMessages()
    // Intentionally ignoring pagination and search changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const debouncedFetch = useDebouncedCallback(fetchMessages, 350)
  // Use debounced fetch for search filter change.
  const setSearch = useCallback(
    (filters) => {
      setSearchFilters(filters)
      debouncedFetch()
    },
    [debouncedFetch],
  )

  const sendMessage = useCallback(
    (messageInput: InboxMessageUserInputs) => {
      const groupUuids = messageInput.groups.map((g) => g.uuid)
      dispatch(
        messageGroups({
          organisationUuid,
          groupUuids,
          title: messageInput.title,
          message: messageInput.message,
          links: messageInput.links,
        }),
      )
    },
    [dispatch, organisationUuid],
  )

  return {
    data,
    isLoading: progress.inProgress,
    error: progress.error,

    pagination,
    setPage: (page) => fetchMessages(page),
    setSearch,

    sendMessage,
  }
}
