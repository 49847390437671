import React from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function CheckedRadio() {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16">
      <g
        id="Organisation-Settings"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="_Screens/Settings/Invite-group-admin"
          transform="translate(-249.000000, -594.000000)"
          fillRule="nonzero">
          <g
            id="atom-/-Toggle-/-Radio-/-On"
            transform="translate(249.000000, 594.000000)">
            <circle
              id="Oval"
              stroke="#CCCCCC"
              strokeWidth="2"
              cx="8"
              cy="8"
              r="6.66666667"
            />
            <circle id="Oval" fill="#4B87FF" cx="8" cy="8" r="4.66666667" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CheckedRadio
