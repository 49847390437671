import {
  TIP_TYPE_ALL,
  TIP_THEME_CALM,
  TIP_THEME_ACTIVE,
  TIP_THEME_RESTFUL,
  TIP_THEME_INTERESTED,
  TIP_THEME_CHEERFUL,
  SORT_DIRECTION_ASC,
} from 'constants/form'

import Button from '@weareroam/cake-ui-v1/Button'
import ButtonSwitch from 'components/atoms/ButtonSwitch'
import ContentContainer from 'components/atoms/ContentContainer'
import ProgressLoader from 'components/atoms/ProgressLoader'
import SearchField from 'components/atoms/SearchField'
import { Modal } from 'components/molecules/Modal'
import Pagination from 'components/molecules/Pagination'
import SortHeading from 'components/molecules/SortHeading'
import TipItem from 'components/molecules/TipItem'
import DeleteTipForm from 'components/organisms/DeleteTipForm'
import TipForm from 'components/organisms/TipForm'
import useTipModals from 'hooks/useTipModals'
import useTips from 'hooks/useTips'
import ChnnlDashboardLayout from 'layouts/ChnnlDashboardLayout'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { TipEnhancerProps } from './Tips.enhancer'

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.xxs}px;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
  > * {
    margin-left: ${({ theme }) => theme.spacing.md}px;
    margin-right: ${({ theme }) => theme.spacing.md}px;
  }
`
export const HeaderLeft = styled.div``
export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`
export const Filters = styled.div`
  margin-left: ${({ theme }) => theme.spacing.md}px;
`

export const TipsHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  padding-bottom: ${({ theme }) => theme.spacing.md}px;
`

export const TipHeaderItem = styled.div`
  margin: 0 ${({ theme }) => theme.spacing.md}px;
  &:nth-child(1) {
    width: 80%;
  }
  &:nth-child(2) {
    width: 5%;
  }
`

export const Spacer = styled.div`
  width: 6%;
`

export const TipsList = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`
// type PaginationProps = keyof Pagination.propTypes & keyof React.Element
// TODO: figure out how to type the below properly
// eslint-disable-next-line
export const StyledPagination = styled<any>(Pagination)`
  display: flex;
  justify-content: flex-end;
`
// interface DeleteModalProps {
//   open: boolean
//   onClose: () => VimeoVideoInfo
// }
// TODO: figure out how to type the below properly
// eslint-disable-next-line
export const TipModal = styled<any>(Modal)`
  width: ${({ width = 500 }) => width})}px;
`

export const NewTipButton = styled(Button)`
  && {
    margin-left: ${({ theme }) => theme.spacing.sm}px;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Tips({ actions, ...props }: TipEnhancerProps) {
  const {
    tipModalUuid,
    isNewTipModalOpen,
    isEditTipModalOpen,
    isDeleteTipModalOpen,
    handleOpenNewTipModal,
    handleCloseNewTipModal,
    handleOpenEditTipsModal,
    handleCloseEditTipsModal,
    handleOpenDeleteTipModal,
    handleCloseDeleteTipModal,
  } = useTipModals()

  const {
    tipType,
    searchFilter,
    pagination,
    handleChangeTipType,
    handleChangePagination,
    handleChangeSearchFilter,
    handleAddSubmit,
    handleEditSubmit,
    handleDeleteTip,
  } = useTips({
    sortOptions: { createdAt: 'DESC' },
    onFetchTips: actions.getTips,
    tipModalUuid,
    onDeleteTip: useCallback(
      async (tipUuid) => {
        actions.deleteTip(tipUuid)
        handleCloseDeleteTipModal()
      },
      [actions, handleCloseDeleteTipModal],
    ),
    onEditTip: useCallback(
      async (updatedTipValues, tipUuid) => {
        await actions.updateTip(updatedTipValues, tipUuid)
        handleCloseEditTipsModal()
      },
      [actions, handleCloseEditTipsModal],
    ),
    onAddTip: useCallback(
      async (updatedTipValues) => {
        await actions.addTip(updatedTipValues)
        handleCloseNewTipModal()
      },
      [actions, handleCloseNewTipModal],
    ),
  })

  const editingTip = React.useMemo(() => {
    if (!props.tips) {
      return null
    }

    return props.tips.find((tip) => tip.uuid === tipModalUuid)
  }, [props.tips, tipModalUuid])

  return (
    <ChnnlDashboardLayout>
      <TipModal open={isDeleteTipModalOpen} onClose={handleCloseDeleteTipModal}>
        <DeleteTipForm
          onCancel={handleCloseDeleteTipModal}
          onSubmit={handleDeleteTip}
        />
      </TipModal>
      <TipModal
        width={800}
        open={isEditTipModalOpen}
        onClose={handleCloseEditTipsModal}>
        {editingTip && (
          <TipForm
            tip={editingTip}
            onCancel={handleCloseEditTipsModal}
            onSubmit={handleEditSubmit}
          />
        )}
      </TipModal>
      <TipModal
        width={800}
        open={isNewTipModalOpen}
        onClose={handleCloseNewTipModal}>
        <TipForm onCancel={handleCloseNewTipModal} onSubmit={handleAddSubmit} />
      </TipModal>
      <ContentContainer>
        <StyledHeader>
          <HeaderLeft>
            <NewTipButton
              type="button"
              variant="contained"
              color="primary"
              size="small"
              onClick={handleOpenNewTipModal}
              id="new-tip-button">
              New tip
            </NewTipButton>
          </HeaderLeft>
          <HeaderRight>
            <SearchField
              placeholder="Search"
              value={searchFilter}
              name="searchFilter"
              onChange={handleChangeSearchFilter}
            />
            <Filters>
              <ButtonSwitch
                size="medium"
                value={TIP_TYPE_ALL}
                name="tipType"
                isActive={tipType === TIP_TYPE_ALL}
                onClick={handleChangeTipType}>
                All
              </ButtonSwitch>
              <ButtonSwitch
                size="medium"
                value={TIP_THEME_RESTFUL}
                name="tipType"
                isActive={tipType === TIP_THEME_RESTFUL}
                onClick={handleChangeTipType}>
                Restful
              </ButtonSwitch>
              <ButtonSwitch
                size="medium"
                value={TIP_THEME_CALM}
                name="tipType"
                isActive={tipType === TIP_THEME_CALM}
                onClick={handleChangeTipType}>
                Calm
              </ButtonSwitch>
              <ButtonSwitch
                size="medium"
                value={TIP_THEME_ACTIVE}
                name="tipType"
                isActive={tipType === TIP_THEME_ACTIVE}
                onClick={handleChangeTipType}>
                Active
              </ButtonSwitch>
              <ButtonSwitch
                size="medium"
                value={TIP_THEME_INTERESTED}
                name="tipType"
                isActive={tipType === TIP_THEME_INTERESTED}
                onClick={handleChangeTipType}>
                Interested
              </ButtonSwitch>
              <ButtonSwitch
                size="medium"
                value={TIP_THEME_CHEERFUL}
                name="tipType"
                isActive={tipType === TIP_THEME_CHEERFUL}
                onClick={handleChangeTipType}>
                Cheerful
              </ButtonSwitch>
            </Filters>
          </HeaderRight>
        </StyledHeader>

        <TipsHeader>
          <TipHeaderItem>
            <SortHeading>
              <strong>Tip</strong>
            </SortHeading>
          </TipHeaderItem>
          <TipHeaderItem>
            <SortHeading>
              <strong>Theme</strong>
            </SortHeading>
          </TipHeaderItem>
          <TipHeaderItem>
            <SortHeading sortDirection={SORT_DIRECTION_ASC}>
              <strong>Icon</strong>
            </SortHeading>
          </TipHeaderItem>
          <Spacer />
        </TipsHeader>
        {props.getTipsProgress.inProgress &&
        (!props.tips || props.tips.length === 0) ? (
          <ProgressLoader fullWidth />
        ) : (
          <>
            <TipsList>
              {props.tips.map((tip, i) => (
                <TipItem
                  key={i}
                  {...tip}
                  onClick={handleOpenEditTipsModal}
                  actions={[
                    {
                      key: 'delete-tip',
                      label: 'Delete tip',
                      onClick: () => {
                        handleOpenDeleteTipModal(tip.uuid)
                      },
                    },
                  ]}
                />
              ))}
            </TipsList>
            <StyledPagination
              pagination={pagination}
              onPageChange={handleChangePagination}
            />
          </>
        )}
      </ContentContainer>
    </ChnnlDashboardLayout>
  )
}

export default Tips
