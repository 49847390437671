import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import ProgressLoader from 'components/atoms/ProgressLoader'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledActions = styled.div`
  display: flex;
  justify-content: center;
`

export const ActionButton = styled(Button)`
  width: 50%;

  && {
    margin: 0 ${({ theme }) => theme.spacing.sm}px;
  }
`

export const ResendTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ResendMemberInvitationForm({
  className,
  onSubmit,
  onCancel,
  progress,
  contacts = [],
}) {
  const inProgress = progress.inProgress
  return (
    <div noValidate autoComplete="off" className={className}>
      <ResendTitle variant="h4">
        Are you sure you would like to resend an invitation to this group
        member:{' '}
        {contacts ? contacts.map((contact) => contact.value).join(' / ') : ''}
      </ResendTitle>
      <StyledActions>
        <ActionButton
          variant="outlined"
          type="button"
          size="small"
          color="primary"
          onClick={onCancel}>
          Cancel
        </ActionButton>
        <ActionButton
          variant="contained"
          size="small"
          color="primary"
          onClick={onSubmit}
          disabled={inProgress}>
          {inProgress ? <ProgressLoader size={18} /> : 'Confirm'}
        </ActionButton>
      </StyledActions>
    </div>
  )
}

ResendMemberInvitationForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  memberUuid: PropTypes.string,
  progress: PropTypes.object,
  organisation: PropTypes.object,
  contacts: PropTypes.arrayOf(PropTypes.object),
}

export default ResendMemberInvitationForm
