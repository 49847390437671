import axios from 'axios'
import { useState, useEffect, useCallback } from 'react'

import { SAUCE_API_URL } from '../config'
import { ReportErrorType } from '../constants/reports'
import { TeamVideo } from '../modules/teamVideo/schema'

export const useTeamVideos = ({
  orgUuid,
}: {
  orgUuid: string
}): {
  data: TeamVideo[]
  isLoading: boolean
  error?: ReportErrorType
  handlers: {
    save: (video: Partial<TeamVideo>) => Promise<void>
    remove: (videoUuid: string) => Promise<void>
  }
} => {
  const [data, setData] = useState<TeamVideo[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<ReportErrorType | undefined>(undefined)

  const fetchTeamVideos = useCallback(async () => {
    setIsLoading(true)
    setError(undefined)
    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${orgUuid}/video?includeNonCurrent=true`,
        {
          params: {},
        },
      )
      const data = response.data.data[0].output
      setData(data)
    } catch (error: unknown) {
      setError(ReportErrorType.GENERIC)
    }
    setIsLoading(false)
  }, [setData, setIsLoading, setError, orgUuid])

  useEffect(() => {
    fetchTeamVideos()
  }, [fetchTeamVideos])

  const save = async (video: Partial<TeamVideo>) => {
    setIsLoading(true)
    setError(undefined)
    video.duration = video.duration || 0
    video.scheduledAt = video.scheduledAt || new Date().toISOString()
    try {
      if (video.uuid) {
        await axios.put(
          `${SAUCE_API_URL}/organisation/${orgUuid}/video/${video.uuid}`,
          video,
        )
      } else {
        await axios.post(
          `${SAUCE_API_URL}/organisation/${orgUuid}/video/`,
          video,
        )
      }
      await fetchTeamVideos()
    } catch (error: unknown) {
      setError(ReportErrorType.GENERIC)
    }
    setIsLoading(false)
  }

  const remove = async (videoUuid: string) => {
    setIsLoading(true)
    setError(undefined)
    try {
      await axios.delete(
        `${SAUCE_API_URL}/organisation/${orgUuid}/video/${videoUuid}`,
        {},
      )
      await fetchTeamVideos()
    } catch (error: unknown) {
      setError(ReportErrorType.GENERIC)
    }
    setIsLoading(false)
  }

  return {
    data,
    isLoading,
    error,
    handlers: {
      save,
      remove,
    },
  }
}
