import { ROLE_SUPER_ADMIN, ROLE_PARTNER_ADMIN } from 'constants/form'

import { createSelector } from 'reselect'

// selectors
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const canEditGroup = (state, props) =>
  !!(
    state.user.canEditGroups &&
    state.user.assignedGroups &&
    state.user.assignedGroups.find((e) => e.uuid === props.uuid)
  )

export const isChnnlAdmin = createSelector(
  (state) => state.authentication.role,
  (role) => role === ROLE_SUPER_ADMIN,
)

export const hasEditRights = createSelector(
  (state) => state.authentication.role,
  (role) =>
    [ROLE_SUPER_ADMIN].includes(role) || [ROLE_PARTNER_ADMIN].includes(role),
)
