import { createSelector } from 'reselect'

// selectors

export const getOrganisationsLinkedToPartnerList = createSelector(
  [
    (state) => state.partnerOrganisations.entities,
    (state, partnerUuid) => partnerUuid,
  ],
  (partnerOrganisations, partnerUuid) =>
    Object.values(partnerOrganisations).filter(
      (org) => org.partner.uuid === partnerUuid,
    ),
)

export const getPartnerlessOrganisations = createSelector(
  [(state) => state.partners.entities, (state) => state.organisations.entities],
  (partners, organisations) => {
    const allOrgs = Object.values(organisations)

    const partneredOrgsUuids = Object.values(partners)
      .map((partner) => partner.linkedOrgs.map((linkedOrg) => linkedOrg.uuid))
      .flat()

    return allOrgs.filter((org) => !partneredOrgsUuids.includes(org.uuid))
  },
)

export const getPartnerOrganisationsLinkedToASinglePartner = createSelector(
  (state) => state.partnerOrganisations.entities,
  (partnerOrganisations) => Object.values(partnerOrganisations),
)
