import { TIP_TYPE_ALL } from 'constants/form'
import { PAGINATION_LIMIT } from 'constants/pagination'

import { TipFormValues } from 'components/organisms/TipForm/TipForm'
import { useCallback, useState, useEffect, useRef } from 'react'
import { useDebouncedCallback } from 'use-debounce'

interface IUseTips {
  sortOptions: Record<string, string>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFetchTips: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDeleteTip: (tipUuid: string) => void
  onEditTip: (tip: TipFormValues, tipUuid: string) => void
  onAddTip: (tip: TipFormValues) => void
  tipModalUuid: string | null
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useTips({
  sortOptions,
  onFetchTips,
  onEditTip,
  onAddTip,
  onDeleteTip,
  tipModalUuid,
}: IUseTips) {
  const [tipType, setTipType] = useState(TIP_TYPE_ALL)
  const [searchFilter, setSearchFilter] = useState('')
  const [pagination, setPagination] = useState({
    start: 0,
    count: 0,
    limit: PAGINATION_LIMIT,
  })
  const prevSearch = useRef(null)

  const handleChangeTipType = useCallback((e) => {
    setTipType(e.target.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeSearchFilter = useCallback((e) => {
    setSearchFilter(e.target.value)
    prevSearch.current = e.target.value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePagination = useCallback(
    (newPageIndex, pagination) => {
      setPagination({
        ...pagination,
        start: newPageIndex * PAGINATION_LIMIT,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination.start],
  )

  const handleAddSubmit = useCallback(
    async (formValues: TipFormValues) => {
      await onAddTip(formValues)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onAddTip],
  )

  const handleEditSubmit = useCallback(
    (formValues: TipFormValues) => {
      if (tipModalUuid) {
        onEditTip(formValues, tipModalUuid)
      }
    },

    [onEditTip, tipModalUuid],
  )

  const handleDeleteTip = useCallback(
    async () => {
      if (tipModalUuid) {
        await onDeleteTip(tipModalUuid)
        await fetchCallback()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tipModalUuid, onDeleteTip],
  )

  // Search for Tips and refetch when filters or pagination changes
  const fetchCallback = useCallback(async () => {
    const response = await onFetchTips({
      start: pagination.start,
      limit: PAGINATION_LIMIT,
      searchFilter,
      sortOptions,
      tipType,
    })
    if (response) {
      setPagination(response)
    }
  }, [onFetchTips, pagination.start, searchFilter, sortOptions, tipType])

  const debouncedFetch = useDebouncedCallback(fetchCallback, 350)

  // Use debounced fetch for search filter change.
  useEffect(
    () => {
      // Don't run this on initial mount.
      if (prevSearch.current !== null) {
        debouncedFetch()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchFilter],
  )

  // Don't debounce the search on button clicks
  useEffect(
    () => {
      fetchCallback()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination.start, tipType],
  )

  return {
    tipType,
    searchFilter,
    sortOptions,
    handleChangeSearchFilter,
    pagination,
    handleChangePagination,
    handleChangeTipType,
    handleDeleteTip,
    handleEditSubmit,
    handleAddSubmit,
  }
}
