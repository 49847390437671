import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import AppSurveyPreviewImage from 'assets/images/app-survey-preview.png'
import ProgressLoader from 'components/atoms/ProgressLoader'
import RowButton from 'components/atoms/RowButton'
import StatusTag from 'components/atoms/StatusTag'
import Box from 'components/molecules/Box'
import DragDropList from 'components/molecules/DragDropList'
import EditableText from 'components/molecules/EditableText'
import { Modal } from 'components/molecules/Modal'
import ConfirmUpdatesForm from 'components/organisms/ConfirmUpdatesForm'
import NavigationPromptForm from 'components/organisms/NavigationPromptForm'
import { deepEqual } from 'fast-equals'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import NavigationPrompt from 'react-router-navigation-prompt'
import styled from 'styled-components'

export const StyledForm = styled.form`
  && {
    text-align: left;
  }
`

export const StyledHeader = styled.header`
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export const HeaderLeft = styled.div`
  flex-grow: 1;
`

export const DescriptionText = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
  margin-top: ${({ theme }) => theme.spacing.sm}px;
`

export const ConfirmUpdatesModal = styled(Modal)`
  width: 480px;
`

export const UpdateButton = styled(Button)`
  width: 140px;
`

export const Section = styled.section`
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export const Items = styled.div`
  margin: ${({ theme }) => theme.spacing.md}px 0;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const Error = styled(StatusTag)`
  margin-top: ${({ theme }) => theme.spacing.md}px;
`

export const StyledDisplayTitle = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  }
`

export const StyledAppSurveyPreview = styled.img`
  height: 250px;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function SurveyQuestionsForm({
  className,
  disableEditing,
  errors,
  handleChange,
  handleSubmit,
  initialValues,
  isConfirmUpdateModalOpen,
  onAddDayType,
  onAddDepartment,
  onAddRole,
  onDeleteDayType,
  onDeleteDepartment,
  onDeleteRole,
  onDragEnd,
  progress,
  setIsConfirmUpdateModalOpen,
  touched,
  values,
}) {
  const handleCloseConfirmationModal = useCallback(
    () => setIsConfirmUpdateModalOpen(false),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  const handleTypeOfDayDragEnd = useCallback(
    (result) => onDragEnd(result, 'typesOfDays'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  const handleOrganisationDepartmentsDragEnd = useCallback(
    (result) => onDragEnd(result, 'organisationDepartments'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  const handleRolesDragEnd = useCallback(
    (result) => onDragEnd(result, 'roles'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  const hasUnsavedChanges = useMemo(
    () =>
      !deepEqual(values.typesOfDays, initialValues.typesOfDays) ||
      !deepEqual(
        values.organisationDepartments,
        initialValues.organisationDepartments,
      ) ||
      !deepEqual(values.roles, initialValues.roles),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      values.typesOfDays,
      initialValues.typesOfDays,
      values.organisationDepartments,
      initialValues.departments,
      values.roles,
      initialValues.roles,
    ],
  )

  return (
    <StyledForm
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      <NavigationPrompt when={hasUnsavedChanges}>
        {({ onConfirm, onCancel }) => (
          <Modal open>
            <NavigationPromptForm onLeave={onConfirm} onStay={onCancel} />
          </Modal>
        )}
      </NavigationPrompt>
      <ConfirmUpdatesModal
        open={isConfirmUpdateModalOpen}
        onClose={handleCloseConfirmationModal}>
        <ConfirmUpdatesForm
          onCancel={handleCloseConfirmationModal}
          onSubmit={handleSubmit}
          title="Confirm survey updates"
          content={
            <React.Fragment>
              All survey changes will be <br /> updates and visible in the app.
            </React.Fragment>
          }
        />
      </ConfirmUpdatesModal>

      <StyledHeader>
        <HeaderLeft>
          <Typography variant="h2">Survey questions</Typography>
          <DescriptionText variant="body1">
            Options for the initial questions of the survey
          </DescriptionText>
          <StyledAppSurveyPreview src={AppSurveyPreviewImage} />
        </HeaderLeft>
        {!disableEditing && (
          <UpdateButton
            variant="contained"
            type="submit"
            color="primary"
            size="small"
            disabled={!hasUnsavedChanges}>
            Update
          </UpdateButton>
        )}
      </StyledHeader>

      {progress.inProgress ? (
        <ProgressLoader fullWidth />
      ) : (
        <React.Fragment>
          <Section>
            <Typography variant="subtitle1">
              Types of days - “Today I have had a...”
            </Typography>
            <DescriptionText variant="body2">
              Type of work and leave days that your organisation provides
              employees.
            </DescriptionText>
            <DragDropList
              disabled={disableEditing}
              onDragEnd={handleTypeOfDayDragEnd}>
              {(provided) => (
                <Items>
                  {values.typesOfDays
                    ? values.typesOfDays.map((typeOfDay, typeOfDayIndex) => (
                        <Box
                          key={typeOfDay.uuid}
                          draggableId={typeOfDay.uuid}
                          draggableIndex={typeOfDayIndex}
                          isDraggable={!disableEditing}
                          isEditable={!disableEditing}
                          onDeleteClick={() => onDeleteDayType(typeOfDayIndex)}
                          errors={
                            touched.typesOfDays &&
                            errors.typesOfDays &&
                            touched.typesOfDays[typeOfDayIndex] &&
                            errors.typesOfDays[typeOfDayIndex]
                              ? Object.values(
                                  errors.typesOfDays[typeOfDayIndex],
                                )
                              : []
                          }
                          headerContent={
                            disableEditing ? (
                              <StyledDisplayTitle>
                                {typeOfDay.type}
                              </StyledDisplayTitle>
                            ) : (
                              <EditableText
                                value={typeOfDay.type}
                                name={`typesOfDays[${typeOfDayIndex}].type`}
                                onChange={handleChange}
                                fullWidth
                                placeholder="New type of day"
                              />
                            )
                          }
                        />
                      ))
                    : null}
                  {provided.placeholder}
                </Items>
              )}
            </DragDropList>
            {!disableEditing && (
              <RowButton onClick={onAddDayType}>+ Add day type</RowButton>
            )}
            {touched.typesOfDays && typeof errors.typesOfDays === 'string' ? (
              <Error themeColor="error" fullWidth>
                {errors.typesOfDays}
              </Error>
            ) : null}
          </Section>

          <Section>
            <Typography variant="subtitle1">
              Organisation departments - “Working in...”
            </Typography>
            <DescriptionText variant="body2">
              Departments of your organisation that your employees work in.
            </DescriptionText>
            <DragDropList onDragEnd={handleOrganisationDepartmentsDragEnd}>
              {(provided) => (
                <Items>
                  {values.organisationDepartments
                    ? values.organisationDepartments.map(
                        (department, departmentIndex) => (
                          <Box
                            key={department.uuid}
                            draggableId={department.uuid}
                            draggableIndex={departmentIndex}
                            isDraggable={!disableEditing}
                            isEditable={!disableEditing}
                            onDeleteClick={() =>
                              onDeleteDepartment(departmentIndex)
                            }
                            errors={
                              touched.organisationDepartments &&
                              errors.organisationDepartments &&
                              touched.organisationDepartments[
                                departmentIndex
                              ] &&
                              errors.organisationDepartments[departmentIndex]
                                ? Object.values(
                                    errors.organisationDepartments[
                                      departmentIndex
                                    ],
                                  )
                                : []
                            }
                            headerContent={
                              disableEditing ? (
                                <StyledDisplayTitle>
                                  {department.department}
                                </StyledDisplayTitle>
                              ) : (
                                <EditableText
                                  value={department.department}
                                  name={`organisationDepartments[${departmentIndex}].department`}
                                  onChange={handleChange}
                                  placeholder="New department"
                                  fullWidth
                                />
                              )
                            }
                          />
                        ),
                      )
                    : null}
                  {provided.placeholder}
                </Items>
              )}
            </DragDropList>
            {!disableEditing && (
              <RowButton onClick={onAddDepartment}>+Add department</RowButton>
            )}
            {touched.organisationDepartments &&
            typeof errors.organisationDepartments === 'string' ? (
              <Error themeColor="error" fullWidth>
                {errors.organisationDepartments}
              </Error>
            ) : null}
          </Section>

          <Section>
            <Typography variant="subtitle1">Organisation roles</Typography>
            <DescriptionText variant="body2">
              Workplace roles in your organisation.
            </DescriptionText>
            <DragDropList onDragEnd={handleRolesDragEnd}>
              {(provided) => (
                <Items>
                  {values.roles
                    ? values.roles.map((role, roleIndex) => (
                        <Box
                          key={role.uuid}
                          draggableId={role.uuid}
                          draggableIndex={roleIndex}
                          isDraggable={!disableEditing}
                          isEditable={!disableEditing}
                          onDeleteClick={() => onDeleteRole(roleIndex)}
                          errors={
                            touched.roles &&
                            errors.roles &&
                            touched.roles[roleIndex] &&
                            errors.roles[roleIndex]
                              ? Object.values(errors.roles[roleIndex])
                              : []
                          }
                          headerContent={
                            disableEditing ? (
                              <StyledDisplayTitle>
                                {role.title}
                              </StyledDisplayTitle>
                            ) : (
                              <EditableText
                                value={role.title}
                                name={`roles[${roleIndex}].title`}
                                onChange={handleChange}
                                placeholder="New role"
                                fullWidth
                              />
                            )
                          }
                        />
                      ))
                    : null}
                  {provided.placeholder}
                </Items>
              )}
            </DragDropList>
            {!disableEditing && (
              <RowButton onClick={onAddRole}>+Add role</RowButton>
            )}
            {touched.roles && typeof errors.roles === 'string' ? (
              <Error themeColor="error" fullWidth>
                {errors.roles}
              </Error>
            ) : null}
          </Section>
        </React.Fragment>
      )}
    </StyledForm>
  )
}

SurveyQuestionsForm.propTypes = {
  className: PropTypes.string,
  disableEditing: PropTypes.bool,
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  isConfirmUpdateModalOpen: PropTypes.bool,
  onAddDayType: PropTypes.func,
  onAddDepartment: PropTypes.func,
  onAddRole: PropTypes.func,
  onDeleteDayType: PropTypes.func,
  onDeleteDepartment: PropTypes.func,
  onDeleteRole: PropTypes.func,
  onDragEnd: PropTypes.func,
  progress: PropTypes.object,
  setIsConfirmUpdateModalOpen: PropTypes.func,
  touched: PropTypes.object,
  values: PropTypes.object,
}

SurveyQuestionsForm.defaultProps = {
  errors: {},
  initialValues: {},
  progress: {},
  touched: {},
  values: {},
}

export default SurveyQuestionsForm
