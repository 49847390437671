import { SupportTrigger } from '../modules/supportTrigger/schema'

import { SupportTriggerSeverity } from './SupportTriggerSeverityEnum'
import { SupportTriggerTypes } from './SupportTriggerTypesEnum'

export const emptyTriggerSet: {
  [key in SupportTriggerTypes]: SupportTrigger
} = {
  [SupportTriggerTypes.FIRST_SURVEY]: {
    type: SupportTriggerTypes.FIRST_SURVEY,
    severity: SupportTriggerSeverity.MEDIUM,
    options: [],
  },
  [SupportTriggerTypes.STANDARD_SURVEY]: {
    type: SupportTriggerTypes.STANDARD_SURVEY,
    severity: SupportTriggerSeverity.MEDIUM,
    options: [],
  },
  [SupportTriggerTypes.LOW_SURVEY]: {
    type: SupportTriggerTypes.LOW_SURVEY,
    severity: SupportTriggerSeverity.MEDIUM,
    options: [],
  },
  [SupportTriggerTypes.BULLYING_REFLECTION]: {
    type: SupportTriggerTypes.BULLYING_REFLECTION,
    severity: SupportTriggerSeverity.MEDIUM,
    options: [],
  },
}
