import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import { Typography } from '@weareroam/cake-ui-v1'
import urlParser from 'js-video-url-parser'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const StyledLink = styled.a`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;
`

const HelperSection = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.sm}px;
`

/*
 Credit to: https://stackoverflow.com/a/37770048 which shows a breakdown at each lines logic
 Formats seconds to M:SS
*/

function formatSecondsToMSS(s) {
  return (s - (s %= 60)) / 60 + (s > 9 ? ':' : ':0') + s
}

const HelperTextComponent = ({ helperText, videoMetaData }) => (
  <>
    {helperText && helperText.length && helperText}
    {!helperText && videoMetaData.hasStartTime && (
      <HelperSection>
        <Typography variant="body2">
          `Video has embedded start time at{' '}
          {formatSecondsToMSS(videoMetaData.params.start)}
        </Typography>
      </HelperSection>
    )}
    {!helperText && videoMetaData.hasPublicVimeoLink && (
      <HelperSection>
        <Typography variant="body2">
          If you have a Vimeo Pro account, you can optimise your video link
          using the instructions found{' '}
          <StyledLink
            href={
              'https://vimeo.zendesk.com/hc/en-us/articles/224823567-Direct-links-to-video-files'
            }
            target="_blank"
            rel="noopener noreferrer">
            here.
          </StyledLink>
        </Typography>
      </HelperSection>
    )}
  </>
)

HelperTextComponent.propTypes = {
  helperText: PropTypes.string,
  videoMetaData: PropTypes.object,
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const VideoUrlTextField = ({ helperText, ...props }) => {
  const videoMetaData = useMemo(() => {
    if (props.value) {
      const parsedUrl = urlParser.parse(props.value)
      const hasStartTime =
        parsedUrl && parsedUrl.params && parsedUrl.params.start

      const hasPublicVimeoLink =
        parsedUrl &&
        parsedUrl.provider === 'vimeo' &&
        props.value &&
        !props.value.includes('player.vimeo.com')

      return {
        ...parsedUrl,
        hasStartTime,
        hasPublicVimeoLink,
      }
    }
    return {}
  }, [props.value])

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="video-url">Video URL </InputLabel>
      <Input id="video-url" {...props} />
      <FormHelperText error={!!helperText}>
        <HelperTextComponent
          helperText={helperText}
          videoMetaData={videoMetaData}
        />
      </FormHelperText>
    </FormControl>
  )
}

VideoUrlTextField.propTypes = {
  helperText: PropTypes.string,
  value: PropTypes.string,
}

export default VideoUrlTextField
