import React from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function UncheckedRadio() {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16">
      <g
        id="Organisation-Settings"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="_Screens/Settings/Invite-group-admin"
          transform="translate(-249.000000, -496.000000)"
          fillRule="nonzero"
          stroke="#CCCCCC"
          strokeWidth="2">
          <g
            id="atom-/-Toggle-/-Radio-/-Off"
            transform="translate(249.000000, 496.000000)">
            <circle id="Oval" cx="8" cy="8" r="6.66666667" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default UncheckedRadio
