import { Switch, Typography } from '@weareroam/cake-ui-v1'
import ClipBoardTray from 'components/molecules/ClipBoardTray'
import PropTypes from 'prop-types'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'

const StyledSignupLinkCard = styled.div`
  && input {
    margin-top: ${({ theme }) => theme.spacing.sm}px;
  }
`

const StyledInfo = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

const StyledSignUpHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const SignupLink = ({
  link = '',
  onChange,
  getProgress,
  progress,
  deletionProgress,
}) => (
  <StyledSignupLinkCard>
    <StyledSignUpHeader>
      <Typography variant="h4">Self-Signup</Typography>
      <Switch
        color="primary"
        checked={!!link}
        onChange={onChange}
        disabled={!!link || progress.inProgress || deletionProgress.inProgress}
      />
    </StyledSignUpHeader>
    <StyledInfo variant="body1">
      If self-signup is activated anyone with this link can easliy join the
      group
    </StyledInfo>
    {progress.inProgress | getProgress.inProgress ? (
      <Skeleton />
    ) : (
      <ClipBoardTray value={link} disableUnderline hidden={!link} />
    )}
  </StyledSignupLinkCard>
)

SignupLink.propTypes = {
  link: PropTypes.string,
  onChange: PropTypes.func,
  getProgress: PropTypes.object,
  progress: PropTypes.object,
  deletionProgress: PropTypes.object,
}

export default SignupLink
