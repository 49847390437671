import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'constants/ui'

import axios from 'axios'
import { SAUCE_API_URL } from 'config'
import { addToast } from 'modules/toasts/actions'
import { normalize } from 'normalizr'
import { ApiBatchResult, ApiResult } from 'types/services'
import { getQueryParams, getPaginationResult, getArrayFilters } from 'utils/api'

import { PaginationParams, SearchCriteria } from '../../hooks/types'
import { DispatchProps } from '../../reducers/types'

import {
  InboxMessage,
  InboxMessageLinkUserInputs,
  InboxMessagePostBody,
} from './interfaces'
import { MessagingActionTypes } from './reducer'
import { messageListSchema } from './schema'

interface MessageGroupsProps {
  organisationUuid: string
  groupUuids: string[]
  title: string
  message: string
  links: InboxMessageLinkUserInputs[]
}

export function messageGroups({
  organisationUuid,
  groupUuids,
  title,
  message,
  links,
}: MessageGroupsProps) {
  return async function dispatcher(
    dispatch: (props: DispatchProps) => void,
  ): Promise<void> {
    dispatch({ type: MessagingActionTypes.API_SEND_MESSAGES_REQUEST })

    const payload: InboxMessagePostBody = groupUuids.map((groupUuid) => ({
      groupUuid,
      title,
      message,
      links,
    }))

    try {
      const response = await axios.post<ApiBatchResult<InboxMessage>>(
        `${SAUCE_API_URL}/organisation/${organisationUuid}/notification/batch`,
        payload,
      )
      const data = response.data.data

      const successfulMessages = data
        .filter(({ success }) => success)
        .map(({ entity }) => entity)

      if (!successfulMessages.length) {
        throw new Error('No messages sent were successful')
      }

      const normalizedMessages = normalize(
        successfulMessages,
        messageListSchema,
      )
      dispatch({
        type: MessagingActionTypes.API_SEND_MESSAGES_SUCCESS,
        payload: normalizedMessages,
      })

      // could be more useful and tell them what groups failed specifically, but this should suffice for now
      const failedResponsesCount = data.filter(({ success }) => !success).length

      if (failedResponsesCount > 0) {
        dispatch(
          addToast({
            message: `There was an error sending the message for ${failedResponsesCount} groups. Please try again`,
            type: TOAST_TYPE_ERROR,
          }),
        )
      }

      dispatch(
        addToast({
          message: 'Your message has been sent',
          type: TOAST_TYPE_SUCCESS,
        }),
      )
    } catch (error) {
      dispatch({ type: MessagingActionTypes.API_SEND_MESSAGES_ERROR, error })
      dispatch(
        addToast({
          message:
            'There was an error sending the message for these groups. Please try again',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}

interface GetMessageHistoryProps {
  organisationUuid: string
  groupUuid?: string
  pagination: PaginationParams
  searchFilters: SearchCriteria[]
}

export function getMessageHistory({
  organisationUuid,
  groupUuid,
  pagination,
  searchFilters,
}: GetMessageHistoryProps) {
  return async function dispatcher(
    dispatch: (props: DispatchProps) => void,
  ): Promise<void> {
    dispatch({ type: MessagingActionTypes.API_GET_MESSAGES_REQUEST })
    try {
      const queryComponents = [
        getQueryParams(pagination),
        'options=%7Bsort:%7BcreatedAt:DESC%7D%7D',
      ]
      if (searchFilters.length) {
        queryComponents.push(getArrayFilters(searchFilters[0]))
      }
      const query = queryComponents.join('&')

      const baseUrl = groupUuid
        ? `${SAUCE_API_URL}/organisation/${organisationUuid}/group/${groupUuid}/notification`
        : `${SAUCE_API_URL}/organisation/${organisationUuid}/notification`

      const response = await axios.get<ApiResult<InboxMessage[]>>(
        `${baseUrl}?${query}`,
      )

      const normalizedData = normalize(response.data.data, messageListSchema)
      const paginationResult = getPaginationResult(response.data)
      dispatch({
        type: MessagingActionTypes.API_GET_MESSAGES_SUCCESS,
        payload: {
          entities: normalizedData.entities,
          pagination: paginationResult,
        },
      })
    } catch (error) {
      dispatch({
        type: MessagingActionTypes.API_GET_MESSAGES_ERROR,
        payload: { error },
      })
      dispatch(
        addToast({
          message:
            'There was an error fetching your message history. Please try again.',
          type: TOAST_TYPE_ERROR,
        }),
      )
    }
  }
}
