import { ReportErrorType } from 'constants/reports'

import Box from 'components/molecules/Box'
import Tab from 'components/molecules/Tab'
import { ReflectionResponsesBarChart } from 'components/organisms/ReflectionResponsesBarChart'
import { ReflectionWellbeingImpactBarChart } from 'components/organisms/ReflectionWellbeingImpactBarChart'
import { ReflectionResponses } from 'hooks/useReflectionCountReport'
import { ReflectionsImpactReport } from 'hooks/useReflectionImpactReport'
import React, { useState } from 'react'
import Analytics from 'services/Analytics'
import styled from 'styled-components'

const Tabs = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`

const Reports = styled.div`
  padding: ${({ theme }) => theme.spacing.md}px;
`

interface Props {
  isGroupReport: boolean
  data: {
    responses?: ReflectionResponses
    impact?: ReflectionsImpactReport
  }
  loading: {
    responses: boolean
    impact: boolean
  }
  errors: {
    responses?: ReportErrorType
    impact?: ReportErrorType
  }
}

export function ReflectionsReport({
  isGroupReport,
  data,
  loading,
  errors,
}: Props): React.ReactElement {
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  return (
    <Box>
      <Tabs>
        <Tab
          activeText={`by number of responses`}
          isActive={activeTabIndex === 0}
          showActiveText
          onClick={() => {
            Analytics.trackEvent({
              category: isGroupReport ? 'Group Overview' : 'Org Overview',
              action: 'Clicked Tab',
              label: 'Reflection Responses View',
            })
            setActiveTabIndex(0)
          }}>
          Reflections
        </Tab>
        <Tab
          activeText={`by percentage impact`}
          showActiveText
          isActive={activeTabIndex === 1}
          onClick={() => {
            Analytics.trackEvent({
              category: isGroupReport ? 'Group Overview' : 'Org Overview',
              action: 'Clicked Tab',
              label: 'Wellbeing Impact View',
            })
            setActiveTabIndex(1)
          }}>
          Impact to Wellbeing
        </Tab>
      </Tabs>
      <Reports>
        {activeTabIndex === 0 && (
          <ReflectionResponsesBarChart
            data={data.responses}
            loading={loading.responses}
            error={errors.responses}
          />
        )}
        {activeTabIndex === 1 && (
          <ReflectionWellbeingImpactBarChart
            data={data.impact}
            loading={loading.impact}
            error={errors.impact}
          />
        )}
      </Reports>
    </Box>
  )
}
