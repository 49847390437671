import ContentContainer from 'components/atoms/ContentContainer'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import { MessagingLayout } from 'layouts/MessagingLayout'
import React, { ChangeEventHandler, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { HeaderButton } from '../../../../components/atoms/HeaderButton/HeaderButton'
import ProgressLoader from '../../../../components/atoms/ProgressLoader'
import SearchField from '../../../../components/atoms/SearchField'
import EmptyState from '../../../../components/molecules/EmptyState'
import Pagination from '../../../../components/molecules/Pagination'
import { TitleWithActions } from '../../../../components/molecules/TitleWithActions/TitleWithActions'
import { MessageFormModal } from '../../../../components/organisms/MessageFormModal/MessageFormModal'
import { MessagingListSection } from '../../../../components/organisms/MessagingListSection/MessagingListSection'
import { useEntitiesSearch } from '../../../../hooks/useEntitiesSearch'
import { useGroups } from '../../../../hooks/useGroups'
import { useMessages } from '../../../../hooks/useMessages'
import { OrganisationOrGroupRouteParams } from '../../../../types/routes'

// TODO: figure out how to type the below properly
// eslint-disable-next-line
export const StyledPagination = styled<any>(Pagination)`
  display: flex;
  justify-content: flex-end;
`

export function Messaging(): React.ReactElement {
  const { orgUuid, groupUuid } = useParams<OrganisationOrGroupRouteParams>()

  const {
    data: groups,
    isLoading: groupsLoading,
    error: groupsError,
  } = useGroups({
    orgUuid,
  })

  const {
    data: messages,
    isLoading: messagesLoading,
    error: messagesError,

    pagination,
    setPage,
    setSearch,

    sendMessage,
  } = useMessages({
    organisationUuid: orgUuid,
    groupUuid: groupUuid,
    initialFilters: [],
  })

  // TODO: This should be encapsulated by the SearchField component
  const {
    searchFilter: searchFieldValue,
    handleChangeSearchFilter: handleChangeSearch,
  } = useEntitiesSearch(groups)

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    handleChangeSearch(e)
    setSearch([
      {
        key: 'message',
        value: [e.target.value],
      },
    ])
  }

  const isInteractive = useMemo(
    () => !groupsLoading && !messagesLoading && !groupsError && !messagesError,
    [groupsError, groupsLoading, messagesError, messagesLoading],
  )

  const [isNewMessageModalOpen, setNewMessageModalOpen] = useState(false)
  const openNewMessageModal = () => setNewMessageModalOpen(true)
  const closeNewMessageModal = () => setNewMessageModalOpen(false)

  const renderContent = () => {
    if (groupsLoading || messagesLoading) {
      return <ProgressLoader fullWidth />
    }

    if (groupsError || messagesError) {
      return (
        <EmptyState
          title="Oh no! We’re not able to show this section right now."
          childComponent={
            <span>
              Something went wrong trying to load messages. We’ve been notified
              and will take a look but if the problem persists then let us know.
            </span>
          }
        />
      )
    }

    return (
      <>
        <MessagingListSection
          groups={groups}
          messages={messages}
          handlers={{
            startNew: openNewMessageModal,
          }}
        />
        <StyledPagination pagination={pagination} onPageChange={setPage} />
        <MessageFormModal
          open={isNewMessageModalOpen}
          editable={true}
          loading={false}
          availableGroups={groups}
          singleGroupOnly={!!groupUuid}
          inboxMessage={{
            title: 'Message from your organisation',
            message: '',
            groups: groupUuid ? [{ uuid: groupUuid, name: '_' }] : [],
            links: [],
          }}
          onCancel={closeNewMessageModal}
          onSubmit={(messageInput) => {
            sendMessage(messageInput)
            closeNewMessageModal()
            setPage(0) // re-fetch the main list
          }}
        />
      </>
    )
  }

  return (
    <AdminDashboardLayout noPadding>
      <MessagingLayout>
        <ContentContainer>
          <TitleWithActions
            title={'Messaging'}
            description={
              'Sending messages to your team promotes a sense of progress and empathy'
            }>
            {isInteractive ? (
              <SearchField
                placeholder="Search"
                value={searchFieldValue}
                name="search"
                onChange={onSearchChange}
              />
            ) : null}
            <HeaderButton
              title={'Send message'}
              type={'primary'}
              disabled={!isInteractive}
              onClick={openNewMessageModal}
            />
          </TitleWithActions>
          {renderContent()}
        </ContentContainer>
      </MessagingLayout>
    </AdminDashboardLayout>
  )
}
