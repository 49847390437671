import React from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function File(props) {
  return (
    <svg width="30px" height="30px" viewBox="0 0 30 30" {...props}>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="File-Upload-/-Progress-/-File-Complete"
          transform="translate(-5.000000, -16.000000)"
          fill="#3574FA">
          <g
            id="atom-/-File-Upload-/-Icon-/-Image"
            transform="translate(5.000000, 16.000000)">
            <rect
              id="Rectangle-4"
              fillOpacity="0.1"
              x="0"
              y="0"
              width="30"
              height="30"
              rx="2"
            />
            <g
              id="1814111-image-photograph"
              strokeWidth="1"
              transform="translate(6.000000, 9.000000)">
              <path
                d="M16.0897959,12.7947368 L1.57959184,12.7947368 C0.734693878,12.7947368 0.0367346939,12.1447368 0.0367346939,11.3578947 L0.0367346939,1.71052632 C0.0367346939,0.923684211 0.734693878,0.273684211 1.57959184,0.273684211 L16.0897959,0.273684211 C16.9346939,0.273684211 17.6326531,0.923684211 17.6326531,1.71052632 L17.6326531,11.3578947 C17.6326531,12.1447368 16.9346939,12.7947368 16.0897959,12.7947368 Z M1.54285714,1.19736842 C1.24897959,1.19736842 1.02857143,1.40263158 1.02857143,1.67631579 L1.02857143,11.3236842 C1.02857143,11.5973684 1.24897959,11.8026316 1.54285714,11.8026316 L16.0530612,11.8026316 C16.3469388,11.8026316 16.5673469,11.5973684 16.5673469,11.3236842 L16.5673469,1.67631579 C16.5673469,1.40263158 16.3469388,1.19736842 16.0530612,1.19736842 L1.54285714,1.19736842 Z"
                id="Fill-12"
                fillRule="nonzero"
              />
              <path
                d="M11.4244898,5.98684211 C10.2857143,5.98684211 9.40408163,5.13157895 9.40408163,4.10526316 C9.40408163,3.07894737 10.322449,2.22368421 11.4244898,2.22368421 C12.5265306,2.22368421 13.444898,3.07894737 13.444898,4.10526316 C13.444898,5.13157895 12.5265306,5.98684211 11.4244898,5.98684211 Z M11.4244898,3.07894737 C10.8367347,3.07894737 10.3591837,3.52368421 10.3591837,4.07105263 C10.3591837,4.61842105 10.8367347,5.06315789 11.4244898,5.06315789 C12.0122449,5.06315789 12.4897959,4.61842105 12.4897959,4.07105263 C12.4897959,3.52368421 12.0122449,3.07894737 11.4244898,3.07894737 Z"
                id="Fill-13"
                fillRule="nonzero"
              />
              <polyline
                id="Fill-14"
                fillRule="nonzero"
                points="14.1795918 12.6236842 5.69387755 5.2 0.844897959 9.37368421 0.183673469 8.72368421 5.69387755 3.96842105 14.8408163 11.9736842 14.1795918 12.6236842"
              />
              <polyline
                id="Fill-15"
                fillRule="nonzero"
                points="16.8244898 10.1605263 13.9959184 8.03947368 11.644898 9.85263158 11.0204082 9.13421053 13.9959184 6.87631579 17.4122449 9.47631579 16.8244898 10.1605263"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default File
