import { useAuth0 } from '@auth0/auth0-react'
import Typography from '@weareroam/cake-ui-v1/Typography'
import LoginForm from 'components/organisms/LoginForm'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import styled from 'styled-components'

const StyledContent = styled.div`
  text-align: center;
  width: 340px;
`

const StyledTitle = styled(Typography)`
  && {
    font-family: 'PP Telegraf', sans-serif;
    font-weight: 300;
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

const StyledParagraph = styled(Typography)`
  && {
    font-family: 'Graphik', sans-serif;
    font-weight: 300;
    letter-spacing: 0.8px;
    font-size: 16px;
    line-height: 24px;
    padding-top: ${({ theme }) => theme.spacing.xs}px;
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export const StyledForm = styled(LoginForm)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Login() {
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    const handleLogin = async () => {
      await loginWithRedirect({
        appState: {
          returnTo: '/profile',
        },
      })
    }

    handleLogin()
  }, [loginWithRedirect])

  return (
    <DefaultLayout>
      <StyledContent>
        <StyledTitle variant="h2">Logging in</StyledTitle>
        <StyledParagraph>You should be redirected soon</StyledParagraph>
      </StyledContent>
    </DefaultLayout>
  )
}

Login.propTypes = {
  onSubmit: PropTypes.func,
}

export default Login
