import { useState, useCallback } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useTipModals() {
  const [isDeleteTipModalOpen, setIsDeleteTipModalOpen] = useState(false)
  const [isEditTipModalOpen, setisEditTipModalOpen] = useState(false)
  const [isNewTipModalOpen, setNewTipModalOpen] = useState(false)
  const [tipModalUuid, setTipModalUuid] = useState<string | null>(null)

  const handleOpenDeleteTipModal = useCallback((uuid) => {
    setTipModalUuid(uuid)
    setIsDeleteTipModalOpen(true)
  }, [])

  const handleCloseDeleteTipModal = useCallback(() => {
    setIsDeleteTipModalOpen(false)
    setTipModalUuid(null)
  }, [])

  const handleOpenEditTipsModal = useCallback((uuid) => {
    setTipModalUuid(uuid)
    setisEditTipModalOpen(true)
  }, [])

  const handleCloseEditTipsModal = useCallback(() => {
    setisEditTipModalOpen(false)
    setTipModalUuid(null)
  }, [])

  const handleOpenNewTipModal = useCallback(() => {
    setTipModalUuid(null)
    setNewTipModalOpen(true)
  }, [])

  const handleCloseNewTipModal = useCallback(() => {
    setNewTipModalOpen(false)
    setTipModalUuid(null)
  }, [])

  return {
    tipModalUuid,
    isDeleteTipModalOpen,
    isEditTipModalOpen,
    isNewTipModalOpen,
    handleOpenDeleteTipModal,
    handleCloseDeleteTipModal,
    handleOpenEditTipsModal,
    handleCloseEditTipsModal,
    handleOpenNewTipModal,
    handleCloseNewTipModal,
  }
}
