import Typography from '@weareroam/cake-ui-v1/Typography'
import SignupForm from 'components/organisms/SignupForm'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import PageNotFound from 'views/PageNotFound'

export const StyledContent = styled.div`
  width: 350px;
  text-align: center;
`

export const StyledTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledText = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export const StyledForm = styled(SignupForm)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledTerms = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.tertiary.dark};
  }
`

export const StyledLink = styled.a`
  text-decoration: underline;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Signup({ onSubmit, token, hasExpired }) {
  if (!token) {
    // Don't show the page if they don't have a token
    return <PageNotFound />
  }

  if (hasExpired) {
    return (
      <DefaultLayout>
        <StyledContent>
          <StyledTitle variant="h2">Invitation has expired!</StyledTitle>
          <StyledText variant="body1">
            Please contact your organisation admin for another invitation.
          </StyledText>
        </StyledContent>
      </DefaultLayout>
    )
  }

  return (
    <DefaultLayout>
      <StyledContent>
        <StyledTitle variant="h2">Nice to meet you!</StyledTitle>
        <StyledText variant="body1">
          Enter your name and password to finish setting up your new account.
        </StyledText>
        <StyledForm onSubmit={onSubmit} />
        <StyledTerms variant="body1">
          By clicking create account you confirm that you have read and agreed
          to our{' '}
          <StyledLink href="https://chnnl.app/privacy" target="_blank">
            privacy policy
          </StyledLink>{' '}
          and{' '}
          <StyledLink href="https://chnnl.app/eula" target="_blank">
            terms and conditions
          </StyledLink>
          .
        </StyledTerms>
      </StyledContent>
    </DefaultLayout>
  )
}

Signup.propTypes = {
  onSubmit: PropTypes.func,
  token: PropTypes.string,
  hasExpired: PropTypes.bool,
}

export default Signup
