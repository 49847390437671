import axios from 'axios'
import { useState, useEffect, useCallback } from 'react'

import { SAUCE_API_URL } from '../config'
import { ReportErrorType } from '../constants/reports'
import { AppManager } from '../modules/appManager/schema'

export const useAppManagersAdmin = ({
  orgUuid,
}: {
  orgUuid: string
}): {
  data: AppManager[]
  isLoading: boolean
  error?: ReportErrorType
  handlers: {
    approve: (managerUuid: string) => Promise<void>
    remove: (managerUuid: string) => Promise<void>
  }
} => {
  const [data, setData] = useState<AppManager[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<ReportErrorType | undefined>(undefined)

  const fetchAppManagers = useCallback(async () => {
    setIsLoading(true)
    setError(undefined)
    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${orgUuid}/manager`,
        {
          params: {},
        },
      )
      const data = response.data.data[0].output
      setData(data)
    } catch (error: unknown) {
      setError(ReportErrorType.GENERIC)
    }
    setIsLoading(false)
  }, [setData, setIsLoading, setError, orgUuid])

  useEffect(() => {
    fetchAppManagers()
  }, [fetchAppManagers])

  const approve = async (managerUuid: string) => {
    setIsLoading(true)
    setError(undefined)
    try {
      await axios.put(`${SAUCE_API_URL}/manager/${managerUuid}`, {
        approved: true,
      })
      await fetchAppManagers()
    } catch (error: unknown) {
      setError(ReportErrorType.GENERIC)
    }
    setIsLoading(false)
  }

  const remove = async (managerUuid: string) => {
    setIsLoading(true)
    setError(undefined)
    try {
      await axios.delete(`${SAUCE_API_URL}/manager/${managerUuid}`, {})
      await fetchAppManagers()
    } catch (error: unknown) {
      setError(ReportErrorType.GENERIC)
    }
    setIsLoading(false)
  }

  return {
    data,
    isLoading,
    error,
    handlers: {
      approve,
      remove,
    },
  }
}
