import React, { useState } from 'react'

import { MOOD_OVERALL, Moods } from '../../../constants/form'
import { useReflectionCountReport } from '../../../hooks/useReflectionCountReport'
import { useReflectionImpactReport } from '../../../hooks/useReflectionImpactReport'
import { useWellbeingReport } from '../../../hooks/useWellbeingReport'
import { useWellbeingSixMonthsReport } from '../../../hooks/useWellbeingSixMonthsReport'
import { formatPeriod } from '../../../utils/date'
import { ReflectionsReport } from '../ReflectionsReport/ReflectionsReport'
import { WellbeingReport } from '../WellbeingReport/WellbeingReport'

interface Props {
  from: Date
  to: Date
  orgUuid: string
  groupUuid?: string
}

export const OverviewReports = ({
  groupUuid,
  orgUuid,
  from,
  to,
}: Props): React.ReactElement => {
  const periodDescription = formatPeriod(from, to)

  // We store mood selection here as it's shared between graphs
  const [mood, setMood] = useState<Moods>(MOOD_OVERALL)

  const {
    data: wellbeingBarData,
    isLoading: isWellbeingReportLoading,
    error: wellbeingReportError,
  } = useWellbeingReport({ from, to, orgUuid, groupUuid, mood })

  const {
    data: wellbeingLineData,
    isLoading: isWellbeingSixMonthsReportLoading,
    error: wellbeingSixMonthsReportError,
  } = useWellbeingSixMonthsReport({ orgUuid, groupUuid, mood })

  const {
    data: reflectionResponses,
    isLoading: isReflectionResponsesLoading,
    error: reflectionResponsesError,
  } = useReflectionCountReport({ from, to, orgUuid, groupUuid })

  const {
    data: reflectionsImpact,
    isLoading: isReflectionsImpactLoading,
    error: reflectionsImpactError,
  } = useReflectionImpactReport({ from, to, orgUuid, groupUuid })

  return (
    <React.Fragment>
      <WellbeingReport
        title={
          groupUuid ? "Your group's wellbeing" : "Your organisation's wellbeing"
        }
        periodDescription={periodDescription}
        data={{ period: wellbeingBarData, sixMonths: wellbeingLineData }}
        loading={{
          period: isWellbeingReportLoading,
          sixMonths: isWellbeingSixMonthsReportLoading,
        }}
        errors={{
          period: wellbeingReportError,
          sixMonths: wellbeingSixMonthsReportError,
        }}
        mood={mood}
        onChangeMood={setMood}
      />
      <ReflectionsReport
        isGroupReport={!!groupUuid}
        data={{ responses: reflectionResponses, impact: reflectionsImpact }}
        loading={{
          responses: isReflectionResponsesLoading,
          impact: isReflectionsImpactLoading,
        }}
        errors={{
          responses: reflectionResponsesError,
          impact: reflectionsImpactError,
        }}
      />
    </React.Fragment>
  )
}
