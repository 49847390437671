import ProgressLoader from 'components/atoms/ProgressLoader'
import { Formik } from 'formik'
import { useOrganisation } from 'hooks/useOrganisation'
import React from 'react'
import * as Yup from 'yup'
import { ObjectSchema } from 'yup'

import { AutoReplyFormUI } from './AutoReplyFormUI'

export interface AutoReplyFormProps {
  orgUuid: string
}

export interface AutoReplyFormValues {
  autoReply: boolean
  autoReplyText?: string
}

export function AutoReplyForm({
  orgUuid,
}: AutoReplyFormProps): React.ReactElement {
  const { data, isLoading, saveAutoReply } = useOrganisation({ orgUuid })

  const validationSchema: ObjectSchema<AutoReplyFormValues> = Yup.object({
    autoReply: Yup.boolean().required(),
    autoReplyText: Yup.string().when('autoReply', {
      is: true,
      then: (schema) =>
        schema
          .required('Please enter your reply')
          .max(320, 'Please keep your message within the character limit'),
    }),
  })

  if (isLoading) {
    return <ProgressLoader fullWidth />
  }

  return (
    <Formik<AutoReplyFormValues>
      initialValues={{
        autoReply: data?.autoReply || false,
        autoReplyText: data?.autoReplyText || '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        await saveAutoReply({
          ...values,
        })
        setSubmitting(false)
      }}>
      {(props) => <AutoReplyFormUI {...props} />}
    </Formik>
  )
}
