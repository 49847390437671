import * as partnerActions from 'modules/partners/actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, mapProps, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  withRouter,
  mapProps((props) => ({
    ...props,
    partnerUuid: props.match.params.partnerUuid,
  })),
  connect(
    // mapStateToProps
    (state, { match }) => ({
      partner: state.partners.entities[match.params.partnerUuid],
      getPartnerProgress: state.progress.getPartner,
    }),
    (dispatch) => {
      const actions = {
        ...partnerActions,
      }
      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  lifecycle({
    componentDidMount() {
      const { actions, match } = this.props

      actions.getPartner(match.params.partnerUuid)
    },
  }),
)
