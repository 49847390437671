import { FormikProps, withFormik } from 'formik'
import { Theme } from 'hooks/useThemes'
import { compose, defaultProps } from 'recompose'
import { isSupportedProviderUrl } from 'utils/validation'
import * as Yup from 'yup'

export interface InnerFormProps {
  className: string
  showEscalationOption: boolean
  hasError: boolean
  inEditMode: boolean
  themes: Theme[]
  onSubmit: (values: FormValues) => void
  onCancel: () => void
  initialValues: {
    uuid?: string
    heading?: string
    subHeading?: string
    email?: string
    contact?: string
    extension?: string
    website?: string
    videoUrl?: string
    description?: string
    escalationOption?: boolean
    createdAt?: string
    updatedAt?: string
    deletedAt?: string
    themes?: Theme[]
    icon?: string
    showIntermediateScreen?: boolean
    intermediateScreenDescription?: string
  }
}

export interface FormValues {
  uuid?: string
  heading: string
  subHeading: string
  email: string
  contact: string
  extension: string
  website: string
  videoUrl: string | undefined
  description: string
  escalationOption: boolean
  createdAt: string
  updatedAt: string
  deletedAt: string
  themes: Partial<Theme>[]
  bullyingThemeAssociated: boolean
  icon: string
  showIntermediateScreen: boolean
  intermediateScreenDescription: string
}

export default compose<
  InnerFormProps & FormikProps<FormValues>,
  InnerFormProps
>(
  defaultProps({
    initialValues: {},
  }),
  withFormik<InnerFormProps, FormValues>({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }) => ({
      subHeading: initialValues.subHeading || '',
      heading: initialValues.heading || '',
      email: initialValues.email || '',
      contact: initialValues.contact || '',
      extension: initialValues.extension || '',
      website: initialValues.website || '',
      videoUrl: initialValues.videoUrl || undefined,
      description: initialValues.description || '',
      escalationOption: initialValues.escalationOption || false,
      createdAt: initialValues.createdAt || '',
      updatedAt: initialValues.updatedAt || '',
      deletedAt: initialValues.deletedAt || '',
      bullyingThemeAssociated: !!initialValues.themes?.find(
        (theme) => theme.key === 'BULLIED_OR_HARASSED',
      ),
      themes: initialValues.themes || [],
      ...(initialValues.uuid ? { uuid: initialValues.uuid } : {}),
      icon: initialValues.icon || '',
      showIntermediateScreen: initialValues.showIntermediateScreen || false,
      intermediateScreenDescription:
        initialValues.intermediateScreenDescription || '',
    }),
    validationSchema: Yup.object({
      subHeading: Yup.string().required('Please enter a subheading'),
      heading: Yup.string().required('Please enter a heading'),
      email: Yup.string().email('Please enter a valid email'),
      website: Yup.string().url('Please enter a valid URL'),
      videoUrl: Yup.string()
        .test(
          'isSupportedProviderUrl',
          'Must be a valid Youtube or Vimeo URL',
          isSupportedProviderUrl,
        )
        .nullable(),
    }),
    handleSubmit: (values, { props: { onSubmit, themes }, setSubmitting }) => {
      setSubmitting(true)
      const payload = toggleBullyingThemeOnPayload(values, themes)
      onSubmit(payload)
    },
  }),
)

export const toggleBullyingThemeOnPayload = (
  values: FormValues,
  themes: Theme[],
): FormValues => {
  const bullyingTheme = themes.find(
    (theme) => theme.key === 'BULLIED_OR_HARASSED',
  )
  if (!bullyingTheme) return values

  return {
    ...values,
    ...(values.bullyingThemeAssociated
      ? {
          themes: [bullyingTheme],
        }
      : { themes: [] }),
  }
}
