import {
  ROLE_SUPER_ADMIN,
  ROLE_ORG_OWNER,
  ROLE_PARTNER_ADMIN,
} from 'constants/form'
import {
  GROUP_DASHBOARD_WELLBEING,
  GROUP_DASHBOARD_BOOSTERS,
  GROUP_DASHBOARD_MEMBERS,
  GROUP_DASHBOARD_MESSAGING,
  GROUP_DASHBOARD_SETTINGS,
  ORG_DASHBOARD_GROUPS,
} from 'constants/routes'

import Typography from '@weareroam/cake-ui-v1/Typography'
import ChevronRight from '@weareroam/cake-ui-v1-icons/ChevronRight'
import authorisedRoles from 'components/hoc/authorisedRoles'
import NavItem from 'components/molecules/NavItem'
import Sidebar from 'components/molecules/Sidebar'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { replaceRouteParams } from 'utils/routes'

export const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  flex-grow: 1;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 125vw;
    height: 65px;
    transform: translateX(-25vw);
    background-color: ${({ theme }) => theme.palette.secondary.main};
    border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  }
`

export const Main = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: stretch;
`

export const StyledContent = styled.div`
  padding: ${({ theme }) => theme.spacing.xl}px
    ${({ theme }) => theme.spacing.lg}px;
  overflow: hidden;
  flex-grow: 1;
  width: calc(100% - 180px);
`

export const Breadcrumbs = styled.nav`
  background: ${({ theme }) => theme.palette.secondary.main};
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  height: 65px;
  padding: ${({ theme }) => theme.spacing.md}px 0
    ${({ theme }) => theme.spacing.md}px ${({ theme }) => theme.spacing.lg}px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`

export const Breadcrumb = styled(Link)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
  font-size: 14px;
`

export const StyledChevron = styled(ChevronRight)`
  margin: 0 ${({ theme }) => theme.spacing.sm}px;
`

export const CurrentPage = styled(Typography)`
  && {
    font-weight: ${({ theme }) => theme.weights.regular};
  }
`

// Prevent group admins from accessing the members tab
const ProtectedNavItem = authorisedRoles([
  ROLE_SUPER_ADMIN,
  ROLE_ORG_OWNER,
  ROLE_PARTNER_ADMIN,
])(NavItem)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function GroupInnerLayout({
  children,
  orgUuid,
  groupUuid,
  groupName,
  hasEditAccess,
}) {
  return (
    <StyledLayout>
      <Breadcrumbs>
        <Breadcrumb
          to={replaceRouteParams(ORG_DASHBOARD_GROUPS, {
            orgUuid,
          })}>
          Groups
        </Breadcrumb>
        <StyledChevron />
        <CurrentPage variant="subtitle1">{groupName}</CurrentPage>
      </Breadcrumbs>
      <Main>
        <Sidebar>
          <NavItem
            direction="vertical"
            to={replaceRouteParams(GROUP_DASHBOARD_WELLBEING, {
              groupUuid,
              orgUuid,
            })}
            text="Wellbeing"
          />
          <NavItem
            direction="vertical"
            to={replaceRouteParams(GROUP_DASHBOARD_BOOSTERS, {
              groupUuid,
              orgUuid,
            })}
            text="Wellbeing Resources"
          />
          {hasEditAccess && (
            <NavItem
              direction="vertical"
              to={replaceRouteParams(GROUP_DASHBOARD_MESSAGING, {
                groupUuid,
                orgUuid,
              })}
              text="Messaging"
            />
          )}
          <ProtectedNavItem
            direction="vertical"
            to={replaceRouteParams(GROUP_DASHBOARD_MEMBERS, {
              groupUuid,
              orgUuid,
            })}
            text="Members"
          />
          <NavItem
            direction="vertical"
            to={replaceRouteParams(GROUP_DASHBOARD_SETTINGS, {
              groupUuid,
              orgUuid,
            })}
            text="Settings"
          />
        </Sidebar>
        <StyledContent>{children}</StyledContent>
      </Main>
    </StyledLayout>
  )
}

GroupInnerLayout.propTypes = {
  children: PropTypes.node,
  orgUuid: PropTypes.string,
  groupUuid: PropTypes.string,
  groupName: PropTypes.string,
  hasEditAccess: PropTypes.bool,
}

export default GroupInnerLayout
