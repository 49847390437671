import * as authActions from 'modules/authentication/actions'
import { hasEditRights } from 'modules/authentication/selectors'
import * as boosterActions from 'modules/boosters/actions'
import { getOrganisationBoostersList } from 'modules/boosters/selectors'
import { connect } from 'react-redux'
import { compose, withHandlers, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  connect(
    // mapStateToProps
    (state, { match }) => ({
      boosterCategories: getOrganisationBoostersList(
        state,
        match.params.orgUuid,
      ),
      hasEditAccess: state.user.canEdit || hasEditRights(state),
      getBoostersProgress: state.progress.getOrganisationBoosters,
      updateBoostersProgress: state.progress.updateOrganisationBoosters,
    }),
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...boosterActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  lifecycle({
    componentDidMount() {
      const { actions, match } = this.props

      actions.getOrganisationBoosters(match.params.orgUuid)
    },
  }),
  withHandlers({
    onSubmitBoosters:
      ({ actions, match }) =>
      ({ boosterCategories }) => {
        // previously API was allowing URLs to be empty strings, no longer shall we allow that, starting with videoUrl
        // it will take some gold coins to make the form behave that way too
        const boosterCategoriesSanitized = boosterCategories.map((category) => {
          const boosters = category.boosters.map((booster) =>
            Object.fromEntries(
              Object.entries(booster).filter(([key, v]) => {
                if (key !== 'videoUrl') return true
                if (key === 'videoUrl' && !!v) return true
                return false
              }),
            ),
          )
          return {
            ...category,
            boosters,
          }
        })

        actions.updateOrganisationBoosters({
          orgUuid: match.params.orgUuid,
          boosters: boosterCategoriesSanitized,
        })
      },
  }),
)
