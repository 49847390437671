import * as authActions from 'modules/authentication/actions'
import * as organisationsActions from 'modules/organisations/actions'
import * as partnerOrganisationsActions from 'modules/partnerOrganisations/actions'
import { getPartnerOrganisationsLinkedToASinglePartner } from 'modules/partnerOrganisations/selectors'
import * as partnersActions from 'modules/partners/actions'
import { connect } from 'react-redux'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  connect(
    (state, { match }) => ({
      partnerOrganisations:
        getPartnerOrganisationsLinkedToASinglePartner(state),
      partnerOrganisationEntities: state.partnerOrganisations.entities,
      getPartnerOrganisationProgress: state.progress.partnerOrganisations,
      partner: state.partners.entities[match.params.partnerUuid],
    }),
    (dispatch) => {
      const actions = {
        ...authActions,
        ...partnerOrganisationsActions,
        ...organisationsActions,
        ...partnersActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  withState(
    'isNewOrganisationModalOpen',
    'setIsNewOrganisationModalOpen',
    false,
  ),
  withState(
    'isUnlinkOrganisationFromPartnerModalOpen',
    'setIsUnlinkOrganisationFromPartnerModalOpen',
    false,
  ),
  withState(
    'isAddNewOrganisationToPartnerModalOpen',
    'setIsAddNewOrganisationToPartnerModalOpen',
    false,
  ),
  withState(
    'modalPartnerOrganisationId',
    'setModalPartnerOrganisationId',
    null,
  ),
  lifecycle({
    componentDidMount() {
      const { match } = this.props
      this.props.actions.getPartnerOrganisations(match.params.partnerUuid)
      this.props.actions.getPartner(match.params.partnerUuid)
    },
  }),
  withHandlers({
    onCreateNewPartnerOrganisation:
      ({ actions, match, setIsAddNewOrganisationToPartnerModalOpen }) =>
      ({
        name,
        email,
        incidentsContactName,
        incidentsContactEmail,
        numberOfSeats,
        industry,
        country,
      }) => {
        actions.createOrganisation({
          name,
          orgOwnerEmail: email,
          incidentsContactName,
          incidentsContactEmail,
          seats: numberOfSeats,
          industry,
          country,
          partnerUuid: match.params.partnerUuid,
        })

        setIsAddNewOrganisationToPartnerModalOpen(false)
        actions.getPartnerOrganisations(match.params.partnerUuid)
      },
    onUnlinkOrganisationFromPartner:
      ({
        actions,
        match,
        modalPartnerOrganisationId,
        setIsUnlinkOrganisationFromPartnerModalOpen,
      }) =>
      (e) => {
        e.preventDefault()
        actions.unlinkOrganisationFromPartner(
          match.params.partnerUuid,
          modalPartnerOrganisationId,
        )
        setIsUnlinkOrganisationFromPartnerModalOpen(false)
      },
  }),
)
