import { Checkbox, FormControlLabel } from '@weareroam/cake-ui-v1'
import { Field } from 'formik'
import React from 'react'
import styled from 'styled-components'

const StyledCheckbox = styled(TransformedCheckbox)`
  fill: currentColor;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  // margin-left: 0;
`

interface ILabelledCheckboxProps {
  name: string
  label: string
  disabled: boolean
}

// TODO: Figure out how to approach the typings for wrapped Cake-UI components
// eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-explicit-any
function TransformedCheckbox(props: any) {
  return (
    <Checkbox
      {...props}
      checked={props.value}
      value={props.value.toString()} // Material UI checkbox expects a string
      color="primary"
    />
  )
}

// Formik enabled component to be reused across forms
export function LabelledCheckbox({
  name,
  label,
  disabled,
}: ILabelledCheckboxProps): React.ReactElement {
  return (
    <StyledFormControlLabel
      control={<Field name={name} as={StyledCheckbox} disabled={disabled} />}
      label={label}
    />
  )
}
