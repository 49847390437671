import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IOrg } from '../models/Organisation'
import {
  getOrganisation,
  updateAutoreply,
} from '../modules/organisations/actions'

interface IUseOrganisation {
  data?: IOrg
  isLoading: boolean
  saveAutoReply: (data: AutoReplyData) => void
}

interface AutoReplyData {
  autoReply: boolean
  autoReplyText?: string
}

export function useOrganisation({
  orgUuid,
}: {
  orgUuid: string
}): IUseOrganisation {
  const dispatch = useDispatch()

  const orgData = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => state.organisations.entities[orgUuid],
  )

  const isLoading = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => state.progress.getOrganisation?.inProgress,
  )

  const fetchData = useCallback(
    ({ orgUuid }: { orgUuid: string }) => {
      dispatch(getOrganisation(orgUuid))
    },
    [dispatch],
  )

  const saveAutoReply = useCallback(
    async ({ autoReply, autoReplyText }: AutoReplyData) => {
      await dispatch(
        updateAutoreply({
          uuid: orgUuid,
          autoReply,
          autoReplyText,
        }),
      )
    },
    [dispatch, orgUuid],
  )

  useEffect(() => {
    fetchData({ orgUuid })
  }, [orgUuid, fetchData])

  return {
    data: orgData,
    isLoading,
    saveAutoReply,
  }
}
