import Typography from '@weareroam/cake-ui-v1/Typography'
import NewPasswordForm from 'components/organisms/NewPasswordForm'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledContent = styled.div`
  text-align: center;
  width: 330px;
`

export const StyledTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

const StyledMessage = styled.p`
  padding: 30px;
  max-width: 600px;
  text-align: center;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, react/prop-types
export function NewPassword({ onSubmit, resetPasswordSuccess = false }) {
  return (
    <DefaultLayout>
      <StyledContent>
        {resetPasswordSuccess ? (
          <>
            <StyledTitle variant="h5">Well done!</StyledTitle>
            <StyledMessage>
              Open the app to login with your new password!
            </StyledMessage>
          </>
        ) : (
          <>
            <StyledTitle variant="h5">New password</StyledTitle>
            <NewPasswordForm onSubmit={onSubmit} />
          </>
        )}
      </StyledContent>
    </DefaultLayout>
  )
}

NewPassword.propTypes = {
  onSubmit: PropTypes.func,
}

export default NewPassword
