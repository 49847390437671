import Typography from '@weareroam/cake-ui-v1/Typography'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import React from 'react'
import styled from 'styled-components'

export const StyledContent = styled.div`
  text-align: center;
`

export const StyledTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export const StyledText = styled(Typography)`
  && {
    margin: ${({ theme }) => theme.spacing.lg}px;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function SignUpLinkExpired() {
  return (
    <DefaultLayout>
      <StyledContent>
        <StyledTitle variant="h2">
          Sorry, self-signup is currently not enabled.
        </StyledTitle>
        <StyledText variant="p">
          Please contact the chnnl administrator in your organization to be
          invited or to activate self-signup.
        </StyledText>
      </StyledContent>
    </DefaultLayout>
  )
}

export default SignUpLinkExpired
