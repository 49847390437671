import { createSelector } from 'reselect'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ENTITLEMENT_SPEAK_UP = 'speak_up'

export const getEntitlementsListForOrg = createSelector(
  (state, props) =>
    props.orgUuid && state.entitlements.administration[props.orgUuid]
      ? state.entitlements.administration[props.orgUuid]
      : {},
  (entitlements) => Object.values(entitlements),
)

export const hasEntitlementEnabled = createSelector(
  [getEntitlementsListForOrg, (state, props) => props],
  (entitlements, props) =>
    entitlements.filter((item) => item.name === props.name && item.isEnabled)
      .length > 0,
)
