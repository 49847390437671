import * as authActions from 'modules/authentication/actions'
import { hasEditRights } from 'modules/authentication/selectors'
import * as dayParametersActions from 'modules/dayParameters/actions'
import { getTypesOfDaysList } from 'modules/dayParameters/selectors'
import * as reflectionActions from 'modules/reflections/actions'
import { getReflectionsList } from 'modules/reflections/selectors'
import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  connect(
    // mapStateToProps
    (state) => ({
      reflections: getReflectionsList(state),
      typesOfDays: getTypesOfDaysList(state),
      getReflectionsProgress: state.progress.getReflections,
      updateReflectionsProgress: state.progress.updateReflections,
      hasEditAccess: state.user.canEdit || hasEditRights(state),
    }),
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...dayParametersActions,
        ...reflectionActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  lifecycle({
    componentDidMount() {
      const {
        actions,
        match: {
          params: { orgUuid },
        },
      } = this.props

      actions.getReflections(orgUuid)
      actions.getDayParameters(orgUuid)
    },
  }),
  withHandlers({
    onCreateReflection:
      ({ actions, match }) =>
      ({ reflection }) => {
        actions.createReflection({
          orgUuid: match.params.orgUuid,
          reflection,
        })
      },
    onUpdateReflection:
      ({ actions, match }) =>
      ({ reflectionUuid, reflection }) => {
        actions.updateReflection({
          orgUuid: match.params.orgUuid,
          reflectionUuid,
          reflection,
        })
      },
    onDeleteReflection:
      ({ actions, match }) =>
      ({ reflectionUuid }) => {
        actions.deleteReflection({
          orgUuid: match.params.orgUuid,
          reflectionUuid,
        })
      },
  }),
)
