import Typography from '@weareroam/cake-ui-v1/Typography'
import InfoIcon from '@weareroam/cake-ui-v1-icons/InfoRounded'
import React from 'react'
import styled from 'styled-components'

export const HeaderInfoContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: start;
  margin-left: ${({ theme }) => theme.spacing.md}px;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  padding: ${({ theme }) => theme.spacing.md}px
    ${({ theme }) => theme.spacing.md + theme.spacing.sm}px;
  background: ${({ theme }) => theme.palette.primary.light};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.tertiary.light};
`

export const HeaderInfoTextContainer = styled.div`
  flex-direction: row;
`

const IconContainer = styled.div`
  margin-right: ${({ theme }) => theme.spacing.md}px;
`

export const Description = styled(Typography)`
  color: black;
  margin-left: ${({ theme }) => theme.spacing.md}px;
`

export const DescriptionHeader = styled(Typography)`
  margin-left: ${({ theme }) => theme.spacing.md}px;
  color: black;
  font-weight: ${({ theme }) => theme.weights.bold} !important;
`

export const ChartExplainerHeader = ({
  title,
  textSections,
}: {
  title: string
  textSections: string[]
}): React.ReactElement => (
  <HeaderInfoContainer>
    <IconContainer>
      <InfoIcon fontSize="large" color="primary" />
    </IconContainer>
    <HeaderInfoTextContainer>
      <DescriptionHeader variant="body2">{title}</DescriptionHeader>
      <Description variant="body2">
        {React.Children.map(textSections, (section, i) =>
          i ? (
            <>
              <br />
              <br />
              {section}
            </>
          ) : (
            section
          ),
        )}
      </Description>
    </HeaderInfoTextContainer>
  </HeaderInfoContainer>
)
