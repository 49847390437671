import { hasEditRights } from 'modules/authentication/selectors'
import * as entitlementsActions from 'modules/entitlements/actions'
import { getEntitlementsListForOrg } from 'modules/entitlements/selectors'
import { addUndo } from 'modules/undo/actions'
import { connect } from 'react-redux'
import { compose, withHandlers, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  connect(
    // mapStateToProps
    (state, { match }) => ({
      hasEditAccess: state.user.canEdit || hasEditRights(state),
      entitlements: getEntitlementsListForOrg(state, {
        orgUuid: match.params.orgUuid,
      }),
      getEntitlementsProgress: state.progress.getEntitlements,
    }),
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...entitlementsActions,
        addUndo,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  lifecycle({
    componentDidMount() {
      const { actions, match } = this.props
      actions.getEntitlements({
        orgUuid: match.params.orgUuid,
      })
    },
  }),
  withHandlers({
    handleUpdateEntitlement:
      ({ actions, match }) =>
      async ({ entitlementUuid, isEnabled }) => {
        await actions.updateEntitlement({
          orgUuid: match.params.orgUuid,
          entitlementUuid,
          isEnabled,
        })
        // add undo, it's tricky as the undo needs to be a remote request
        // so if we add the action in updateEntitlement it would create spaghetti code
        actions.addUndo({
          callback: () => {
            actions.updateEntitlement({
              orgUuid: match.params.orgUuid,
              entitlementUuid,
              isEnabled: !isEnabled,
            })
          },
        })
      },
    handleResetEntitlement:
      ({ actions, match }) =>
      async () => {
        await actions.resetEntitlements({
          orgUuid: match.params.orgUuid,
        })

        await actions.getEntitlements({
          orgUuid: match.params.orgUuid,
        })
      },
  }),
)
