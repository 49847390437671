import Typography from '@weareroam/cake-ui-v1/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledTab = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.palette.secondary.main : theme.palette.background.main};
  padding: ${({ theme }) => theme.spacing.md}px;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: ${({ isActive }) => (isActive ? 'auto' : 'pointer')};
  border: ${({ theme, isActive }) =>
    !isActive ? `1px solid ${theme.palette.tertiary.light}` : ''};
`

export const ActiveText = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Tab({
  children,
  activeText,
  isActive,
  onClick,
  showActiveText,
}) {
  return (
    <StyledTab isActive={isActive} onClick={onClick}>
      <Typography variant="h4">{children}</Typography>
      {(isActive || showActiveText) && activeText && (
        <ActiveText variant="body1">{activeText}</ActiveText>
      )}
    </StyledTab>
  )
}

Tab.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
  activeText: PropTypes.string,
  onClick: PropTypes.func,
  showActiveText: PropTypes.bool,
}

export default Tab
