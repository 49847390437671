import React from 'react'

// eslint-disable-next-line react/display-name,react/prop-types
export const DeleteCircle = React.memo<{ color?: string }>(({ color }) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="14" cy="14" r="13.5" stroke={color || '#828797'} />
    <path
      d="M14.809 13.9993L18.9106 9.11021C18.9793 9.02896 18.9215 8.90552 18.8153 8.90552H17.5684C17.4949 8.90552 17.4246 8.93833 17.3762 8.99458L13.9934 13.0274L10.6106 8.99458C10.5637 8.93833 10.4934 8.90552 10.4184 8.90552H9.17151C9.06526 8.90552 9.00745 9.02896 9.0762 9.11021L13.1778 13.9993L9.0762 18.8883C9.0608 18.9064 9.05092 18.9286 9.04773 18.9521C9.04454 18.9757 9.04818 18.9997 9.05822 19.0212C9.06826 19.0428 9.08426 19.061 9.10435 19.0737C9.12443 19.0865 9.14774 19.0931 9.17151 19.093H10.4184C10.4918 19.093 10.5621 19.0602 10.6106 19.004L13.9934 14.9711L17.3762 19.004C17.4231 19.0602 17.4934 19.093 17.5684 19.093H18.8153C18.9215 19.093 18.9793 18.9696 18.9106 18.8883L14.809 13.9993Z"
      fill={color || '#828797'}
    />
  </svg>
))
