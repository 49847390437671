import { Typography } from '@weareroam/cake-ui-v1'
import Button from '@weareroam/cake-ui-v1/Button'
import { Theme } from '@weareroam/cake-ui-v1/styles/createMuiTheme'
import styled from 'styled-components'

export const ModalFormTitleV2 = styled(Typography)`
  && {
    margin: ${({ theme }) => theme.spacing.sm}px 0
      ${({ theme }) => theme.spacing.mdlg}px 0;
  }
`

export const ModalFormBodyV2 = styled.div`
  display: flex;
  flex-direction: column;
`

export const ModalFormFieldsV2 = styled.fieldset<{
  theme: Theme
  scrollable?: boolean
}>`
  // Need a bit of padding for input and error labels
  padding-top: ${({ theme }) => theme.spacing.sm}px;
  padding-bottom: ${({ theme }) => theme.spacing.mdlg}px;

  display: flex;
  flex-direction: column;

  overflow-y: ${(props) => (props.scrollable ? 'auto' : 'hidden')};
  overflow-x: hidden;

  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.mdlg}px !important;
  }
`

// TODO: display grid?
export const ModalFormActionsV2 = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;

  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.xl}px;
  }
`

export const ModalFormActionButtonV2 = styled(Button)`
  width: 157px;

  && {
    padding: 6px;
    font-size: 0.9rem;
    background: ${(props) =>
      props.variant === 'flat' ? 'rgba(41, 133, 252, 0.1)' : ''};
  }
`
