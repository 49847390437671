import { RequestProgressState } from 'modules/progress'
import { RootState } from 'modules/RootState'
import { actions } from 'modules/tips/actions'
import { Tip } from 'modules/tips/schema'
import { getTipsList } from 'modules/tips/selectors'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'

export interface TipEnhancerProps {
  actions: {
    getTips: typeof actions.getTips
    addTip: typeof actions.addTip
    deleteTip: typeof actions.deleteTip
    updateTip: typeof actions.updateTip
  }
  tips: Tip[]
  getTipsProgress: RequestProgressState
}

export default compose<TipEnhancerProps, TipEnhancerProps>(
  connect(
    // mapStateToProps
    (state: RootState) => ({
      tips: getTipsList(state),
      getTipsProgress: state.progress.getTips,
    }),
    // mapDispatchToProps
    (dispatch) => ({
      actions: bindActionCreators(actions, dispatch),
    }),
  ),
)
