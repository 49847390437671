import Button from '@weareroam/cake-ui-v1/Button'
import TextField from '@weareroam/cake-ui-v1/TextField'
import Typography from '@weareroam/cake-ui-v1/Typography'
import Autosuggest from 'components/molecules/Autosuggest'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styled from 'styled-components'

export const StyledName = styled(TextField)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.xl}px;
  }
`
export const StyledFields = styled.fieldset`
  margin: ${({ theme }) => theme.spacing.md}px 0
    ${({ theme }) => theme.spacing.xl}px 0;
`

export const Actions = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`

export const ActionButton = styled(Button)`
  width: 50%;

  && {
    margin: 0 ${({ theme }) => theme.spacing.sm}px;
  }
`

export const Note = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function NewGroupForm({
  handleSubmit,
  className,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  admins,
  onAddChip,
  onDeleteChip,
  onCancel,
  setFieldValue,
}) {
  const handleOnAutosuggestChange = useCallback((e, update) => {
    if (update && update.newValue) {
      if (update.method === 'click') {
        onAddChip(update.newValue)
      } else {
        setFieldValue('textInput', update.newValue)
      }
    } else {
      handleChange(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      <Typography variant="h2">Create group</Typography>
      <StyledFields>
        <StyledName
          id="name"
          name="name"
          label="Group name"
          type="text"
          value={values.name}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.name && !!errors.name}
          helperText={touched.name ? errors.name : ''}
          fullWidth
        />

        <Autosuggest
          variant="chip"
          value={values.textInput}
          chips={values.admins}
          onDeleteChip={onDeleteChip}
          onAddChip={onAddChip}
          error={touched.admins && !!errors.admins}
          helperText={touched.admins ? errors.admins : ''}
          onChange={handleOnAutosuggestChange}
          placeholder="Group admins. Enter names or email addresses..."
          name="textInput"
          onBlur={handleBlur}
          suggestions={admins}
          noneSelectedHelperText="This group does not exist"
        />
      </StyledFields>

      <Actions>
        <ActionButton
          size="small"
          variant="outlined"
          color="primary"
          onClick={onCancel}>
          Cancel
        </ActionButton>
        <ActionButton
          size="small"
          variant="contained"
          type="submit"
          color="primary">
          Create
        </ActionButton>
      </Actions>

      <Note variant="body2">
        Group admins you have invited from here will be able to edit their
        group, if you wish to change their permissions, you can do so in the
        organisation’s settings.
      </Note>
    </form>
  )
}

NewGroupForm.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  admins: PropTypes.arrayOf(PropTypes.object),
  onAddChip: PropTypes.func,
  onDeleteChip: PropTypes.func,
  onCancel: PropTypes.func,
  setFieldValue: PropTypes.func,
}

NewGroupForm.defaultProps = {
  errors: {},
  values: {},
  touched: {},
  admins: [],
}

export default NewGroupForm
