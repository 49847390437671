import {
  ModalFormActionButton,
  ModalFormActions,
  ModalFormBody,
  ModalFormFields,
  ModalFormTextField,
  ModalFormTitle,
} from 'components/atoms/ModalForm/ModalForm'
import { LabelledTextarea } from 'components/molecules/Forms/LabelledTextarea/LabelledTextarea'
import {
  ModalTab,
  TabbedModal,
} from 'components/molecules/TabbedModal/TabbedModal'
import { format } from 'date-fns'
import { useFormik } from 'formik'
import urlParser from 'js-video-url-parser'
import { TeamVideo } from 'modules/teamVideo/schema'
import React, { useCallback, useEffect, useState } from 'react'
import { useAsyncCallback } from 'react-async-hook'
import styled from 'styled-components'
import * as Yup from 'yup'

import { DateInputValue, TimeInputValue } from '../../../constants/date'
import VimeoController from '../../../services/VimeoController'

const StyledTabbedModal = styled(TabbedModal)`
  width: 662px;
  min-height: 580px;
`

const FormBody = styled(ModalFormBody)`
  height: 484px;
`

interface Props {
  open: boolean
  isNew?: boolean
  videoUpdate: Partial<TeamVideo>
  loading?: boolean
  onCancel: () => void
  onSubmit: (video: Partial<TeamVideo>) => void
}

export const VideoUpdateModal = ({
  open,
  isNew,
  videoUpdate,
  loading,
  onCancel,
  onSubmit,
}: Props): React.ReactElement => {
  const [activeTab, setActiveTab] = useState('details')

  const descriptionMaxLength = 150

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    setFieldValue,
    setFieldTouched,
  } = useFormik<
    Partial<TeamVideo> & { scheduledDate?: string; scheduledTime?: string }
  >({
    initialValues: {
      ...videoUpdate,
      ...(videoUpdate.scheduledAt
        ? {
            scheduledDate: format(videoUpdate.scheduledAt, DateInputValue),
            scheduledTime: format(videoUpdate.scheduledAt, TimeInputValue),
          }
        : undefined),
    },
    validationSchema: Yup.object({
      url: Yup.string().required('Please enter the url'),
      title: Yup.string().required('Please enter the title'),
      description: Yup.string().max(
        descriptionMaxLength,
        'Please keep description within the character limit',
      ),
    }),
    onSubmit: (values) => {
      if (values.scheduledDate && values.scheduledTime) {
        // Need separate date parts into constructor params
        //   so the new date will be in the user's timezone
        //   as opposed to new Date('2000-01-01') will be in UTC
        const year = +values.scheduledDate.substr(0, 4)
        const month = +values.scheduledDate.substr(5, 2) - 1 // JS is weird...
        const day = +values.scheduledDate.substr(8, 2)
        const hours = +values.scheduledTime.substr(0, 2)
        const minutes = +values.scheduledTime.substr(3, 2)
        values.scheduledAt = new Date(
          year,
          month,
          day,
          hours,
          minutes,
        ).toISOString()
      } else {
        values.scheduledAt = undefined
      }
      onSubmit(values)
    },
  })

  const asyncVimeoVideoInfo = useAsyncCallback(
    VimeoController.getVimeoVideoInfo,
  )

  const onUrlBlur = useCallback(() => {
    const url = values.url
    if (!url) return
    const videoMetaData = urlParser.parse(url)
    if (videoMetaData?.provider === 'vimeo') {
      asyncVimeoVideoInfo.execute(videoMetaData.id)
    }
  }, [asyncVimeoVideoInfo, values.url])

  useEffect(() => {
    if (asyncVimeoVideoInfo.result) {
      setFieldValue('title', asyncVimeoVideoInfo.result.title, true)
      setFieldValue(
        'description',
        asyncVimeoVideoInfo.result.description.replace(/<br \/>/g, ''),
        true,
      )
      setFieldValue('duration', asyncVimeoVideoInfo.result.duration)
      // Hack to trigger validation errors https://github.com/jaredpalmer/formik/issues/2059
      setTimeout(() => {
        setFieldTouched('title', true)
        setFieldTouched('description', true)
        setFieldTouched('duration', true)
      })
    }
  }, [asyncVimeoVideoInfo.result, setFieldTouched, setFieldValue])

  const tabs: Array<ModalTab> = [
    {
      id: 'details',
      title: 'Details',
      contents: (
        <FormBody>
          <ModalFormTitle variant="h2">
            {isNew ? 'Add new' : 'Edit'} video
          </ModalFormTitle>
          <ModalFormFields>
            <ModalFormTextField
              id="url"
              name="url"
              label="Video link"
              type="text"
              value={values.url}
              fullWidth
              required
              error={touched.url && !!errors.url}
              helperText={touched.url ? errors.url : ''}
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur(e)
                onUrlBlur()
              }}
            />
            <ModalFormTextField
              id="title"
              name="title"
              label="Video title"
              type="text"
              value={values.title}
              fullWidth
              required
              error={touched.title && !!errors.title}
              helperText={touched.title ? errors.title : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <LabelledTextarea
              id="description"
              name="description"
              label="Video description"
              disabled={false}
              required
              rows={3}
              maxLength={descriptionMaxLength}
              value={values.description}
              error={touched.description && !!errors.description}
              helperText={touched.description ? errors.description : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </ModalFormFields>
          <ModalFormActions>
            <ModalFormActionButton
              size="small"
              variant="contained"
              type="submit"
              color="primary"
              disabled={!isValid}
              onClick={() => setActiveTab('schedule')}>
              Next Step
            </ModalFormActionButton>
          </ModalFormActions>
        </FormBody>
      ),
    },
    {
      id: 'schedule',
      title: 'Scheduling',
      contents: (
        <FormBody>
          <ModalFormTitle variant="h2">Schedule release?</ModalFormTitle>
          <ModalFormFields>
            <ModalFormTextField
              id="scheduledDate"
              name="scheduledDate"
              label="Date"
              type="date"
              value={values.scheduledDate}
              error={touched.scheduledDate && !!errors.scheduledDate}
              helperText={touched.scheduledDate ? errors.scheduledDate : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <ModalFormTextField
              id="scheduledTime"
              name="scheduledTime"
              label="Time"
              type="time"
              value={values.scheduledTime}
              error={touched.scheduledTime && !!errors.scheduledTime}
              helperText={touched.scheduledTime ? errors.scheduledTime : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </ModalFormFields>
          <ModalFormActions>
            <ModalFormActionButton
              size="small"
              variant="flat"
              type="submit"
              color="primary"
              onClick={() => setActiveTab('details')}
              disabled={loading}>
              Go Back
            </ModalFormActionButton>
            <ModalFormActionButton
              size="small"
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading || !isValid}
              onClick={() => handleSubmit()}>
              {values.scheduledDate && values.scheduledTime
                ? 'Save'
                : 'Post Now'}
            </ModalFormActionButton>
          </ModalFormActions>
        </FormBody>
      ),
    },
  ]

  return (
    <StyledTabbedModal
      open={open}
      maxWidth={'md'}
      tabs={tabs}
      activeTab={activeTab}
      handleTabClick={setActiveTab}
      hasCloseButton={true}
      closeButtonDisabled={loading}
      onClose={onCancel}
    />
  )
}
