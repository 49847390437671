import ContentContainer from 'components/atoms/ContentContainer'
import BoostersForm from 'components/organisms/BoostersForm'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import AppLayout from 'layouts/AppLayout'
import PropTypes from 'prop-types'
import React from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Boosters({
  hasEditAccess,
  onSubmitBoosters,
  boosterCategories,
  getBoostersProgress,
  updateBoostersProgress,
}) {
  return (
    <AdminDashboardLayout noPadding>
      <AppLayout>
        <ContentContainer>
          <BoostersForm
            onSubmit={onSubmitBoosters}
            disableEditting={!hasEditAccess}
            boosterCategories={boosterCategories}
            progress={getBoostersProgress}
            updateProgress={updateBoostersProgress}
            description={
              <React.Fragment>
                These wellbeing resources are organisation-wide, group-specific{' '}
                <br /> wellbeing resources can be managed in the group section.
              </React.Fragment>
            }
          />
        </ContentContainer>
      </AppLayout>
    </AdminDashboardLayout>
  )
}

Boosters.propTypes = {
  hasEditAccess: PropTypes.bool,
  onSubmitBoosters: PropTypes.func,
  boosterCategories: PropTypes.arrayOf(PropTypes.object),
  getBoostersProgress: PropTypes.object,
  updateBoostersProgress: PropTypes.object,
}

export default Boosters
