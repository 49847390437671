import {
  ROLE_SUPER_ADMIN,
  ROLE_ORG_OWNER,
  ROLE_ORG_ADMIN,
  ROLE_PARTNER_ADMIN,
} from 'constants/form'
import {
  ORG_DASHBOARD_OVERVIEW,
  ORG_DASHBOARD_GROUPS,
  ORG_DASHBOARD_JOURNALS,
  ORG_DASHBOARD_APP,
  ORG_DASHBOARD_SETTINGS,
  ORG_DASHBOARD_MESSAGING,
} from 'constants/routes'

import Button from '@weareroam/cake-ui-v1/Button'
import Menu from '@weareroam/cake-ui-v1/Menu'
import Typography from '@weareroam/cake-ui-v1/Typography'
import ExpandMore from '@weareroam/cake-ui-v1-icons/ExpandMore'
import authorisedRoles from 'components/hoc/authorisedRoles'
import InviteSuccessModal from 'components/molecules/InviteSuccessModal'
import MenuItem from 'components/molecules/MenuItem'
import { Modal } from 'components/molecules/Modal'
import NavItem from 'components/molecules/NavItem'
import InviteMembersForm from 'components/organisms/InviteMembersForm'
import PropTypes from 'prop-types'
import React, { useRef, useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { replaceRouteParams } from 'utils/routes'

export const StyledOrgAdminHeader = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  background-color: ${({ theme }) => theme.palette.secondary.main};
  padding: ${({ theme }) => theme.spacing.xl}px
    ${({ theme }) => theme.spacing.lg}px 0;
`

export const StyledContent = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
`

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
`

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
`

export const StyledTitle = styled(Typography)`
  && {
    font-weight: ${({ theme }) => theme.weights.regular};
  }
`

export const StyledSubTitle = styled(Typography)`
  && {
    font-weight: ${({ theme }) => theme.weights.ultra};
  }
`

export const StyledLogoTitle = styled.div`
  display: inline-flex;
  align-items: center;
`

export const StyledOrgDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledNav = styled.nav`
  align-self: stretch;
  flex-grow: 1;
  display: flex;

  > * {
    margin: 0 ${({ theme }) => theme.spacing.sm}px;
  }
`

export const StyledInviteModal = styled(Modal)`
  width: 600px;
  && {
    max-height: 700px;
    padding-left: ${({ theme }) => theme.spacing.md}px;
    padding-right: ${({ theme }) => theme.spacing.md}px;
    padding-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export const StyledSuccessModal = styled(InviteSuccessModal)`
  width: 500px;
`

export const StyledLogo = styled.img`
  max-height: 50px;
  margin-right: ${({ theme }) => theme.spacing.md}px;
`

// Group admins should only have access to the groups and settings tab
const ProtectedNavItem = authorisedRoles([
  ROLE_SUPER_ADMIN,
  ROLE_ORG_OWNER,
  ROLE_ORG_ADMIN,
  ROLE_PARTNER_ADMIN,
])(NavItem)

const ProtectedAppNavItem = authorisedRoles([
  ROLE_SUPER_ADMIN,
  ROLE_ORG_OWNER,
  ROLE_ORG_ADMIN,
  ROLE_PARTNER_ADMIN,
])(NavItem)

// Only users with edit access can invite members
const ProtectedInviteButton = authorisedRoles(
  [ROLE_SUPER_ADMIN, ROLE_ORG_OWNER, ROLE_ORG_ADMIN, ROLE_PARTNER_ADMIN],
  true,
)(Button)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function OrgAdminHeader({
  logoSrc,
  organisationName,
  organisationInviteToken,
  setIsInviteMembersModalOpen,
  isInviteMembersModalOpen,
  orgUuid,
  groups,
  onInviteMembersSubmit,
  seatsRemaining,
  isInvitationSuccessModalOpen,
  setIsInvitationSuccessModalOpen,
  numInvitationsSent,
  onLogout,
  name,
  inviteProgress,
  actions,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const menuRef = useRef(null)
  const handleToggleMenu = useCallback(() => {
    setIsMenuOpen(!isMenuOpen)
  }, [isMenuOpen])

  useEffect(
    () => {
      if (inviteProgress.success) {
        setIsInviteMembersModalOpen(false)
        setIsInvitationSuccessModalOpen(true)
        actions.inviteMembersToGroupReset()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inviteProgress.success],
  )

  // On Dismount reset
  useEffect(
    () => () => {
      actions.inviteMembersToGroupReset()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <StyledOrgAdminHeader>
      <StyledInviteModal
        open={isInviteMembersModalOpen}
        hasCloseButton
        onClose={() => {
          setIsInviteMembersModalOpen(false)
          actions.inviteMembersToGroupReset()
        }}>
        <InviteMembersForm
          onSubmit={onInviteMembersSubmit}
          groups={groups}
          progress={inviteProgress}
          onCancel={() => setIsInviteMembersModalOpen(false)}
          seatsRemaining={seatsRemaining}
        />
      </StyledInviteModal>
      <StyledSuccessModal
        open={isInvitationSuccessModalOpen}
        hasCloseButton
        onClose={() => setIsInvitationSuccessModalOpen(false)}
        numInvitationsSent={numInvitationsSent}
      />
      <StyledContent>
        <Top>
          <StyledLogoTitle>
            {logoSrc && <StyledLogo src={logoSrc} alt="" />}
            <StyledOrgDetailContainer>
              <span>
                <StyledTitle variant="h5">{organisationName}</StyledTitle>
                {organisationInviteToken && (
                  <StyledSubTitle variant="body1">
                    Invite Code: {organisationInviteToken}
                  </StyledSubTitle>
                )}
              </span>
            </StyledOrgDetailContainer>
          </StyledLogoTitle>
          <Button
            buttonRef={menuRef}
            type="button"
            variant="text"
            color="inherit"
            onClick={handleToggleMenu}>
            {name} <ExpandMore />
          </Button>
          <Menu
            open={isMenuOpen}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            getContentAnchorEl={null}
            anchorEl={menuRef.current}
            onClose={handleToggleMenu}>
            <MenuItem onClick={onLogout}>Logout</MenuItem>
          </Menu>
        </Top>
        <Bottom>
          <StyledNav>
            <ProtectedNavItem
              text="Overview"
              to={replaceRouteParams(ORG_DASHBOARD_OVERVIEW, { orgUuid })}
            />
            <NavItem
              text="Groups"
              to={replaceRouteParams(ORG_DASHBOARD_GROUPS, { orgUuid })}
            />
            <ProtectedNavItem
              text="Journals"
              to={replaceRouteParams(ORG_DASHBOARD_JOURNALS, { orgUuid })}
            />
            <ProtectedAppNavItem
              text="App"
              to={replaceRouteParams(ORG_DASHBOARD_APP, { orgUuid })}
            />
            <ProtectedNavItem
              text="Messaging"
              to={replaceRouteParams(ORG_DASHBOARD_MESSAGING, {
                orgUuid,
              })}
            />
            <NavItem
              text="Settings"
              to={replaceRouteParams(ORG_DASHBOARD_SETTINGS, { orgUuid })}
            />
          </StyledNav>
          <ProtectedInviteButton
            type="button"
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => setIsInviteMembersModalOpen(true)}>
            Invite members
          </ProtectedInviteButton>
        </Bottom>
      </StyledContent>
    </StyledOrgAdminHeader>
  )
}

OrgAdminHeader.propTypes = {
  logoSrc: PropTypes.string,
  organisationName: PropTypes.string,
  organisationInviteToken: PropTypes.string,
  setIsInviteMembersModalOpen: PropTypes.func,
  isInviteMembersModalOpen: PropTypes.bool,
  orgUuid: PropTypes.string,
  groups: PropTypes.arrayOf(PropTypes.object),
  onInviteMembersSubmit: PropTypes.func,
  seatsRemaining: PropTypes.number,
  failedInvites: PropTypes.arrayOf(PropTypes.object),
  successfulInvites: PropTypes.arrayOf(PropTypes.object),
  setFailedInvites: PropTypes.func,
  setSuccessfulInvites: PropTypes.func,
  isInvitationSuccessModalOpen: PropTypes.bool,
  setIsInvitationSuccessModalOpen: PropTypes.func,
  numInvitationsSent: PropTypes.number,
  onLogout: PropTypes.func,
  name: PropTypes.string,
  inviteProgress: PropTypes.object,
  actions: PropTypes.object,
}

OrgAdminHeader.defaultProps = {
  groups: [],
}

export default OrgAdminHeader
