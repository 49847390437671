import idx from 'idx'
import * as authActions from 'modules/authentication/actions'
import * as groupActions from 'modules/groups/actions'
import * as signUpActions from 'modules/groupSignUpLink/actions'
import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  connect(
    // mapStateToProps\
    (state, { match }) => {
      const { orgUuid, groupUuid } = match.params
      return {
        orgUuid,
        groupUuid,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        group: idx(state, (_) => _.groups.selected) || {},
        groupSignUpLink: state.groupSignUpLinks[groupUuid],
        getSignUpLinkProgress: state.progress.getSignUpLink,
        createSignUpLinkProgress: state.progress.createSignUpLink,
        archiveSignUpLinkProgress: state.progress.archiveSignUpLink,
      }
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...groupActions,
        ...signUpActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  withHandlers({
    createUrl:
      ({ actions, groupUuid, orgUuid }) =>
      () => {
        actions.createGroupSignUpLink({ groupUuid, orgUuid })
      },
    archiveUrl:
      ({ actions, groupUuid, orgUuid, groupSignUpLink }) =>
      () => {
        if (groupSignUpLink) {
          return actions.archiveGroupSignUpLink({
            groupUuid,
            orgUuid,
            uuid: groupSignUpLink.uuid,
          })
        }
      },
  }),
  lifecycle({
    componentDidMount() {
      const { actions, groupUuid, orgUuid } = this.props
      actions.getGroupMembers(orgUuid, groupUuid)
      actions.getGroupSignUpLink({ orgUuid, groupUuid })
    },
  }),
)
