import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledActions = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;

  && {
    > * {
      margin: 0 ${({ theme }) => theme.spacing.sm}px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

export const StyledTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledText = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
    color: ${({ theme }) => theme.palette.tertiary.dark};
  }
`

export const StyledFooterText = styled(Typography)`
  && {
    margin-top: ${({ theme }) => theme.spacing.md}px;
    color: ${({ theme }) => theme.palette.tertiary.main};
    transform: translateY(12px);
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ConfirmUpdatesForm({
  className,
  onSubmit,
  onCancel,
  title,
  content,
  footerText,
  confirmText,
  cancelText,
}) {
  return (
    <form
      onSubmit={onSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      <StyledTitle variant="h4">{title}</StyledTitle>
      <StyledText variant="body1">{content}</StyledText>
      <StyledActions>
        <Button
          variant="outlined"
          type="button"
          size="small"
          color="primary"
          onClick={onCancel}>
          {cancelText}
        </Button>
        <Button variant="contained" type="submit" size="small" color="primary">
          {confirmText}
        </Button>
      </StyledActions>
      {footerText && (
        <StyledFooterText variant="body2">{footerText}</StyledFooterText>
      )}
    </form>
  )
}

ConfirmUpdatesForm.defaultProps = {
  confirmText: 'Yes, update',
  cancelText: 'No, cancel',
}

ConfirmUpdatesForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  content: PropTypes.node,
  title: PropTypes.string,
  footerText: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
}

export default ConfirmUpdatesForm
