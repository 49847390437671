import { Box, TextField, Typography } from '@mui/material'
import Checkbox from '@weareroam/cake-ui-v1/Checkbox'
import FormControlLabel from '@weareroam/cake-ui-v1/FormControlLabel/FormControlLabel'
import LegacyTypography from '@weareroam/cake-ui-v1/Typography'
import { TabbedModalV2 } from 'components/molecules/TabbedModalV2/TabbedModalV2'
import { useFormik } from 'formik'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import { Group } from '../../../modules/groups/schema'
import {
  InboxMessageUserInputs,
  InboxMessageValidation,
} from '../../../modules/messaging/interfaces'
import {
  ModalFormActionButtonV2,
  ModalFormActionsV2,
  ModalFormBodyV2,
  ModalFormFieldsV2,
  ModalFormTitleV2,
} from '../../atoms/ModalForm/ModalFormV2'
import { ModalTab } from '../../molecules/TabbedModal/TabbedModal'
import { MessageLinksForm } from '../MessageLinksForm/MessageLinksForm'

const StyledTabbedModal = styled(TabbedModalV2)`
  width: 662px;
  min-height: 580px;
`

const FormBody = styled(ModalFormBodyV2)`
  height: 532px;
`

// TODO: Unify with Notice Update Modal
const GroupCheckbox = styled(FormControlLabel).attrs({
  classes: {
    label: 'checkbox-label-class',
  },
})`
  width: 100%;
  font-weight: inherit;
  && {
    &:not(:last-child) {
      margin-bottom: 0px;
      border-bottom: 1px solid #adb9cb;
    }
  }

  .checkbox-label-class {
    font-weight: inherit;
    white-space: nowrap;
  }
`

interface MessageFormProps {
  open: boolean
  editable: boolean
  loading: boolean
  availableGroups: Group[]
  // Don't show the group selection (useful for group admins with only one group)
  singleGroupOnly: boolean
  inboxMessage: InboxMessageUserInputs
  onCancel: () => void
  onSubmit: (inboxMessage: InboxMessageUserInputs) => void
}

export const MessageFormModal = ({
  open,
  editable,
  loading,
  availableGroups,
  singleGroupOnly,
  inboxMessage,
  onCancel,
  onSubmit,
}: MessageFormProps): React.ReactElement => {
  const [activeTab, setActiveTab] = useState('details')
  const [linkNotAttached, setLinkNotAttached] = useState(false)

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    setFieldTouched,
    setFieldValue,
  } = useFormik<InboxMessageUserInputs>({
    initialValues: {
      ...inboxMessage,
    },
    validationSchema: InboxMessageValidation,
    onSubmit: (values) => {
      onSubmit(values)
    },
  })

  const toggleAGroup = useCallback(
    (group: Group) => {
      // Touched validator takes the value before the update, but runs async after
      // validation for setFieldValue. Hence disabling validation here.
      setFieldTouched('groups', true, false)
      if (
        values.groups.find((selectedGroup) => selectedGroup.uuid === group.uuid)
      ) {
        setFieldValue(
          'groups',
          values.groups.filter(
            (selectedGroup) => selectedGroup.uuid !== group.uuid,
          ),
          true,
        )
      } else {
        setFieldValue('groups', [group, ...values.groups], true)
      }
    },
    [setFieldTouched, setFieldValue, values.groups],
  )

  const tabs: Array<ModalTab> = [
    {
      id: 'details',
      title: 'Details',
      contents: (
        <FormBody>
          <Box m={0.5} />
          <LegacyTypography variant="h1" align="left">
            {editable ? 'Send a' : 'View'} message
          </LegacyTypography>
          <Typography
            variant="body1"
            align="left"
            color="text.disabled"
            marginY={2.5}>
            You can send messages straight to the inbox of employees to update
            them on organisation wide changes, positive messages, goal setting
            and more
          </Typography>
          <ModalFormFieldsV2 scrollable>
            <TextField
              id="title"
              name="title"
              label="Message title"
              type="text"
              value={values.title}
              disabled={!editable}
              fullWidth
              size="small"
              required
              error={touched.title && !!errors.title}
              helperText={touched.title ? errors.title : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              id="message"
              name="message"
              placeholder="Enter your message"
              disabled={!editable}
              size="small"
              multiline
              rows={5}
              required
              value={values.message}
              error={touched.message && !!errors.message}
              helperText={touched.message ? errors.message : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <MessageLinksForm
              links={values.links}
              editable={editable}
              onUnsavedChanges={(hasChanges) => setLinkNotAttached(hasChanges)}
              onSave={(newValue, shouldValidate) => {
                setFieldValue('links', newValue, shouldValidate)
              }}
            />
          </ModalFormFieldsV2>

          <ModalFormActionsV2>
            {singleGroupOnly ? (
              <ModalFormActionButtonV2
                size="small"
                variant="contained"
                type="submit"
                color="primary"
                disabled={!isValid || linkNotAttached}
                onClick={() => handleSubmit()}>
                Send
              </ModalFormActionButtonV2>
            ) : (
              <ModalFormActionButtonV2
                size="small"
                variant="contained"
                type="submit"
                color="primary"
                disabled={!values.message || linkNotAttached}
                onClick={() => setActiveTab('groups')}>
                Next step
              </ModalFormActionButtonV2>
            )}
          </ModalFormActionsV2>
        </FormBody>
      ),
    },
  ]
  if (!singleGroupOnly) {
    tabs.push({
      id: 'groups',
      title: 'Groups',
      contents: (
        <FormBody>
          <ModalFormTitleV2 variant="h1" align="left">
            Select groups
          </ModalFormTitleV2>
          <ModalFormFieldsV2 scrollable>
            <div>
              <GroupCheckbox
                key="all"
                label="All groups"
                onChange={() => {
                  setFieldTouched('groups', true, false)
                  if (values.groups.length === availableGroups.length) {
                    setFieldValue('groups', [])
                  } else {
                    setFieldValue('groups', [...availableGroups])
                  }
                }}
                control={
                  <Checkbox
                    value=""
                    color="primary"
                    checked={values.groups.length === availableGroups.length}
                  />
                }
              />
              {availableGroups
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((group) => (
                  <GroupCheckbox
                    key={group.uuid}
                    label={group.name}
                    onChange={() => toggleAGroup(group)}
                    control={
                      <Checkbox
                        value=""
                        color="primary"
                        checked={
                          !!values.groups.find(
                            (selectedGroup) =>
                              selectedGroup.uuid === group.uuid,
                          )
                        }
                      />
                    }
                  />
                ))}
            </div>
          </ModalFormFieldsV2>
          <Typography variant="h6" color="error">
            {touched.groups && errors.groups}
          </Typography>
          <ModalFormActionsV2>
            <ModalFormActionButtonV2
              size="small"
              variant="flat"
              type="submit"
              color="primary"
              onClick={() => setActiveTab('details')}
              disabled={loading}>
              Go back
            </ModalFormActionButtonV2>
            {editable ? (
              <ModalFormActionButtonV2
                size="small"
                variant="contained"
                type="submit"
                color="primary"
                disabled={!isValid}
                onClick={() => handleSubmit()}>
                Send
              </ModalFormActionButtonV2>
            ) : (
              <ModalFormActionButtonV2
                size="small"
                variant="contained"
                color="primary"
                onClick={() => onCancel()}>
                Close
              </ModalFormActionButtonV2>
            )}
          </ModalFormActionsV2>
        </FormBody>
      ),
    })
  }

  return (
    <StyledTabbedModal
      open={open}
      maxWidth={'md'}
      tabs={tabs}
      activeTab={activeTab}
      hideTabs={!!singleGroupOnly}
      handleTabClick={setActiveTab}
      hasCloseButton={true}
      closeButtonDisabled={loading}
      onClose={onCancel}
    />
  )
}
