import Checkbox from '@weareroam/cake-ui-v1/Checkbox'
import FormControlLabel from '@weareroam/cake-ui-v1/FormControlLabel/FormControlLabel'
import {
  ModalFormActionButton,
  ModalFormActions,
  ModalFormBody,
  ModalFormFields,
  ModalFormTitle,
} from 'components/atoms/ModalForm/ModalForm'
import { LabelledTextarea } from 'components/molecules/Forms/LabelledTextarea/LabelledTextarea'
import {
  ModalTab,
  TabbedModal,
} from 'components/molecules/TabbedModal/TabbedModal'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'

import { Group } from '../../../modules/groups/schema'
import { TeamNotice } from '../../../modules/teamNotice/schema'
import { PartialWithRequired } from '../../../utils/types'

const StyledTabbedModal = styled(TabbedModal)`
  width: 662px;
  min-height: 580px;
`

const FormBody = styled(ModalFormBody)`
  height: 484px;
`

// TODO: Unify with Message Form Modal
const GroupCheckbox = styled(FormControlLabel).attrs({
  classes: {
    label: 'checkbox-label-class',
  },
})`
  width: 100%;
  font-weight: inherit;
  && {
    &:not(:last-child) {
      margin-bottom: 0px;
      border-bottom: 1px solid #adb9cb;
    }
  }

  .checkbox-label-class {
    font-weight: inherit;
    white-space: nowrap;
  }
`

interface Props {
  open: boolean
  isNew?: boolean
  noticeUpdate: PartialWithRequired<TeamNotice, 'approved' | 'groups'>
  groups: Group[]
  loading?: boolean
  onCancel: () => void
  onSubmit: (notice: Partial<TeamNotice>) => void
}

export const NoticeUpdateModal = ({
  open,
  isNew,
  noticeUpdate,
  loading,
  groups,
  onCancel,
  onSubmit,
}: Props): React.ReactElement => {
  const [activeTab, setActiveTab] = useState('details')

  const messageMaxLength = 250

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    setFieldValue,
  } = useFormik<PartialWithRequired<TeamNotice, 'approved' | 'groups'>>({
    initialValues: {
      ...noticeUpdate,
    },
    validationSchema: Yup.object({
      message: Yup.string()
        .required('Please enter the message')
        .max(
          messageMaxLength,
          'Please keep the message within the character limit',
        ),
    }),
    onSubmit: (values) => {
      onSubmit(values)
    },
  })

  const tabs: Array<ModalTab> = [
    {
      id: 'details',
      title: 'Details',
      contents: (
        <FormBody>
          <ModalFormTitle variant="h2">
            {isNew ? 'Post a' : 'Edit'} message
          </ModalFormTitle>
          <ModalFormFields>
            <LabelledTextarea
              id="message"
              name="message"
              label="Message"
              disabled={loading}
              required
              rows={6}
              maxLength={messageMaxLength}
              value={values.message}
              error={touched.message && !!errors.message}
              helperText={touched.message ? errors.message : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </ModalFormFields>
          <ModalFormActions>
            <ModalFormActionButton
              size="small"
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading || !isValid || !values.message}
              onClick={() => setActiveTab('groups')}>
              Next step
            </ModalFormActionButton>
          </ModalFormActions>
        </FormBody>
      ),
    },
    {
      id: 'groups',
      title: 'Groups',
      contents: (
        <FormBody>
          <ModalFormTitle variant="h2">Select groups</ModalFormTitle>
          <ModalFormFields>
            <GroupCheckbox
              key="all"
              label="All groups"
              onChange={() => {
                if (values.groups.length === groups.length) {
                  setFieldValue('groups', [])
                } else {
                  setFieldValue('groups', [...groups])
                }
              }}
              control={
                <Checkbox
                  value=""
                  color="primary"
                  checked={values.groups?.length === groups.length}
                />
              }
            />
            {groups &&
              groups
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((group) => (
                  <GroupCheckbox
                    key={group.uuid}
                    label={group.name}
                    onChange={() => {
                      if (
                        values.groups.find(
                          (selectedGroup) => selectedGroup.uuid === group.uuid,
                        )
                      ) {
                        setFieldValue(
                          'groups',
                          values.groups.filter(
                            (selectedGroup) =>
                              selectedGroup.uuid !== group.uuid,
                          ),
                        )
                      } else {
                        setFieldValue('groups', [group, ...values.groups])
                      }
                    }}
                    control={
                      <Checkbox
                        value=""
                        color="primary"
                        checked={
                          !!values.groups?.find(
                            (selectedGroup) =>
                              selectedGroup.uuid === group.uuid,
                          )
                        }
                      />
                    }
                  />
                ))}
          </ModalFormFields>
          <ModalFormActions>
            <ModalFormActionButton
              size="small"
              variant="flat"
              type="submit"
              color="primary"
              onClick={() => setActiveTab('details')}
              disabled={loading}>
              Go back
            </ModalFormActionButton>
            <ModalFormActionButton
              size="small"
              variant="contained"
              type="submit"
              color="primary"
              disabled={
                loading || !isValid || !values.groups || !values.groups.length
              }
              onClick={() => handleSubmit()}>
              {values.uuid ? 'Save' : 'Send'}
            </ModalFormActionButton>
          </ModalFormActions>
        </FormBody>
      ),
    },
  ]

  return (
    <StyledTabbedModal
      open={open}
      maxWidth={'md'}
      tabs={tabs}
      activeTab={activeTab}
      handleTabClick={setActiveTab}
      hasCloseButton={true}
      closeButtonDisabled={loading}
      onClose={onCancel}
    />
  )
}
