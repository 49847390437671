/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReportErrorType } from 'constants/reports'

import Typography from '@weareroam/cake-ui-v1/Typography'
import ProgressLoader from 'components/atoms/ProgressLoader'
import { ChartExplainerHeader } from 'components/molecules/ChartExplainerHeader'
import EmptyState from 'components/molecules/EmptyState'
import {
  ReflectionImpactLine,
  ReflectionsImpactReport,
} from 'hooks/useReflectionImpactReport'
import React from 'react'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  ReferenceLine,
  LabelList,
  ResponsiveContainer,
  Label,
  LabelProps,
} from 'recharts'
import styled from 'styled-components'
import theme from 'styles/theme'
import { sortOnAttributeByAbsoluteValue } from 'utils/arrayUtils'
import { truncateWithElipsis } from 'utils/formatting'
import { isEven } from 'utils/validation'

const StyledArea = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  overflow: hidden;
  margin: auto;
  padding-right: 32px;
`

const StyledContainer = styled(ResponsiveContainer)`
  svg {
    overflow: visible;
  }
`

const Rect = styled.rect<{ isEven: boolean }>`
  height: 2.5em;
  fill: ${({ isEven }) =>
    isEven
      ? (theme.palette as any).chart.evenRow
      : (theme.palette as any).chart.oddRow};
`

export const TitleHeading = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  padding-bottom: ${({ theme }) => theme.spacing.md / 2}px;
  padding-left: ${({ theme }) => theme.spacing.md}px;
  padding-right: ${({ theme }) => theme.spacing.md}px;
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};
`

export const HeaderInfoContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: start;
  margin-left: ${({ theme }) => theme.spacing.md}px;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  padding: ${({ theme }) => theme.spacing.md}px
    ${({ theme }) => theme.spacing.md + theme.spacing.sm}px;
  background: ${({ theme }) => theme.palette.primary.light};
  max-width: 590px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.tertiary.light};
`

export const HeaderInfoTextContainer = styled.div`
  flex-direction: row;
`

export const Description = styled(Typography)`
  color: black;
  margin-left: ${({ theme }) => theme.spacing.md}px;
`

export const DescriptionHeader = styled(Typography)`
  margin-left: ${({ theme }) => theme.spacing.md}px;
  color: black;
  font-weight: ${({ theme }) => theme.weights.bold} !important;
`

const renderCustomizedLabelNegative = (props: LabelProps) => {
  const { value, width, height, y, x } = props
  if (Number(value) < 0) {
    return (
      <g>
        <text
          fontWeight="bold"
          x={Number(x) + Number(width) - 30}
          y={Number(y) + Number(height) / 2}
          fill={(theme.palette as any).chart.negative}
          textAnchor="middle"
          dominantBaseline="middle">
          {Math.round(Number(value))}%
        </text>
      </g>
    )
  }
  return null
}

const renderCustomizedLabelPositive = (props: LabelProps) => {
  const { value, width, height, y, x } = props

  if (Number(value) === 0) {
    return (
      <g>
        <text
          fontWeight="bold"
          x={Number(x) + Number(width) + 30}
          y={Number(y) + Number(height) / 2}
          fill={(theme.palette as any).chart.neutral}
          textAnchor="middle"
          dominantBaseline="middle">
          +{Math.round(Number(value))}%
        </text>
      </g>
    )
  }

  if (!value) {
    return null
  }

  return (
    <g>
      <text
        fontWeight="bold"
        x={Number(x) + Number(width) + 30}
        y={Number(y) + Number(height) / 2}
        fill={(theme.palette as any).quaternary.dark}
        textAnchor="middle"
        dominantBaseline="middle">
        +{Math.round(Number(value))}%
      </text>
    </g>
  )
}

// https://github.com/recharts/recharts/issues/1817
// eslint-disable-next-line
// @ts-ignore
const renderCustomizedTick = ({ payload, y, index }) => (
  <g>
    <Rect isEven={isEven(index)} x="-69.5%" y={y - 20} width="175%" />
    <text
      x="-62.5%"
      y={y}
      fontSize="14"
      fontWeight="400"
      fill={(theme.palette as any).text.main}
      textAnchor="start"
      dominantBaseline="middle">
      <title>{payload.value}</title>
      {truncateWithElipsis(payload.value, 52)}
    </text>
  </g>
)

const rowHeight = 40

export function ReflectionWellbeingImpactBarChart({
  data = { reflections: [], meanWellbeing: 0 },
  loading,
  error,
}: {
  data?: ReflectionsImpactReport
  loading: boolean
  error?: ReportErrorType
}): React.ReactElement {
  const chartHeight = React.useMemo(
    () => rowHeight * data.reflections.length + 40,
    [data.reflections.length],
  )

  const transformedData = React.useMemo(
    () =>
      sortOnAttributeByAbsoluteValue<ReflectionImpactLine>(
        data.reflections,
        'percentDifferenceToMean',
      ).map((line) => {
        const effect = Math.round(line.percentDifferenceToMean ?? 0)
        return {
          ...line,
          effect,
          ...(effect >= 0 ? { positive: effect } : { negative: effect }),
        }
      }),
    [data],
  )

  if (loading) {
    return <ProgressLoader fullWidth />
  }

  if (error === ReportErrorType.GENERIC) {
    return (
      <EmptyState
        title="Oh no! We’re not able to show this report right now."
        childComponent={
          <span>
            Something went wrong trying to show this report. We’ve been notified
            and will take a look but if the problem persists then let us know.
          </span>
        }
      />
    )
  }

  if (
    (data?.reflections?.length ?? 0) === 0 ||
    error === ReportErrorType.MIN_SAMPLE_REQUIRED
  ) {
    return (
      <EmptyState
        title="There’s not enough data to show this report for the requested reporting period"
        childComponent={
          <span>
            To protect user anonymity and to ensure a minimum sample size this
            report requires at least five responses.
          </span>
        }
      />
    )
  }

  return (
    <>
      <ChartExplainerHeader
        title="What is this chart telling me?"
        textSections={[
          'This chart reports the average impact that a user-reported reflection had on their wellbeing.',
          'The percentage shown is the difference between the average wellbeing score of individuals who reported it vs. the overall wellbeing score of the organisation.',
        ]}
      />
      <TitleHeading>
        <Title variant="body1">Reflections</Title>
        <Title variant="body1">Impact</Title>
      </TitleHeading>
      <StyledArea>
        <StyledContainer width="60%" height={chartHeight}>
          <ComposedChart
            layout="vertical"
            stackOffset="sign"
            data={transformedData}
            margin={{
              right: 20,
            }}>
            <XAxis axisLine={false} type="number" tick={false}>
              <Label position="bottom" />
            </XAxis>
            <YAxis
              dataKey="title"
              type="category"
              scale="band"
              tick={renderCustomizedTick}
              tickSize={10}
              axisLine={false}
            />
            <Bar
              dataKey="negative"
              barSize={28}
              fill={(theme.palette as any).chart.negative85}
              stackId="stack">
              <LabelList
                dataKey="negative"
                content={renderCustomizedLabelNegative}
              />
            </Bar>
            <ReferenceLine
              x={0}
              stroke={(theme.palette as any).chart.referenceLine}
            />
            <Bar
              dataKey="positive"
              barSize={29}
              fill={(theme.palette as any).chart.positive}
              stackId="stack">
              <LabelList
                dataKey="positive"
                content={renderCustomizedLabelPositive}
              />
            </Bar>
          </ComposedChart>
        </StyledContainer>
      </StyledArea>
    </>
  )
}
