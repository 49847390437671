import {
  ROLE_ORG_OWNER,
  ROLE_SUPER_ADMIN,
  ROLE_ORG_ADMIN,
  ROLE_PARTNER_ADMIN,
} from 'constants/form'
import { PAGINATION_LIMIT } from 'constants/pagination'

import {
  Table,
  Typography,
  TableRow,
  TableBody,
  TablePagination,
  TableHead,
  TableCell,
  Button,
  Checkbox,
  IconButton,
} from '@weareroam/cake-ui-v1'
import ContentContainer from 'components/atoms/ContentContainer'
import ProgressLoader from 'components/atoms/ProgressLoader'
import SearchField from 'components/atoms/SearchField'
import authorisedRoles from 'components/hoc/authorisedRoles'
import MemberTableFilter from 'components/molecules/MemberTableFilter'
import { Modal } from 'components/molecules/Modal'
import MoreActions from 'components/molecules/MoreActions'
import Pagination from 'components/molecules/Pagination'
import { MoveMembersForm } from 'components/organisms/MoveMembersForm/MoveMembersForm'
import RemoveMultiMemberForm from 'components/organisms/RemoveMultiMemberForm/RemoveMultiMemberForm'
import ResendMemberInvitationForm from 'components/organisms/ResendMemberInvitationForm'
import ResendMultiMemberInvitationsForm from 'components/organisms/ResendMultiMemberInvitationsForm/ResendMultiMemberInvitationsForm'
import useMemberModals from 'hooks/useMemberModals'
import useMembers from 'hooks/useMembers'
import { getGroupMemberStatus } from 'modules/groups/selectors'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledFilters = styled.header`
  && {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
    padding-right: ${({ theme }) => theme.spacing.sm}px;
  }
`

export const FilterRight = styled.div`
  && {
    display: flex;
    align-items: center;
  }
  && > * {
    margin-left: ${({ theme }) => theme.spacing.sm}px;
    margin-right: ${({ theme }) => theme.spacing.sm}px;
  }
`

export const StyledTable = styled(Table)``

export const MemberRow = styled(TableRow)`
  && {
    &:hover {
      cursor: pointer;
    }
  }
`

export const ToolBarTitle = styled(Typography)`
  && {
    flex: 1 1 100%;
    padding-left: ${({ theme }) => theme.spacing.sm}px;
  }
`

export const StyledAdminHeaderText = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const StyledActions = styled.div`
  && {
    display: flex;
    justify-self: flex-end;
    justify-content: flex-end;
  }
`

export const StyledIconButton = styled(IconButton)`
  && {
    color: ${({ theme }) => theme.palette.tertiary.dark};
    margin: 0;
    padding: ${({ theme }) => theme.spacing.sm}px;
  }
`

export const StyledCheckBox = styled(Checkbox)`
  && {
    color: ${({ theme }) => theme.palette.tertiary.dark};
    margin: 0;
    padding: 0;
  }
`

export const ActionsCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const PermissionsText = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};
  margin: ${({ theme }) => theme.spacing.sm}px 0
    ${({ theme }) => theme.spacing.sm}px ${({ theme }) => theme.spacing.sm}px;
`

export const GroupText = styled(Typography)`
  && {
    margin: ${({ theme }) => theme.spacing.sm}px 0;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

export const InviteButton = styled(Button)`
  && {
    margin-left: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledLink = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: ${({ theme }) => theme.spacing.xs}px;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
`

export const PendingText = styled(Typography)`
  font-style: italic;
  color: ${({ theme }) => theme.palette.tertiary.light};
  && {
    margin-right: ${({ theme }) => theme.spacing.sm}px;
  }
`

export const StyledTablePagination = styled(TablePagination)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const StyledInviteModal = styled(Modal)`
  width: 520px;
`

export const StyledRemoveModal = styled(Modal)`
  width: 410px;
`

export const StyledResendModal = styled(Modal)`
  width: 500px;
`

export const StyledDeleteModal = styled(Modal)`
  width: 460px;
`

export const ResendModal = styled(Modal)`
  width: 500px;
`

export const MoveMembersModal = styled(Modal)`
  width: 530px;
`

export const RemoveModal = styled(Modal)`
  width: 500px;
`

export const StyledSuccessModal = styled(Modal)`
  width: 500px;
`

// Check Members.js too. The MemberTable itself is protected there.
const ProtectedMoreActions = authorisedRoles(
  [ROLE_PARTNER_ADMIN, ROLE_ORG_OWNER, ROLE_SUPER_ADMIN, ROLE_ORG_ADMIN],
  true,
)(MoreActions)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function MemberTable({
  groups, // For the move modal
  members = {},
  orgUuid,
  groupUuid,
  getGroupMembersProgress,
  singleResendProgress,
  multipleResendProgress,
  moveMembersProgress,
  actions,
}) {
  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_LIMIT)
  const [pageIndex, setPageIndex] = useState(0)
  const {
    memberUuid,
    handleOpenInvitationSuccessModal,

    isResendModalOpen,
    handleOpenSingleResendModal,
    handleCloseSingleResendModal,

    isMultiResendModalOpen,
    handleOpenMultipleResendModal,
    handleCloseMultipleResendModal,

    isMoveMembersModalOpen,
    handleOpenMoveMembersModal,
    handleCloseMoveMembersModal,

    isRemoveMemberModalOpen,
    handleOpenDeleteMemberModal,
    handleCloseDeleteMemberModal,
  } = useMemberModals()
  const {
    searchFilter,
    filteredMembers,
    statusFilter,
    handleChangeSearchFilter,
    handleChangeStatusFilter,

    selected,
    isSelected,
    handleSelectAll,
    handleSelection,

    onResendSingleInvite,
    onResendMultipleInvites,
    onMoveMembers,
    onRemoveMultipleUsers,
  } = useMembers({
    actions,
    members: Object.values(members),

    handleOpenInvitationSuccessModal,

    handleCloseSingleResendModal,
    handleCloseMultipleResendModal,
    handleCloseMoveMembersModal,
    handleCloseDeleteMemberModal,
  })
  const numSelected = selected.length
  const rowCount = filteredMembers.length
  const handleChangePage = (event, newPage) => {
    setPageIndex(newPage)
  }

  const handleResendSingle = () => {
    const member = members[memberUuid]
    if (member) {
      onResendSingleInvite({
        groupUuid: member.group.uuid,
        orgUuid,
        userUuid: member.user.uuid,
      })
    }
  }

  const handleResendMulti = () => {
    if (selected && selected.length > 0) {
      onResendMultipleInvites({ userUuids: selected, orgUuid, groupUuid })
    }
  }

  const handleMoveMembers = ({ newGroupUuid }) => {
    if (selected && selected.length > 0) {
      onMoveMembers({
        orgUuid,
        userUuids: selected,
        sourceGroupUuid: groupUuid,
        targetGroupUuid: newGroupUuid,
      })
    }
  }

  const handleRemoveMultiple = () => {
    if (selected && selected.length > 0) {
      onRemoveMultipleUsers({ userUuids: selected, orgUuid, groupUuid })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPageIndex(0)
  }

  return (
    <ContentContainer>
      <ResendModal
        open={isMultiResendModalOpen}
        onCancel={handleCloseMultipleResendModal}>
        <ResendMultiMemberInvitationsForm
          onCancel={handleCloseMultipleResendModal}
          onSubmit={handleResendMulti}
          progress={multipleResendProgress}
          numSelected={numSelected}
        />
      </ResendModal>
      <ResendModal
        open={isResendModalOpen}
        onClose={handleCloseSingleResendModal}>
        <ResendMemberInvitationForm
          onCancel={handleCloseSingleResendModal}
          memberUuid={memberUuid}
          contacts={members[memberUuid] && members[memberUuid].contacts}
          progress={singleResendProgress}
          onSubmit={handleResendSingle}
        />
      </ResendModal>
      <MoveMembersModal
        open={isMoveMembersModalOpen}
        onClose={handleCloseMoveMembersModal}>
        <MoveMembersForm
          onCancel={handleCloseMoveMembersModal}
          onSubmit={handleMoveMembers}
          progress={moveMembersProgress}
          numSelected={numSelected}
          groups={groups.filter((group) => group.uuid !== groupUuid)}
        />
      </MoveMembersModal>
      <RemoveModal
        open={isRemoveMemberModalOpen}
        onClose={handleCloseDeleteMemberModal}>
        <RemoveMultiMemberForm
          onCancel={handleCloseDeleteMemberModal}
          progress={singleResendProgress}
          onSubmit={handleRemoveMultiple}
          numSelected={numSelected}
        />
      </RemoveModal>
      <StyledFilters>
        <FilterRight>
          <SearchField
            placeholder="Search"
            value={searchFilter}
            onChange={handleChangeSearchFilter}
          />
          <MemberTableFilter
            statusFilter={statusFilter}
            handleChangeStatusFilter={handleChangeStatusFilter}
          />
          <StyledActions>
            <ProtectedMoreActions
              color="primary"
              actions={[
                {
                  key: `resend-invitations-${selected}`,
                  label: 'Resend sms invitations',
                  onClick: () => {
                    handleOpenMultipleResendModal()
                  },
                },
                {
                  key: `move-users-${selected}`,
                  label: 'Move users to another group',
                  onClick: () => {
                    handleOpenMoveMembersModal()
                  },
                },
                {
                  key: `remove-users-${selected}`,
                  label: 'Delete users',
                  onClick: () => {
                    handleOpenDeleteMemberModal()
                  },
                },
              ]}
            />
          </StyledActions>
        </FilterRight>
      </StyledFilters>
      <form>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <StyledCheckBox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={handleSelectAll}
                  color="primary"
                />
              </TableCell>
              <TableCell>
                <StyledAdminHeaderText variant="body1">
                  Email/Phone
                </StyledAdminHeaderText>
              </TableCell>
              <TableCell>
                <StyledAdminHeaderText variant="body1">
                  Status
                </StyledAdminHeaderText>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMembers &&
              filteredMembers
                .slice(
                  pageIndex * rowsPerPage,
                  pageIndex * rowsPerPage + rowsPerPage,
                )
                .map(({ uuid, status, contacts, user }) => {
                  const editPermissionActions = []
                  const isMemberSelected = isSelected(user.uuid)

                  if (
                    status !== 'active' &&
                    contacts.find((contact) => contact.contactType === 'phone')
                  ) {
                    editPermissionActions.push({
                      key: `resend-invitation-${uuid}`,
                      label: 'Resend sms invitation',
                      onClick: () => {
                        handleOpenSingleResendModal(uuid)
                      },
                    })
                  }

                  return (
                    <MemberRow
                      key={user.uuid}
                      onClick={() => {
                        handleSelection(user.uuid)
                      }}
                      selected={isMemberSelected}>
                      <TableCell align="center">
                        <StyledCheckBox
                          checked={isMemberSelected}
                          color="primary"
                          value={user.uuid}
                        />
                      </TableCell>
                      <TableCell>
                        {contacts &&
                          contacts.map((contact) => (
                            <Typography
                              key={`contact-${contact.uuid}-${uuid}`}
                              variant="body1">
                              {contact.value}
                            </Typography>
                          ))}
                      </TableCell>
                      <TableCell>
                        <PendingText variant="body1">
                          {getGroupMemberStatus(status)}
                        </PendingText>
                      </TableCell>
                      <TableCell>
                        <ActionsCell>
                          {editPermissionActions.length > 0 && (
                            <ProtectedMoreActions
                              color="primary"
                              actions={editPermissionActions}
                            />
                          )}
                        </ActionsCell>
                      </TableCell>
                    </MemberRow>
                  )
                })}
          </TableBody>
        </StyledTable>
      </form>
      {getGroupMembersProgress.inProgress ? <ProgressLoader fullWidth /> : null}
      <StyledTablePagination
        rowsPerPageOptions={[5, 8, 15]}
        component="div"
        count={filteredMembers.length}
        rowsPerPage={rowsPerPage}
        page={pageIndex}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </ContentContainer>
  )
}

MemberTable.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object),
  members: PropTypes.object,
  orgUuid: PropTypes.string,
  groupUuid: PropTypes.string,
  getGroupMembersProgress: PropTypes.object,
  singleResendProgress: PropTypes.object,
  multipleResendProgress: PropTypes.object,
  moveMembersProgress: PropTypes.object,
  modalHandlers: PropTypes.object,
  hasEditAccess: PropTypes.bool,
  actions: PropTypes.object,
}

export default MemberTable
