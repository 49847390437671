import { ORG_DASHBOARD_OVERVIEW } from 'constants/routes'
import { TOAST_TYPE_ERROR } from 'constants/ui'

import * as authActions from 'modules/authentication/actions'
import * as organisationsActions from 'modules/organisations/actions'
import * as routeModule from 'modules/route'
import * as toastActions from 'modules/toasts/actions'
import { connect } from 'react-redux'
import { compose, withHandlers, lifecycle, withState } from 'recompose'
import { bindActionCreators } from 'redux'
import { replaceRouteParams } from 'utils/routes'

export default compose(
  connect(
    // mapStateToProps
    (state, { match }) => {
      const { orgUuid } = match.params

      return {
        organisation: state.organisations.entities[orgUuid],
        getOrganisationProgress: state.progress.getOrganisation,
      }
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...routeModule,
        ...toastActions,
        ...organisationsActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  lifecycle({
    componentDidMount() {
      const { actions, match, organisation } = this.props

      if (!organisation) {
        // refetch the organisation if the user refreshes
        actions.getOrganisation(match.params.orgUuid)
      }
    },
  }),
  withState('uploadProgress', 'setUploadProgress', 0),
  withState('uploadTotal', 'setUploadTotal', 0),
  withHandlers({
    onImageUploadError:
      ({ actions }) =>
      () => {
        actions.addToast({
          message: 'Sorry, we only support PNG/JPEG for organisation images',
          type: TOAST_TYPE_ERROR,
        })
      },
    onSubmit:
      ({ actions, match, setUploadProgress, setUploadTotal }) =>
      async ({ organisationName, logo }) => {
        const { orgUuid } = match.params
        const promises = []

        if (logo && typeof logo === 'object') {
          // Upload logo only if they selected a logo
          promises.push(
            actions.uploadOrganisationLogo({
              uuid: orgUuid,
              file: logo,
              onUploadProgress: (progressEvent) => {
                setUploadProgress(progressEvent.loaded)
                setUploadTotal(progressEvent.total)
              },
            }),
          )
        }

        promises.push(
          actions.updateOrganisation({ name: organisationName, uuid: orgUuid }),
        )
        promises.push(actions.completeOrganisationSetup({ uuid: orgUuid }))

        // Wait for all actions to finish before redirecting
        await Promise.all(promises)

        actions.redirect(
          replaceRouteParams(ORG_DASHBOARD_OVERVIEW, { orgUuid }),
        )
      },
  }),
)
