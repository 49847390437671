import Typography from '@weareroam/cake-ui-v1/Typography'
import React from 'react'
import styled from 'styled-components'

interface TitleWithActionsProps {
  title: string
  description: string
  children?: React.ReactNode
}

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.xxs}px;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

const HeaderLeft = styled.div``
const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`

const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};
`

/** Page title. Please use HeaderWithActions for section headers. */
export function TitleWithActions({
  title,
  description,
  children,
}: TitleWithActionsProps): React.ReactElement {
  return (
    <StyledHeader>
      <HeaderLeft>
        <Typography variant="h2">{title}</Typography>
        <Description variant="body1">{description}</Description>
      </HeaderLeft>
      <HeaderRight>{children}</HeaderRight>
    </StyledHeader>
  )
}
