import Typography from '@weareroam/cake-ui-v1/Typography'
import StatusTag from 'components/atoms/StatusTag'
import EditActions from 'components/molecules/EditActions'
import { IReflection } from 'models/IReflection'
import React from 'react'
import styled from 'styled-components'
import { sentimentColours } from 'views/Dashboard/Organisation/App/Reflections/Reflections'

export const ReflectionBodyContainer = styled.div`
  display: flex;
  align-items: center;
  width: 85%;
`

export const ReflectionStyledHeaderText = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const ReflectionDetailsLeft = styled.div`
  min-width: 60%;
  margin-right: ${({ theme }) => theme.spacing.md}px;
  display: flex;
  align-items: center;
`

export const ReflectionDetailsRight = styled.div`
  min-width: 40%;
  display: flex;
  align-items: center;
`

const ReflectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const StatusTagContainer = styled.div`
  display: box;
  align-self: center;
  padding-right: 1rem;
`

interface ReflectionRowProps {
  reflection: IReflection
  disableEditing: boolean
  onEdit?: () => void
  onDelete?: () => void
}

export function ReflectionRow({
  reflection,
  disableEditing,
  onEdit,
  onDelete,
}: ReflectionRowProps): React.ReactElement {
  return (
    <ReflectionHeader>
      <>
        <ReflectionBodyContainer>
          <ReflectionDetailsLeft>
            {reflection.sentiment && (
              <StatusTagContainer>
                <StatusTag themeColor={sentimentColours[reflection.sentiment]}>
                  {reflection.sentiment}
                </StatusTag>
              </StatusTagContainer>
            )}
            <Typography variant="h4">{reflection.title}</Typography>
          </ReflectionDetailsLeft>
          <ReflectionDetailsRight>
            <ReflectionStyledHeaderText variant="body2">
              {reflection.dayTypes &&
                reflection.dayTypes.length > 0 &&
                reflection.dayTypes.map((dt) => dt.type).join(', ')}
              {(!reflection.dayTypes || !reflection.dayTypes.length) &&
                'All Day Types'}
              {reflection.repeats === 'Every 4 weeks' && ', Every 4 weeks'}
              {reflection.repeats === 'Quarterly' && ', Quarterly'}
            </ReflectionStyledHeaderText>
          </ReflectionDetailsRight>
        </ReflectionBodyContainer>

        {!disableEditing && <EditActions onEdit={onEdit} onDelete={onDelete} />}
      </>
    </ReflectionHeader>
  )
}
