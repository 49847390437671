import { Theme } from '../hooks/useThemes'

export const BnHKey = 'BULLIED_OR_HARASSED'

export enum ReflectionThemes {
  BULLIED_OR_HARASSED = 'Bullying or Harassment',
}

export interface WellbeingResource {
  uuid: string
  heading: string
  headerImage: string | null
  subHeading: string
  email: string
  contact: string
  extension: string
  website: string
  videoUrl: string | null
  description: string
  escalationOption: boolean
  themes: Array<Theme>
}

export function isBnHResource(resource: WellbeingResource): boolean {
  return !!resource?.themes?.find((theme) => theme.key === BnHKey)
}
