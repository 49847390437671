import ContentContainer from 'components/atoms/ContentContainer'
import { HeaderButton } from 'components/atoms/HeaderButton/HeaderButton'
import { RoundedPanel } from 'components/atoms/RoundedPanel/RoundedPanel'
import { ApprovalModal } from 'components/molecules/ApprovalModal/ApprovalModal'
import { ListSection } from 'components/molecules/ListSection/ListSection'
import { TitleWithActions } from 'components/molecules/TitleWithActions/TitleWithActions'
import { VideoUpdateCard } from 'components/organisms/VideoUpdateCard/VideoUpdateCard'
import { VideoUpdateModal } from 'components/organisms/VideoUpdateModal/VideoUpdateModal'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import { MessagingLayout } from 'layouts/MessagingLayout'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import ProgressLoader from '../../../../components/atoms/ProgressLoader'
import EmptyState from '../../../../components/molecules/EmptyState'
import { useTeamVideos } from '../../../../hooks/useTeamVideos'
import { TeamVideo } from '../../../../modules/teamVideo/schema'
import { OrganisationRouteParams } from '../../../../types/routes'

const SpacedCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: ${({ theme }) => theme.spacing.lg}px;
  column-gap: ${({ theme }) => theme.spacing.mdlg}px;
`

export function Network(): React.ReactElement {
  const { orgUuid } = useParams<OrganisationRouteParams>()
  const {
    data: videos,
    isLoading,
    error,
    handlers: { save: saveVideo, remove: removeVideo },
  } = useTeamVideos({
    orgUuid,
  })

  const [activeVideos, setActiveVideos] = useState<Array<TeamVideo>>([])
  const [scheduledVideos, setScheduledVideos] = useState<Array<TeamVideo>>([])
  useEffect(() => {
    setActiveVideos(
      videos.filter(isCurrent).sort(sortByScheduledOrCreatedTime).reverse(),
    )
    setScheduledVideos(
      videos.filter((e) => !isCurrent(e)).sort(sortByScheduledOrCreatedTime),
    )
  }, [videos])

  const isCurrent = (video: TeamVideo): boolean => {
    const activeAt = new Date(video.scheduledAt || video.createdAt)
    return activeAt <= new Date()
  }

  const sortByScheduledOrCreatedTime = (a: TeamVideo, b: TeamVideo): number => {
    const first = new Date(a.scheduledAt || a.createdAt)
    const second = new Date(b.scheduledAt || b.createdAt)
    return first.getTime() - second.getTime()
  }

  const [toEdit, setToEdit] = useState<Partial<TeamVideo> | undefined>(
    undefined,
  )
  const [editing, setEditing] = useState(false)

  const actionEdit = useCallback(
    async (video: Partial<TeamVideo>) => {
      setEditing(true)
      await saveVideo(video)
      setEditing(false)
      setToEdit(undefined)
    },
    [saveVideo],
  )
  const cancelEdit = () => setToEdit(undefined)

  const [toDelete, setToDelete] = useState<TeamVideo | undefined>(undefined)
  const [deleting, setDeleting] = useState(false)

  const actionDeletion = useCallback(async () => {
    setDeleting(true)
    toDelete?.uuid && (await removeVideo(toDelete.uuid))
    setDeleting(false)
    setToDelete(undefined)
  }, [removeVideo, toDelete])
  const cancelDeletion = () => setToDelete(undefined)

  const renderCards = (videos: Array<TeamVideo>): React.ReactElement | null => {
    if (!videos.length) {
      return null
    }
    // @ts-expect-error TypeScript thinks that array will consist of JSX.Elements, not ReactElements.
    return videos.map((video) => (
      <VideoUpdateCard
        key={video.uuid}
        description={video.description}
        isEditable
        isDeletable
        onDelete={() => setToDelete(video)}
        onEdit={() => setToEdit(video)}
        activeAt={new Date(video.scheduledAt || video.createdAt)}
        title={video.title}
        videoUrl={video.url}
      />
    ))
  }

  if (isLoading || deleting || editing) {
    return (
      <AdminDashboardLayout noPadding>
        <MessagingLayout>
          <ContentContainer>
            <ProgressLoader fullWidth />
          </ContentContainer>
        </MessagingLayout>
      </AdminDashboardLayout>
    )
  }

  if (error) {
    return (
      <AdminDashboardLayout noPadding>
        <MessagingLayout>
          <ContentContainer>
            <EmptyState
              title="Oh no! We’re not able to show this section right now."
              childComponent={
                <span>
                  Something went wrong trying to load team videos. We’ve been
                  notified and will take a look but if the problem persists then
                  let us know.
                </span>
              }
            />
          </ContentContainer>
        </MessagingLayout>
      </AdminDashboardLayout>
    )
  }

  return (
    <AdminDashboardLayout noPadding>
      <MessagingLayout>
        <ContentContainer>
          <TitleWithActions
            title={'Network'}
            description={'Send video updates to your organisation'}>
            <HeaderButton
              title={'Add video'}
              type={'primary'}
              onClick={() =>
                setToEdit({
                  title: '',
                  url: '',
                })
              }
            />
          </TitleWithActions>
          <ListSection
            columns={[
              {
                title: 'Active videos',
                width: 100,
                alignment: 'start',
              },
            ]}>
            <RoundedPanel>
              <SpacedCards>
                {activeVideos.length
                  ? renderCards(activeVideos)
                  : 'No videos active yet'}
              </SpacedCards>
            </RoundedPanel>
          </ListSection>
          <ListSection
            columns={[
              {
                title: 'Scheduled videos',
                width: 100,
                alignment: 'start',
              },
            ]}>
            <RoundedPanel>
              <SpacedCards>
                {scheduledVideos.length
                  ? renderCards(scheduledVideos)
                  : 'No videos scheduled yet'}
              </SpacedCards>
            </RoundedPanel>
          </ListSection>
        </ContentContainer>

        {toEdit && (
          <VideoUpdateModal
            open={true}
            isNew={!toEdit.uuid}
            videoUpdate={toEdit}
            onCancel={() => cancelEdit()}
            onSubmit={(video) => actionEdit(video)}
          />
        )}

        {toDelete && (
          <ApprovalModal
            title={`Delete ${toDelete.title}?`}
            description={
              "Are you sure? The video will be removed from the page and won't be shared anymore."
            }
            actionLabel={'Delete'}
            loading={isLoading}
            onApprove={() => actionDeletion()}
            onCancel={() => cancelDeletion()}
          />
        )}
      </MessagingLayout>
    </AdminDashboardLayout>
  )
}
