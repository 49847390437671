import Typography from '@weareroam/cake-ui-v1/Typography'
import ContentContainer from 'components/atoms/ContentContainer'
import ProgressLoader from 'components/atoms/ProgressLoader'
import StatusTag from 'components/atoms/StatusTag'
import { ReflectionRow } from 'components/organisms/ReflectionRow/ReflectionRow'
import ReflectionsForm from 'components/organisms/ReflectionsForm'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import AppLayout from 'layouts/AppLayout'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledHeader = styled.header`
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const ReflectionList = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export const Reflection = styled(Typography)`
  padding: ${({ theme }) => theme.spacing.lg}px 0;
  padding-left: ${({ theme }) => theme.spacing.md}px;
  border-top: 1px solid ${({ theme }) => theme.palette.tertiary.light};
`

export const sentimentColours = {
  positive: 'success',
  neutral: 'quinary',
  negative: 'error',
}

export const StyledStatusTag = styled(StatusTag)`
  margin-right: 1rem;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Reflections({
  reflections,
  typesOfDays,
  onCreateReflection,
  onUpdateReflection,
  onDeleteReflection,
  getReflectionsProgress,
  updateReflectionsProgress,
  hasEditAccess,
}) {
  const customReflections = reflections.filter(
    (reflection) => reflection.isCustom,
  )
  const industryReflections = reflections.filter(
    (reflection) => !reflection.isCustom,
  )

  return (
    <AdminDashboardLayout noPadding>
      <AppLayout>
        <ContentContainer>
          <StyledHeader>
            <Typography variant="h2">Reflections</Typography>
            <Description variant="body1">
              Reflections are questions asked at the end of the survey to help
              pinpoint positive or negative areas
            </Description>
          </StyledHeader>
          {getReflectionsProgress.inProgress ? (
            <ProgressLoader fullWidth />
          ) : (
            <>
              <ReflectionsForm
                onCreate={onCreateReflection}
                onUpdate={onUpdateReflection}
                onDelete={onDeleteReflection}
                disableEditing={!hasEditAccess}
                reflections={customReflections}
                typesOfDays={typesOfDays}
                progress={getReflectionsProgress}
                updateProgress={updateReflectionsProgress}
                description={
                  <Description variant="body1">
                    Custom reflections for custom insights
                  </Description>
                }
              />
              <StyledHeader>
                <Typography variant="h3">
                  Industry standard reflections
                </Typography>
                <Description variant="body1">
                  Standard reflections allow us to provide meta analysis across
                  industries
                </Description>
              </StyledHeader>
              <ReflectionList>
                {industryReflections
                  .filter(({ organisationId }) => organisationId == null)
                  .map((reflection) => (
                    <Reflection key={reflection.uuid} variant="h4">
                      <ReflectionRow
                        reflection={reflection}
                        disableEditing={true}
                      />
                    </Reflection>
                  ))}
              </ReflectionList>
            </>
          )}
        </ContentContainer>
      </AppLayout>
    </AdminDashboardLayout>
  )
}

Reflections.propTypes = {
  reflections: PropTypes.arrayOf(PropTypes.object),
  typesOfDays: PropTypes.arrayOf(PropTypes.object),
  onCreateReflection: PropTypes.func,
  onUpdateReflection: PropTypes.func,
  onDeleteReflection: PropTypes.func,
  getReflectionsProgress: PropTypes.object,
  updateReflectionsProgress: PropTypes.object,
  hasEditAccess: PropTypes.bool,
}

Reflections.defaultProps = {
  getReflectionsProgress: {},
  reflections: [],
}

export default Reflections
