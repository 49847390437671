import { ReflectionSentimentEnum } from 'constants/ReflectionSentimentEnum'

import Typography from '@weareroam/cake-ui-v1/Typography'
import ProgressLoader from 'components/atoms/ProgressLoader'
import RowButton from 'components/atoms/RowButton'
import Box from 'components/molecules/Box'
import DragDropList from 'components/molecules/DragDropList'
import { Modal } from 'components/molecules/Modal'
import ConfirmUpdatesForm from 'components/organisms/ConfirmUpdatesForm'
import { ReflectionItemForm } from 'components/organisms/ReflectionItemForm'
import {
  ReflectionRow,
  ReflectionBodyContainer,
  ReflectionStyledHeaderText,
  ReflectionDetailsLeft,
  ReflectionDetailsRight,
} from 'components/organisms/ReflectionRow/ReflectionRow'
import { FormikProps } from 'formik'
import idx from 'idx'
import { IDayType } from 'models/IDayType'
import { IReflectionSubQuestion } from 'models/IReflectionSubQuestion'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { FormValues, HandlerProps, FormProps } from './index'

export const StyledHolder = styled.div`
  && {
    text-align: left;
  }
`

export const StyledHeader = styled.header`
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export const HeaderLeft = styled.div`
  flex-grow: 1;
`

export const DeleteConfirmModal = styled(Modal)``

export const ReflectionsSection = styled.section`
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export const Reflections = styled.div`
  margin: ${({ theme }) => theme.spacing.md}px 0;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const ReflectionsHeader = styled.div`
  margin: ${({ theme }) => theme.spacing.md}px 0;
  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
  padding: 0 ${({ theme }) => theme.spacing.md}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
`

export const AddReflectionModal = styled(Modal)`
  width: 600px;
`

export function ReflectionsForm({
  className,
  values,
  errors,
  touched,
  title = 'Custom Reflections',
  description,
  progress,
  onDragEnd,
  onAddReflection,
  onDeleteReflection,
  typesOfDays,
  disableEditing,
  isAddReflectionModalOpen,
  setIsAddReflectionModalOpen,
  onCloseAddReflectionModal,
  editReflectionIndex,
  setEditReflectionIndex,
  onEditReflection,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  setDeleteReflectionIndex,
}: FormProps & FormikProps<FormValues> & HandlerProps): React.ReactElement {
  const handleReflectionItemSubmit = useCallback(
    (value) => {
      onCloseAddReflectionModal()
      onEditReflection(value, editReflectionIndex)
    },
    [onCloseAddReflectionModal, onEditReflection, editReflectionIndex],
  )

  const handleAddReflectionItem = useCallback(
    (value) => {
      onCloseAddReflectionModal()
      onAddReflection(value)
    },
    [onCloseAddReflectionModal, onAddReflection],
  )

  const handleAddReflectionAction = useCallback(() => {
    setEditReflectionIndex(-1)
    setIsAddReflectionModalOpen(true)
  }, [setEditReflectionIndex, setIsAddReflectionModalOpen])

  const handleEditReflectionAction = useCallback(
    (reflectionIndex) => {
      if (values.reflections && values.reflections[reflectionIndex]) {
        setEditReflectionIndex(reflectionIndex)
        setIsAddReflectionModalOpen(true)
      }
    },
    [setEditReflectionIndex, setIsAddReflectionModalOpen, values.reflections],
  )

  const handleDeleteReflectionAction = useCallback(
    (reflectionIndex) => {
      if (values.reflections && values.reflections[reflectionIndex]) {
        setIsDeleteModalOpen(true)
        setDeleteReflectionIndex(reflectionIndex)
      }
    },
    [setIsDeleteModalOpen, setDeleteReflectionIndex, values.reflections],
  )

  const handleCloseDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false)
    setDeleteReflectionIndex(-1)
  }, [setIsDeleteModalOpen, setDeleteReflectionIndex])

  const filteredTypesOfDays = useMemo(
    () =>
      typesOfDays?.map((item) => ({
        value: item.uuid,
        label: item.type,
      })),
    [typesOfDays],
  )

  const ReflectionItemFormProps = useMemo(() => {
    if (values.reflections && values.reflections[editReflectionIndex]) {
      return {
        inEditMode: true,
        initialTitle: values.reflections[editReflectionIndex].title,
        initialSentiment: values.reflections[editReflectionIndex].sentiment,
        initialDayTypes: values.reflections[editReflectionIndex].dayTypes,
        initialSubQuestions:
          values.reflections[editReflectionIndex].subQuestions,
        typesOfDays: filteredTypesOfDays,
        onSubmit: handleReflectionItemSubmit,
      }
    } else {
      return {
        inEditMode: false,
        initialTitle: '',
        initialSentiment: '' as ReflectionSentimentEnum | '',
        initialDayTypes: [] as IDayType[],
        initialSubQuestions: [] as IReflectionSubQuestion[],
        typesOfDays: filteredTypesOfDays,
        onSubmit: handleAddReflectionItem,
      }
    }
  }, [
    editReflectionIndex,
    handleAddReflectionItem,
    handleReflectionItemSubmit,
    values.reflections,
    filteredTypesOfDays,
  ])

  return (
    <StyledHolder className={className}>
      <AddReflectionModal
        hasCloseButton
        open={isAddReflectionModalOpen}
        onClose={onCloseAddReflectionModal}>
        <ReflectionItemForm
          onCancel={onCloseAddReflectionModal}
          {...ReflectionItemFormProps}
        />
      </AddReflectionModal>

      <DeleteConfirmModal
        hasCloseButton
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}>
        <ConfirmUpdatesForm
          confirmText="Yes, delete"
          onSubmit={onDeleteReflection}
          onCancel={handleCloseDeleteModal}
          title="Are you sure you would like to delete this reflection?"
        />
      </DeleteConfirmModal>

      <StyledHeader>
        <HeaderLeft>
          <Typography variant="h3">{title}</Typography>
          {description}
        </HeaderLeft>
      </StyledHeader>
      {progress.inProgress &&
      (!values.reflections || values.reflections.length === 0) ? (
        <ProgressLoader fullWidth />
      ) : (
        <React.Fragment>
          {!disableEditing && (
            <RowButton onClick={handleAddReflectionAction}>
              + Add reflection
            </RowButton>
          )}

          <ReflectionsSection>
            <ReflectionsHeader>
              <ReflectionBodyContainer>
                <ReflectionDetailsLeft>
                  <ReflectionStyledHeaderText variant="body2">
                    Sentiment
                  </ReflectionStyledHeaderText>
                </ReflectionDetailsLeft>
                <ReflectionDetailsRight>
                  <ReflectionStyledHeaderText variant="body2">
                    Day type
                  </ReflectionStyledHeaderText>
                </ReflectionDetailsRight>
              </ReflectionBodyContainer>
            </ReflectionsHeader>
            <DragDropList onDragEnd={onDragEnd}>
              {(provided) => (
                <Reflections>
                  {values.reflections
                    ? values.reflections.map((reflection, reflectionIndex) => {
                        const isTouched = idx(
                          touched,
                          // eslint-disable-next-line @typescript-eslint/naming-convention
                          (_) => _.reflections[reflectionIndex],
                        )
                        const reflectionErrors = idx(
                          errors,
                          // eslint-disable-next-line @typescript-eslint/naming-convention
                          (_) => _.reflections[reflectionIndex],
                        )
                        return (
                          <Box
                            key={reflection.uuid || reflectionIndex}
                            draggableId={
                              reflection.uuid || `${reflectionIndex}`
                            }
                            draggableIndex={reflectionIndex}
                            isDraggable={false}
                            errors={
                              isTouched && reflectionErrors
                                ? Object.values(reflectionErrors)
                                : []
                            }
                            headerContent={
                              <ReflectionRow
                                reflection={reflection}
                                disableEditing={disableEditing}
                                onEdit={() => {
                                  handleEditReflectionAction(reflectionIndex)
                                }}
                                onDelete={() => {
                                  handleDeleteReflectionAction(reflectionIndex)
                                }}
                              />
                            }
                          />
                        )
                      })
                    : null}
                  {provided.placeholder}
                </Reflections>
              )}
            </DragDropList>
          </ReflectionsSection>
        </React.Fragment>
      )}
    </StyledHolder>
  )
}

export default ReflectionsForm
