import { localAxios } from '../utils/videoThumbnail'

interface VimeoVideoInfo {
  title: string
  description: string
  duration: number
  width: number
  height: number
}

// TODO: Keep in sync with chnnl-app repo
export default class VimeoController {
  static getVimeoVideoInfo = async (
    videoId: string,
  ): Promise<VimeoVideoInfo> => {
    const response = await localAxios.get<VimeoVideoInfo[]>(
      `https://vimeo.com/api/v2/video/${videoId}.json`,
    )
    return response.data?.[0]
  }
}
