import { differenceInSeconds } from 'date-fns'
import * as authActions from 'modules/authentication/actions'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'
import { getTokenExpirationTime } from 'utils/authentication'
import { parseSearchString } from 'utils/routes'

export default compose(
  connect(
    // mapStateToProps
    (state, { location }) => {
      const { token } = parseSearchString(location.search)
      const tokenExpirationTime = getTokenExpirationTime(token)
      const hasExpired =
        differenceInSeconds(tokenExpirationTime, Date.now()) < 0

      return {
        token,
        hasExpired,
      }
    },
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  withHandlers({
    onSubmit:
      ({ actions, token }) =>
      ({ firstName, lastName, password }) => {
        actions.verifyAccount({ firstName, lastName, password, token })
      },
  }),
)
