import React from 'react'

// eslint-disable-next-line react/display-name
export const EditPen = React.memo(() => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.17195 5.79973C5.94182 5.02986 7.42906 3.54262 9.63366 1.33802C10.3726 0.599053 11.5707 0.599053 12.3097 1.33802C13.0487 2.07699 13.0487 3.2751 12.3097 4.01407L5.08438 11.2394L1.33792 12.3098L2.40834 8.56334C3.12946 7.84222 3.67031 7.30137 4.03088 6.9408"
      stroke="#212121"
    />
    <path d="M2.54198 8.4297L5.00921 10.8969" stroke="#212121" />
    <path d="M9.34528 2.38754L11.6408 4.68311" stroke="#212121" />
  </svg>
))
