import { FeatureKeys } from 'constants/features'

import { useFeature } from '@optimizely/react-sdk'
import { Switch } from '@weareroam/cake-ui-v1'
import Button from '@weareroam/cake-ui-v1/Button'
import TextField from '@weareroam/cake-ui-v1/TextField'
import Typography from '@weareroam/cake-ui-v1/Typography'
import EmptyState from 'components/molecules/EmptyState'
import { FormikProps } from 'formik'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { FormValues, InnerFormProps } from './BoosterCategoryForm.enhancer'

export const StyledEmail = styled(TextField)``

export const StyledFields = styled.div`
  margin: ${({ theme }) => theme.spacing.lg}px 0
    ${({ theme }) => theme.spacing.xl}px;

  && {
    > * {
      margin-bottom: ${({ theme }) => theme.spacing.md}px;
    }
  }
`

export const ContactNumber = styled.div`
  display: flex;
`

export const StyledConfigItem = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  padding-bottom: ${({ theme }) => theme.spacing.lg}px;
`

export const Extension = styled(TextField)`
  && {
    width: 140px;
    margin-left: ${({ theme }) => theme.spacing.md}px;
  }
`

export const Actions = styled.footer`
  display: flex;
  width: 320px;
  margin: 0 auto;

  && {
    > * {
      margin: 0 ${({ theme }) => theme.spacing.sm}px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

type BoosterCategoryFormProps = InnerFormProps & FormikProps<FormValues>

export function BoosterCategoryForm({
  className,
  handleSubmit,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  onCancel,
  inEditMode,
  hasError,
  themes,
}: BoosterCategoryFormProps): React.ReactElement {
  const onFormSubmit = useCallback((e) => {
    e.stopPropagation()
    handleSubmit(e)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [themeToggleEnabled] = useFeature(FeatureKeys.BoosterThemeToggle)

  if (themeToggleEnabled && hasError) {
    return (
      <EmptyState
        title="Oh no! We’re not able to show this form right now."
        childComponent={
          <span>
            We’ve been notified and will take a look but if the problem persists
            then let us know.
          </span>
        }
      />
    )
  }
  return (
    <form
      onSubmit={onFormSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      <Typography variant="h2">
        {inEditMode ? 'Edit category' : 'Add new category'}
      </Typography>
      <StyledFields>
        <TextField
          id="categoryName"
          name="categoryName"
          label="Category name"
          type="text"
          value={values.categoryName}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched?.categoryName && !!errors.categoryName}
          helperText={touched?.categoryName ? errors.categoryName : ''}
          fullWidth
        />
      </StyledFields>
      {themeToggleEnabled && themes?.length ? (
        <StyledConfigItem>
          Surface this resource category when themes of bullying and harassment
          are detected in the app?
          <Switch
            name="bullyingThemeAssociated"
            id="bullyingThemeAssociated"
            checked={values.bullyingThemeAssociated}
            color="primary"
            onChange={handleChange}
          />
        </StyledConfigItem>
      ) : null}
      <Actions>
        <Button
          variant="outlined"
          type="button"
          color="primary"
          fullWidth
          size="small"
          onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          color="primary"
          size="small">
          {inEditMode ? 'Edit category' : 'Add category'}
        </Button>
      </Actions>
    </form>
  )
}

export default BoosterCategoryForm
