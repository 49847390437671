import { ROLE_ORG_OWNER, ROLE_PARTNER_ADMIN } from 'constants/form'

import { isChnnlAdmin, hasEditRights } from 'modules/authentication/selectors'
import * as groupsActions from 'modules/groups/actions'
import { getGroupsList } from 'modules/groups/selectors'
import { addUndo } from 'modules/undo/actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  withRouter,
  connect(
    // mapStateToProps
    (state, { match }) => ({
      orgUuid: match.params.orgUuid,
      hasEditAccess: state.user.canEdit || hasEditRights(state),
      groups: getGroupsList(state),
      members: state.groups.selected && state.groups.selected.members,
      getGroupMembersProgress: state.progress.getGroupMembers,
      singleResendProgress: state.progress.reinviteGroupMember,
      multipleResendProgress: state.progress.resendMultipleMemberInvites,
      moveMembersProgress: state.progress.moveMembers,
      canTransferOrgOwnership:
        isChnnlAdmin(state) ||
        state.authentication.role === ROLE_ORG_OWNER ||
        state.authentication.role === ROLE_PARTNER_ADMIN,
    }),
    (dispatch) => {
      const actions = {
        ...groupsActions,
        addUndo,
      }
      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
)
