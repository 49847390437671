import { withFormik } from 'formik'
import { compose, withState, withHandlers } from 'recompose'
import * as Yup from 'yup'

export default compose(
  withState('isAddBoosterModalOpen', 'setIsAddBoosterModalOpen', false),
  withState('isConfirmUpdateModalOpen', 'setIsConfirmUpdateModalOpen', false),
  withState('editBoosterIndex', 'setEditBoosterIndex', null),
  withState('boosterCategoryIndex', 'setBoosterCategoryIndex', null),
  withState(
    'isBoosterCategoryModalOpen',
    'setIsBoosterCategoryModalOpen',
    false,
  ),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ boosterCategories }) => ({
      boosterCategories: boosterCategories || [],
    }),
    validationSchema: Yup.object({
      boosterCategories: Yup.array().of(
        Yup.object({
          categoryName: Yup.string().required('Please enter a category name'),
          // TODO: Requires at least one booster per category for form to save.
          // Doesn't work well with an empty Chatbot category.
          // boosters: Yup.array().min(1, 'Please add a booster item'),
        }),
      ),
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
      if (props.isConfirmUpdateModalOpen) {
        // If the confirmation modal is already open, submit the form
        setSubmitting(true)
        // If form has no error
        if (props.onSubmit) {
          props.onSubmit(values)
        }

        props.setIsConfirmUpdateModalOpen(false)
      } else {
        // Open confirmation modal before submitting the form
        props.setIsConfirmUpdateModalOpen(true)
      }
    },
  }),
  withHandlers({
    onCloseAddBoosterModal:
      ({
        setIsAddBoosterModalOpen,
        setEditBoosterIndex,
        setBoosterCategoryIndex,
      }) =>
      () => {
        setIsAddBoosterModalOpen(false)
        setEditBoosterIndex(null)
        setBoosterCategoryIndex(null)
      },
    onCloseBoosterCategoryModal:
      ({ setIsBoosterCategoryModalOpen, setBoosterCategoryIndex }) =>
      () => {
        setIsBoosterCategoryModalOpen(false)
        setBoosterCategoryIndex(null)
      },
  }),
  withHandlers({
    onAddBoosterSubmit:
      ({
        values,
        boosterCategoryIndex,
        onCloseAddBoosterModal,
        setFieldValue,
        editBoosterIndex,
      }) =>
      (form) => {
        if (boosterCategoryIndex === null) {
          return
        }

        const existingBoosterCategory =
          values.boosterCategories[boosterCategoryIndex]

        if (editBoosterIndex === null) {
          // If adding a new booster item to an existing booster category
          setFieldValue('boosterCategories', [
            ...values.boosterCategories.slice(0, boosterCategoryIndex),
            {
              ...existingBoosterCategory,
              boosters: [
                ...existingBoosterCategory.boosters,
                // Add the new booster item to the booster category
                {
                  ...form,
                },
              ],
            },
            ...values.boosterCategories.slice(boosterCategoryIndex + 1),
          ])
        } else {
          // if editing an existing booster item in a booster category
          setFieldValue('boosterCategories', [
            ...values.boosterCategories.slice(0, boosterCategoryIndex),
            {
              ...existingBoosterCategory,
              boosters: [
                ...existingBoosterCategory.boosters.slice(0, editBoosterIndex),
                // Edit the booster item for the booster category
                {
                  ...form,
                },
                ...existingBoosterCategory.boosters.slice(editBoosterIndex + 1),
              ],
            },
            ...values.boosterCategories.slice(boosterCategoryIndex + 1),
          ])
        }

        // Remove category index and close the modal
        onCloseAddBoosterModal()
      },
    onAddBoosterCategory:
      ({ values, setFieldValue }) =>
      () => {
        setFieldValue('boosterCategories', [
          {
            categoryName: '',
            boosters: [],
          },
          ...values.boosterCategories,
        ])
      },
    onDeleteBoosterCategory:
      ({ values, setFieldValue }) =>
      (categoryIndex) => {
        setFieldValue(
          'boosterCategories',
          values.boosterCategories.filter(
            (category, index) => index !== categoryIndex,
          ),
        )
      },
    onDeleteBoosterItem:
      ({ values, setFieldValue }) =>
      ({ boosterCategoryIndex, boosterItemIndex }) => {
        const existingBoosterCategory =
          values.boosterCategories[boosterCategoryIndex]

        setFieldValue('boosterCategories', [
          ...values.boosterCategories.slice(0, boosterCategoryIndex),
          {
            ...existingBoosterCategory,
            boosters: existingBoosterCategory.boosters.filter(
              (b, index) => index !== boosterItemIndex,
            ),
          },
          ...values.boosterCategories.slice(boosterCategoryIndex + 1),
        ])
      },
    onDragEnd:
      ({ values, setFieldValue }) =>
      (result) => {
        // dropped outside the list
        if (!result.destination) {
          return
        }

        // Return the reordered list
        const reorderedList = Array.from(values.boosterCategories)
        const [removed] = reorderedList.splice(result.source.index, 1)
        reorderedList.splice(result.destination.index, 0, removed)

        setFieldValue('boosterCategories', reorderedList)
      },
    onBoosterCategorySubmit:
      ({
        values,
        setFieldValue,
        boosterCategoryIndex,
        onCloseBoosterCategoryModal,
      }) =>
      (form) => {
        const existingBoosterCategory =
          values.boosterCategories[boosterCategoryIndex]

        setFieldValue('boosterCategories', [
          ...values.boosterCategories.slice(0, boosterCategoryIndex),
          {
            ...existingBoosterCategory,
            categoryName: form.categoryName,
            themes: form.themes,
          },
          ...values.boosterCategories.slice(boosterCategoryIndex + 1),
        ])
        onCloseBoosterCategoryModal()
      },
  }),
)
