import { ORG_DASHBOARD_OVERVIEW } from 'constants/routes'

import Button from '@weareroam/cake-ui-v1/Button'
import ContentContainer from 'components/atoms/ContentContainer'
import ProgressLoader from 'components/atoms/ProgressLoader'
import SearchField from 'components/atoms/SearchField'
import EmptyState from 'components/molecules/EmptyState'
import EntityCard from 'components/molecules/EntityCard'
import { Modal } from 'components/molecules/Modal'
import LinkOrganisationToPartnerForm from 'components/organisms/LinkOrganisationToPartnerForm'
import NewOrganisationForm from 'components/organisms/NewOrganisationForm'
import UnlinkOrganisationFromPartnerForm from 'components/organisms/UnlinkOrganisationFromPartnerForm'
import useEntitiesSearch from 'hooks/useEntitiesSearch'
import PartnerInnerLayout from 'layouts/PartnerInnerLayout'
import PropTypes from 'prop-types'
import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { replaceRouteParams } from 'utils/routes'

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`
export const StyledSearch = styled(SearchField)`
  width: 400px;
`

export const StyledDeleteModal = styled(Modal)`
  width: 450px;
`

export const StyledItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* Negative margin for items on the edges */
  margin: -${({ theme }) => theme.spacing.md}px;
  max-width: calc(100% + ${({ theme }) => theme.spacing.md * 2}px);
`

export const Organisation = styled(EntityCard)`
  width: 230px;
  margin: ${({ theme }) => theme.spacing.md}px;
`

export const StyledHeaderButtons = styled.div`
  // display: flex;
  // float: right;
  display: inline-block;
  float: right;
`

export const NewOrganisationButton = styled(Button)`
  && {
    margin-left: ${({ theme }) => theme.spacing.sm}px;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function PartnerOrganisations({
  setIsAddNewOrganisationToPartnerModalOpen,
  isAddNewOrganisationToPartnerModalOpen,
  setIsUnlinkOrganisationFromPartnerModalOpen,
  isUnlinkOrganisationFromPartnerModalOpen,
  onUnlinkOrganisationFromPartner,
  isLinkExistingOrganisationToPartnerModalOpen,
  setIsLinkExistingOrganisationToPartnerModalOpen,
  onLinkOrganisationToPartner,
  onCreateNewPartnerOrganisation,
  partnerOrganisations,
  modalPartnerOrganisationId,
  setModalPartnerOrganisationId,
  partnerOrganisationEntities,
  partners,
  allOrganisations,
  partnerlessOrganisations,
  getPartnerOrganisationProgress,
}) {
  const { partnerUuid } = useParams()

  // TODO: Implement in the next PR
  const { searchFilter, handleChangeSearchFilter, filteredEntities } =
    useEntitiesSearch(partnerOrganisations, 'organisation.name')

  return (
    <PartnerInnerLayout>
      {/* New organisation form modal */}
      <Modal
        open={isAddNewOrganisationToPartnerModalOpen}
        onClose={() => setIsAddNewOrganisationToPartnerModalOpen(false)}
        hasCloseButton>
        <NewOrganisationForm
          onSubmit={onCreateNewPartnerOrganisation}
          partners={partners}
          partner={partners[partnerUuid]}
          onClose={() => setIsAddNewOrganisationToPartnerModalOpen(false)}
        />
      </Modal>
      <Modal
        open={isLinkExistingOrganisationToPartnerModalOpen}
        onClose={() => setIsLinkExistingOrganisationToPartnerModalOpen(false)}
        hasCloseButton>
        <LinkOrganisationToPartnerForm
          onSubmit={onLinkOrganisationToPartner}
          allOrganisations={allOrganisations}
          partnerlessOrganisations={partnerlessOrganisations}
          onClose={() => {
            setIsLinkExistingOrganisationToPartnerModalOpen(false)
          }}
        />
      </Modal>

      <StyledDeleteModal
        open={isUnlinkOrganisationFromPartnerModalOpen}
        onClose={() => setIsUnlinkOrganisationFromPartnerModalOpen(false)}
        hasCloseButton>
        <UnlinkOrganisationFromPartnerForm
          linkedOrganisation={
            partnerOrganisationEntities[modalPartnerOrganisationId]
          }
          onSubmit={onUnlinkOrganisationFromPartner}
          onCancel={() => setIsUnlinkOrganisationFromPartnerModalOpen(false)}
        />
      </StyledDeleteModal>

      <div>
        <ContentContainer>
          <StyledHeader>
            <StyledSearch
              placeholder="Search"
              value={searchFilter}
              onChange={handleChangeSearchFilter}
            />
            <StyledHeaderButtons>
              <NewOrganisationButton
                type="button"
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setIsAddNewOrganisationToPartnerModalOpen(true)}
                id="new-organisation-button">
                New organisation
              </NewOrganisationButton>
              <NewOrganisationButton
                type="button"
                variant="contained"
                color="primary"
                size="small"
                onClick={() =>
                  setIsLinkExistingOrganisationToPartnerModalOpen(true)
                }
                id="existing-organisation-button">
                Add existing organisation
              </NewOrganisationButton>
            </StyledHeaderButtons>
          </StyledHeader>
        </ContentContainer>
        <ContentContainer>
          {getPartnerOrganisationProgress ? (
            <ProgressLoader fullWidth />
          ) : filteredEntities.length ? (
            <StyledItems>
              {filteredEntities.map((partnerOrg) => {
                const unlinkAction = {
                  key: 'delete',
                  label: 'Unlink Organisation',
                  onClick: () => {
                    setIsUnlinkOrganisationFromPartnerModalOpen(true)
                    setModalPartnerOrganisationId(partnerOrg.uuid)
                  },
                }

                const regularActions = [
                  {
                    ...unlinkAction,
                  },
                ]

                return (
                  <Organisation
                    dataQa={partnerOrg.organisation.name}
                    key={partnerOrg.uuid}
                    to={replaceRouteParams(ORG_DASHBOARD_OVERVIEW, {
                      orgUuid: partnerOrg.organisation.uuid,
                    })}
                    title={partnerOrg.organisation.name}
                    logo={partnerOrg.organisation.logo}
                    footerText={`${partnerOrg.adminCount} admins`}
                    actions={regularActions}
                  />
                )
              })}
            </StyledItems>
          ) : (
            <EmptyState
              title="Nothing to chnnl here yet."
              text="Get started by adding a new organisation."
            />
          )}
        </ContentContainer>
      </div>
    </PartnerInnerLayout>
  )
}

PartnerOrganisations.propTypes = {
  isAddNewOrganisationToPartnerModalOpen: PropTypes.bool,
  isAddExistingOrganisationToPartnerModalOpen: PropTypes.bool,
  setIsAddNewOrganisationToPartnerModalOpen: PropTypes.func,
  setIsLinkExistingOrganisationToPartnerModalOpen: PropTypes.func,
  isUnlinkOrganisationFromPartnerModalOpen: PropTypes.bool,
  setIsUnlinkOrganisationFromPartnerModalOpen: PropTypes.func,
  isLinkExistingOrganisationToPartnerModalOpen: PropTypes.bool,
  onUnlinkOrganisationFromPartner: PropTypes.func,
  onLinkOrganisationToPartner: PropTypes.func,
  onCreateNewPartnerOrganisation: PropTypes.func,
  partnerOrganisations: PropTypes.arrayOf(PropTypes.object),
  modalPartnerOrganisationId: PropTypes.string,
  setModalPartnerOrganisationId: PropTypes.func,
  organisationEntities: PropTypes.object,
  partnerOrganisationEntities: PropTypes.object,
  partners: PropTypes.object,
  allOrganisations: PropTypes.arrayOf(PropTypes.object),
  partnerlessOrganisations: PropTypes.arrayOf(PropTypes.object),
  getPartnerOrganisationProgress: PropTypes.object,
}

PartnerOrganisations.defaultProps = {
  partnerOrganisations: [],
  partnerOrganisationEntities: {},
  partners: {},
  allOrganisations: [],
  partnerlessOrganisations: [],
}

export default PartnerOrganisations
