import { Button } from '@weareroam/cake-ui-v1'
import Typography from '@weareroam/cake-ui-v1/Typography'
import React from 'react'
import styled from 'styled-components'

import ProgressLoader from '../../atoms/ProgressLoader'
import { Modal } from '../Modal'

const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
  && {
    margin: ${({ theme }) => theme.spacing.xl}px 0;
  }
`

const ActionsHolder = styled.div`
  display: flex;
  justify-content: center;
`

const ApproveButton = styled(Button)`
  width: 50%;

  && {
    margin: 0 ${({ theme }) => theme.spacing.mdlg}px;
  }
`

const CancelButton = styled(ApproveButton)`
  && {
    // Doesn't exist in our palette...
    // TODO: See if we need a new palette after branding from Previously Unavailable is finalised
    background: #eaf3fe;
  }
`

const StyledModal = styled(Modal)`
  width: 500px;
  padding: ${({ theme }) => theme.spacing.xl}px;
`

interface ApprovalModalProps {
  title: string
  description: string
  actionLabel: string
  cancelLabel?: string
  onApprove: () => void
  onCancel?: () => void
  loading?: boolean
}

export const ApprovalModal = ({
  title,
  description,
  actionLabel,
  cancelLabel,
  onApprove,
  onCancel,
  loading,
}: ApprovalModalProps): React.ReactElement => (
  <StyledModal
    maxWidth={'sm'}
    hasCloseButton={true}
    closeButtonDisabled={loading}
    open={true}
    onClose={onCancel}>
    {loading ? (
      <ProgressLoader fullWidth />
    ) : (
      <>
        <Typography variant="h4">{title}</Typography>
        <Description variant="body1">{description}</Description>
      </>
    )}
    <ActionsHolder>
      <CancelButton
        variant="flat"
        type="button"
        size="small"
        color="primary"
        disabled={loading}
        onClick={onCancel}>
        {cancelLabel || 'Cancel'}
      </CancelButton>
      <ApproveButton
        variant="contained"
        type="submit"
        size="small"
        color="primary"
        disabled={loading}
        onClick={onApprove}>
        {actionLabel}
      </ApproveButton>
    </ActionsHolder>
  </StyledModal>
)
