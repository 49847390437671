import ContentContainer from 'components/atoms/ContentContainer'
import { DatePeriodSelector } from 'components/organisms/DatePeriodSelector/DatePeriodSelector'
import { OverviewReports } from 'components/organisms/OverviewReports/OverviewReports'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import GroupInnerLayout from 'layouts/GroupInnerLayout'
import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { GroupRouteParams } from 'types/routes'
import { getPeriodFromName, Period, PeriodBoundaries } from 'utils/date'

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  background: #ffffff;
  border: 1px solid #dae1ee;
  box-shadow: inset 0 1px 1px 0 white;
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacing.md}px;
  width: 50%;
`

export const GroupOverview = (): React.ReactElement => {
  const { orgUuid, groupUuid } = useParams<GroupRouteParams>()

  const defaultPeriod: Period = 'last-thirty'

  // Need to use an object here. Otherwise will get race conditions
  //  where one of the dates is set before another triggering bogus
  //  API requests.
  const [period, setPeriod] = React.useState<PeriodBoundaries>(
    getPeriodFromName(defaultPeriod),
  )

  return (
    <AdminDashboardLayout noPadding>
      <GroupInnerLayout>
        <ContentContainer>
          <StyledHeader>
            <DatePeriodSelector
              initialPeriod={defaultPeriod}
              onPeriodChange={setPeriod}
            />
          </StyledHeader>
          <OverviewReports
            orgUuid={orgUuid}
            groupUuid={groupUuid}
            from={period.startDateTime}
            to={period.endDateTime}
          />
        </ContentContainer>
      </GroupInnerLayout>
    </AdminDashboardLayout>
  )
}
