import React, { useState, useMemo, useCallback } from 'react'

import { IPartnerAdmin } from '../models/PartnerAdmin'

export function usePartnerAdminsSearch(partnerAdmins: IPartnerAdmin[]): {
  searchFilter: string
  handleChangeSearchFilter: React.FormEventHandler
  filteredAdmins: IPartnerAdmin[]
} {
  const [searchFilter, setSearchFilter] = useState('')

  const handleChangeSearchFilter = useCallback((e) => {
    setSearchFilter(e.target.value)
  }, [])

  const filteredAdmins = useMemo(
    () =>
      partnerAdmins.filter((partnerAdmin) => {
        const lcSearch = searchFilter.toLowerCase()
        const lcFirstName = partnerAdmin.profile.firstName
          ? partnerAdmin.profile.firstName.toLowerCase()
          : ''
        const lcLastName = partnerAdmin.profile.lastName
          ? partnerAdmin.profile.lastName.toLowerCase()
          : ''
        const lcEmail = partnerAdmin.email.toLowerCase()

        return (
          lcFirstName.startsWith(lcSearch) ||
          lcLastName.startsWith(lcSearch) ||
          lcEmail.startsWith(lcSearch)
        )
      }),
    [partnerAdmins, searchFilter],
  )

  return {
    searchFilter,
    handleChangeSearchFilter,
    filteredAdmins,
  }
}
