import React from 'react'
import styled from 'styled-components'

import { HeaderWithActions } from '../HeaderWithActions/HeaderWithActions'

interface AreaSectionProps {
  heading?: string
  subHeading?: string
  headerActions?: React.ReactNode
  children: React.ReactNode
}

const StyledSection = styled.section`
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  padding: ${({ theme }) => theme.spacing.md}px
    ${({ theme }) => theme.spacing.lg}px;

  border: 2px dashed ${({ theme }) => theme.palette.tertiary.light};
  border-radius: 3px;
`

export function AreaSection({
  heading,
  subHeading,
  headerActions,
  children,
}: AreaSectionProps): React.ReactElement {
  return (
    <StyledSection>
      {(heading || headerActions) && (
        <HeaderWithActions title={heading || ''} description={subHeading || ''}>
          {headerActions}
        </HeaderWithActions>
      )}
      {children}
    </StyledSection>
  )
}
