/*
  Partitions an array into two arrays based on predicate, example usage:
  const [negative, positive ] = partition<ReflectionImpactPoint>(reflectionReport, (x) => x.effect < 0)
*/
export function partition<T = never>(
  array: T[],
  predicate: (x: T) => boolean,
): T[][] {
  return array.reduce(
    (acc: T[][], e) => {
      acc[predicate(e) ? 0 : 1].push(e)
      return acc
    },
    [[], []],
  )
}

/*
    Returns new array sorted on given attribute by absolute value
  */
export const sortOnAttributeByAbsoluteValue = <T = Record<string, unknown>>(
  array: T[],
  key: keyof T,
): T[] =>
  array.slice().sort((a, b) => {
    const aVal = Math.abs(Number(a[key]))
    const bVal = Math.abs(Number(b[key]))
    return bVal - aVal
  })
