import { API_ISO_DATE_FORMAT } from 'constants/date'
import { ReportErrorType } from 'constants/reports'

import axios from 'axios'
import { SAUCE_API_URL } from 'config'
import { format } from 'date-fns'
import { useState, useEffect } from 'react'
import { ErrorPayload } from 'types/services'

// Keep in sync with backend/chnnl-survey/models/reflections-report/
export interface ReflectionResponse {
  title: string
  count: number
  sentiment: 'positive' | 'negative'
}

export interface ReflectionResponses {
  total: number
  reflections: ReflectionResponse[]
  max: number
}

export const useReflectionCountReport = ({
  from,
  to,
  orgUuid,
  groupUuid,
}: {
  from: Date
  to: Date
  orgUuid: string
  groupUuid?: string
}): {
  data?: ReflectionResponses
  isLoading: boolean
  error?: ReportErrorType
} => {
  const [reportData, setReportData] = useState<ReflectionResponses | undefined>(
    undefined,
  )
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<ReportErrorType | undefined>(undefined)

  const startDate = format(from, API_ISO_DATE_FORMAT)
  const endDate = format(to, API_ISO_DATE_FORMAT)

  useEffect(() => {
    const fetchReflectionResponses = async () => {
      const url = !groupUuid
        ? `${SAUCE_API_URL}/organisation/${orgUuid}/report/reflections-count`
        : `${SAUCE_API_URL}/organisation/${orgUuid}/group/${groupUuid}/report/reflections-count`
      setIsLoading(true)
      setError(undefined)
      try {
        const response = await axios.get(url, {
          params: {
            startDate,
            endDate,
          },
        })
        const data = response.data.data[0]
        setReportData(data)
      } catch (error: unknown) {
        const brandedError = error as ErrorPayload
        if (
          brandedError?.response?.data?.status === 403 &&
          brandedError?.response?.data?.message.includes('needs at least')
        ) {
          setError(ReportErrorType.MIN_SAMPLE_REQUIRED)
        } else {
          setError(ReportErrorType.GENERIC)
        }
      }
      setIsLoading(false)
    }
    fetchReflectionResponses()
  }, [
    setReportData,
    setIsLoading,
    setError,
    from,
    to,
    orgUuid,
    groupUuid,
    startDate,
    endDate,
  ])

  return {
    data: reportData,
    error,
    isLoading,
  }
}
