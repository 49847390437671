import { ReflectionSentimentEnum } from 'constants/ReflectionSentimentEnum'

import { IAutoSuggestItem } from 'models/IAutoSuggestItem'
import { IDayType } from 'models/IDayType'
import { IReflectionSubQuestion } from 'models/IReflectionSubQuestion'

import { default as ReflectionItemFormElement } from './ReflectionItemForm'
import { enhance } from './ReflectionItemForm.enhancer'

export interface FormProps {
  initialTitle: string
  initialSentiment: ReflectionSentimentEnum | ''
  initialDayTypes: IDayType[]
  initialSubQuestions: IReflectionSubQuestion[]
}

export interface ReflectionItemFormValues {
  title: string
  sentiment: ReflectionSentimentEnum | ''
  dayTypes: string[]
  subQuestions: IReflectionSubQuestion[]
  textInput: string
}

export interface InnerFormProps {
  onCancel: () => void
  onSubmit: (value: ReflectionItemFormValues) => void
  inEditMode: boolean
  typesOfDays: IAutoSuggestItem[]
}

export interface HandlerProps {
  setSentiment: (sentiment: ReflectionSentimentEnum) => void
  onAddChip: (chip: string) => void
  onDeleteChip: (chip: string) => void
}

export const ReflectionItemForm = enhance(ReflectionItemFormElement)
