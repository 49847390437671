import { Button, TextField } from '@weareroam/cake-ui-v1'
import { ChevronDown } from 'components/atoms/Icons/ChevronDown'
import { ChevronUp } from 'components/atoms/Icons/ChevronUp'
import { FormikErrors, FormikTouched } from 'formik'
import { IReflectionSubQuestion } from 'models/IReflectionSubQuestion'
import React from 'react'
import styled from 'styled-components'
import { numberToWord } from 'utils/numberToWord'

const StyledRoot = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.mdlg}px;
`
const StyledChevronDown = styled(ChevronDown)`
  && {
    font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  }
`
const StyledChevronUp = styled(ChevronUp)`
  && {
    font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  }
`
const StyledTextFieldHolder = styled.div`
  position: relative;
`
const StyledTextField = styled(TextField)`
  && {
    margin-top: ${({ theme }) => theme.spacing.sm}px;
  }
`
const StyledArrowButtonHolder = styled.div`
  position: absolute;
  top: 20px;
  right: 0px;
`
const StyledArrowButton = styled(Button)`
  && {
    padding: 4px 5px;
    min-width: 20px;
  }
`
export const StyledOptionsRow = styled.div`
  display: flex;
  justify-content: space-between;
`
const StyledDeleteButtonHolder = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`
const StyledDeleteButton = styled(Button)`
  && {
    padding: 4px 5px;
  }
`

interface IReflectionItemFormSubQuestionProps {
  index: number
  subQuestion: IReflectionSubQuestion
  touched: FormikTouched<IReflectionSubQuestion> | undefined
  errors: FormikErrors<IReflectionSubQuestion>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleBlur?: React.EventHandler<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange?: React.EventHandler<any>
  handleDelete: (index: number) => void
}

const ReflectionItemFormSubQuestion = ({
  index,
  subQuestion,
  handleBlur,
  handleChange,
  touched,
  errors,
  handleDelete,
}: IReflectionItemFormSubQuestionProps): React.ReactElement => {
  const isOpened = !subQuestion?.title
  const [opened, setOpened] = React.useState<boolean>(isOpened)
  const handleDisplay = React.useCallback(() => {
    setOpened(!opened)
  }, [opened])
  return (
    <StyledRoot>
      <StyledTextFieldHolder>
        <TextField
          id={`subQuestions.${index}.title`}
          name={`subQuestions.${index}.title`}
          label={`Sub-question ${numberToWord(index + 1)}`}
          type="text"
          value={subQuestion.title}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched?.title && !!errors?.title}
          helperText={touched?.title && errors?.title ? errors?.title : ''}
          fullWidth
        />
        <StyledArrowButtonHolder>
          <StyledArrowButton
            variant="text"
            type="button"
            color="secondary"
            size="small"
            onClick={handleDisplay}>
            {opened ? <StyledChevronUp /> : <StyledChevronDown />}
          </StyledArrowButton>
        </StyledArrowButtonHolder>
      </StyledTextFieldHolder>
      {opened && (
        <div>
          <StyledOptionsRow>
            <StyledTextField
              id={`subQuestions.${index}.option1`}
              name={`subQuestions.${index}.option1`}
              label="Sub-option one"
              type="text"
              value={subQuestion.option1}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched?.option1 && !!errors?.option1}
              helperText={
                touched?.option1 && errors?.option1 ? errors?.option1 : ''
              }
            />
            <StyledTextField
              id={`subQuestions.${index}.option2`}
              name={`subQuestions.${index}.option2`}
              label="Sub-option two"
              type="text"
              value={subQuestion.option2}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched?.option2 && !!errors?.option2}
              helperText={
                touched?.option2 && errors?.option2 ? errors?.option2 : ''
              }
            />
          </StyledOptionsRow>
          <StyledOptionsRow>
            <StyledTextField
              id={`subQuestions.${index}.option3`}
              name={`subQuestions.${index}.option3`}
              label="Sub-option three"
              type="text"
              value={subQuestion.option3}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched?.option3 && !!errors?.option3}
              helperText={
                touched?.option3 && errors?.option3 ? errors?.option3 : ''
              }
            />
            <StyledDeleteButtonHolder>
              <StyledDeleteButton
                variant="text"
                type="button"
                color="primary"
                size="small"
                onClick={() => handleDelete(index)}>
                Delete sub-question
              </StyledDeleteButton>
            </StyledDeleteButtonHolder>
          </StyledOptionsRow>
        </div>
      )}
    </StyledRoot>
  )
}

export default ReflectionItemFormSubQuestion
