import { UTC_FORMAT } from 'constants/date'
import { MOOD_OVERALL, Moods } from 'constants/form'

import axios from 'axios'
import { format } from 'date-fns'
import { useState, useEffect } from 'react'

import { SAUCE_API_URL } from '../config'
import { ReportErrorType } from '../constants/reports'
import { ErrorPayload } from '../types/services'

export interface WellbeingBarItem {
  label: string
  averageMood: number
  totalUsers: number
}

export const useWellbeingReport = ({
  from,
  to,
  mood,
  orgUuid,
  groupUuid,
}: {
  from: Date
  to: Date
  mood: Moods
  orgUuid: string
  groupUuid?: string
}): {
  data?: WellbeingBarItem[]
  isLoading: boolean
  error?: ReportErrorType
} => {
  const [reportData, setReportData] = useState<WellbeingBarItem[] | undefined>(
    undefined,
  )
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<ReportErrorType | undefined>(undefined)

  const startDate = format(from, UTC_FORMAT)
  const endDate = format(to, UTC_FORMAT)

  useEffect(() => {
    const fetchWellbeingMood = async () => {
      setIsLoading(true)
      setError(undefined)
      try {
        const response = await axios.get(
          `${SAUCE_API_URL}/wellbeing/user-baskets`,
          {
            params: {
              orgs: [orgUuid],
              mood: mood === MOOD_OVERALL ? null : mood,
              startDate,
              endDate,
              ...(groupUuid ? { groupUuids: [groupUuid] } : {}),
            },
          },
        )
        const data = response.data.data
        setReportData(data)
      } catch (error: unknown) {
        const brandedError = error as ErrorPayload
        if (
          brandedError?.response?.data?.status === 403 &&
          brandedError?.response?.data?.message.includes('needs at least')
        ) {
          setError(ReportErrorType.MIN_SAMPLE_REQUIRED)
        } else {
          setError(ReportErrorType.GENERIC)
        }
      }
      setIsLoading(false)
    }
    fetchWellbeingMood()
  }, [
    setReportData,
    setIsLoading,
    setError,
    orgUuid,
    groupUuid,
    mood,
    startDate,
    endDate,
  ])

  return {
    data: reportData,
    isLoading,
    error,
  }
}
