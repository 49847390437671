import Checkbox from '@weareroam/cake-ui-v1/Checkbox'
import FormControlLabel from '@weareroam/cake-ui-v1/FormControlLabel/FormControlLabel'
import Typography from '@weareroam/cake-ui-v1/Typography'
import {
  ModalFormActionButton,
  ModalFormActions,
  ModalFormBody,
  ModalFormFields,
  ModalFormTextField,
  ModalFormTitle,
} from 'components/atoms/ModalForm/ModalForm'
import { Modal } from 'components/molecules/Modal/Modal'
import { useFormik } from 'formik'
import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'

import {
  SupportOptionNames,
  SupportOptionTypes,
} from '../../../constants/SupportOptionTypesEnum'
import {
  SupportTriggerNames,
  SupportTriggerTypes,
} from '../../../constants/SupportTriggerTypesEnum'
import { useThemes } from '../../../hooks/useThemes'
import { useWellbeingResources } from '../../../hooks/useWellbeingResources'
import { SupportOption } from '../../../modules/supportOption/schema'
import { OrganisationRouteParams } from '../../../types/routes'

const StyledModal = styled(Modal)`
  width: 662px;
  min-height: 580px;
`

const FormBody = styled(ModalFormBody)`
  height: 484px;
`

export const ResourcesSection = styled.div`
  display: flex;
  flex-direction: column;
`

const ResourceCheckbox = styled(FormControlLabel).attrs({
  classes: {
    label: 'checkbox-label-class',
  },
})`
  width: 100%;
  font-weight: inherit;
  && {
    &:not(:last-child) {
      margin-bottom: 0px;
      border-bottom: 1px solid #adb9cb;
    }
  }

  .checkbox-label-class {
    font-weight: inherit;
    white-space: nowrap;
  }
`

interface Props {
  open: boolean
  triggerType: SupportTriggerTypes
  supportOption: SupportOption
  loading?: boolean
  onCancel: () => void
  onSubmit: (supportOption: SupportOption) => void
}

export const SupportOptionUpdateModal = ({
  open,
  triggerType,
  supportOption,
  onCancel,
  onSubmit,
}: Props): React.ReactElement => {
  const promptMaxLength = 30

  const { orgUuid } = useParams<OrganisationRouteParams>()
  const {
    categoriesList: allResourceCategories,
    isLoading: isLoadingResources,
  } = useWellbeingResources({
    orgUuid,
  })

  const { data: allThemes } = useThemes()

  const standardValidation = Yup.object({
    primaryActionText: Yup.string()
      .nullable()
      .max(
        promptMaxLength,
        'Please keep the prompt within the character limit',
      ),
  })

  const resourcesValidation = Yup.object({
    itemIds: Yup.array().min(1),
  })

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    setFieldValue,
  } = useFormik<SupportOption>({
    initialValues: {
      ...supportOption,
    },
    validationSchema:
      supportOption.type === SupportOptionTypes.RESOURCES
        ? resourcesValidation
        : standardValidation,
    onSubmit: (values) => {
      onSubmit(values)
    },
  })

  const renderResources = (): React.ReactElement<typeof ResourcesSection> => (
    <ResourcesSection>
      <Typography variant="h3">Themes</Typography>
      {allThemes &&
        allThemes.map((theme) => (
          <ResourceCheckbox
            key={theme.key}
            label={theme.title}
            onChange={() => {
              // Nothing yet, adding the first one.
              if (!values.themes) {
                setFieldValue('themes', [theme.key])
                return
              }
              if (
                values.themes.find(
                  (selectedTheme) => selectedTheme === theme.key,
                )
              ) {
                setFieldValue(
                  'themes',
                  values.themes.filter(
                    (selectedTheme) => selectedTheme !== theme.key,
                  ),
                )
              } else {
                setFieldValue('themes', [theme.key, ...values.themes])
              }
            }}
            control={
              <Checkbox
                value=""
                color="primary"
                checked={!!values.themes?.find((e) => e === theme.key)}
              />
            }
          />
        ))}
      <Typography variant="h1">&nbsp;</Typography>
      <Typography variant="h3">Individual Resources</Typography>
      {allResourceCategories &&
        allResourceCategories.map((category) =>
          // .sort() is mutating. Creating a copy. These entities come directly from Redux state and shouldn't be mutated.
          [...category.boosters]
            .sort((a, b) => a.heading?.localeCompare(b.heading || ''))
            .map((resource) => (
              <ResourceCheckbox
                key={resource.uuid}
                label={resource.heading}
                onChange={() => {
                  // Nothing yet, adding the first one.
                  if (!values.itemIds) {
                    setFieldValue('itemIds', [resource.uuid])
                    return
                  }
                  if (
                    values.itemIds.find(
                      (selectedResourceUuid) =>
                        selectedResourceUuid === resource.uuid,
                    )
                  ) {
                    setFieldValue(
                      'itemIds',
                      values.itemIds.filter(
                        (selectedResourceUuid) =>
                          selectedResourceUuid !== resource.uuid,
                      ),
                    )
                  } else {
                    setFieldValue('itemIds', [resource.uuid, ...values.itemIds])
                  }
                }}
                control={
                  <Checkbox
                    value=""
                    color="primary"
                    checked={
                      !!values.itemIds?.find(
                        (selectedResourceUuid) =>
                          selectedResourceUuid === resource.uuid,
                      )
                    }
                  />
                }
              />
            )),
        )}
      {touched.itemIds && !!errors.itemIds && (
        <Typography>At least one resource required</Typography>
      )}
    </ResourcesSection>
  )

  return (
    <StyledModal
      open={open}
      maxWidth={'md'}
      hasCloseButton={true}
      onClose={onCancel}>
      <FormBody>
        <ModalFormTitle variant="h2">
          {SupportTriggerNames[triggerType]}{' '}
          {SupportOptionNames[supportOption.type]}
        </ModalFormTitle>
        <ModalFormFields>
          <ModalFormTextField
            id="primaryActionText"
            name="primaryActionText"
            label="Prompt"
            type="text"
            disabled={isLoadingResources}
            value={values.primaryActionText}
            fullWidth
            error={touched.title && !!errors.title}
            helperText={touched.title ? errors.title : ''}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {supportOption.type === SupportOptionTypes.RESOURCES &&
            renderResources()}
        </ModalFormFields>
        <ModalFormActions>
          <ModalFormActionButton
            size="small"
            variant="outlined"
            type="submit"
            color="primary"
            disabled={isLoadingResources}
            onClick={onCancel}>
            Cancel
          </ModalFormActionButton>
          <ModalFormActionButton
            size="small"
            variant="contained"
            type="submit"
            color="primary"
            disabled={isLoadingResources || !isValid}
            onClick={() => handleSubmit()}>
            Update
          </ModalFormActionButton>
        </ModalFormActions>
        <Typography variant="h1">&nbsp;</Typography>
      </FormBody>
    </StyledModal>
  )
}
