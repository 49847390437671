import React from 'react'

// eslint-disable-next-line react/display-name
export const ChevronDown = React.memo(() => (
  <svg
    width="17"
    height="11"
    viewBox="0 0 17 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.51719 5.29889C8.32193 5.49415 8.00535 5.49415 7.81008 5.29889L2.65764 0.146446C2.46238 -0.0488157 2.14579 -0.0488155 1.95053 0.146447L0.146447 1.95053C-0.0488156 2.14579 -0.0488153 2.46238 0.146447 2.65764L8.16348 10.6747C8.16351 10.6747 8.16356 10.6747 8.16359 10.6747C8.16361 10.6746 8.16366 10.6746 8.16369 10.6747C8.16372 10.6747 8.16376 10.6747 8.16379 10.6747L16.1808 2.65764C16.3761 2.46238 16.3761 2.14579 16.1808 1.95053L14.3767 0.146446C14.1815 -0.048816 13.8649 -0.0488163 13.6696 0.146446L8.51719 5.29889Z"
      fill="#979797"
    />
  </svg>
))
