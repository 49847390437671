import {
  Input,
  InputAdornment,
  IconButton,
  TooltipWithCaret,
  Slide,
} from '@weareroam/cake-ui-v1'
import { FileCopyOutlined } from '@weareroam/cake-ui-v1-icons'
import PropTypes from 'prop-types'
import React, { useState, useRef } from 'react'
import styled from 'styled-components'

export const StyledInputAdornment = styled(InputAdornment)`
  padding-right: 0;
`
export const StyledInput = styled(Input)`
  width: 100%;
  height: 2rem;
  && {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const StyledEmptyState = styled.div`
  width: 100%;
  height: 2rem;
  && {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ClipBoardTray = ({
  value,
  disableUnderline,
  disabled,
  hidden,
}) => {
  const inputRef = useRef(null)
  const [copySuccess, setCopySuccess] = useState('')

  const handleClick = (event) => {
    if (inputRef && inputRef.current) {
      inputRef.current.select()
    }
    document.execCommand('copy')
    event.target.focus()
    setCopySuccess('Copied!')
    setTimeout(() => {
      setCopySuccess('')
    }, 3000)
  }

  return (
    <Slide direction="left" in={!hidden}>
      <StyledInput
        type="url"
        value={value}
        size="small"
        inputRef={inputRef}
        disableUnderline={disableUnderline}
        disable={disabled}
        endAdornment={
          <StyledInputAdornment position="end" edge="end">
            <TooltipWithCaret
              placement="top"
              open={!!copySuccess}
              title={copySuccess}>
              <IconButton
                aria-label="copy to clipboard"
                onClick={handleClick}
                edge="end"
                disable={disabled}>
                <FileCopyOutlined />
              </IconButton>
            </TooltipWithCaret>
          </StyledInputAdornment>
        }
      />
    </Slide>
  )
}

ClipBoardTray.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  disableUnderline: PropTypes.bool,
}

export default ClipBoardTray
