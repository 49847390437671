import { Typography } from '@weareroam/cake-ui-v1'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import React from 'react'
import styled from 'styled-components'

const StyledDefaultLayout = styled(DefaultLayout)`
  && {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    background-color: rgb(170, 213, 183);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: flex-end;
    overflow-y: hidden;
  }
`

export const CustomLayout = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${({ theme }) => theme.spacing.md}px;
    flex-direction: column;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const SignUpSuccess = () => (
  <StyledDefaultLayout>
    <CustomLayout>
      <Typography variant="h1">Done! </Typography>
      <br />
      <Typography variant="h2">
        You should receive a text message soon
      </Typography>
    </CustomLayout>
  </StyledDefaultLayout>
)

export default SignUpSuccess
