import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import ContentContainer from '../../../../components/atoms/ContentContainer'
import { HeaderButton } from '../../../../components/atoms/HeaderButton/HeaderButton'
import ProgressLoader from '../../../../components/atoms/ProgressLoader'
import EmptyState from '../../../../components/molecules/EmptyState'
import { TitleWithActions } from '../../../../components/molecules/TitleWithActions/TitleWithActions'
import { SupportTriggerCard } from '../../../../components/organisms/SupportTriggerCard/SupportTriggerCard'
import {
  SupportTriggerNames,
  SupportTriggerTypes,
} from '../../../../constants/SupportTriggerTypesEnum'
import { emptyTriggerSet } from '../../../../constants/triggers'
import { useSupportTriggers } from '../../../../hooks/useSupportTriggers'
import AdminDashboardLayout from '../../../../layouts/AdminDashboardLayout'
import AppLayout from '../../../../layouts/AppLayout'
import { SupportOption } from '../../../../modules/supportOption/schema'
import { SupportTrigger } from '../../../../modules/supportTrigger/schema'
import { OrganisationRouteParams } from '../../../../types/routes'

export function SupportOptions(): React.ReactElement {
  const { orgUuid } = useParams<OrganisationRouteParams>()
  const {
    data: supportTriggers,
    isLoading,
    error,
    handlers: {
      create: createSupportTrigger,
      update: updateSupportTrigger,
      remove: removeSupportTrigger,
    },
  } = useSupportTriggers({
    orgUuid,
  })

  // Making a copy of triggers. The page accumulates edits until user presses the save ("Update") button.
  const [editableSupportTriggers, setEditableSupportTriggers] =
    useState<{ [key in SupportTriggerTypes]: SupportTrigger }>(emptyTriggerSet)
  useEffect(() => {
    setEditableSupportTriggers(structuredClone(supportTriggers))
  }, [supportTriggers])

  // Set a flag once any entity is edited, enables the save button.
  const [dirty, setDirty] = useState(false)

  const addOption = (trigger: SupportTrigger, item: SupportOption) => {
    setEditableSupportTriggers({
      ...editableSupportTriggers,
      [trigger.type]: {
        ...trigger,
        options: [...trigger.options, item],
      },
    })
    setDirty(true)
  }

  const updateOption = (trigger: SupportTrigger, item: SupportOption) => {
    setEditableSupportTriggers({
      ...editableSupportTriggers,
      [trigger.type]: {
        ...trigger,
        options: [...trigger.options.filter((e) => e.uuid !== item.uuid), item],
      },
    })
    setDirty(true)
  }

  const removeOption = (trigger: SupportTrigger, item: SupportOption) => {
    setEditableSupportTriggers({
      ...editableSupportTriggers,
      [trigger.type]: {
        ...trigger,
        options: trigger.options.filter((e) => e !== item),
      },
    })
    setDirty(true)
  }

  const saveChanges = () => {
    saveOrDeleteTrigger(editableSupportTriggers.firstSurvey)
    saveOrDeleteTrigger(editableSupportTriggers.standardSurvey)
    saveOrDeleteTrigger(editableSupportTriggers.bullyingReflection)
  }

  const saveOrDeleteTrigger = (trigger: SupportTrigger) => {
    if (trigger.uuid) {
      if (trigger.options.length === 0) {
        removeSupportTrigger(trigger.uuid)
      } else {
        updateSupportTrigger(trigger)
      }
    } else {
      // New trigger
      if (trigger.options.length > 0) {
        createSupportTrigger(trigger)
      }
    }
  }

  if (isLoading) {
    return (
      <AdminDashboardLayout noPadding>
        <AppLayout>
          <ContentContainer>
            <ProgressLoader fullWidth />
          </ContentContainer>
        </AppLayout>
      </AdminDashboardLayout>
    )
  }

  if (error) {
    return (
      <AdminDashboardLayout noPadding>
        <AppLayout>
          <ContentContainer>
            <EmptyState
              title="Oh no! We’re not able to show this section right now."
              childComponent={
                <span>
                  Something went wrong trying to load support options. We’ve
                  been notified and will take a look but if the problem persists
                  then let us know.
                </span>
              }
            />
          </ContentContainer>
        </AppLayout>
      </AdminDashboardLayout>
    )
  }

  return (
    <AdminDashboardLayout noPadding>
      <AppLayout>
        <ContentContainer>
          <TitleWithActions
            title={'Support Options'}
            description={
              "These support options are organisation-wide. The resource options can be edited in the 'Wellbeing Resources' tab on the sidebar."
            }>
            <HeaderButton
              title={'Save Changes'}
              type={'primary'}
              disabled={isLoading || !dirty}
              onClick={saveChanges}
            />
          </TitleWithActions>
          {/* TODO: Respect admin edit rights. */}
          <SupportTriggerCard
            trigger={editableSupportTriggers.firstSurvey}
            title={SupportTriggerNames.firstSurvey}
            description={'Surfaced for the first check-in.'}
            isEditable
            onAddOption={(item) =>
              addOption(editableSupportTriggers.firstSurvey, item)
            }
            onUpdateOption={(item) =>
              updateOption(editableSupportTriggers.firstSurvey, item)
            }
            onDeleteOption={(item) =>
              removeOption(editableSupportTriggers.firstSurvey, item)
            }
          />
          <SupportTriggerCard
            trigger={editableSupportTriggers.standardSurvey}
            title={SupportTriggerNames.standardSurvey}
            description={'Surfaced for every check-in.'}
            isEditable
            onAddOption={(item) =>
              addOption(editableSupportTriggers.standardSurvey, item)
            }
            onUpdateOption={(item) =>
              updateOption(editableSupportTriggers.standardSurvey, item)
            }
            onDeleteOption={(item) =>
              removeOption(editableSupportTriggers.standardSurvey, item)
            }
          />
          <SupportTriggerCard
            trigger={editableSupportTriggers.bullyingReflection}
            title={SupportTriggerNames.bullyingReflection}
            description={
              'Surfaced when themes of bullying and harassment are prevalent.'
            }
            isEditable
            onAddOption={(item) =>
              addOption(editableSupportTriggers.bullyingReflection, item)
            }
            onUpdateOption={(item) =>
              updateOption(editableSupportTriggers.bullyingReflection, item)
            }
            onDeleteOption={(item) =>
              removeOption(editableSupportTriggers.bullyingReflection, item)
            }
          />
        </ContentContainer>
      </AppLayout>
    </AdminDashboardLayout>
  )
}
