import { createSelector } from 'reselect'

export const getTypesOfDaysList = createSelector(
  [
    (state) => state.dayParameters.typesOfDays.entities,
    (state) => state.dayParameters.typesOfDays.orderedIds,
  ],
  (typesOfDays, orderedIds) => orderedIds.map((id) => typesOfDays[id]),
)

export const getDepartmentsList = createSelector(
  [
    (state) => state.dayParameters.departments.entities,
    (state) => state.dayParameters.departments.orderedIds,
  ],
  (departments, orderedIds) => orderedIds.map((id) => departments[id]),
)

export const getRolesList = createSelector(
  [
    (state) => state.dayParameters.roles.entities,
    (state) => state.dayParameters.roles.orderedIds,
  ],
  (roles, orderedIds) => orderedIds.map((id) => roles[id]),
)
