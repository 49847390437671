import Button from '@weareroam/cake-ui-v1/Button'
import TextField from '@weareroam/cake-ui-v1/TextField'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledEmail = styled(TextField)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.xl}px;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ResetPasswordForm({
  className,
  handleSubmit,
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
}) {
  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      <StyledEmail
        id="email"
        name="email"
        label="Email Address"
        type="email"
        value={values.email}
        onBlur={handleBlur}
        onChange={handleChange}
        fullWidth
        error={touched.email && !!errors.email}
        helperText={touched.email ? errors.email : ''}
      />
      <Button
        variant="contained"
        type="submit"
        fullWidth
        color="primary"
        size="large"
        id="reset-password-button">
        Reset password
      </Button>
    </form>
  )
}

ResetPasswordForm.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
}

ResetPasswordForm.defaultProps = {
  errors: {},
  values: {},
  touched: {},
}

export default ResetPasswordForm
