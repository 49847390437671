import { Typography } from '@weareroam/cake-ui-v1'
import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'

import { ClickArea } from '../../atoms/ClickArea/ClickArea'
import { ApproveCircle } from '../../atoms/Icons/ApproveCircle'
import { DeleteCircle } from '../../atoms/Icons/DeleteCircle'
import { EditCircle } from '../../atoms/Icons/EditCircle'

const StyledActionArea = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30px;
`

const StyledLabel = styled(Typography)`
  && {
    margin-top: 5px;
    font-size: 10px;
  }
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

type ActionIconNames = 'approve' | 'edit' | 'delete'

interface Props {
  icon: ActionIconNames
  label?: string
  onClick: MouseEventHandler<HTMLDivElement>
}

function renderIcon(icon: ActionIconNames) {
  switch (icon) {
    case 'approve':
      return <ApproveCircle />
    case 'edit':
      return <EditCircle />
    case 'delete':
      return <DeleteCircle color="#FF025D" />
  }
}

export function ListItemAction({
  icon,
  label,
  onClick,
}: Props): React.ReactElement {
  return (
    <StyledActionArea>
      <ClickArea onClick={onClick} />
      {renderIcon(icon)}
      {label && <StyledLabel>{label}</StyledLabel>}
    </StyledActionArea>
  )
}
