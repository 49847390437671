import { withFormik } from 'formik'
import { compose, withHandlers } from 'recompose'
import * as Yup from 'yup'

import { PartnersConstants } from '../../../constants/PartnersConstantsEnum'

export default compose(
  withFormik({
    mapPropsToValues: () => ({
      orgUuid: PartnersConstants.NO_ORGANISATION_SELECTED,
    }),
    validationSchema: Yup.object({
      orgUuid: Yup.string().required(
        'Please select if an organisation belongs with a partner',
      ),
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
      setSubmitting(true)
      // If form has no error
      if (props.onSubmit) {
        props.onSubmit(values)
      }
    },
  }),
  withHandlers({
    selectOrg:
      ({ setFieldValue }) =>
      (orgUuid) => {
        setFieldValue('orgUuid', orgUuid)
      },
  }),
)
