import * as authActions from 'modules/authentication/actions'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  connect(
    // mapStateToProps
    (
      // eslint-disable-next-line @typescript-eslint/naming-convention
      { authentication: { API_RESET_PASSWORD_SUCCESS } } = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authentication: { API_RESET_PASSWORD_SUCCESS: false },
      },
    ) => ({
      resetPasswordSuccess: API_RESET_PASSWORD_SUCCESS,
    }),
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  withHandlers({
    onSubmit:
      ({ actions, location }) =>
      ({ password }) => {
        const token = location.search.replace('?token=', '')
        actions.resetPassword(password, token)
      },
  }),
)
