import { createSelector } from 'reselect'

import { RootState } from '../RootState'

export const getMessageList = createSelector(
  (state: RootState) => state.messaging.entities,
  (messages) => Object.values(messages),
)

export const getGroupMessagesList = createSelector(
  [getMessageList, (state: RootState, groupUuid: string) => groupUuid],
  (messages, groupUuid) =>
    messages.filter((message) => message.groupUuid === groupUuid),
)

export const getOrganisationMessagesList = createSelector(
  [getMessageList],
  (messages) => messages,
)

export const getGetMessagesProgress = createSelector(
  (state: RootState) => state.messaging.progress.getMessages,
  (requestProgressState) => requestProgressState,
)

export const getGetMessagesPagination = createSelector(
  (state: RootState) => state.messaging.pagination,
  (paginationState) => paginationState,
)
