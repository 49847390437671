import { Switch } from '@weareroam/cake-ui-v1'
import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import ContentContainer from 'components/atoms/ContentContainer'
import ProgressLoader from 'components/atoms/ProgressLoader'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import OrgSettingsLayout from 'layouts/OrgSettingsLayout'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

const StyledHeaderCopy = styled.div``

const StyledHeaderRight = styled.div`
  display: flex;
  width: 180px;
  justify-content: space-between;
  align-items: center;
`

const SubheaderText = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};

  && {
    margin: ${({ theme }) => theme.spacing.sm}px 0;
  }
`

const EntitlementsDiv = styled.div`
  margin: ${({ theme }) => theme.spacing.md}px 0;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

const EntitlementItem = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.secondary.main};
  border: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  box-shadow: inset 0 1px 1px 0 white;
  border-radius: 4px;
  align-items: center;
`

const ItemNameTypography = styled(Typography)`
  padding-left: 10px;
  display: flex;
`

const ResetEntitlementsButton = styled(Button)`
  && {
    margin-left: ${({ theme }) => theme.spacing.md}px;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Entitlements({
  hasEditAccess,
  entitlements,
  getEntitlementsProgress,
  handleUpdateEntitlement,
  handleResetEntitlement,
}) {
  return (
    <AdminDashboardLayout noPadding>
      <OrgSettingsLayout>
        <ContentContainer>
          <StyledHeader>
            <StyledHeaderCopy>
              <Typography variant="h2">Entitlements</Typography>
              <SubheaderText variant="body1">
                Your app users will be able to have a customised platform
                experience based on their entitlement state.
              </SubheaderText>
            </StyledHeaderCopy>
            <StyledHeaderRight>
              <ResetEntitlementsButton
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  handleResetEntitlement()
                }}>
                Reset To Default
              </ResetEntitlementsButton>
            </StyledHeaderRight>
          </StyledHeader>
          {getEntitlementsProgress.inProgress ? (
            <ProgressLoader fullWidth />
          ) : null}
          <EntitlementsDiv>
            {entitlements
              ? entitlements.map((entitlement, index) => (
                  <EntitlementItem key={index}>
                    <ItemNameTypography variant="h4">
                      {entitlement.displayName
                        ? entitlement.displayName
                        : entitlement.name}
                    </ItemNameTypography>
                    <Switch
                      color="primary"
                      checked={entitlement.isEnabled}
                      onChange={() => {
                        handleUpdateEntitlement({
                          entitlementUuid: entitlement.uuid,
                          isEnabled: !entitlement.isEnabled,
                        })
                      }}
                      value={entitlement.uuid}
                      disabled={!hasEditAccess}
                    />
                  </EntitlementItem>
                ))
              : null}
          </EntitlementsDiv>
        </ContentContainer>
      </OrgSettingsLayout>
    </AdminDashboardLayout>
  )
}

Entitlements.propTypes = {
  hasEditAccess: PropTypes.bool,
  entitlements: PropTypes.arrayOf(PropTypes.object),
  getEntitlementsProgress: PropTypes.object,
  handleUpdateEntitlement: PropTypes.func,
  handleResetEntitlement: PropTypes.func,
}

Entitlements.defaultProps = {
  entitlements: [],
  getEntitlementsProgress: {},
}

export default Entitlements
