import TextField from '@weareroam/cake-ui-v1/TextField'
import { Field, FieldProps } from 'formik'
import React from 'react'
import styled from 'styled-components'

const StyledTextField = styled(TextField)`
  div {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    padding: 0;
  }

  textarea {
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.md}px;
  }

  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export interface ValidatedTextareaProps {
  name: string
  disabled: boolean
}

export function ValidatedTextarea({
  name,
  disabled,
}: ValidatedTextareaProps): React.ReactElement {
  return (
    <Field name={name} disabled={disabled}>
      {({ field, form }: FieldProps) => (
        <StyledTextField
          type="text"
          fullWidth
          multiline
          rows={5}
          variant="filled"
          disabled={disabled}
          error={form.touched[field.name] && !!form.errors[field.name]}
          helperText={form.touched[field.name] ? form.errors[field.name] : ''}
        />
      )}
    </Field>
  )
}
