import { RedFlagPolicyLink } from 'constants/ExternalLinks'

import Typography from '@weareroam/cake-ui-v1/Typography'
import ContentContainer from 'components/atoms/ContentContainer'
import ProgressLoader from 'components/atoms/ProgressLoader'
import RowButton from 'components/atoms/RowButton'
import Box from 'components/molecules/Box'
import DragDropList from 'components/molecules/DragDropList'
import KeyContact from 'components/molecules/KeyContact'
import { Modal } from 'components/molecules/Modal'
import ConfirmUpdatesForm from 'components/organisms/ConfirmUpdatesForm'
import KeyContactForm from 'components/organisms/KeyContactForm'
import idx from 'idx'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import AppLayout from 'layouts/AppLayout'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledLink = styled(Link)`
  display: inline-block;
  color: ${({ theme }) => theme.palette.primary.main};
`

export const DeleteConfirmModal = styled(Modal)``

export const KeyContacts = styled.div`
  margin: ${({ theme }) => theme.spacing.md}px 0;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const AddKeyContactModal = styled(Modal)`
  width: 500px;
`

export const KeyContactsText = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};

  && {
    margin: ${({ theme }) => theme.spacing.sm}px 0;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Contacts = ({
  keyContactList,
  deleteConfirmId,
  keyContactsProgress,
  setEditKeyContactIndex,
  onDragEnd,
  hasEditAccess,
  setDeleteConfirmId,
  setIsAddKeyContactModalOpen,
  editKeyContactIndex,
  onAddKeyContactSubmit,
  onDeleteContactConfirm,
  onCloseAddKeyContactModal,
  isAddKeyContactModalOpen,
}) => {
  const handleOpenKeyContactModal = useCallback(() => {
    setIsAddKeyContactModalOpen(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdminDashboardLayout noPadding>
      <AppLayout>
        <ContentContainer>
          <AddKeyContactModal
            hasCloseButton
            open={isAddKeyContactModalOpen}
            onClose={onCloseAddKeyContactModal}>
            <KeyContactForm
              onSubmit={onAddKeyContactSubmit}
              inEditMode={editKeyContactIndex !== null}
              // eslint-disable-next-line @typescript-eslint/naming-convention
              initialValues={idx(keyContactList, (_) => _[editKeyContactIndex])}
              onCancel={onCloseAddKeyContactModal}
            />
          </AddKeyContactModal>
          <DeleteConfirmModal
            hasCloseButton
            open={!!deleteConfirmId}
            onClose={() => setDeleteConfirmId(null)}>
            <ConfirmUpdatesForm
              confirmText="Yes, delete"
              onSubmit={onDeleteContactConfirm}
              onCancel={() => setDeleteConfirmId(null)}
              title="Are you sure you would like to delete&nbsp;this&nbsp;contact?"
              footerText="Remember to press 'update' once you have made all your changes."
            />
          </DeleteConfirmModal>
          <Typography variant="h2">Red Flag Contacts</Typography>
          <KeyContactsText variant="body1">
            Red Flag Contacts are representatives within people management from
            your organisation that chnnl will contact regarding Red Flags.
            <br />
            By adding details below, these people agree to be contacted by chnnl
            when Red Flags occur.{' '}
            <StyledLink to={{ pathname: RedFlagPolicyLink }} target="_blank">
              Red Flag Incident Policy
            </StyledLink>
            .
          </KeyContactsText>
          {keyContactsProgress && keyContactsProgress.inProgress ? (
            <ProgressLoader fullWidth />
          ) : (
            <React.Fragment>
              <DragDropList onDragEnd={onDragEnd}>
                {(provided) => (
                  <KeyContacts>
                    {keyContactList
                      ? keyContactList.map((keyContact, index) => (
                          <Box
                            key={keyContact.uuid}
                            draggableId={keyContact.uuid}
                            draggableIndex={index}
                            isDraggable={hasEditAccess}
                            isEditable={hasEditAccess}
                            headerContent={
                              <KeyContact
                                name={keyContact.fullName}
                                position={keyContact.position}
                                contactNumber={keyContact.contactNumber}
                                extension={keyContact.extension}
                                email={keyContact.email}
                                website={keyContact.website}
                              />
                            }
                            onEditClick={() => {
                              setEditKeyContactIndex(index)
                              setIsAddKeyContactModalOpen(true)
                            }}
                            onDeleteClick={() =>
                              setDeleteConfirmId(keyContact.uuid)
                            }
                          />
                        ))
                      : null}
                    {provided.placeholder}
                  </KeyContacts>
                )}
              </DragDropList>
              {hasEditAccess && (
                <RowButton onClick={handleOpenKeyContactModal}>
                  + Add Red Flag Contact
                </RowButton>
              )}
            </React.Fragment>
          )}
        </ContentContainer>
      </AppLayout>
    </AdminDashboardLayout>
  )
}

Contacts.propTypes = {
  keyContactsProgress: PropTypes.shape({
    inProgress: PropTypes.bool,
  }),
  values: PropTypes.object,
  deleteConfirmId: PropTypes.string,
  setDeleteConfirmId: PropTypes.func,
  setFieldValue: PropTypes.func,
  keyContactList: PropTypes.arrayOf(PropTypes.object),
  onAddKeyContactSubmit: PropTypes.func,
  setIsAddKeyContactModalOpen: PropTypes.func,
  onDragEnd: PropTypes.func,
  setEditKeyContactIndex: PropTypes.func,
  isAddKeyContactModalOpen: PropTypes.bool,
  editKeyContactIndex: PropTypes.number,
  onDeleteContactConfirm: PropTypes.func,
  hasEditAccess: PropTypes.bool,
  onCloseAddKeyContactModal: PropTypes.func,
}

export default Contacts
