import { Typography } from '@weareroam/cake-ui-v1'
import ContentContainer from 'components/atoms/ContentContainer'
import { HeaderButton } from 'components/atoms/HeaderButton/HeaderButton'
import { ApprovalModal } from 'components/molecules/ApprovalModal/ApprovalModal'
import { ListSection } from 'components/molecules/ListSection/ListSection'
import { TitleWithActions } from 'components/molecules/TitleWithActions/TitleWithActions'
import { format } from 'date-fns'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import { MessagingLayout } from 'layouts/MessagingLayout'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import ProgressLoader from '../../../../components/atoms/ProgressLoader'
import EmptyState from '../../../../components/molecules/EmptyState'
import { ListItem } from '../../../../components/molecules/ListItem/ListItem'
import { ListItemAction } from '../../../../components/molecules/ListItemAction/ListItemAction'
import { ListItemActions } from '../../../../components/molecules/ListItemActions/ListItemActions'
import { NoticeUpdateModal } from '../../../../components/organisms/NoticeUpdateModal/NoticeUpdateModal'
import { HumanReadableDatetime } from '../../../../constants/date'
import { useGroups } from '../../../../hooks/useGroups'
import { useTeamNotices } from '../../../../hooks/useTeamNotices'
import { TeamNotice } from '../../../../modules/teamNotice/schema'
import { OrganisationRouteParams } from '../../../../types/routes'
import { PartialWithRequired } from '../../../../utils/types'

const SecondaryColumn = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export function NoticeBoard(): React.ReactElement {
  const { orgUuid } = useParams<OrganisationRouteParams>()
  const {
    data: notices,
    isLoading,
    error,
    handlers: { save: saveNotice, remove: removeNotice },
  } = useTeamNotices({
    orgUuid,
  })

  const { data: groups, isLoading: groupsLoading } = useGroups({
    orgUuid,
  })

  const [activeNotices, setActiveNotices] = useState<Array<TeamNotice>>([])
  const [notApprovedNotices, setNotApprovedNotices] = useState<
    Array<TeamNotice>
  >([])
  useEffect(() => {
    setActiveNotices(
      notices
        .filter((e) => e.approved)
        .sort(sortByScheduledOrCreatedTime)
        .reverse()
        .splice(0, 3),
    )
    setNotApprovedNotices(
      notices.filter((e) => !e.approved).sort(sortByScheduledOrCreatedTime),
    )
  }, [notices])

  const sortByScheduledOrCreatedTime = (
    a: TeamNotice,
    b: TeamNotice,
  ): number => {
    const first = new Date(a.createdAt)
    const second = new Date(b.createdAt)
    return first.getTime() - second.getTime()
  }

  const [toEdit, setToEdit] = useState<
    PartialWithRequired<TeamNotice, 'approved' | 'groups'> | undefined
  >(undefined)
  const [editing, setEditing] = useState(false)

  const actionEdit = useCallback(
    async (notice: Partial<TeamNotice>) => {
      setEditing(true)
      await saveNotice(notice)
      setEditing(false)
      setToEdit(undefined)
    },
    [saveNotice],
  )
  const cancelEdit = () => setToEdit(undefined)

  const [toApprove, setToApprove] = useState<TeamNotice | undefined>(undefined)
  const actionApproval = useCallback(async () => {
    setEditing(true)
    await saveNotice({ ...toApprove, approved: !toApprove?.approved })
    setEditing(false)
    setToApprove(undefined)
  }, [saveNotice, toApprove])
  const cancelApproval = () => setToApprove(undefined)

  const [toDecline, setToDecline] = useState<TeamNotice | undefined>(undefined)
  const cancelDecline = () => setToDecline(undefined)

  const [toDelete, setToDelete] = useState<TeamNotice | undefined>(undefined)
  const [deleting, setDeleting] = useState(false)

  const actionDeletion = useCallback(async () => {
    setDeleting(true)
    toDelete?.uuid && (await removeNotice(toDelete.uuid))
    setDeleting(false)
    setToDelete(undefined)
  }, [removeNotice, toDelete])
  const cancelDeletion = () => setToDelete(undefined)

  if (isLoading || deleting || editing) {
    return (
      <AdminDashboardLayout noPadding>
        <MessagingLayout>
          <ContentContainer>
            <ProgressLoader fullWidth />
          </ContentContainer>
        </MessagingLayout>
      </AdminDashboardLayout>
    )
  }

  if (error) {
    return (
      <AdminDashboardLayout noPadding>
        <MessagingLayout>
          <ContentContainer>
            <EmptyState
              title="Oh no! We’re not able to show this section right now."
              childComponent={
                <span>
                  Something went wrong trying to load the notice board. We’ve
                  been notified and will take a look but if the problem persists
                  then let us know.
                </span>
              }
            />
          </ContentContainer>
        </MessagingLayout>
      </AdminDashboardLayout>
    )
  }

  return (
    <AdminDashboardLayout noPadding>
      <MessagingLayout>
        <ContentContainer>
          <TitleWithActions
            title={'Notice Board'}
            description={
              'Sending messages to your team promotes a sense of progress and empathy'
            }>
            <HeaderButton
              title={'Add notice'}
              type={'primary'}
              onClick={() =>
                setToEdit({
                  approved: true,
                  message: '',
                  groups: [],
                })
              }
            />
          </TitleWithActions>
          <ListSection
            columns={[
              {
                title: 'Message',
                width: 56,
              },
              {
                title: 'Group',
                width: 18,
              },
              {
                title: 'Date / Time',
                width: 17,
              },
              {
                title: 'Action',
                width: 9,
              },
            ]}>
            {activeNotices.length ? (
              activeNotices.map((item) => (
                <ListItem
                  key={item.uuid}
                  columns={[
                    {
                      contents: (
                        <Typography variant="body1">{item.message}</Typography>
                      ),
                      width: 56,
                    },
                    {
                      contents: (
                        <SecondaryColumn variant="body2">
                          {item.groups.map((e) => e.name).join(', ')}
                        </SecondaryColumn>
                      ),
                      width: 18,
                    },
                    {
                      contents: (
                        <SecondaryColumn variant="body2">
                          {format(item.createdAt, HumanReadableDatetime)}
                        </SecondaryColumn>
                      ),
                      width: 17,
                    },
                    {
                      contents: (
                        <ListItemActions>
                          <ListItemAction
                            icon={'edit'}
                            label={'Edit'}
                            onClick={() => setToEdit(item)}
                          />
                          <ListItemAction
                            icon={'delete'}
                            label={'Delete'}
                            onClick={() => setToDelete(item)}
                          />
                        </ListItemActions>
                      ),
                      width: 9,
                    },
                  ]}
                />
              ))
            ) : (
              <Typography variant="body1">No messages posted yet</Typography>
            )}
          </ListSection>
          <TitleWithActions
            title={'To be approved'}
            description={
              'The following messages from your team need to be approved before being posted to the notice board'
            }
          />
          <ListSection
            columns={[
              {
                title: 'Message',
                width: 52,
              },
              {
                title: 'Group',
                width: 18,
              },
              {
                title: 'Date / Time',
                width: 17,
              },
              {
                title: 'Action',
                width: 13,
              },
            ]}>
            {notApprovedNotices.length ? (
              notApprovedNotices.map((item) => (
                <ListItem
                  key={item.uuid}
                  columns={[
                    {
                      contents: (
                        <Typography variant="body1">{item.message}</Typography>
                      ),
                      width: 52,
                    },
                    {
                      contents: (
                        <SecondaryColumn variant="body2">
                          {item.groups.map((e) => e.name).join(', ')}
                        </SecondaryColumn>
                      ),
                      width: 18,
                    },
                    {
                      contents: (
                        <SecondaryColumn variant="body2">
                          {format(item.createdAt, HumanReadableDatetime)}
                        </SecondaryColumn>
                      ),
                      width: 17,
                    },
                    {
                      contents: (
                        <ListItemActions>
                          <ListItemAction
                            icon={'approve'}
                            label={'Approve'}
                            onClick={() => setToApprove(item)}
                          />
                          <ListItemAction
                            icon={'delete'}
                            label={'Decline'}
                            onClick={() => setToDelete(item)}
                          />
                          <ListItemAction
                            icon={'edit'}
                            label={'Edit'}
                            onClick={() => setToEdit(item)}
                          />
                        </ListItemActions>
                      ),
                      width: 13,
                    },
                  ]}
                />
              ))
            ) : (
              <Typography variant="body1">Nothing to approve yet</Typography>
            )}
          </ListSection>
        </ContentContainer>

        {toEdit && (
          <NoticeUpdateModal
            open={true}
            isNew={!toEdit.uuid}
            noticeUpdate={toEdit}
            groups={groups}
            loading={groupsLoading}
            onCancel={() => cancelEdit()}
            onSubmit={(notice) => actionEdit(notice)}
          />
        )}

        {toApprove && (
          <ApprovalModal
            title={`Approve?`}
            description={
              'This message will be shared with the team. Are you sure?'
            }
            actionLabel={'Approve'}
            loading={isLoading}
            onApprove={() => actionApproval()}
            onCancel={() => cancelApproval()}
          />
        )}

        {toDecline && (
          <ApprovalModal
            title={`Decline?`}
            description={'This notice will not be shared. Are you sure?'}
            actionLabel={'Decline'}
            loading={isLoading}
            onApprove={() => actionDeletion()}
            onCancel={() => cancelDecline()}
          />
        )}

        {toDelete && (
          <ApprovalModal
            title={`Delete ${toDelete.message}?`}
            description={
              "The notice will be removed from the page and won't be shared anymore. Are you sure?"
            }
            actionLabel={'Delete'}
            loading={isLoading}
            onApprove={() => actionDeletion()}
            onCancel={() => cancelDeletion()}
          />
        )}
      </MessagingLayout>
    </AdminDashboardLayout>
  )
}
