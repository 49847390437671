import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { WellbeingResourcesCategory } from '../models/WellbeingResourcesCategory'
import { getOrganisationBoosters } from '../modules/boosters/actions'

interface IUseWellbeingResources {
  categories?: Record<string, WellbeingResourcesCategory>
  categoriesList?: WellbeingResourcesCategory[]
  isLoading: boolean
}

export function useWellbeingResources({
  orgUuid,
}: {
  orgUuid: string
}): IUseWellbeingResources {
  const dispatch = useDispatch()

  const categories: Record<string, WellbeingResourcesCategory> = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => state.boosters.entities,
    // (state: any) => state.boosters.entities[orgUuid],
  )

  const categoriesList: Array<WellbeingResourcesCategory> = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) =>
      Object.keys(state.boosters.entities).map(
        (key) => state.boosters.entities[key],
      ),
  )

  const isLoading = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => state.progress.boosters?.inProgress,
  )

  const fetchData = useCallback(
    ({ orgUuid }: { orgUuid: string }) => {
      dispatch(getOrganisationBoosters(orgUuid))
    },
    [dispatch],
  )

  useEffect(() => {
    fetchData({ orgUuid })
  }, [orgUuid, fetchData])

  return {
    categories,
    categoriesList,
    isLoading,
  }
}
