import * as Sentry from '@sentry/react'
import './polyfills'
import 'assets/styles/fonts.css'
import { MuiThemeProvider } from '@weareroam/cake-ui-v1/styles'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga4'
import { Provider } from 'react-redux'
import configureStore from 'store/configureStore'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from 'styles/GlobalStyles'
import theme from 'styles/theme'
import App from 'views/App'

import { ENV, SENTRY_DSN, GA_MEASUREMENT_ID } from './config'
import * as serviceWorker from './serviceWorker'

if (ENV !== 'local') {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENV,
    debug: false,
  })
  ReactGA.initialize(GA_MEASUREMENT_ID)
}

const store = configureStore()

// eslint-disable-next-line @typescript-eslint/naming-convention
const render = (Component) =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <React.Fragment>
            <Component />
            <GlobalStyle />
          </React.Fragment>
        </ThemeProvider>
      </MuiThemeProvider>
    </Provider>,
    document.getElementById('root'),
  )

render(App)

/* eslint-disable no-undef */
if (module.hot) {
  module.hot.accept('./views/App', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const NextApp = require('./views/App').default
    render(NextApp)
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
