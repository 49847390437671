import { useAuth0 } from '@auth0/auth0-react'
import Typography from '@weareroam/cake-ui-v1/Typography'
import ContentContainer from 'components/atoms/ContentContainer'
import { Modal } from 'components/molecules/Modal'
import ChangePasswordForm from 'components/organisms/ChangePasswordForm'
import EditProfileForm from 'components/organisms/EditProfileForm'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import OrgSettingsLayout from 'layouts/OrgSettingsLayout'
import PropTypes from 'prop-types'
import React, { useState, useCallback } from 'react'
import styled from 'styled-components'

export const StyledHeader = styled.header``
export const StyledFooter = styled.div`
  display: flex;
  max-width: 372px;
  margin-top: ${({ theme }) => theme.spacing.lg}px;
`
export const StyledLink = styled(Typography)`
  && {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary.main};
    &:hover {
      color: ${({ theme }) => theme.palette.primary.dark};
    }
  }
`
export const StyledModalContent = styled.div`
  width: 400px;
`
export const StyledFormTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Profile({ user, handleSubmit, handlePasswordChange }) {
  const { user: authUser, isAuthenticated } = useAuth0()
  const [isResetPasswordModalOpen, toggleResetPasswordModal] = useState(false)
  const handleResetPasswordToggle = useCallback(
    () => {
      toggleResetPasswordModal(!isResetPasswordModalOpen)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isResetPasswordModalOpen],
  )

  const handleSubmitNewPassword = useCallback((values) => {
    handlePasswordChange(values)
    toggleResetPasswordModal(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdminDashboardLayout noPadding>
      <OrgSettingsLayout>
        <ContentContainer>
          <EditProfileForm
            showConfirmHeader
            initialValues={user}
            onSubmit={handleSubmit}
          />
          {isAuthenticated && !authUser.sub.startsWith('samlp') ? (
            <StyledFooter>
              <StyledLink onClick={handleResetPasswordToggle}>
                Change password
              </StyledLink>
            </StyledFooter>
          ) : null}
        </ContentContainer>
      </OrgSettingsLayout>
      <Modal
        hasCloseButton
        open={isResetPasswordModalOpen}
        onClose={handleResetPasswordToggle}>
        <StyledModalContent>
          <StyledFormTitle variant="h2">Change password</StyledFormTitle>
          <ChangePasswordForm onSubmit={handleSubmitNewPassword} />
        </StyledModalContent>
      </Modal>
    </AdminDashboardLayout>
  )
}

Profile.propTypes = {
  user: PropTypes.object,
  handleSubmit: PropTypes.func,
  handlePasswordChange: PropTypes.func,
}

export default Profile
