import { FeatureKeys } from 'constants/features'
import {
  ROLE_ORG_OWNER,
  ROLE_SUPER_ADMIN,
  ROLE_ORG_ADMIN,
  ROLE_PARTNER_ADMIN,
} from 'constants/form'
import { GROUP_DASHBOARD_SETTINGS } from 'constants/routes'

import { useFeature } from '@optimizely/react-sdk'
import Button from '@weareroam/cake-ui-v1/Button'
import Table from '@weareroam/cake-ui-v1/Table'
import TableBody from '@weareroam/cake-ui-v1/TableBody'
import TableCell from '@weareroam/cake-ui-v1/TableCell'
import TableHead from '@weareroam/cake-ui-v1/TableHead'
import TableRow from '@weareroam/cake-ui-v1/TableRow'
import Typography from '@weareroam/cake-ui-v1/Typography'
import ContentContainer from 'components/atoms/ContentContainer'
import ProgressLoader from 'components/atoms/ProgressLoader'
import SearchField from 'components/atoms/SearchField'
import StatusTag from 'components/atoms/StatusTag'
import authorisedRoles from 'components/hoc/authorisedRoles'
import { Modal } from 'components/molecules/Modal'
import MoreActions from 'components/molecules/MoreActions'
import DeleteAdminForm from 'components/organisms/DeleteAdminForm'
import InviteNewAdminForm from 'components/organisms/InviteNewAdminForm'
import { AppManagersTable } from 'components/organisms/OrganisationSettings/AppManagersTable'
import ResendInvitationForm from 'components/organisms/ResendInvitationForm'
import { useAdminsSearch } from 'hooks/useAdminsSearch'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import OrgSettingsLayout from 'layouts/OrgSettingsLayout'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { getAdminRoleThemeColor, getAdminRoleLabel } from 'utils/admins'
import { replaceRouteParams } from 'utils/routes'

import { AdminsComponentPropsInner } from './AdminsEnhancer'

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export const StyledHeaderRight = styled.div`
  display: flex;
  width: 420px;
  justify-content: space-between;
  align-items: center;
`

export const StyledAdminHeaderText = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const ActionsCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const PermissionsText = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};
  margin: ${({ theme }) => theme.spacing.sm}px 0
    ${({ theme }) => theme.spacing.sm}px ${({ theme }) => theme.spacing.sm}px;
`

export const GroupText = styled(Typography)`
  && {
    margin: ${({ theme }) => theme.spacing.sm}px 0;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

export const InviteButton = styled(Button)`
  && {
    margin-left: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledLink = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.palette.tertiary.dark};
  margin-bottom: ${({ theme }) => theme.spacing.xs}px;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const PendingText = styled(Typography)`
  font-style: italic;
  color: ${({ theme }) => theme.palette.tertiary.dark};
  && {
    margin-right: ${({ theme }) => theme.spacing.sm}px;
  }
`

export const StyledInviteModal = styled(Modal)`
  width: 520px;
`

export const StyledRemoveModal = styled(Modal)`
  width: 410px;
`

export const StyledResendModal = styled(Modal)`
  width: 500px;
`

export const StyledDeleteModal = styled(Modal)`
  width: 460px;
`

const ProtectedInviteAdminButton = authorisedRoles(
  [ROLE_PARTNER_ADMIN, ROLE_ORG_OWNER, ROLE_SUPER_ADMIN, ROLE_ORG_ADMIN],
  true,
)(InviteButton)

const ProtectedMoreActions = authorisedRoles(
  [ROLE_PARTNER_ADMIN, ROLE_ORG_OWNER, ROLE_SUPER_ADMIN, ROLE_ORG_ADMIN],
  true,
)(MoreActions)

export function AdminsUI({
  onInviteNewAdminSubmit,
  isInviteNewAdminModalOpen,
  setIsInviteNewAdminModalOpen,
  setEditUserId,
  editUserId,
  admins,
  adminEntities,
  groups,
  getAdminsProgress,
  orgUuid,
  canTransferOrgOwnership,
  isOrgOwner,
  hasEditAccess,
  handleDeleteAdmin,
  resendInviteUserId,
  setResendInviteUserId,
  resendInviteModalOpen,
  setResendInviteModalOpen,
  handleResendInvitationConfirm,
  isDeleteAdminModalOpen,
  setIsDeleteAdminModalOpen,
}: AdminsComponentPropsInner): React.ReactElement {
  const { searchFilter, handleChangeSearchFilter, filteredAdmins } =
    useAdminsSearch(admins)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const editUser = adminEntities[editUserId!]
  const [videoUpdatesEnabled] = useFeature(FeatureKeys.VideoUpdates)

  return (
    <AdminDashboardLayout noPadding>
      <StyledInviteModal
        hasCloseButton
        open={isInviteNewAdminModalOpen}
        onClose={() => {
          setIsInviteNewAdminModalOpen(false)
        }}>
        <InviteNewAdminForm
          onSubmit={onInviteNewAdminSubmit}
          inEditMode={!!editUserId}
          canTransferOrgOwnership={canTransferOrgOwnership}
          isOrgOwner={isOrgOwner}
          isEditingOrgOwner={
            editUser ? editUser.role === ROLE_ORG_OWNER : false
          }
          userName={editUser ? editUser.fullName : 'User'}
          onCancel={() => {
            setIsInviteNewAdminModalOpen(false)
          }}
          title={
            editUser
              ? `${editUser.fullName || 'User'} is...`
              : 'Invite new admin'
          }
          initialValues={editUser || {}}
          groups={groups.map((group) => ({
            label: group.name,
            value: group.uuid,
          }))}
        />
      </StyledInviteModal>
      <StyledResendModal
        hasCloseButton
        open={resendInviteModalOpen}
        onClose={() => setResendInviteModalOpen(false)}>
        <ResendInvitationForm
          onSubmit={(e) => {
            handleResendInvitationConfirm(e, adminEntities[resendInviteUserId])
          }}
          onCancel={() => {
            setResendInviteModalOpen(false)
          }}
        />
      </StyledResendModal>
      <StyledDeleteModal
        hasCloseButton
        open={isDeleteAdminModalOpen}
        onClose={() => {
          setIsDeleteAdminModalOpen(false)
        }}>
        <DeleteAdminForm
          admin={editUserId ? adminEntities[editUserId] : undefined}
          onSubmit={() => {
            editUserId && handleDeleteAdmin(adminEntities[editUserId])
          }}
          onCancel={() => {
            setIsDeleteAdminModalOpen(false)
          }}
        />
      </StyledDeleteModal>
      <OrgSettingsLayout>
        <ContentContainer>
          <StyledHeader>
            <Typography variant="h2">Admins</Typography>
            <StyledHeaderRight>
              <SearchField
                placeholder="Search"
                value={searchFilter}
                onChange={handleChangeSearchFilter}
              />
              <ProtectedInviteAdminButton
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  setEditUserId(null)
                  setIsInviteNewAdminModalOpen(true)
                }}>
                Invite new admin
              </ProtectedInviteAdminButton>
            </StyledHeaderRight>
          </StyledHeader>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <StyledAdminHeaderText variant="body1">
                    Name
                  </StyledAdminHeaderText>
                </TableCell>
                <TableCell>
                  <StyledAdminHeaderText variant="body1">
                    Email
                  </StyledAdminHeaderText>
                </TableCell>
                <TableCell>
                  <StyledAdminHeaderText variant="body1">
                    Admin type
                  </StyledAdminHeaderText>
                </TableCell>
                <TableCell>
                  <StyledAdminHeaderText variant="body1">
                    Assigned to
                  </StyledAdminHeaderText>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAdmins.map((admin) => {
                const editPermissionActions = [
                  {
                    key: `edit-permissions-${admin.userUuid}`,
                    label: 'Edit permissions',
                    onClick: () => {
                      setEditUserId(admin.userUuid)
                      setIsInviteNewAdminModalOpen(true)
                    },
                  },
                ]

                if (hasEditAccess && admin.role !== ROLE_ORG_OWNER) {
                  editPermissionActions.push({
                    key: `delete-admin-${admin.userUuid}`,
                    label: 'Delete admin',
                    onClick: () => {
                      setEditUserId(admin.userUuid)
                      setIsDeleteAdminModalOpen(true)
                    },
                  })
                }

                if (!admin.accepted) {
                  editPermissionActions.push({
                    key: `resend-invitation-${admin.userUuid}`,
                    label: 'Resend invitation',
                    onClick: () => {
                      setResendInviteUserId(admin.userUuid)
                      setResendInviteModalOpen(true)
                    },
                  })
                }

                return (
                  <TableRow key={admin.userUuid}>
                    <TableCell>
                      <Typography variant="body1">
                        {admin.accepted ? admin.fullName : 'Pending'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{admin.email}</Typography>
                    </TableCell>
                    <TableCell>
                      <StatusTag
                        themeColor={getAdminRoleThemeColor(admin.role)}>
                        {getAdminRoleLabel(admin.role)}
                      </StatusTag>
                      {admin.editRights ? (
                        <PermissionsText variant="body2">
                          Can edit
                        </PermissionsText>
                      ) : (
                        <PermissionsText variant="body2">
                          Can view
                        </PermissionsText>
                      )}
                    </TableCell>
                    <TableCell>
                      <GroupText variant="body1">
                        {admin.assignedGroups
                          ? admin.assignedGroups.map((group) => (
                              <StyledLink
                                key={group.uuid}
                                to={replaceRouteParams(
                                  GROUP_DASHBOARD_SETTINGS,
                                  {
                                    groupUuid: group.uuid,
                                    orgUuid,
                                  },
                                )}>
                                {group.name}
                              </StyledLink>
                            ))
                          : null}
                      </GroupText>
                    </TableCell>
                    <TableCell>
                      <ActionsCell>
                        {!admin.accepted && (
                          <PendingText variant="body1">Pending</PendingText>
                        )}
                        <ProtectedMoreActions
                          color="primary"
                          actions={editPermissionActions}
                        />
                      </ActionsCell>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          {getAdminsProgress.inProgress ? <ProgressLoader fullWidth /> : null}
          {videoUpdatesEnabled && <AppManagersTable orgUuid={orgUuid} />}
        </ContentContainer>
      </OrgSettingsLayout>
    </AdminDashboardLayout>
  )
}
