import * as authActions from 'modules/authentication/actions'
import { getUser } from 'modules/user/selectors'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, mapProps, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  withRouter,
  mapProps((props) => ({
    ...props,
    partnerUuid: props.match.params.partnerUuid,
  })),
  connect(
    // mapStateToProps
    (state) => ({
      user: getUser(state),
    }),
    (dispatch) => {
      const actions = {
        ...authActions,
      }
      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  withHandlers({
    onLogout:
      ({ actions }) =>
      () => {
        actions.logout()
      },
  }),
)
