import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import { Modal } from 'components/molecules/Modal'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const SuccessTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const SuccessBody = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.tertiary.dark};
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SuccessModal({ open, onClose, numInvitationsSent, hasCloseButton }) {
  return (
    <Modal open={open} hasCloseButton={hasCloseButton}>
      <SuccessTitle variant="h2">{`Nice! Invitation${
        numInvitationsSent === 1 ? '' : 's'
      } sent.`}</SuccessTitle>
      <SuccessBody variant="body1">
        {`We've sent your invitation to ${numInvitationsSent} recipient${
          numInvitationsSent === 1 ? '' : 's'
        }.

      Invited members will each receive a unique code to log in to the app
      with.`}
      </SuccessBody>
      <Button
        type="button"
        variant="contained"
        color="primary"
        size="small"
        onClick={onClose}>
        Done
      </Button>
    </Modal>
  )
}

SuccessModal.propTypes = {
  open: PropTypes.bool,
  hasCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  numInvitationsSent: PropTypes.number || PropTypes.string,
}

export default SuccessModal
