import { Table, TableBody, TableHead } from '@weareroam/cake-ui-v1'
import TableCell from '@weareroam/cake-ui-v1/TableCell'
import TableRow from '@weareroam/cake-ui-v1/TableRow'
import Typography from '@weareroam/cake-ui-v1/Typography'
import StatusTag from 'components/atoms/StatusTag'
import React from 'react'
import styled from 'styled-components'

import { ROLE_GROUP_ADMIN } from '../../../constants/form'
import { useAppManagersAdmin } from '../../../hooks/useAppManagersAdmin'
import { AppManager } from '../../../modules/appManager/schema'
import { getAdminRoleThemeColor } from '../../../utils/admins'
import {
  PermissionsText,
  StyledAdminHeaderText,
} from '../../../views/Dashboard/Organisation/Settings/Admins/AdminsUI'
import ProgressLoader from '../../atoms/ProgressLoader'
import EmptyState from '../../molecules/EmptyState'
import { TableRowAction } from '../../molecules/TableRowAction/TableRowAction'

const StyledTitle = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing.xl}px !important;
`

const StyledTable = styled(Table)`
  margin-top: ${({ theme }) => theme.spacing.lg}px;
`

const ActionsCell = styled.div`
  width: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

interface Props {
  orgUuid: string
}

export const AppManagersTable = ({ orgUuid }: Props): React.ReactElement => {
  const {
    data: appManagers,
    isLoading,
    error,
    handlers: { approve: approveManager, remove: removeManager },
  } = useAppManagersAdmin({
    orgUuid,
  })

  const renderEmpty = (): React.ReactElement => (
    <TableRow>
      <TableCell colSpan={4}>No app managers registered yet.</TableCell>
    </TableRow>
  )

  const renderLines = (
    appManagers: Array<AppManager>,
  ): React.ReactElement | null => {
    if (!appManagers.length) {
      return null
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore It's still afraid of an empty array
    return appManagers.map((manager) => (
      <TableRow key={manager.uuid}>
        <TableCell>
          {manager.user.profile.preferredName || 'Not supplied'}
        </TableCell>
        <TableCell>{manager.user.contacts[0].value}</TableCell>
        <TableCell>
          <StatusTag themeColor={getAdminRoleThemeColor(ROLE_GROUP_ADMIN)}>
            Manager
          </StatusTag>
          <PermissionsText variant="body2">
            Can edit My Team page
          </PermissionsText>
        </TableCell>
        <TableCell>
          {manager.approved ? (
            <ActionsCell>
              <TableRowAction
                icon={'delete'}
                label={'Remove'}
                onClick={() => removeManager(manager.uuid)}
              />
            </ActionsCell>
          ) : (
            <ActionsCell>
              <TableRowAction
                icon={'approve'}
                label={'Approve'}
                onClick={() => approveManager(manager.uuid)}
              />
              <TableRowAction
                icon={'delete'}
                label={'Decline'}
                onClick={() => removeManager(manager.uuid)}
              />
            </ActionsCell>
          )}
        </TableCell>
      </TableRow>
    ))
  }

  const renderSection = () => {
    if (isLoading) {
      return <ProgressLoader fullWidth />
    }

    if (error) {
      return (
        <EmptyState
          title="Oh no! We’re not able to show this section right now."
          childComponent={
            <span>
              Something went wrong trying to load app managers. We’ve been
              notified and will take a look but if the problem persists then let
              us know.
            </span>
          }
        />
      )
    }

    return (
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell>
              <StyledAdminHeaderText variant="body1">
                Name
              </StyledAdminHeaderText>
            </TableCell>
            <TableCell>
              <StyledAdminHeaderText variant="body1">
                Phone
              </StyledAdminHeaderText>
            </TableCell>
            <TableCell>
              <StyledAdminHeaderText variant="body1">
                Admin type
              </StyledAdminHeaderText>
            </TableCell>
            <TableCell>
              <StyledAdminHeaderText variant="body1">
                Approval
              </StyledAdminHeaderText>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {appManagers.length ? renderLines(appManagers) : renderEmpty()}
        </TableBody>
      </StyledTable>
    )
  }

  return (
    <React.Fragment>
      <StyledTitle variant="h2">App Managers</StyledTitle>
      {renderSection()}
    </React.Fragment>
  )
}
