export enum SupportOptionTypes {
  /** Ask user to journal about their experience */
  JOURNAL = 'journal',
  /** Show some wellbeing resources */
  RESOURCES = 'resources',
  /** Show a link to wellbeing resources page */
  RESOURCES_LINK = 'resourcesLink',
}

export const SupportOptionNames: { [key in SupportOptionTypes]: string } = {
  journal: 'Journal Prompt',
  resources: 'Wellbeing Resources',
  resourcesLink: 'Wellbeing Resources Page',
}
