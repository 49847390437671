import {
  ROLE_SUPER_ADMIN,
  ROLE_ORG_OWNER,
  ROLE_GROUP_ADMIN,
  ROLE_ORG_ADMIN,
  ROLE_PARTNER_ADMIN,
} from 'constants/form'
import {
  LOGIN,
  SIGN_UP,
  RESET_PASSWORD,
  NEW_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  SIGN_UP_ORGANISATION,
  CHNNL_DASHBOARD,
  CHNNL_DASHBOARD_ORGANISATIONS,
  CHNNL_DASHBOARD_TIPS,
  CHNNL_DASHBOARD_JOURNALS,
  CHNNL_DASHBOARD_DISTRESSED_USERS,
  CHNNL_DASHBOARD_SPEAKUP,
  CHNNL_DASHBOARD_CHATBOT,
  CHNNL_DASHBOARD_PARTNERS,
  CHNNL_DASHBOARD_SETTINGS,
  CHNNL_DASHBOARD_SETTINGS_PROFILE,
  CHNNL_DASHBOARD_SETTINGS_PASSWORD,
  CHNNL_DASHBOARD_SETTINGS_NOTIFICATIONS,
  ORG_DASHBOARD,
  ORG_DASHBOARD_OVERVIEW,
  ORG_DASHBOARD_GROUPS,
  ORG_DASHBOARD_JOURNALS,
  ORG_DASHBOARD_SPEAKUP,
  ORG_DASHBOARD_APP,
  ORG_DASHBOARD_APP_ORGANISATION,
  ORG_DASHBOARD_APP_SUPPORT,
  ORG_DASHBOARD_APP_SURVEY,
  ORG_DASHBOARD_APP_REFLECTIONS,
  ORG_DASHBOARD_APP_BOOSTERS,
  ORG_DASHBOARD_APP_PATHWAYS,
  ORG_DASHBOARD_MESSAGING,
  ORG_DASHBOARD_MESSAGING_ORGANISATION,
  ORG_DASHBOARD_MESSAGING_NETWORK,
  ORG_DASHBOARD_MESSAGING_NOTICEBOARD,
  ORG_DASHBOARD_SETTINGS,
  ORG_DASHBOARD_SETTINGS_ADMINS,
  ORG_DASHBOARD_SETTINGS_GENERAL,
  ORG_DASHBOARD_SETTINGS_PROFILE,
  ORG_DASHBOARD_SETTINGS_NOTIFICATIONS,
  ORG_DASHBOARD_SETTINGS_ENTITLEMENTS,
  GROUP_DASHBOARD,
  GROUP_DASHBOARD_WELLBEING,
  GROUP_DASHBOARD_BOOSTERS,
  GROUP_DASHBOARD_MESSAGING,
  GROUP_DASHBOARD_MEMBERS,
  GROUP_DASHBOARD_SETTINGS,
  ORG_DASHBOARD_APP_CONTACTS,
  APP_TOKEN_DEEPLINK,
  SIGN_UP_LINK_EXPIRED,
  SIGN_ME_UP,
  SIGN_UP_SUCCESS,
  PARTNER_DASHBOARD_SETTINGS_DETAILS,
  PARTNER_DASHBOARD_ORGANISATIONS,
  PARTNER_DASHBOARD_ADMINS,
  PARTNER_ADMIN_DASHBOARD_ORGANISATIONS,
  AUTH_CALLBACK,
  NO_ACCOUNT,
  CHNNL_DASHBOARD_ADMIN_MESSAGES,
} from 'constants/routes'

import { Auth0Provider } from '@auth0/auth0-react'
import AppDownloadRedirect from 'components/atoms/AppDownloadRedirect'
import ScreenSizeMessage from 'components/atoms/ScreenSizeMessage'
import PrivateRoute from 'components/organisms/PrivateRoute'
import Toasts from 'components/organisms/Toasts'
import Undo from 'components/organisms/Undo'
import { ConnectedRouter } from 'connected-react-router'
import FeatureProvider from 'providers/FeatureProvider'
import React from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { history } from 'store/history'
import { replaceRouteParams } from 'utils/routes'
import { Authenticated } from 'views/Auth/Authenticated'
import { NoAccount } from 'views/Auth/NoAccount'
import { AdminMessages } from 'views/Dashboard/Chnnl/AdminMessages'
import ChnnlChatbot from 'views/Dashboard/Chnnl/Chatbot'
import { DistressedUsers } from 'views/Dashboard/Chnnl/DistressedUsers'
import ChnnlJournals from 'views/Dashboard/Chnnl/Journals'
import ChnnlOrganisations from 'views/Dashboard/Chnnl/Organisations'
import ChnnlPartners from 'views/Dashboard/Chnnl/Partners'
import ChnnlSettings from 'views/Dashboard/Chnnl/Settings'
import ChnnlSettingsNotifications from 'views/Dashboard/Chnnl/Settings/Notifications'
import ChnnlSettingsPassword from 'views/Dashboard/Chnnl/Settings/Password'
import ChnnlSettingsProfile from 'views/Dashboard/Chnnl/Settings/Profile'
import ChnnlSpeakUp from 'views/Dashboard/Chnnl/SpeakUp'
import ChnnlTips from 'views/Dashboard/Chnnl/Tips'
import OrganisationBoosters from 'views/Dashboard/Organisation/App/Boosters'
import OrganisationAppContacts from 'views/Dashboard/Organisation/App/Contacts'
import OrganisationAppOrganisation from 'views/Dashboard/Organisation/App/Organisation'
import OrganisationPathways from 'views/Dashboard/Organisation/App/Pathways'
import OrganisationReflections from 'views/Dashboard/Organisation/App/Reflections'
import { SupportOptions } from 'views/Dashboard/Organisation/App/SupportOptions'
import OrganisationSurvey from 'views/Dashboard/Organisation/App/Survey'
import OrganisationGroups from 'views/Dashboard/Organisation/Groups'
import GroupBoosters from 'views/Dashboard/Organisation/Groups/Boosters'
import GroupMembers from 'views/Dashboard/Organisation/Groups/Members'
import { GroupOverview } from 'views/Dashboard/Organisation/Groups/Overview/GroupOverview'
import GroupSettings from 'views/Dashboard/Organisation/Groups/Settings'
import OrganisationJournals from 'views/Dashboard/Organisation/Journals'
import { Messaging } from 'views/Dashboard/Organisation/Messaging/Messaging'
import { Network } from 'views/Dashboard/Organisation/Messaging/Network'
import { NoticeBoard } from 'views/Dashboard/Organisation/Messaging/NoticeBoard'
import { OrganisationOverview } from 'views/Dashboard/Organisation/Overview/OrganisationOverview'
import { Admins as OrganisationSettingsAdmin } from 'views/Dashboard/Organisation/Settings/Admins'
import OrganisationSettingsEntitlements from 'views/Dashboard/Organisation/Settings/Entitlements'
import OrganisationSettingsGeneral from 'views/Dashboard/Organisation/Settings/General'
import OrganisationSettingsNotifications from 'views/Dashboard/Organisation/Settings/Notifications'
import OrganisationSettingsProfile from 'views/Dashboard/Organisation/Settings/Profile'
import OrganisationSpeakUp from 'views/Dashboard/Organisation/SpeakUp'
import PartnerAdminOrganisationsView from 'views/Dashboard/Partner/PartnerAdminOrganisationsView'
import PartnerAdmins from 'views/Dashboard/Partner/PartnerAdmins'
import PartnerGeneralSettings from 'views/Dashboard/Partner/PartnerGeneralSettings'
import PartnerOrganisations from 'views/Dashboard/Partner/PartnerOrganisations'
import Login from 'views/Login'
import NewPassword from 'views/NewPassword'
import PageNotFound from 'views/PageNotFound/PageNotFound'
import ResetPassword from 'views/ResetPassword'
import ResetPasswordSuccess from 'views/ResetPassword/Success'
import SignMeUp from 'views/SignMeUp'
import Signup from 'views/Signup'
import SignupOrganisationOnboarding from 'views/Signup/Organisation'
import SignUpLinkExpired from 'views/SignUpLinkExpired/SignUpLinkExpired'
import SignUpSuccess from 'views/SignUpSuccess'

import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, CHNNL_API_URL } from '../../config'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function App() {
  const onRedirectCallback = () => {
    console.log('Redirect callback')
    // TODO: history support so users return to their previous route
    // history.push(appState?.returnTo || window.location.pathname)
  }

  return (
    <div className="App">
      <ConnectedRouter history={history}>
        <Auth0Provider
          domain={AUTH0_DOMAIN}
          clientId={AUTH0_CLIENT_ID}
          authorizationParams={{
            // eslint-disable-next-line @typescript-eslint/naming-convention
            redirect_uri: `${window.location.origin}${AUTH_CALLBACK}`,
            audience: CHNNL_API_URL,
          }}
          onRedirectCallback={onRedirectCallback}>
          <FeatureProvider>
            <div>
              <Switch>
                {/* Redirect landing page */}
                <Route
                  exact
                  path="/"
                  component={() => <Redirect to={LOGIN} />}
                />
                <Route exact path={AUTH_CALLBACK} component={Authenticated} />
                <Route
                  path={APP_TOKEN_DEEPLINK}
                  component={AppDownloadRedirect}
                />
                <Route
                  path={SIGN_UP_LINK_EXPIRED}
                  component={SignUpLinkExpired}
                />
                <Route path={SIGN_ME_UP} component={SignMeUp} />
                <Route path={SIGN_UP_SUCCESS} component={SignUpSuccess} />
                {/* Account stuff */}
                <Route exact path={LOGIN} component={Login} />
                <Route exact path={SIGN_UP} component={Signup} />
                <PrivateRoute
                  exact
                  path={SIGN_UP_ORGANISATION}
                  component={SignupOrganisationOnboarding}
                />
                <Route exact path={RESET_PASSWORD} component={ResetPassword} />
                <Route exact path={NEW_PASSWORD} component={NewPassword} />
                <Route
                  exact
                  path={RESET_PASSWORD_SUCCESS}
                  component={ResetPasswordSuccess}
                />
                <Route exact path={NO_ACCOUNT} component={NoAccount} />

                {/* chnnl */}
                <PrivateRoute
                  exact
                  path={CHNNL_DASHBOARD}
                  component={() => (
                    <Redirect to={CHNNL_DASHBOARD_ORGANISATIONS} />
                  )}
                  authorisedRoles={[ROLE_SUPER_ADMIN]}
                />
                <PrivateRoute
                  exact
                  path={CHNNL_DASHBOARD_ORGANISATIONS}
                  component={ChnnlOrganisations}
                  authorisedRoles={[ROLE_SUPER_ADMIN]}
                />
                <PrivateRoute
                  exact
                  path={CHNNL_DASHBOARD_TIPS}
                  component={ChnnlTips}
                  authorisedRoles={[ROLE_SUPER_ADMIN]}
                />
                <PrivateRoute
                  exact
                  path={CHNNL_DASHBOARD_JOURNALS}
                  component={ChnnlJournals}
                  authorisedRoles={[ROLE_SUPER_ADMIN]}
                />
                <PrivateRoute
                  exact
                  path={CHNNL_DASHBOARD_ADMIN_MESSAGES}
                  component={AdminMessages}
                  authorisedRoles={[ROLE_SUPER_ADMIN]}
                />
                <PrivateRoute
                  exact
                  path={CHNNL_DASHBOARD_DISTRESSED_USERS}
                  component={DistressedUsers}
                  authorisedRoles={[ROLE_SUPER_ADMIN]}
                />
                <PrivateRoute
                  exact
                  path={CHNNL_DASHBOARD_SPEAKUP}
                  component={ChnnlSpeakUp}
                  authorisedRoles={[ROLE_SUPER_ADMIN]}
                />
                <PrivateRoute
                  exact
                  path={CHNNL_DASHBOARD_CHATBOT}
                  component={ChnnlChatbot}
                  authorisedRoles={[ROLE_SUPER_ADMIN]}
                />
                <PrivateRoute
                  exact
                  path={CHNNL_DASHBOARD_SETTINGS}
                  component={ChnnlSettings}
                  authorisedRoles={[ROLE_SUPER_ADMIN]}
                />
                <PrivateRoute
                  exact
                  path={CHNNL_DASHBOARD_SETTINGS_PROFILE}
                  component={ChnnlSettingsProfile}
                  authorisedRoles={[ROLE_SUPER_ADMIN]}
                />
                <PrivateRoute
                  exact
                  path={CHNNL_DASHBOARD_SETTINGS_PASSWORD}
                  component={ChnnlSettingsPassword}
                  authorisedRoles={[ROLE_SUPER_ADMIN]}
                />
                <PrivateRoute
                  exact
                  path={CHNNL_DASHBOARD_SETTINGS_NOTIFICATIONS}
                  component={ChnnlSettingsNotifications}
                  authorisedRoles={[ROLE_SUPER_ADMIN]}
                />

                {/* Organisations */}
                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD}
                  component={({ match }) => (
                    <Redirect
                      to={replaceRouteParams(CHNNL_DASHBOARD_ORGANISATIONS, {
                        orgUuid: match.params.orgUuid,
                      })}
                    />
                  )}
                  authorisedRoles={[
                    ROLE_SUPER_ADMIN,
                    ROLE_PARTNER_ADMIN,
                    ROLE_ORG_ADMIN,
                    ROLE_ORG_OWNER,
                  ]}
                />
                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_OVERVIEW}
                  component={OrganisationOverview}
                  authorisedRoles={[
                    ROLE_SUPER_ADMIN,
                    ROLE_PARTNER_ADMIN,
                    ROLE_ORG_ADMIN,
                    ROLE_ORG_OWNER,
                  ]}
                />
                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_GROUPS}
                  component={OrganisationGroups}
                  authorisedRoles={[
                    ROLE_SUPER_ADMIN,
                    ROLE_PARTNER_ADMIN,
                    ROLE_ORG_ADMIN,
                    ROLE_ORG_OWNER,
                    ROLE_GROUP_ADMIN,
                  ]}
                />
                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_JOURNALS}
                  component={OrganisationJournals}
                  authorisedRoles={[
                    ROLE_SUPER_ADMIN,
                    ROLE_PARTNER_ADMIN,
                    ROLE_ORG_ADMIN,
                    ROLE_ORG_OWNER,
                  ]}
                />
                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_SPEAKUP}
                  component={OrganisationSpeakUp}
                  authorisedRoles={[
                    ROLE_SUPER_ADMIN,
                    ROLE_PARTNER_ADMIN,
                    ROLE_ORG_ADMIN,
                    ROLE_ORG_OWNER,
                  ]}
                />
                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_APP}
                  component={({ match }) => (
                    <Redirect
                      to={replaceRouteParams(ORG_DASHBOARD_APP_ORGANISATION, {
                        orgUuid: match.params.orgUuid,
                      })}
                    />
                  )}
                  authorisedRoles={[
                    ROLE_SUPER_ADMIN,
                    ROLE_PARTNER_ADMIN,
                    ROLE_ORG_ADMIN,
                    ROLE_ORG_OWNER,
                  ]}
                />
                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_MESSAGING}
                  component={({ match }) => (
                    <Redirect
                      to={replaceRouteParams(
                        ORG_DASHBOARD_MESSAGING_ORGANISATION,
                        {
                          orgUuid: match.params.orgUuid,
                        },
                      )}
                    />
                  )}
                  authorisedRoles={[
                    ROLE_SUPER_ADMIN,
                    ROLE_PARTNER_ADMIN,
                    ROLE_ORG_ADMIN,
                    ROLE_ORG_OWNER,
                  ]}
                />
                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_MESSAGING_ORGANISATION}
                  component={Messaging}
                  authorisedRoles={[
                    ROLE_SUPER_ADMIN,
                    ROLE_PARTNER_ADMIN,
                    ROLE_ORG_ADMIN,
                    ROLE_ORG_OWNER,
                  ]}
                />
                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_MESSAGING_NETWORK}
                  component={Network}
                  authorisedRoles={[
                    ROLE_SUPER_ADMIN,
                    ROLE_PARTNER_ADMIN,
                    ROLE_ORG_ADMIN,
                    ROLE_ORG_OWNER,
                  ]}
                />
                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_MESSAGING_NOTICEBOARD}
                  component={NoticeBoard}
                  authorisedRoles={[
                    ROLE_SUPER_ADMIN,
                    ROLE_PARTNER_ADMIN,
                    ROLE_ORG_ADMIN,
                    ROLE_ORG_OWNER,
                  ]}
                />
                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_APP_BOOSTERS}
                  component={OrganisationBoosters}
                  authorisedRoles={[
                    ROLE_SUPER_ADMIN,
                    ROLE_PARTNER_ADMIN,
                    ROLE_ORG_ADMIN,
                    ROLE_ORG_OWNER,
                  ]}
                />
                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_APP_PATHWAYS}
                  component={OrganisationPathways}
                  authorisedRoles={[
                    ROLE_SUPER_ADMIN,
                    ROLE_PARTNER_ADMIN,
                    ROLE_ORG_ADMIN,
                    ROLE_ORG_OWNER,
                  ]}
                />
                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_APP_ORGANISATION}
                  component={OrganisationAppOrganisation}
                  authorisedRoles={[
                    ROLE_SUPER_ADMIN,
                    ROLE_PARTNER_ADMIN,
                    ROLE_ORG_ADMIN,
                    ROLE_ORG_OWNER,
                  ]}
                />
                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_APP_CONTACTS}
                  component={OrganisationAppContacts}
                  authorisedRoles={[
                    ROLE_SUPER_ADMIN,
                    ROLE_PARTNER_ADMIN,
                    ROLE_ORG_ADMIN,
                    ROLE_ORG_OWNER,
                  ]}
                />
                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_APP_SURVEY}
                  component={OrganisationSurvey}
                  authorisedRoles={[
                    ROLE_SUPER_ADMIN,
                    ROLE_PARTNER_ADMIN,
                    ROLE_ORG_ADMIN,
                    ROLE_ORG_OWNER,
                  ]}
                />
                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_APP_SUPPORT}
                  component={SupportOptions}
                  authorisedRoles={[
                    ROLE_SUPER_ADMIN,
                    ROLE_PARTNER_ADMIN,
                    ROLE_ORG_ADMIN,
                    ROLE_ORG_OWNER,
                  ]}
                />
                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_APP_REFLECTIONS}
                  component={OrganisationReflections}
                  authorisedRoles={[
                    ROLE_SUPER_ADMIN,
                    ROLE_PARTNER_ADMIN,
                    ROLE_ORG_ADMIN,
                    ROLE_ORG_OWNER,
                  ]}
                />

                {/* Org settings */}
                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_SETTINGS}
                  component={({ match, role }) => {
                    if (role === ROLE_GROUP_ADMIN) {
                      return (
                        <Redirect
                          to={replaceRouteParams(
                            ORG_DASHBOARD_SETTINGS_PROFILE,
                            {
                              orgUuid: match.params.orgUuid,
                            },
                          )}
                        />
                      )
                    }

                    return (
                      <Redirect
                        to={replaceRouteParams(ORG_DASHBOARD_SETTINGS_GENERAL, {
                          orgUuid: match.params.orgUuid,
                        })}
                      />
                    )
                  }}
                />

                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_SETTINGS_ADMINS}
                  component={OrganisationSettingsAdmin}
                />

                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_SETTINGS_GENERAL}
                  component={OrganisationSettingsGeneral}
                />

                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_SETTINGS_PROFILE}
                  component={OrganisationSettingsProfile}
                />

                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_SETTINGS_NOTIFICATIONS}
                  component={OrganisationSettingsNotifications}
                />

                <PrivateRoute
                  exact
                  path={ORG_DASHBOARD_SETTINGS_ENTITLEMENTS}
                  component={OrganisationSettingsEntitlements}
                />

                {/* Group */}
                <PrivateRoute
                  exact
                  path={GROUP_DASHBOARD}
                  component={({ match }) => (
                    <Redirect
                      to={replaceRouteParams(GROUP_DASHBOARD_WELLBEING, {
                        groupUuid: match.params.groupUuid,
                        orgUuid: match.params.orgUuid,
                      })}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path={GROUP_DASHBOARD_WELLBEING}
                  component={GroupOverview}
                />

                <PrivateRoute
                  exact
                  path={GROUP_DASHBOARD_BOOSTERS}
                  component={GroupBoosters}
                />

                <PrivateRoute
                  exact
                  path={GROUP_DASHBOARD_MESSAGING}
                  component={Messaging}
                />

                <PrivateRoute
                  exact
                  path={GROUP_DASHBOARD_MEMBERS}
                  component={GroupMembers}
                />

                <PrivateRoute
                  exact
                  path={GROUP_DASHBOARD_SETTINGS}
                  component={GroupSettings}
                />
                <PrivateRoute
                  exact
                  path={CHNNL_DASHBOARD_PARTNERS}
                  component={ChnnlPartners}
                  authorisedRoles={[ROLE_SUPER_ADMIN]}
                />
                <PrivateRoute
                  exact
                  path={PARTNER_DASHBOARD_SETTINGS_DETAILS}
                  component={PartnerGeneralSettings}
                  authorisedRoles={[ROLE_SUPER_ADMIN]}
                />
                <PrivateRoute
                  exact
                  path={PARTNER_DASHBOARD_ORGANISATIONS}
                  component={PartnerOrganisations}
                  authorisedRoles={[ROLE_SUPER_ADMIN]}
                />
                <PrivateRoute
                  exact
                  path={PARTNER_ADMIN_DASHBOARD_ORGANISATIONS}
                  component={PartnerAdminOrganisationsView}
                  authorisedRoles={[ROLE_SUPER_ADMIN, ROLE_PARTNER_ADMIN]}
                />
                <PrivateRoute
                  exact
                  path={PARTNER_DASHBOARD_ADMINS}
                  component={PartnerAdmins}
                  authorisedRoles={[ROLE_SUPER_ADMIN]}
                />

                {/* 404 */}
                <Route component={PageNotFound} />
              </Switch>
              <ScreenSizeMessage />
            </div>
          </FeatureProvider>
        </Auth0Provider>
      </ConnectedRouter>
      <Toasts />
      <Undo />
    </div>
  )
}

export default App
