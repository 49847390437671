import { createSelector } from 'reselect'

export const getBoostersList = createSelector(
  (state) => state.boosters.entities,
  (boosters) => Object.values(boosters),
)

export const getGroupBoostersList = createSelector(
  [getBoostersList, (state, groupUuid) => groupUuid],
  (boosters, groupUuid) =>
    boosters.filter((booster) => booster.groupUuid === groupUuid),
)

export const getOrganisationBoostersList = createSelector(
  [getBoostersList, (state, orgUuid) => orgUuid],
  (boosters, orgUuid) =>
    boosters.filter(
      (booster) =>
        // Only get boosters part of the organisation but not in a group
        booster.orgUuid === orgUuid && !booster.groupUuid,
    ),
)
