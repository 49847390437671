import axios, { AxiosError } from 'axios'
import { SAUCE_API_URL } from 'config'
import { useEffect, useState } from 'react'
import { ApiResult } from 'types/services'

export interface Theme {
  uuid: string
  title: string
  key: string
}

export const useThemes = (): {
  data?: Theme[]
  error?: string
} => {
  const [data, setData] = useState<Theme[] | undefined>(undefined)
  const [error, setError] = useState<string | undefined>(undefined)

  const fetchReflections = async () => {
    setError(undefined)

    try {
      const result = await axios.get<ApiResult<Theme[]>>(
        `${SAUCE_API_URL}/theme`,
      )
      setData(result.data.data)
    } catch (error: unknown) {
      setError((error as AxiosError).message)
    }
  }

  useEffect(() => {
    fetchReflections()
  }, [])

  return {
    data: data,
    error: error,
  }
}
