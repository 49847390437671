import { FeatureKeys } from 'constants/features'
import { ReflectionSentimentEnum } from 'constants/ReflectionSentimentEnum'

import { InputLabel } from '@material-ui/core'
import { useFeature } from '@optimizely/react-sdk'
import { Button, TextField } from '@weareroam/cake-ui-v1'
import Typography from '@weareroam/cake-ui-v1/Typography'
import ButtonSwitch from 'components/atoms/ButtonSwitch'
import { AddCircle } from 'components/atoms/Icons/AddCircle'
import StatusTag from 'components/atoms/StatusTag/StatusTag'
import Autosuggest from 'components/molecules/Autosuggest'
import { Form, FormikProps, FieldArray, FormikErrors } from 'formik'
import { IReflectionSubQuestion } from 'models/IReflectionSubQuestion'
import React from 'react'
import styled from 'styled-components'
import { sentimentColours } from 'views/Dashboard/Organisation/App/Reflections/Reflections'

import { ButtonSwitchGroup } from '../InviteNewAdminForm/InviteNewAdminForm'

import ReflectionItemFormSubQuestion from './ReflectionItemFormSubQuestion'

import { InnerFormProps, ReflectionItemFormValues, HandlerProps } from './index'

const StyledReflectionField = styled(TextField)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.mdlg}px;
  }
`

const Actions = styled.footer`
  display: flex;
  width: 320px;
  margin: 0 auto;

  && {
    > * {
      margin: 0 ${({ theme }) => theme.spacing.sm}px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const StyledRadioOption = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`

export const StyledModalTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledFooter = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
`
export const SentimentError = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
  text-align: left;
`
const StyledSubQuestionFormHolder = styled.div`
  text-align: left;
`
const StyledAddButton = styled(Button)`
  && {
    padding: 4px 5px;
  }
`

const ReflectionItemForm = ({
  handleBlur,
  handleChange,
  handleSubmit,
  touched,
  errors,
  inEditMode,
  onCancel,
  values,
  setSentiment,
  typesOfDays,
  onAddChip,
  onDeleteChip,
  setFieldValue,
}: InnerFormProps &
  FormikProps<ReflectionItemFormValues> &
  HandlerProps): React.ReactElement => {
  const handleOnAutoSuggestChange = React.useCallback((e, update) => {
    if (update && update.newValue) {
      if (update.method === 'click') {
        onAddChip(update.newValue)
      } else {
        setFieldValue('textInput', update.newValue)
      }
    } else {
      handleChange(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const filteredOptions = React.useMemo(
    () =>
      typesOfDays.filter((t) => !values.dayTypes.some((s) => s === t.label)),
    [typesOfDays, values.dayTypes],
  )
  const [reflectionSubQuestionsEnabled] = useFeature(
    FeatureKeys.ReflectionSubQuestions,
  )

  return (
    <Form onSubmit={handleSubmit} noValidate autoComplete="off">
      <StyledModalTitle variant="h2">
        {inEditMode ? 'Edit reflection' : 'Add new reflection'}
      </StyledModalTitle>
      <StyledReflectionField
        id="title"
        name="title"
        label="Title"
        type="text"
        value={values.title}
        onBlur={handleBlur}
        onChange={handleChange}
        error={touched.title && !!errors.title}
        helperText={touched.title ? errors.title : ''}
        fullWidth
      />
      {reflectionSubQuestionsEnabled && (
        <FieldArray
          name="subQuestions"
          render={(arrayHelpers) => (
            <StyledSubQuestionFormHolder>
              {values.subQuestions?.map((subQuestion, index) => {
                const touchedSubQuestion = touched?.subQuestions?.[index]
                const errorsSubQuestion = errors?.subQuestions?.[
                  index
                ] as FormikErrors<IReflectionSubQuestion>
                return (
                  <ReflectionItemFormSubQuestion
                    key={index}
                    index={index}
                    subQuestion={subQuestion}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleDelete={(idx) => arrayHelpers.remove(idx)}
                    touched={touchedSubQuestion}
                    errors={errorsSubQuestion}
                  />
                )
              })}
              {values.subQuestions?.length < 2 && (
                <StyledAddButton
                  variant="text"
                  type="button"
                  color="secondary"
                  size="small"
                  onClick={() =>
                    arrayHelpers.push({
                      title: '',
                      option1: '',
                      option2: '',
                      option3: '',
                    })
                  }>
                  <AddCircle />
                  &nbsp;&nbsp;Add sub-question
                </StyledAddButton>
              )}
            </StyledSubQuestionFormHolder>
          )}
        />
      )}
      <StyledRadioOption>
        <InputLabel error={touched.sentiment && !!errors.sentiment}>
          Sentiment
        </InputLabel>
        <ButtonSwitchGroup>
          <ButtonSwitch
            size="small"
            name="sentiment"
            isActive={values.sentiment === ReflectionSentimentEnum.POSITIVE}
            value={ReflectionSentimentEnum.POSITIVE}
            onClick={() => setSentiment(ReflectionSentimentEnum.POSITIVE)}>
            <StatusTag
              themeColor={sentimentColours[ReflectionSentimentEnum.POSITIVE]}>
              {'positive'}
            </StatusTag>
          </ButtonSwitch>
          <ButtonSwitch
            size="small"
            name="sentiment"
            isActive={values.sentiment === ReflectionSentimentEnum.NEGATIVE}
            value={ReflectionSentimentEnum.NEGATIVE}
            onClick={() => setSentiment(ReflectionSentimentEnum.NEGATIVE)}>
            <StatusTag
              themeColor={sentimentColours[ReflectionSentimentEnum.NEGATIVE]}>
              {'negative'}
            </StatusTag>
          </ButtonSwitch>
        </ButtonSwitchGroup>
      </StyledRadioOption>
      <Autosuggest
        variant="chip"
        value={values.textInput}
        chips={values.dayTypes}
        onDeleteChip={onDeleteChip}
        onAddChip={onAddChip}
        error={touched.dayTypes && !!errors.dayTypes}
        helperText={touched.dayTypes ? errors.dayTypes : ''}
        onChange={handleOnAutoSuggestChange}
        placeholder="All Day Types"
        name="textInput"
        onBlur={handleBlur}
        suggestions={filteredOptions}
        noneSelectedHelperText="This Day type does not exist"
      />
      <StyledFooter>
        {touched.sentiment && errors.sentiment && (
          <SentimentError variant="body2">{errors.sentiment}</SentimentError>
        )}
      </StyledFooter>
      <Actions>
        <Button
          variant="outlined"
          type="button"
          color="primary"
          fullWidth
          size="small"
          onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          type="submit"
          disabled={
            (touched.sentiment && !!errors.sentiment) ||
            (touched.title && !!errors.title)
          }
          fullWidth
          color="primary"
          size="small">
          Save changes
        </Button>
      </Actions>
    </Form>
  )
}

export default ReflectionItemForm
