import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { Box, IconButton, Link } from '@mui/material'
import React from 'react'

import { InboxMessageLinkUserInputs } from '../../../modules/messaging/interfaces'

interface LinkInputsLineProps {
  link: InboxMessageLinkUserInputs
  onDelete: () => void
}

export const LinkItemLine = ({
  link,
  onDelete,
}: LinkInputsLineProps): React.ReactElement => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
    }}>
    <Link variant={'body2'} href={link.url} target="_blank" rel="noreferrer">
      {link.title}
    </Link>
    &nbsp;
    <IconButton
      aria-label="delete"
      size="small"
      color="primary"
      onClick={onDelete}>
      <CancelOutlinedIcon fontSize="small" />
    </IconButton>
  </Box>
)
