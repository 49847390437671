import React, { CSSProperties, ReactNode } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'

import { StyledDragIcon } from '../Box/Box'

interface ListItemColumn {
  contents: ReactNode
  width: number
  alignment?: 'flex-start' | 'center' | 'flex-end'
}

interface ListItemProps {
  columns: Array<ListItemColumn>
  draggable?: boolean
  draggableId?: string
  draggableIndex?: number
  variant?: 'raised' | 'flat'
  verticallyCentered?: boolean
}

const ListItemPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: white;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: ${({ theme }) => theme.spacing.md}px;
  margin: ${({ theme }) => theme.spacing.mdlg}px 0;
`

const ListItemFlatPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: white;
  border: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  border-radius: 3px;
  padding: ${({ theme }) => theme.spacing.sm}px;
  margin: ${({ theme }) => theme.spacing.sm}px 0;
`

interface DragProps {
  isDragging: boolean
}

const DraggableBox = styled.div<DragProps>`
  box-shadow: ${({ isDragging }) =>
    isDragging
      ? '2px 6px 7px 0 rgba(197,197,197,0.25), 0 2px 3px 0 rgba(35,38,43,0.05), 0 0 1px 0 rgba(28,35,50,0.14), inset 0 1px 1px 0 #FFFFFF'
      : ''};
`

const ListItemColumn = styled.div`
  padding-right: ${({ theme }) => theme.spacing.md}px;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }
`

const centerVertically: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
}

export function ListItem({
  columns,
  draggable,
  draggableId,
  draggableIndex,
  variant = 'raised',
  verticallyCentered,
}: ListItemProps): React.ReactElement {
  const contents = (
    <>
      {columns.map((column, index) => (
        <ListItemColumn
          key={index}
          style={{
            width: column.width + '%',
            justifyContent: column.alignment || 'flex-start',
            ...(verticallyCentered ? centerVertically : {}),
          }}>
          {column.contents}
        </ListItemColumn>
      ))}
    </>
  )

  const panel =
    variant === 'raised' ? (
      <ListItemPanel>{contents}</ListItemPanel>
    ) : (
      <ListItemFlatPanel>{contents}</ListItemFlatPanel>
    )

  if (!draggable || !draggableId) {
    return panel
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    <Draggable draggableId={draggableId} index={draggableIndex!}>
      {(provided, snapshot) => (
        <DraggableBox
          ref={provided.innerRef}
          {...provided.draggableProps}
          isDragging={snapshot.isDragging}
          style={provided.draggableProps.style}>
          {variant === 'raised' ? (
            <ListItemPanel>
              {
                <span {...provided.dragHandleProps}>
                  <StyledDragIcon />
                </span>
              }
              {contents}
            </ListItemPanel>
          ) : (
            <ListItemFlatPanel>
              {
                <span {...provided.dragHandleProps}>
                  <StyledDragIcon />
                </span>
              }
              {contents}
            </ListItemFlatPanel>
          )}
        </DraggableBox>
      )}
    </Draggable>
  )
}
