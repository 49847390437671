import Button from '@weareroam/cake-ui-v1/Button'
import TextField from '@weareroam/cake-ui-v1/TextField'
import Typography from '@weareroam/cake-ui-v1/Typography'
import Box from 'components/molecules/Box'
import Dropzone from 'components/molecules/Dropzone'
import FilePreview from 'components/molecules/FilePreview'
import { Modal } from 'components/molecules/Modal'
import ConfirmUpdatesForm from 'components/organisms/ConfirmUpdatesForm'
import NavigationPromptForm from 'components/organisms/NavigationPromptForm'
import PartnerInnerLayout from 'layouts/PartnerInnerLayout'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import NavigationPrompt from 'react-router-navigation-prompt'
import styled from 'styled-components'

export const StyledHeaderButtons = styled.div`
  display: inline-block;
  float: right;
`

export const StyledTextInputContainer = styled.div`
  width: 50%;
`

export const StyledInputContainer = styled.div``

export const StyledUploadTitle = styled(Typography)``

export const StyledDropzone = styled(Dropzone)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`
export const StyledFilePreview = styled(FilePreview)``

export const StyledUploadBox = styled(Box)`
  margin-top: 48px;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`
export const UploadBoxContent = styled.div`
  padding: ${({ theme }) => theme.spacing.lg}px;
`
export const StyledLogo = styled.img`
  height: 40px;
`
export const UpdatePartnerButton = styled(Button)`
  width: 140px;
`
export const StyledActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const StyledButton = styled(Button)`
  && {
    width: 50%;
    margin: 0 ${({ theme }) => theme.spacing.sm}px;
  }
`
export const StyledModalTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`
export const ConfirmUpdatesModal = styled(Modal)`
  width: 480px;
`
export const UpdateButton = styled(Button)`
  width: 140px;
`
export const StyledHeader = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`
export const HeaderLeft = styled.div`
  flex-grow: 1;
`
export const StyledName = styled(TextField)`
  && {
    width: 50%;
    margin-bottom: ${({ theme }) => theme.spacing.xl}px;
    padding-right: ${({ theme }) => theme.spacing.xs}px;
  }
`
export const StyledForm = styled.form`
  && {
    text-align: left;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function PartnerGeneralSettings({
  onImageUploadError,
  uploadTotal,
  uploadProgress,
  partner,
  handleBlur,
  handleChange,
  onUpdatePartner,
  setIsConfirmUpdateModalOpen,
  isConfirmUpdateModalOpen,
  handleSubmit,
  errors,
  values,
  touched,
  className,
  setFieldValue,
}) {
  const handleCloseConfirmUpdatesModal = useCallback(() => {
    setIsConfirmUpdateModalOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const hasUnsavedChanges = useMemo(
    () =>
      (values.logo && typeof values.logo === 'object') ||
      values.partnerTitle !== partner.title,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partner.title, values.partnerTitle, values.logo],
  )

  return (
    <PartnerInnerLayout>
      <StyledForm
        onSubmit={handleSubmit}
        noValidate
        autoComplete="off"
        className={className}>
        <NavigationPrompt when={hasUnsavedChanges}>
          {({ onConfirm, onCancel }) => (
            <Modal open>
              <NavigationPromptForm onLeave={onConfirm} onStay={onCancel} />
            </Modal>
          )}
        </NavigationPrompt>
        <ConfirmUpdatesModal
          open={isConfirmUpdateModalOpen}
          onClose={handleCloseConfirmUpdatesModal}>
          <ConfirmUpdatesForm
            onCancel={handleCloseConfirmUpdatesModal}
            onSubmit={onUpdatePartner}
            title="Confirm partner updates"
            content={
              <React.Fragment>
                All parter changes will be <br /> updates and visible in the
                dashboard.
              </React.Fragment>
            }
          />
        </ConfirmUpdatesModal>

        <StyledHeader>
          <HeaderLeft>
            <Typography variant="h2">Partner details</Typography>
          </HeaderLeft>
          <UpdateButton
            variant="contained"
            type="submit"
            color="primary"
            size="small"
            disabled={!hasUnsavedChanges}>
            Update
          </UpdateButton>
        </StyledHeader>
        {partner ? (
          <StyledInputContainer>
            <StyledName
              id="partnerTitle"
              name="partnerTitle"
              label="Partner title"
              type="text"
              value={values.partnerTitle || ''}
              InputLabelProps={{ shrink: true }}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.partnerTitle && !!errors.partnerTitle}
              helperText={touched.partnerTitle ? errors.partnerTitle : ''}
            />
            <StyledUploadBox
              headerContent={
                <StyledUploadTitle variant="h4">Partner logo</StyledUploadTitle>
              }>
              <UploadBoxContent>
                <StyledDropzone
                  accept={['image/png', 'image/jpeg']}
                  onDrop={(drop) => {
                    if (drop[0]) {
                      setFieldValue('logo', drop[0])
                    } else {
                      onImageUploadError()
                    }
                  }}
                  name="logo"
                />

                {/* Either logo is a url from API (string) or an object from selecting a new logo */}
                {typeof values.logo === 'string' ? (
                  <StyledLogo src={values.logo} alt="Logo" />
                ) : (
                  values.logo && (
                    <StyledFilePreview
                      name={values.logo.name}
                      total={uploadTotal}
                      progress={uploadProgress}
                    />
                  )
                )}
              </UploadBoxContent>
            </StyledUploadBox>
          </StyledInputContainer>
        ) : (
          'Loading...'
        )}
      </StyledForm>
    </PartnerInnerLayout>
  )
}

PartnerGeneralSettings.propTypes = {
  getPartnerProgress: PropTypes.object,
  onImageUploadError: PropTypes.func,
  uploadTotal: PropTypes.number,
  uploadProgress: PropTypes.number,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  onUpdatePartner: PropTypes.func,
  onPartnerTitleInputChange: PropTypes.func,
  setIsConfirmUpdateModalOpen: PropTypes.func,
  isConfirmUpdateModalOpen: PropTypes.bool,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  partner: PropTypes.object,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  className: PropTypes.string,
}

PartnerGeneralSettings.defaultProps = {
  getPartnerProgress: {},
  partner: { title: '', logo: '' },
  errors: {},
  values: {},
  touched: {},
}

export default PartnerGeneralSettings
