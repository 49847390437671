import Button from '@weareroam/cake-ui-v1/Button'
import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'

interface HeaderButtonProps {
  title: string
  type: 'primary'
  disabled?: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}

const StyledButton = styled(Button)`
  && {
    margin-left: ${({ theme }) => theme.spacing.md}px;
  }
`

export function HeaderButton({
  title,
  type,
  disabled,
  onClick,
}: HeaderButtonProps): React.ReactElement {
  if (type === 'primary')
    return (
      <StyledButton
        variant="contained"
        size="small"
        color="primary"
        disabled={disabled}
        onClick={onClick}>
        {title}
      </StyledButton>
    )
  return <></>
}
