import React from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function FaceNeutral(props) {
  return (
    <svg width="21px" height="21px" viewBox="0 0 21 21" {...props}>
      <g
        id="Chnnl-admin-home"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="_Screens/Super-admin/selected-orgs"
          transform="translate(-336.000000, -338.000000)"
          stroke="currentColor"
          strokeWidth="1.092224">
          <g id="Group-10" transform="translate(337.000000, 339.000000)">
            <g id="Group-9">
              <path
                d="M9.51609412,18.9347294 C14.7176235,18.9347294 18.9342824,14.7174 18.9342824,9.51587059 C18.9342824,4.31456471 14.7176235,0.0979058824 9.51609412,0.0979058824 C4.31456471,0.0979058824 0.0979058824,4.31456471 0.0979058824,9.51587059 C0.0979058824,14.7174 4.31456471,18.9347294 9.51609412,18.9347294 Z"
                id="Stroke-1"
                fill="transparent"
              />
              <path
                d="M8.25784706,9.31833445 C8.25784706,7.96352269 7.15987059,6.86554622 5.80505882,6.86554622 C4.45091765,6.86554622 3.35294118,7.96352269 3.35294118,9.31833445"
                id="Stroke-5"
                transform="translate(5.805394, 8.091940) rotate(-180.000000) translate(-5.805394, -8.091940) "
              />
              <path
                d="M15.6023849,9.31833445 C15.6023849,7.96352269 14.5044084,6.86554622 13.1495966,6.86554622 C11.7954555,6.86554622 10.697479,7.96352269 10.697479,9.31833445"
                id="Stroke-7"
                transform="translate(13.149932, 8.091940) rotate(-180.000000) translate(-13.149932, -8.091940) "
              />
            </g>
            <path
              d="M9.79287071,14.0754918 C11.3185785,14.0754918 12.8442863,13.8448745 14.369994,13.3836401"
              id="Path-2"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default FaceNeutral
