import axios from 'axios'
import { camelCase, snakeCase } from 'change-case'
import { useCallback, useEffect, useState } from 'react'

import { SAUCE_API_URL } from '../config'
import { ReportErrorType } from '../constants/reports'
import { SupportTriggerTypes } from '../constants/SupportTriggerTypesEnum'
import { emptyTriggerSet } from '../constants/triggers'
import { SupportTrigger } from '../modules/supportTrigger/schema'
import { WithRequired } from '../utils/types'

export const useSupportTriggers = ({
  orgUuid,
}: {
  orgUuid: string
}): {
  data: { [key in SupportTriggerTypes]: SupportTrigger }
  isLoading: boolean
  error?: ReportErrorType
  handlers: {
    create: (supportTrigger: SupportTrigger) => Promise<void>
    update: (
      supportTrigger: WithRequired<SupportTrigger, 'uuid'>,
    ) => Promise<void>
    remove: (supportTriggerUuid: string) => Promise<void>
  }
} => {
  const [data, setData] =
    useState<{ [key in SupportTriggerTypes]: SupportTrigger }>(emptyTriggerSet)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<ReportErrorType | undefined>(undefined)

  const fetchSupportTriggers = useCallback(async () => {
    setIsLoading(true)
    setError(undefined)
    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${orgUuid}/support/trigger`,
        {
          params: {},
        },
      )
      const data = response.data.data[0].output
      // Populating a dictionary of triggers from array for presentation
      const triggers = { ...emptyTriggerSet }
      for (const item of data) {
        // TODO: Getting to the level where adapter or normalizer is required
        //@ts-expect-error item.type is 'any'. it's a string coming from the backend in snake_case
        triggers[camelCase(item.type)] = {
          ...item,
          type: camelCase(item.type),
          //@ts-expect-error all stuff on the wire is 'any'
          options: item.options.map((option) => ({
            ...option,
            type: camelCase(option.type),
          })),
        }
      }
      setData(triggers)
    } catch (error: unknown) {
      setError(ReportErrorType.GENERIC)
    }
    setIsLoading(false)
  }, [setData, setIsLoading, setError, orgUuid])

  useEffect(() => {
    fetchSupportTriggers()
  }, [fetchSupportTriggers])

  const create = async (supportTrigger: SupportTrigger) => {
    setIsLoading(true)
    setError(undefined)

    // TODO: Yep. Need an adapter for these conversions.
    const toSave = {
      ...supportTrigger,
      orgUuid,
      type: snakeCase(supportTrigger.type),
      options: supportTrigger.options.map((option) => ({
        ...option,
        type: snakeCase(option.type),
      })),
    }
    try {
      await axios.post(
        `${SAUCE_API_URL}/organisation/${orgUuid}/support/trigger/`,
        toSave,
      )
      await fetchSupportTriggers()
    } catch (error: unknown) {
      setError(ReportErrorType.GENERIC)
    }
    setIsLoading(false)
  }

  const update = async (
    supportTrigger: WithRequired<SupportTrigger, 'uuid'>,
  ) => {
    setIsLoading(true)
    setError(undefined)

    const toSave = {
      ...supportTrigger,
      type: snakeCase(supportTrigger.type),
      options: supportTrigger.options.map((option) => ({
        ...option,
        type: snakeCase(option.type),
      })),
    }
    try {
      await axios.put(
        `${SAUCE_API_URL}/support/trigger/${supportTrigger.uuid}`,
        toSave,
      )
      await fetchSupportTriggers()
    } catch (error: unknown) {
      setError(ReportErrorType.GENERIC)
    }
    setIsLoading(false)
  }

  const remove = async (supportTriggerUuid: string) => {
    setIsLoading(true)
    setError(undefined)
    try {
      await axios.delete(
        `${SAUCE_API_URL}/support/trigger/${supportTriggerUuid}`,
        {},
      )
      await fetchSupportTriggers()
    } catch (error: unknown) {
      setError(ReportErrorType.GENERIC)
    }
    setIsLoading(false)
  }

  return {
    data,
    isLoading,
    error,
    handlers: {
      create,
      update,
      remove,
    },
  }
}
