import * as Sentry from '@sentry/react'
import urlParser from 'js-video-url-parser'
import {
  CountryCode,
  parsePhoneNumberFromString,
} from 'libphonenumber-js/mobile'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function required(value: unknown) {
  return !value
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isEmpty(value: unknown) {
  return !Array.isArray(value) || !value.length
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function validName(value: string) {
  return value && /^[A-Za-z ]+$/i.test(value)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function validUUID(value: string) {
  return (
    value &&
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
      value,
    )
  ) // eslint-disable-line max-len
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function validEmail(value: string) {
  return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function validUrl(value: string) {
  return (
    value &&
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/i.test(
      value,
    )
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function validMoney(value: string) {
  return value && !/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/i.test(value)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function validNumber(value: string) {
  return value && !/^([0-9]+)$/i.test(value)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function numberRange(min: number, max: number, value: string) {
  const v = parseInt(value, 10)
  return v >= min && v <= max
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ipAddress(value: string) {
  return (
    value &&
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      value,
    )
  ) // eslint-disable-line max-len
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function samePassword(value1: string, value2: string) {
  return value1 === value2
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function withinMinLength(length: number, value: string) {
  return !(value.length < length)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function withinMaxLength(length: number, value: string) {
  return !(value.length > length)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function exactLength(length: number, value: string) {
  return value.length !== length
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function lengthInRange(min: number, max: number, value: string) {
  return value.length >= min && value.length <= max
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function minLength(length: number, value: string) {
  return value.length > length
}

type ObjectInterface = Record<string, unknown>
export const getTrueObject = (obj: ObjectInterface): ObjectInterface => {
  if (!obj) return {}
  const oKeys = Object.keys(obj)

  if (oKeys && oKeys.length > 0) {
    const newObj = Object.assign(obj)
    oKeys.forEach((key: string) => {
      const value = obj[key]
      if (!value) {
        newObj[key] = null
      }
    })

    return newObj
  }

  return {}
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isValidMobileNumberForRegion = (
  phoneNumber: string,
  region: CountryCode,
) => {
  try {
    const number = parsePhoneNumberFromString(phoneNumber, region)
    const numberType = number?.getType()
    if (number) {
      return (
        number.isValid() &&
        (numberType === 'MOBILE' || numberType === 'FIXED_LINE_OR_MOBILE')
      )
    }
  } catch (error) {
    Sentry.captureException(error)
    return false
  }
}

const supportedProviders = ['youtube', 'vimeo']

export const isSupportedProviderUrl = (
  videoUrl: string | null | undefined = '',
): boolean => {
  if (!videoUrl) {
    return true
  }

  const parsedUrl = urlParser.parse(videoUrl)
  return !!parsedUrl && supportedProviders.includes(parsedUrl.provider || '')
}

export const isEven = (value: number): boolean => {
  if (value === 0) return true
  if (value % 2 === 0) return true
  else return false
}
