import { LOGIN } from 'constants/routes'

import * as authActions from 'modules/authentication/actions'
import { redirect } from 'modules/route'
import { connect } from 'react-redux'
import { compose, withState, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  connect(
    // mapStateToProps
    () => ({}),
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        redirect,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  withState('requestSent', 'setRequestSentState', false),
  withState('email', 'setEmail', null),
  withHandlers({
    onSubmit:
      ({ actions, setRequestSentState, setEmail }) =>
      async ({ email }) => {
        const response = await actions.resetPasswordRequest(email)

        if (response) {
          setEmail(email)
          setRequestSentState(true)
        }
      },
    onLoginLinkClick:
      ({ actions }) =>
      () => {
        actions.redirect(LOGIN)
      },
  }),
)
