import idx from 'idx'
import { canEditGroup, hasEditRights } from 'modules/authentication/selectors'
import * as groupActions from 'modules/groups/actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, mapProps, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  withRouter,
  mapProps((props) => ({
    ...props,
    orgUuid: props.match.params.orgUuid,
    groupUuid: props.match.params.groupUuid,
  })),
  connect(
    // mapStateToProps
    (state, { groupUuid }) => ({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      groupName: idx(state, (_) => _.groups.entities[groupUuid].name),
      hasEditAccess:
        state.user.canEdit ||
        canEditGroup(state, { uuid: groupUuid }) ||
        hasEditRights(state),
    }),
    (dispatch) => {
      const actions = {
        ...groupActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  lifecycle({
    componentDidMount() {
      const { actions, match } = this.props

      actions.getGroups(match.params.orgUuid)
    },
  }),
)
