import omitProps from '@hocs/omit-props'
import { hasEditRights } from 'modules/authentication/selectors'
import { connect } from 'react-redux'
import { compose, branch, renderNothing } from 'recompose'

/**
 * This HOC renders the component only if the users role is one of the authorised roles
 * @param {Array}   authorisedRoles The array of authorised roles
 * @param {Boolean} requiresCanEdit If the user required edit access to view the component
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function authorisedRoles(authorisedRoles, requiresCanEdit) {
  return compose(
    connect(
      // mapStateToProps
      (state) => ({
        role: state.authentication.role,
        hasEditAccess: state.user.canEdit || hasEditRights(state),
      }),
      () => ({}),
    ),
    branch(
      // Hide if the user is not one of the specified roles or they require edit access but only have view access
      ({ role, hasEditAccess }) =>
        !authorisedRoles.includes(role) || (requiresCanEdit && !hasEditAccess),
      renderNothing,
    ),
    omitProps('role', 'hasEditAccess'),
  )
}

export default authorisedRoles
