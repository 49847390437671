import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styled from 'styled-components'

export const StyledRowButton = styled.button`
  width: 100%;
  text-align: left;
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.primary.main};
  border: 1px dashed ${({ theme }) => theme.palette.tertiary.light};
  outline: 0;
  cursor: pointer;
  background: transparent;
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  padding: ${({ theme }) => theme.spacing.md}px
    ${({ theme }) => theme.spacing.lg}px;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function RowButton({ children, onClick, className, value }) {
  const handleOnClick = useCallback(
    () => {
      if (onClick) {
        onClick(value)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  )

  return (
    <StyledRowButton
      type="button"
      onClick={handleOnClick}
      className={className}>
      {children}
    </StyledRowButton>
  )
}

RowButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.any,
}

export default RowButton
