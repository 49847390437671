import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledContent = styled.div`
  text-align: center;
  width: 330px;
`

export const StyledTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledText = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.xl}px;
  }
`

export const DesktopOnlyButton = styled(Button)`
  display: block;

  @media (max-width: 1024px) {
    display: none;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Success({ goToLogin }) {
  return (
    <DefaultLayout>
      <StyledContent>
        <StyledTitle variant="h5">Reset password</StyledTitle>
        <StyledText variant="body1">
          A link to reset your password has been <br />
          sent to your email address.
        </StyledText>
        <DesktopOnlyButton
          variant="contained"
          color="primary"
          type="button"
          fullWidth
          onClick={goToLogin}>
          Return to login
        </DesktopOnlyButton>
      </StyledContent>
    </DefaultLayout>
  )
}

Success.propTypes = {
  goToLogin: PropTypes.func.isRequired,
}

export default Success
