import Typography from '@weareroam/cake-ui-v1/Typography'
import React from 'react'
import styled from 'styled-components'

export interface ListSectionColumn {
  title: string
  width: number
  alignment?: 'start' | 'center' | 'end'
}

interface ListSectionProps {
  columns: Array<ListSectionColumn>
  children: React.ReactNode
}

const StyledSection = styled.section`
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

const StyledHeader = styled.div`
  margin: ${({ theme }) => theme.spacing.md}px 0;
  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
  padding: 0 ${({ theme }) => theme.spacing.md}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};

  display: flex;
  align-items: center;
  width: 100%;
`

const SectionColumn = styled.div`
  padding-right: ${({ theme }) => theme.spacing.md}px;
`

const ColumnText = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export function ListSection({
  columns,
  children,
}: ListSectionProps): React.ReactElement {
  return (
    <StyledSection>
      <StyledHeader>
        {columns.map((column) => (
          <SectionColumn
            key={column.title}
            style={{
              minWidth: column.width + '%',
              textAlign: column.alignment || 'start',
            }}>
            <ColumnText variant="body2">{column.title}</ColumnText>
          </SectionColumn>
        ))}
      </StyledHeader>
      {children}
    </StyledSection>
  )
}
