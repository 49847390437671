import { ReflectionSentimentEnum } from 'constants/ReflectionSentimentEnum'

import { FormikProps, withFormik } from 'formik'
import { compose, withState, withHandlers } from 'recompose'
import * as Yup from 'yup'

import { FormProps, FormValues, HandlerProps } from './index'

export default compose<
  HandlerProps & FormProps & FormikProps<FormValues>,
  FormProps
>(
  withState('isAddReflectionModalOpen', 'setIsAddReflectionModalOpen', false),
  withState('editReflectionIndex', 'setEditReflectionIndex', null),
  withState('isDeleteModalOpen', 'setIsDeleteModalOpen', false),
  withState('deleteReflectionIndex', 'setDeleteReflectionIndex', null),
  withFormik<FormProps, FormValues>({
    enableReinitialize: true,
    mapPropsToValues: ({ reflections = [] }) => ({
      reflections: reflections.map((reflection, index) => ({
        ...reflection,
        order: index + 1,
      })),
    }),
    validationSchema: Yup.object({
      reflections: Yup.array().of(
        Yup.object({
          title: Yup.string().required('Please enter a reflection title'),
        }),
      ),
    }),
    handleSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
    },
  }),
  withHandlers<FormikProps<FormValues> & FormProps, HandlerProps>({
    onCloseAddReflectionModal:
      ({ setIsAddReflectionModalOpen }) =>
      () => {
        setIsAddReflectionModalOpen(false)
      },
    onAddReflection:
      ({ typesOfDays, onCreate }) =>
      (newValue) => {
        if (onCreate) {
          const newReflection = {
            title: newValue.title,
            sentiment: newValue.sentiment as ReflectionSentimentEnum,
            dayTypes:
              typesOfDays.filter((dayType) =>
                newValue.dayTypes?.some((td) => td === dayType.type),
              ) || [],
            subQuestions:
              newValue?.subQuestions?.map((item, index) => ({
                ...item,
                order: index + 1,
              })) || [],
          }
          onCreate({
            reflection: newReflection,
          })
        }
      },
    onEditReflection:
      ({ values, typesOfDays, onUpdate }) =>
      (newValue, index) => {
        const reflection = values.reflections.find(
          (reflection, i) => i === index,
        )
        if (onUpdate && reflection) {
          const newReflection = {
            ...reflection,
            title: newValue.title,
            sentiment: newValue.sentiment as ReflectionSentimentEnum,
            dayTypes:
              typesOfDays.filter((dayType) =>
                newValue.dayTypes?.some((td) => td === dayType.type),
              ) || [],
            subQuestions:
              newValue?.subQuestions?.map((item, index) => ({
                ...item,
                order: index + 1,
              })) || [],
          }
          onUpdate({
            reflection: newReflection,
            reflectionUuid: reflection.uuid,
          })
        }
      },
    onDeleteReflection:
      ({
        values,
        deleteReflectionIndex,
        onDelete,
        setDeleteReflectionIndex,
        setIsDeleteModalOpen,
      }) =>
      (e) => {
        e.preventDefault()

        const reflection = values.reflections.find(
          (reflection, index) => index === deleteReflectionIndex,
        )
        if (onDelete && reflection) {
          onDelete({ reflectionUuid: reflection.uuid })
        }

        setIsDeleteModalOpen(false)
        setDeleteReflectionIndex(-1)
      },
    onDragEnd:
      ({ values, setFieldValue }) =>
      (result) => {
        // dropped outside the list
        if (!result.destination) {
          return
        }

        // Return the reordered list
        const reorderedList = Array.from(values.reflections)
        const [removed] = reorderedList.splice(result.source.index, 1)
        reorderedList.splice(result.destination.index, 0, removed)

        const listWithOrder = reorderedList.map((reflection, index) => ({
          ...reflection,
          order: index + 1,
        }))

        setFieldValue('reflections', listWithOrder)
      },
  }),
)
