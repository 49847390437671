import React from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Upload() {
  return (
    <svg width="31px" height="38px" viewBox="0 0 31 38">
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="molecule-/-File-Upload-/-Large-/-Normal"
          transform="translate(-154.000000, -25.000000)"
          fill="#3574FA">
          <g id="Group" transform="translate(154.000000, 25.000000)">
            <path
              d="M21.8934794,0.823772235 C21.4286018,0.35460709 20.5825997,0 19.9252778,0 L9.17804019,0 L4.96355716,0 C2.22698986,0 0,2.24933076 0,5.01441045 L0,32.9846389 C0,35.7497185 2.22651934,38 4.96355716,38 L27.8851314,38 C28.2991925,38 28.6346761,37.6610793 28.6346761,37.242776 C28.6346761,36.8249481 28.2991925,36.4860274 27.8851314,36.4860274 L4.96355716,36.4860274 C3.0527594,36.4860274 1.49814826,34.9150134 1.49814826,32.9846389 L1.49814826,5.01441045 C1.49814826,3.08403593 3.05322992,1.51349729 4.96355716,1.51349729 L9.17804019,1.51349729 L19.9252778,1.51349729 C19.9530387,1.51349729 19.988328,1.52347952 20.0193826,1.52680694 L20.0193826,8.20777564 C20.0193826,9.79970479 21.3006193,11.0935929 22.8749924,11.0935929 L29.4886771,11.0935929 C29.4911865,11.1141911 29.4955781,18.4527199 29.5018517,33.1091792 C29.5018517,33.5265317 29.8368648,33.8659278 30.2509259,33.8659278 C30.6649869,33.8659278 31,33.5265317 31,33.1091792 L31,11.1881864 C31,10.52413 30.6494597,9.66946036 30.184582,9.19981987 L21.8934794,0.823772235 Z M21.5170603,8.20730029 L21.5170603,2.58397338 L28.4427023,9.58009557 L22.8749924,9.58009557 C22.1259183,9.58009557 21.5170603,8.96404894 21.5170603,8.20730029 Z"
              id="Shape-Copy"
            />
            <path
              d="M20.7886117,19.2259131 C20.5063069,18.9322497 20.0488095,18.9322497 19.7669585,19.2259131 L15.7225553,23.4330422 L15.7225553,12.7520993 C15.7225553,12.336627 15.3994024,12 15,12 C14.6005976,12 14.2774447,12.336627 14.2774447,12.7520993 L14.2774447,23.4330422 L10.2339492,19.2259131 C9.95164432,18.9322497 9.49414701,18.9322497 9.21138826,19.2259131 C8.92953725,19.5195764 8.92953725,19.9954811 9.21138826,20.2891444 L14.4889465,25.7790443 C14.6246525,25.9206826 14.8084684,26 15,26 C15.1915316,26 15.3753475,25.9206826 15.5110535,25.7790443 L20.7886117,20.2891444 C21.0704628,19.9954811 21.0704628,19.5195764 20.7886117,19.2259131 Z"
              id="Shape-Copy-5"
              transform="translate(15.000000, 19.000000) rotate(-180.000000) translate(-15.000000, -19.000000) "
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Upload
