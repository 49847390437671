import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'

const StyledLink = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;
  cursor: pointer;
`

interface TextLinkProps {
  children: React.ReactNode
  onClick: MouseEventHandler<HTMLSpanElement>
}

export const TextLink = ({
  children,
  onClick,
}: TextLinkProps): React.ReactElement => (
  <StyledLink onClick={onClick}>{children}</StyledLink>
)
