import {
  ORG_DASHBOARD_APP_BOOSTERS,
  ORG_DASHBOARD_APP_SURVEY,
  ORG_DASHBOARD_APP_CONTACTS,
  ORG_DASHBOARD_APP_REFLECTIONS,
  ORG_DASHBOARD_APP_ORGANISATION,
  ORG_DASHBOARD_APP_SUPPORT,
} from 'constants/routes'

import NavItem from 'components/molecules/NavItem'
import Sidebar from 'components/molecules/Sidebar'
import PropTypes from 'prop-types'
import React from 'react'
import { replaceRouteParams } from 'utils/routes'

import { SideNavigationContainer } from '../SideNavigation/SideNavigationContainer'
import { SideNavigationContent } from '../SideNavigation/SideNavigationContent'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function AppLayout({ children, orgUuid }) {
  return (
    <SideNavigationContainer>
      <Sidebar>
        <NavItem
          direction="vertical"
          to={replaceRouteParams(ORG_DASHBOARD_APP_ORGANISATION, {
            orgUuid,
          })}
          text="Organisation"
        />
        <NavItem
          direction="vertical"
          to={replaceRouteParams(ORG_DASHBOARD_APP_CONTACTS, {
            orgUuid,
          })}
          text="Red Flag Contacts"
        />
        <NavItem
          direction="vertical"
          to={replaceRouteParams(ORG_DASHBOARD_APP_SURVEY, { orgUuid })}
          text="Survey"
        />
        <NavItem
          direction="vertical"
          to={replaceRouteParams(ORG_DASHBOARD_APP_SUPPORT, {
            orgUuid,
          })}
          text="Support Options"
        />
        <NavItem
          direction="vertical"
          to={replaceRouteParams(ORG_DASHBOARD_APP_REFLECTIONS, {
            orgUuid,
          })}
          text="Reflections"
        />
        <NavItem
          direction="vertical"
          to={replaceRouteParams(ORG_DASHBOARD_APP_BOOSTERS, { orgUuid })}
          text="Wellbeing Resources"
        />
      </Sidebar>
      <SideNavigationContent>{children}</SideNavigationContent>
    </SideNavigationContainer>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node,
  orgUuid: PropTypes.string.isRequired,
}

export default AppLayout
