import { TOAST_TYPE_ERROR } from 'constants/ui'

import * as authActions from 'modules/authentication/actions'
import { hasEditRights } from 'modules/authentication/selectors'
import * as keyContactsActions from 'modules/keyContacts/actions'
import { getKeyContactsList } from 'modules/keyContacts/selectors'
import * as organisationsActions from 'modules/organisations/actions'
import * as toastActions from 'modules/toasts/actions'
import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers, withState } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  connect(
    // mapStateToProps
    (state, { match }) => ({
      organisation: state.organisations.entities[match.params.orgUuid],
      keyContacts: getKeyContactsList(state),
      hasEditAccess: state.user.canEdit || hasEditRights(state),
    }),
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...toastActions,
        ...organisationsActions,
        ...keyContactsActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  withState('uploadProgress', 'setUploadProgress', 0),
  withState('uploadTotal', 'setUploadTotal', 0),
  withHandlers({
    onImageUploadError:
      ({ actions }) =>
      () => {
        actions.addToast({
          message: 'Sorry, we only support PNG/JPEG for organisation images',
          type: TOAST_TYPE_ERROR,
        })
      },
    onUpdateOrganisationDetails:
      ({ actions, match, setUploadProgress, setUploadTotal, organisation }) =>
      ({ logo, organisationName, incidentsContactUuid }) => {
        if (typeof logo !== 'string' && logo !== null) {
          actions.uploadOrganisationLogo({
            uuid: match.params.orgUuid,
            file: logo,
            onUploadProgress: (progressEvent) => {
              setUploadProgress(progressEvent.loaded)
              setUploadTotal(progressEvent.total)
            },
          })
        }

        if (
          organisationName !== organisation.name ||
          (organisation.incidentsContact
            ? organisation.incidentsContact.uuid !== incidentsContactUuid
            : incidentsContactUuid)
        ) {
          actions.updateOrganisation({
            uuid: match.params.orgUuid,
            name: organisationName,
            incidentsContactUuid,
          })
        }
      },
  }),
  lifecycle({
    componentDidMount() {
      const { actions, match } = this.props

      actions.getKeyContacts(match.params.orgUuid)
    },
  }),
)
