import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledActions = styled.div`
  display: flex;
  justify-content: center;
`

export const ActionButton = styled(Button)`
  width: 50%;

  && {
    margin: 0 ${({ theme }) => theme.spacing.sm}px;
  }
`

export const StyledTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.tertiary.dark};
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function DeleteGroupForm({ className, onSubmit, onCancel, group }) {
  return (
    <form
      onSubmit={onSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      <StyledTitle variant="h4">
        Are you sure you would like to delete{' '}
        {group?.name ? group.name : 'this group'}?
      </StyledTitle>
      <StyledText variant="body1">
        This group will be permanently removed. Any past data will still remain
        in the organisation&apos;s reports.
        <br />
        <br />
        Users associated with this group will also be deleted. They won&apos;t
        be able to access the chnnl app.
        <br />
        <br />
        If you wish to retain users within your organisation then please move
        them to a different group before deletion.
      </StyledText>
      <StyledActions>
        <ActionButton
          variant="outlined"
          type="button"
          size="small"
          color="primary"
          onClick={onCancel}>
          No, cancel
        </ActionButton>
        <ActionButton
          variant="contained"
          type="submit"
          size="small"
          color="primary">
          Yes, delete
        </ActionButton>
      </StyledActions>
    </form>
  )
}

DeleteGroupForm.propTypes = {
  className: PropTypes.string,
  group: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
}

DeleteGroupForm.defaultProps = {
  group: {},
}

export default DeleteGroupForm
