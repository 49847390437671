import { FeatureKeys } from 'constants/features'
import { TOGGLE_ALL, SORT_DIRECTION_ASC } from 'constants/form'

import { useFeature } from '@optimizely/react-sdk'
import Button from '@weareroam/cake-ui-v1/Button'
import Checkbox from '@weareroam/cake-ui-v1/Checkbox'
import ListItemText from '@weareroam/cake-ui-v1/ListItemText'
import Typography from '@weareroam/cake-ui-v1/Typography'
import ExportIcon from '@weareroam/cake-ui-v1-icons/ImportExport'
import ContentContainer from 'components/atoms/ContentContainer'
import ProgressLoader from 'components/atoms/ProgressLoader'
import SearchField from 'components/atoms/SearchField'
import DropdownButton from 'components/molecules/DropdownButton'
import JournalTask from 'components/molecules/JournalTask'
import MenuItem from 'components/molecules/MenuItem'
import Pagination from 'components/molecules/Pagination'
import SortHeading from 'components/molecules/SortHeading'
import useEntitiesFilter from 'hooks/useEntitiesFilter'
import useJournals from 'hooks/useJournals'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledHeader = styled.header`
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`
export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};
`
export const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`
export const ExportButton = styled(Button)`
  && {
    margin-right: ${({ theme }) => theme.spacing.md}px;
  }
`
export const Right = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

const AllGroupsLabel = styled(ListItemText)`
  && {
    font-weight: ${({ theme }) => theme.weights.bold};
  }
`

export const JournalsHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  padding-bottom: ${({ theme }) => theme.spacing.md}px;
`

export const JournalHeaderItem = styled.div`
  margin: 0 ${({ theme }) => theme.spacing.md}px;
  &:nth-child(1) {
    width: 50%;
  }
  &:nth-child(2) {
    flex: 1;
  }
  &:nth-child(3) {
    width: 37.5%;
  }
  &:nth-child(4) {
    width: 2.5%;
  }
  & > div {
    justify-content: ${({ align }) => align || 'center'};
  }
`

export const JournalsList = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Journals({
  groups,
  onExport,
  journals,
  organisation,
  getJournalsProgress,
  getGroupsProgress,
  actions,
  repliesByJournal,
}) {
  const {
    filteredEntities: filteredGroups,
    handleChangeFilteredEntities,
    renderFilterLabel,
  } = useEntitiesFilter(groups, 'group', !getGroupsProgress.inProgress)

  const [showGroups] = useFeature(FeatureKeys.ShowJournalGroups)

  const {
    searchFilter,
    handleChangeSearchFilter,
    pagination,
    handleChangePagination,
  } = useJournals({
    organisation,
    filters:
      filteredGroups.length === groups.length
        ? undefined
        : { key: 'groups', value: filteredGroups },
    sortOptions: { createdAt: 'DESC' },
    onFetchJournals: actions.getOrgJournals,
    fetchJournalNotification: actions.getJournalReply,
    repliesByJournal,
  })
  return (
    <AdminDashboardLayout>
      <ContentContainer>
        <StyledHeader>
          <Typography variant="h2">Journals</Typography>
          <Description variant="body1">
            Entries have been willingly shared by your members and <br /> may
            have been edited for increased anonymity.
          </Description>
        </StyledHeader>
        <Filters>
          {showGroups && (
            <DropdownButton
              value={filteredGroups}
              onChange={handleChangeFilteredEntities}
              renderValue={renderFilterLabel}>
              <MenuItem value={TOGGLE_ALL}>
                <Checkbox
                  color="primary"
                  checked={filteredGroups.length === groups.length}
                />
                <AllGroupsLabel primary="All groups" />
              </MenuItem>
              {groups.map((group) => (
                <MenuItem key={group.uuid} value={group.uuid}>
                  <Checkbox
                    color="primary"
                    checked={filteredGroups.includes(group.uuid)}
                  />
                  <ListItemText primary={group.name} />
                </MenuItem>
              ))}
            </DropdownButton>
          )}
          <Right>
            <ExportButton variant="text" color="primary" onClick={onExport}>
              <ExportIcon /> Export
            </ExportButton>
            <SearchField
              placeholder="Search"
              value={searchFilter}
              onChange={handleChangeSearchFilter}
            />
          </Right>
        </Filters>

        <JournalsHeader>
          <JournalHeaderItem>
            <SortHeading>Journal entry</SortHeading>
          </JournalHeaderItem>
          <JournalHeaderItem>
            <SortHeading>Notification</SortHeading>
          </JournalHeaderItem>
          <JournalHeaderItem>
            <SortHeading>
              <strong>Org</strong>
              {showGroups ? ' / Group' : ''}
            </SortHeading>
          </JournalHeaderItem>
          <JournalHeaderItem align="flex-end">
            <SortHeading sortDirection={SORT_DIRECTION_ASC}>
              <strong>Date</strong>
            </SortHeading>
          </JournalHeaderItem>
        </JournalsHeader>

        {getJournalsProgress.inProgress &&
        (!journals || journals.length === 0) ? (
          <ProgressLoader fullWidth />
        ) : (
          <React.Fragment>
            <JournalsList>
              {journals.map((journal) => (
                <JournalTask
                  key={journal.uuid}
                  readOnly
                  shared={true}
                  entry={journal.entry}
                  redFlagged={journal.redFlagged}
                  yellowFlagged={journal.yellowFlagged}
                  organisationName={journal.organisation.name}
                  groupName={showGroups ? journal.group.name : ''}
                  date={journal.forDate}
                  value={journal.uuid}
                  isJournalModerationView={false}
                  journalReplies={repliesByJournal(journal.originalUuid)}
                />
              ))}
            </JournalsList>
            <StyledPagination
              pagination={pagination}
              onPageChange={handleChangePagination}
            />
          </React.Fragment>
        )}
      </ContentContainer>
    </AdminDashboardLayout>
  )
}

Journals.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object),
  journals: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.object,
  getJournalsProgress: PropTypes.object,
  getGroupsProgress: PropTypes.object,
  organisation: PropTypes.string,
  onExport: PropTypes.func,
  repliesByJournal: PropTypes.func,
}

Journals.defaultProps = {
  groups: [],
  journals: [],
  actions: {},
  getJournalsProgress: {},
  getGroupsProgress: {},
}

export default Journals
