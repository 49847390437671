import { Input, Button } from '@weareroam/cake-ui-v1'
import Typography from '@weareroam/cake-ui-v1/Typography'
import Select from 'components/molecules/Select'
import SelectAndTextInput from 'components/molecules/SelectAndTextInput'
import { Form } from 'formik'
import styled from 'styled-components'

export const StyledDefaultLayout = styled.div`
  && {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    background-color: rgb(170, 213, 183);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
    overflow-y: hidden;
    z-index: 100;
    @supports (display: flex) {
      @media only screen and (max-width: 1024px) {
        align-items: center;
      }
    }
  }
`

export const CustomLayout = styled.div`
  && {
    display: flex;
    height: 100%;
    justify-content: center;
    align-content: center;
    padding: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledHeader = styled.div`
  && {
    clear: both;
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
    @media (max-height: 600px) {
      margin-bottom: ${({ theme }) => theme.spacing.md}px;
    }
  }
`

export const StyledTitle1 = styled(Typography)`
  && {
    width: auto;
    display: inline;
    font-size: 60px;
    color: #5c8267;

    @media (max-width: 475px) {
      font-size: 50px;
    }
    @media (max-height: 600px) {
      font-size: 35px;
    }
  }
`

export const StyledText = styled(Typography)`
  && {
    color: #5c8267;
    max-width: 475px;
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
    margin-top: ${({ theme }) => theme.spacing.md}px;
    @media (max-height: 600px) {
      margin-bottom: ${({ theme }) => theme.spacing.sm}px;
      margin-top: ${({ theme }) => theme.spacing.sm}px;
    }
  }
`

export const StyledInfo = styled.div`
  display: flex;
  max-width: 450px;
  flex-direction: column;
  justify-content: center;
`

export const StyledImage = styled.img`
  && {
    margin-bottom: -10em;
    @media (min-height: 1080px) {
      width: 675px;
      margin-top: 15em;
      margin-bottom: -15em;
    }
  }
`

export const StyledChnnlLogo = styled.img`
  && {
    height: 50px;
    display: inline;
    font-size: 60px;
    color: white;
    @media (max-width: 475px) {
      height: 40px;
    }
    @media (max-height: 600px) {
      height: 30px;
    }
  }
`

export const StyledSignup = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.md}px;
  align-content: center;
  justify-content: center;
`

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`

export const StyledInput = styled(Input)`
  display: flex;
  background-color: white;
  color: #e4e4ea;
  border-top-right-radius: ${({ theme }) => theme.spacing.sm}px;
  border-bottom-right-radius: ${({ theme }) => theme.spacing.sm}px;
  padding: ${({ theme }) => theme.spacing.sm}px;
`

export const StyledSelectAndTextInput = styled(SelectAndTextInput)``

export const StyledSelect = styled(Select)`
  && {
    text-align: center;
    color: #8d92a3;
    margin-top: 0;
    max-width: 100px;
    background-color: white;
    border-top-left-radius: ${({ theme }) => theme.spacing.sm}px;
    border-bottom-left-radius: ${({ theme }) => theme.spacing.sm}px;
    padding: ${({ theme }) => theme.spacing.sm}px;
  }
  &&,
  svg {
    fill: #8d92a3;
  }
`

export const MobileOnly = `
@media only screen 
and (max-width: 1024px) 
{
display: flex;
}
`

export const DesktopOnly = `
@media (max-width: 1024px) {
display: none;
}
`

export const StyledSMSButton = styled(Button)`
  && {
    display: flex;
    margin-top: ${({ theme }) => theme.spacing.xs}px;
    width: 125px;
    align-self: flex-end;
  }
`

export const DeskTopOnlyStyledImage = styled(StyledImage)`
  ${DesktopOnly}
`

export const StyledImageContainer = styled.div`
  && {
    display: flex;
    flex-grow: 2;
    ${DesktopOnly}
  }
`

export const StyledLoaderContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.lg}px;
  padding: ${({ theme }) => theme.spacing.lg}px;
`
