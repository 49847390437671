import React, { useState, useMemo, useCallback } from 'react'

import { Admin } from '../modules/admins/schema'

export function useAdminsSearch(admins: Admin[]): {
  searchFilter: string
  handleChangeSearchFilter: React.FormEventHandler
  filteredAdmins: Admin[]
} {
  const [searchFilter, setSearchFilter] = useState('')

  const handleChangeSearchFilter = useCallback((e) => {
    setSearchFilter(e.target.value)
  }, [])

  const filteredAdmins = useMemo(
    () =>
      admins.filter((admin) => {
        const lcSearch = searchFilter.toLowerCase()
        const lcName = admin.fullName ? admin.fullName.toLowerCase() : ''
        const lcEmail = admin.email.toLowerCase()

        return lcName.startsWith(lcSearch) || lcEmail.startsWith(lcSearch)
      }),
    [admins, searchFilter],
  )

  return {
    searchFilter,
    handleChangeSearchFilter,
    filteredAdmins,
  }
}
