/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReportErrorType } from 'constants/reports'

import Typography from '@weareroam/cake-ui-v1/Typography'
import ProgressLoader from 'components/atoms/ProgressLoader'
import { ChartExplainerHeader } from 'components/molecules/ChartExplainerHeader'
import EmptyState from 'components/molecules/EmptyState'
import {
  ReflectionResponse,
  ReflectionResponses,
} from 'hooks/useReflectionCountReport'
import React from 'react'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  ReferenceLine,
  LabelList,
  ResponsiveContainer,
  Label,
  LabelProps,
} from 'recharts'
import styled from 'styled-components'
import theme from 'styles/theme'
import { partition } from 'utils/arrayUtils'
import { truncateWithElipsis } from 'utils/formatting'
import { isEven } from 'utils/validation'

const StyledArea = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  overflow: hidden;
  margin: auto;
  padding-right: 32px;
`

const StyledContainer = styled(ResponsiveContainer)`
  svg {
    overflow: visible;
  }
`

const Rect = styled.rect<{ isEven: boolean }>`
  height: 2.5em;
  fill: ${({ isEven }) =>
    isEven
      ? (theme.palette as any).chart.evenRow
      : (theme.palette as any).chart.oddRow};
`

export const TitleHeading = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  padding-bottom: ${({ theme }) => theme.spacing.md / 2}px;
  padding-left: ${({ theme }) => theme.spacing.md}px;
  padding-right: 15.5%;
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};
`

const renderCustomizedLabel = (props: LabelProps) => {
  const { value, width, height, y, x } = props

  if (Number(value) === 0) {
    return (
      <g>
        <text
          fontSize="14"
          fontWeight="400"
          x={Number(x) + Number(width) + 20}
          y={Number(y) + Number(height) / 2}
          fill={(theme.palette as any).text.main}
          textAnchor="middle"
          dominantBaseline="middle">
          {value}
        </text>
      </g>
    )
  }

  if (!value) {
    return null
  }

  return (
    <g>
      <text
        fontSize="14"
        fontWeight="400"
        x={Number(x) + Number(width) + 20}
        y={Number(y) + Number(height) / 2}
        fill={(theme.palette as any).text.main}
        textAnchor="middle"
        dominantBaseline="middle">
        {value}
      </text>
    </g>
  )
}

// https://github.com/recharts/recharts/issues/1817
// eslint-disable-next-line
// @ts-ignore
const renderCustomizedTick = ({ payload, y, index }) => (
  <g>
    <Rect isEven={isEven(index)} x="-68.5%" y={y - 20} width="175%" />
    <text
      x="-62.5%"
      y={y}
      fontSize="14"
      fontWeight="400"
      fill={(theme.palette as any).text.main}
      textAnchor="start"
      dominantBaseline="middle">
      <title>{payload.value}</title>
      {truncateWithElipsis(payload.value, 52)}
    </text>
  </g>
)

const rowHeight = 40

export function ReflectionResponsesBarChart({
  data = { total: 0, reflections: [], max: 0 },
  loading,
  error,
}: {
  data?: ReflectionResponses
  loading: boolean
  error?: ReportErrorType
}): React.ReactElement {
  const [positiveReflections, negativeReflections] = React.useMemo(
    () =>
      partition<ReflectionResponse>(
        data.reflections,
        (x) => x.sentiment === 'positive',
      ),

    [data.reflections],
  )

  const positiveChartHeight = React.useMemo(
    () => rowHeight * positiveReflections.length + 40,
    [positiveReflections.length],
  )
  const negativeChartHeight = React.useMemo(
    () => rowHeight * negativeReflections.length + 40,
    [negativeReflections.length],
  )

  if (loading) {
    return <ProgressLoader fullWidth />
  }

  if (error === ReportErrorType.GENERIC) {
    return (
      <EmptyState
        title="Oh no! We’re not able to show this report right now."
        childComponent={
          <span>
            Something went wrong trying to show this report. We’ve been notified
            and will take a look but if the problem persists then let us know.
          </span>
        }
      />
    )
  }

  if (
    (data?.reflections?.length ?? 0) === 0 ||
    error === ReportErrorType.MIN_SAMPLE_REQUIRED
  ) {
    return (
      <EmptyState
        title="There’s not enough data to show this report for the requested reporting period"
        childComponent={
          <span>
            To protect user anonymity and to ensure a minimum sample size this
            report requires at least five responses.
          </span>
        }
      />
    )
  }

  return (
    <>
      <ChartExplainerHeader
        title="What is this chart telling me?"
        textSections={[
          'This chart shows user-reported reflections from most to least reported.',
          'Items with the most responses indicate a theme that may warrant further reinforcement (positive reflections) vs. investigation or follow up (negative reflections).',
        ]}
      />
      {positiveReflections.length > 0 && (
        <>
          <TitleHeading>
            <Title variant="body1">Positive</Title>
          </TitleHeading>
          <StyledArea>
            <StyledContainer width="60%" height={positiveChartHeight}>
              <ComposedChart
                layout="vertical"
                stackOffset="sign"
                data={positiveReflections}
                margin={{
                  right: 20,
                  left: 20,
                }}>
                <XAxis
                  axisLine={false}
                  type="number"
                  tick={false}
                  domain={[0, data.max]}>
                  <Label position="bottom" />
                </XAxis>
                <YAxis
                  dataKey="title"
                  type="category"
                  scale="band"
                  tick={renderCustomizedTick}
                  tickSize={10}
                  axisLine={false}
                />
                <ReferenceLine
                  x={0}
                  stroke={(theme.palette as any).chart.referenceLine}
                />
                <Bar
                  dataKey="count"
                  fill={(theme.palette as any).chart.neutral}
                  stackId="stack"
                  barSize={28}>
                  <LabelList dataKey="count" content={renderCustomizedLabel} />
                </Bar>
              </ComposedChart>
            </StyledContainer>
          </StyledArea>
        </>
      )}
      {negativeReflections.length > 0 && (
        <>
          <TitleHeading>
            <Title variant="body1">Negative</Title>
          </TitleHeading>
          <StyledArea>
            <StyledContainer width="60%" height={negativeChartHeight}>
              <ComposedChart
                layout="vertical"
                stackOffset="sign"
                data={negativeReflections}
                margin={{
                  right: 20,
                  left: 20,
                }}>
                <XAxis
                  axisLine={false}
                  type="number"
                  tick={false}
                  domain={[0, data.max]}>
                  <Label position="bottom" />
                </XAxis>
                <YAxis
                  dataKey="title"
                  type="category"
                  scale="band"
                  tick={renderCustomizedTick}
                  tickSize={10}
                  axisLine={false}
                />
                <ReferenceLine
                  x={0}
                  stroke={(theme.palette as any).chart.referenceLine}
                />
                <Bar
                  dataKey="count"
                  fill={(theme.palette as any).chart.neutral}
                  stackId="stack"
                  barSize={28}>
                  <LabelList dataKey="count" content={renderCustomizedLabel} />
                </Bar>
              </ComposedChart>
            </StyledContainer>
          </StyledArea>
        </>
      )}
    </>
  )
}
