import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import ProgressLoader from 'components/atoms/ProgressLoader'
import StatusTag from 'components/atoms/StatusTag'
import Autosuggest from 'components/molecules/Autosuggest'
import SelectAndTextInput from 'components/molecules/SelectAndTextInput'
import styled from 'styled-components'

export const StyledFields = styled.fieldset`
  margin: ${({ theme }) => theme.spacing.xl}px 0
    ${({ theme }) => theme.spacing.xxl}px;
`

export const StyledTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledProgressLoader = styled(ProgressLoader)`
  margin: 0;
  padding: 12px;
`

export const StyledDescription = styled(Typography)`
  && {
    width: 70%;
    margin: 0 auto;
    color: ${({ theme }) => theme.palette.tertiary.dark};
  }
`

export const StyledFooterText = styled(Typography)`
  && {
    margin-top: ${({ theme }) => theme.spacing.lg}px;
    color: ${({ theme }) => theme.palette.tertiary.main};
  }
`

export const StyledAutosuggest = styled(Autosuggest)`
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`

export const Actions = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledInputActions = styled(Actions)`
  margin: ${({ theme }) => theme.spacing.sm}px 0
    ${({ theme }) => theme.spacing.sm}px 0;
`

export const ActionButton = styled(Button)`
  width: 50%;

  && {
    margin: 0 ${({ theme }) => theme.spacing.sm}px;
  }
`

export const InnerContent = styled.div`
  width: 65%;
  margin: 0 auto;
  a {
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: none;
    cursor: pointer;
  }
`

export const StyledForm = styled.form`
  padding-bottom: ${({ theme }) => theme.spacing.lg}px;
`

export const StyledSeatsRemaining = styled(StatusTag)`
  margin: ${({ theme }) => theme.spacing.md}px 0;
  text-transform: none;
  text-align: center;
`

export const StyledSelectAndTextInput = styled(SelectAndTextInput)`
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
`
