import {
  ADMIN_PERMISSION_CAN_EDIT,
  ROLE_ORG_ADMIN,
  ROLE_GROUP_ADMIN,
  ROLE_ORG_OWNER,
  ADMIN_PERMISSION_VIEW_ONLY,
} from 'constants/form'

import { adminsActions } from 'modules/admins/actions'
import { Admin, AdminRoles } from 'modules/admins/schema'
import { getAdminsList } from 'modules/admins/selectors'
import { authenticationActions } from 'modules/authentication/actions'
import { isChnnlAdmin, hasEditRights } from 'modules/authentication/selectors'
import { groupsActions } from 'modules/groups/actions'
import { Group } from 'modules/groups/schema'
import { getGroupsList } from 'modules/groups/selectors'
import { organisationActions } from 'modules/organisations/actions'
import { RequestProgressState } from 'modules/progress'
import { RootState } from 'modules/RootState'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'
import { OrganisationRouteParams } from 'types/routes'

type AdminsActions = typeof authenticationActions &
  typeof adminsActions &
  typeof groupsActions &
  typeof organisationActions

interface AdminsHandlers {
  onInviteNewAdminSubmit: (props: {
    inEditMode: boolean
    email: string
    assignedToGroups: Array<string>
    role: AdminRoles
    orgAdminPermission:
      | typeof ADMIN_PERMISSION_VIEW_ONLY
      | typeof ADMIN_PERMISSION_CAN_EDIT
    groupAdminPermission:
      | typeof ADMIN_PERMISSION_VIEW_ONLY
      | typeof ADMIN_PERMISSION_CAN_EDIT
  }) => void

  handleResendInvitationConfirm: (
    e: React.FormEvent<HTMLFormElement>,
    props: {
      role: AdminRoles
      email: string
      uuid: string
    },
  ) => Promise<void>

  handleDeleteAdmin: (props: {
    role: AdminRoles
    uuid: string
  }) => Promise<void>
}

export interface AdminsComponentProps
  extends RouteComponentProps<OrganisationRouteParams> {
  actions: AdminsActions
  isInviteNewAdminModalOpen: boolean
  setIsInviteNewAdminModalOpen: (input: boolean) => void
  editUserId: string | null
  setEditUserId: (input: string | null) => void
  admins: Admin[]
  groups: Group[]
  adminEntities: Record<string, Admin>
  getAdminsProgress: RequestProgressState
  orgUuid: string
  canTransferOrgOwnership: boolean
  isOrgOwner: boolean
  resendInviteUserId: string
  setResendInviteUserId: (input: string) => void
  resendInviteModalOpen: boolean
  setResendInviteModalOpen: (input: boolean) => void
  isDeleteAdminModalOpen: boolean
  setIsDeleteAdminModalOpen: (input: boolean) => void
  hasEditAccess: boolean
}

export interface AdminsComponentPropsInner
  extends AdminsComponentProps,
    AdminsHandlers {}

export const AdminsEnhancer = compose<
  AdminsComponentPropsInner,
  AdminsComponentProps
>(
  connect<unknown, unknown, AdminsComponentProps, RootState>(
    // mapStateToProps
    (state, { match }) => ({
      admins: getAdminsList(state),
      adminEntities: state.admins.entities,
      groups: getGroupsList(state),
      getAdminsProgress: state.progress.getOrganisationAdmins,
      orgUuid: match.params.orgUuid,
      hasEditAccess: state.user.canEdit || hasEditRights(state),
      isOrgOwner: state.authentication.role === ROLE_ORG_OWNER,
      canTransferOrgOwnership:
        isChnnlAdmin(state) || state.authentication.role === ROLE_ORG_OWNER,
    }),
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authenticationActions,
        ...adminsActions,
        ...groupsActions,
        ...organisationActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  withState('editUserId', 'setEditUserId', null),
  withState('isInviteNewAdminModalOpen', 'setIsInviteNewAdminModalOpen', false),
  withState('resendInviteUserId', 'setResendInviteUserId', null),
  withState('resendInviteModalOpen', 'setResendInviteModalOpen', false),
  withState('isDeleteAdminModalOpen', 'setIsDeleteAdminModalOpen', false),
  lifecycle<AdminsComponentProps, RootState>({
    componentDidMount() {
      const { actions, match } = this.props
      actions.getOrgAdmins(match.params.orgUuid)
      actions.getGroups(match.params.orgUuid)
    },
  }),
  withHandlers<AdminsComponentProps, AdminsHandlers>({
    onInviteNewAdminSubmit:
      ({
        actions,
        match,
        setIsInviteNewAdminModalOpen,
        editUserId,
        adminEntities,
      }) =>
      ({
        inEditMode,
        email,
        role,
        assignedToGroups,
        orgAdminPermission,
        groupAdminPermission,
      }) => {
        if (inEditMode) {
          let canEdit

          switch (role) {
            case ROLE_ORG_OWNER:
              canEdit = true
              break
            case ROLE_GROUP_ADMIN:
              canEdit = groupAdminPermission === ADMIN_PERMISSION_CAN_EDIT
              break
            case ROLE_ORG_ADMIN:
              canEdit = orgAdminPermission === ADMIN_PERMISSION_CAN_EDIT
              break
            default:
              canEdit = false
              break
          }

          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const admin = adminEntities[editUserId!]

          if (role !== admin.role) {
            actions.changeAdminRole({
              orgUuid: match.params.orgUuid,
              canEdit,
              userUuid: admin.userUuid,
              assignedGroups:
                role === ROLE_GROUP_ADMIN ? assignedToGroups : undefined,
              role,
            })
          } else {
            actions.updateAdmin({
              role,
              orgUuid: match.params.orgUuid,
              canEdit,
              adminUuid: admin.uuid,
              assignedGroups: assignedToGroups,
            })
          }
        } else {
          switch (role) {
            case ROLE_ORG_ADMIN:
              actions.inviteOrgAdmin({
                email,
                orgUuid: match.params.orgUuid,
                canEdit: orgAdminPermission === ADMIN_PERMISSION_CAN_EDIT,
              })
              break
            case ROLE_GROUP_ADMIN:
              actions.inviteGroupAdminToGroups({
                email,
                canEdit: groupAdminPermission === ADMIN_PERMISSION_CAN_EDIT,
                orgUuid: match.params.orgUuid,
                groups: assignedToGroups,
              })
              break
            default:
              break
          }
        }

        setIsInviteNewAdminModalOpen(false)
      },
    handleResendInvitationConfirm:
      ({ actions, match, setResendInviteModalOpen }) =>
      async (e, { role, email, uuid }) => {
        e.preventDefault()

        switch (role) {
          case ROLE_ORG_ADMIN:
            await actions.resendAdminInvitation({
              uuid,
              isGroupAdmin: false,
              orgUuid: match.params.orgUuid,
            })
            break
          case ROLE_GROUP_ADMIN:
            await actions.resendAdminInvitation({
              uuid,
              isGroupAdmin: true,
              orgUuid: match.params.orgUuid,
            })
            break
          case ROLE_ORG_OWNER:
          default:
            await actions.resendOrganisationInvitation({
              email,
              uuid: match.params.orgUuid,
            })
            break
        }

        setResendInviteModalOpen(false)
      },
    handleDeleteAdmin:
      ({ match, actions, orgUuid, setIsDeleteAdminModalOpen }) =>
      async ({ role, uuid }) => {
        switch (role) {
          case ROLE_GROUP_ADMIN:
            await actions.deleteGroupAdmin({ uuid, orgUuid })
            break
          case ROLE_ORG_ADMIN:
            await actions.deleteOrgAdmin({ uuid, orgUuid })
            break
          default:
            break
        }
        actions.getOrgAdmins(match.params.orgUuid)
        actions.getGroups(match.params.orgUuid)
        setIsDeleteAdminModalOpen(false)
      },
  }),
)
