import { Box, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import { useFormik } from 'formik'
import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'

import {
  InboxMessageLinkUserInputs,
  InboxMessageLinkValidation,
} from '../../../modules/messaging/interfaces'
import { LinkItemLine } from '../../molecules/LinkItemLine/LinkItemLine'

const InputsLine = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.md}px;
`

const AddLinkButton = styled(Button)`
  padding: 0 !important;

  > span {
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

interface MessageLinksFormProps {
  links: InboxMessageLinkUserInputs[]
  editable: boolean
  onSave: (
    value: InboxMessageLinkUserInputs[],
    shouldValidate?: boolean | undefined,
  ) => unknown
  onUnsavedChanges: (hasUnsavedChanges: boolean) => void
}

export const MessageLinksForm = ({
  links,
  editable,
  onSave,
  onUnsavedChanges,
}: MessageLinksFormProps): React.ReactElement => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    dirty,
    touched,
    values,
  } = useFormik<InboxMessageLinkUserInputs>({
    initialValues: {
      title: '',
      url: '',
    },
    validationSchema: InboxMessageLinkValidation,
    onSubmit: (values, formikHelpers) => {
      onSave([...links, values], false)
      formikHelpers.resetForm()
    },
  })

  const deleteLink = useCallback(
    (linkToDelete: InboxMessageLinkUserInputs) => {
      onSave(links.filter((e) => e !== linkToDelete))
    },
    [links, onSave],
  )

  useEffect(() => {
    onUnsavedChanges(dirty)
  }, [dirty, onUnsavedChanges])

  return (
    <>
      <InputsLine>
        <TextField
          id="title"
          name="title"
          value={values.title}
          disabled={!editable}
          error={touched.title && !!errors.title}
          helperText={touched.title ? errors.title : ''}
          onBlur={handleBlur}
          onChange={handleChange}
          variant="outlined"
          size="small"
          fullWidth={true}
          placeholder="Custom link title"></TextField>
        <TextField
          id="url"
          name="url"
          value={values.url}
          disabled={!editable}
          error={touched.url && !!errors.url}
          helperText={touched.url ? errors.url : ''}
          onBlur={handleBlur}
          onChange={handleChange}
          variant="outlined"
          size="small"
          fullWidth={true}
          placeholder="Paste the link URL here"></TextField>
      </InputsLine>
      <Box sx={{ display: 'grid', gridAutoColumns: '1fr', m: 0 }}>
        <Box
          sx={{
            gridRow: '1',
            gridColumn: 'span 3',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
          {links.map((link, index) => (
            <LinkItemLine
              link={link}
              key={index}
              onDelete={() => deleteLink(link)}
            />
          ))}
        </Box>
        <Box
          sx={{
            gridRow: '1',
            gridColumn: 'span 1',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
          }}>
          <AddLinkButton
            variant="text"
            type="submit"
            disabled={!editable || !values.title || !isValid}
            onClick={() => handleSubmit()}>
            + Attach Link
          </AddLinkButton>
        </Box>
      </Box>
    </>
  )
}
