import React from 'react'

// eslint-disable-next-line react/display-name
export const ChevronUp = React.memo(() => (
  <svg
    width="17"
    height="11"
    viewBox="0 0 17 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.80996 5.37579C8.00522 5.18053 8.3218 5.18053 8.51706 5.37579L13.6695 10.5282C13.8648 10.7235 14.1814 10.7235 14.3766 10.5282L16.1807 8.72415C16.376 8.52889 16.376 8.21231 16.1807 8.01704L8.16367 7.72674e-06C8.16364 -2.08835e-05 8.16359 -2.08835e-05 8.16356 7.72674e-06C8.16353 3.6337e-05 8.16349 3.6337e-05 8.16346 7.72675e-06C8.16343 -2.08835e-05 8.16339 -2.08835e-05 8.16336 7.72675e-06L0.14632 8.01704C-0.0489426 8.21231 -0.0489426 8.52889 0.14632 8.72415L1.95041 10.5282C2.14567 10.7235 2.46225 10.7235 2.65751 10.5282L7.80996 5.37579Z"
      fill="#979797"
    />
  </svg>
))
