import { deepEqual } from 'fast-equals'
import * as authActions from 'modules/authentication/actions'
import { hasEditRights } from 'modules/authentication/selectors'
import * as keyContactsActions from 'modules/keyContacts/actions'
import { getKeyContactsList } from 'modules/keyContacts/selectors'
import * as organisationsActions from 'modules/organisations/actions'
import { connect } from 'react-redux'
import {
  compose,
  withState,
  lifecycle,
  withHandlers,
  withStateHandlers,
} from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  connect(
    (state, { match }) => ({
      organisation: state.organisations.entities[match.params.orgUuid],
      keyContacts: getKeyContactsList(state),
      keyContactIds: state.keyContacts.orderedIds,
      keyContactsProgress: state.progress.getKeyContacts,
      hasEditAccess: state.user.canEdit || hasEditRights(state),
    }),
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...organisationsActions,
        ...keyContactsActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  withStateHandlers(
    ({ keyContacts: keyContactList }) => ({
      keyContactList,
    }),
    {
      setKeyContactList: () => (keyContactList) => ({
        keyContactList,
      }),
    },
  ),
  withState('isAddKeyContactModalOpen', 'setIsAddKeyContactModalOpen', false),
  withState('isConfirmUpdateModalOpen', 'setIsConfirmUpdateModalOpen', false),
  withState('isAddKeyContactModalOpen', 'setIsAddKeyContactModalOpen', false),
  withState('editKeyContactIndex', 'setEditKeyContactIndex', null),
  withState('deleteConfirmId', 'setDeleteConfirmId', null),
  withHandlers({
    onCloseAddKeyContactModal:
      ({ setEditKeyContactIndex, setIsAddKeyContactModalOpen }) =>
      () => {
        setEditKeyContactIndex(null)
        setIsAddKeyContactModalOpen(false)
      },
    updateKeyContacts:
      ({ actions, match, keyContactIds }) =>
      (keyContacts, hideToast) => {
        actions.updateKeyContacts({
          orgUuid: match.params.orgUuid,
          contacts: keyContacts.map((contact) => {
            const newContact = {
              ...contact,
            }

            Object.keys(newContact).forEach((key) => {
              // remove empty strings from the body
              if (newContact[key] === '') {
                delete newContact[key]
              }
            })

            if (!keyContactIds.includes(contact.uuid)) {
              // Remove uuid from newly created contacts before passing to the backend
              delete newContact.uuid

              return newContact
            }

            return newContact
          }),
          hideToast,
        })
      },
  }),
  withHandlers({
    onAddKeyContactSubmit:
      ({
        keyContactList,
        editKeyContactIndex,
        onCloseAddKeyContactModal,
        updateKeyContacts,
        setKeyContactList,
      }) =>
      (form) => {
        let contacts = keyContactList

        if (editKeyContactIndex !== null) {
          // if editing, replace the index
          contacts = [
            ...keyContactList.slice(0, editKeyContactIndex),
            { ...form },
            ...keyContactList.slice(editKeyContactIndex + 1),
          ]
        } else {
          // if not editing, append to the list
          keyContactList.push(form)
        }

        updateKeyContacts(contacts)
        setKeyContactList(contacts)

        // Remove edit index and close the modal
        onCloseAddKeyContactModal()
      },
    onDragEnd:
      ({ keyContacts, setKeyContactList, updateKeyContacts }) =>
      (result) => {
        // dropped outside the list
        if (!result.destination) {
          return
        }

        // Return the reordered list
        const reorderedList = Array.from(keyContacts)
        const [removed] = reorderedList.splice(result.source.index, 1)
        reorderedList.splice(result.destination.index, 0, removed)

        setKeyContactList(reorderedList)
        updateKeyContacts(reorderedList, true)
      },
    onDeleteContactConfirm:
      ({
        keyContactList,
        deleteConfirmId,
        setKeyContactList,
        updateKeyContacts,
        setDeleteConfirmId,
      }) =>
      (e) => {
        e.preventDefault()

        const contacts = keyContactList.filter(
          (c) => c.uuid !== deleteConfirmId,
        )
        setKeyContactList(contacts)
        updateKeyContacts(contacts)
        setDeleteConfirmId(null)
      },
  }),
  lifecycle({
    componentDidMount() {
      const { actions, match } = this.props

      actions.getKeyContacts(match.params.orgUuid)
    },
    componentDidUpdate({ keyContacts: prevKeyContacts }) {
      if (!deepEqual(this.props.keyContacts, prevKeyContacts)) {
        this.props.setKeyContactList(this.props.keyContacts)
      }
    },
  }),
)
