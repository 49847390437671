import {
  ROLE_ORG_ADMIN,
  ROLE_GROUP_ADMIN,
  ROLE_ORG_OWNER,
  ADMIN_PERMISSION_VIEW_ONLY,
  ADMIN_PERMISSION_CAN_EDIT,
} from 'constants/form'

import Button from '@weareroam/cake-ui-v1/Button'
import Checkbox from '@weareroam/cake-ui-v1/Checkbox'
import FormControlLabel from '@weareroam/cake-ui-v1/FormControlLabel'
import TextField from '@weareroam/cake-ui-v1/TextField'
import Typography from '@weareroam/cake-ui-v1/Typography'
import ButtonSwitch from 'components/atoms/ButtonSwitch'
import Radio from 'components/atoms/Radio'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledFields = styled.fieldset`
  margin: ${({ theme }) => theme.spacing.lg}px 0;
  text-align: left;
`
export const StyledEmail = styled(TextField)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.xl}px;
  }
`

export const StyledRadio = styled(Radio)`
  && {
    height: 35px;
  }
`

export const StyledRadioOption = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

export const ButtonSwitchGroup = styled.div`
  display: inline-flex;
  width: 140px;
`

export const StyledRadioControl = styled(FormControlLabel)`
  flex-grow: 1;
`

const checkboxLabelClass = 'checkbox-label-class'
export const StyledCheckboxControl = styled(FormControlLabel).attrs({
  classes: {
    label: checkboxLabelClass,
  },
})`
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  width: 100%;
  font-weight: inherit;

  .${checkboxLabelClass} {
    font-weight: inherit;
  }
`

export const RadioDescription = styled(Typography)`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing.sm}px;
  border-top: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  color: ${({ theme }) => theme.palette.tertiary.main};

  && {
    margin-top: ${({ theme }) => theme.spacing.sm}px;
    margin-left: ${({ theme }) => theme.spacing.mdlg}px;
  }
`

export const AllGroups = styled.div`
  font-weight: ${({ theme }) => theme.weights.bold};
`

export const StyledGroups = styled.div`
  max-height: 225px;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  overflow-y: auto;
  overflow-x: hidden;
`

export const Actions = styled.footer`
  display: flex;
  justify-content: center;
`

export const ActionButton = styled(Button)`
  width: 140px;
  && {
    margin: 0 ${({ theme }) => theme.spacing.sm}px;
  }
`

export const BackButton = styled(Button)`
  && {
    position: absolute;
    top: ${({ theme }) => theme.spacing.md}px;
    left: ${({ theme }) => theme.spacing.sm}px;
  }
`

export const OrgOwnerWarning = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.error.light};
  color: ${({ theme }) => theme.palette.error.main};
  padding: ${({ theme }) => theme.spacing.md}px;
  text-align: center;
`

export const StyledForm = styled.form`
  padding-bottom: ${({ theme }) => theme.spacing.lg}px;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function InviteNewAdminForm({
  handleSubmit,
  className,
  values,
  errors,
  handleBlur,
  handleChange,
  orgAdminPermission,
  groupAdminPermission,
  setOrgAdminPermission,
  setGroupAdminPermission,
  isAssigningToGroup,
  groups,
  toggleAllAssignedToGroupCheckbox,
  toggleAssignedToGroupCheckbox,
  setIsAssigningToGroup,
  inEditMode,
  title,
  touched,
  onCancel,
  canTransferOrgOwnership,
  isOrgOwner,
  isEditingOrgOwner,
  userName,
}) {
  return (
    <StyledForm
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      {!isAssigningToGroup ? (
        <div>
          <Typography variant="h2">{title}</Typography>
          <StyledFields>
            {/* Only show org owner option if editing a user and the current user is a chnnl admin or org owner. Else show an email address field for invitation */}
            {canTransferOrgOwnership && inEditMode ? (
              <StyledRadioOption>
                <StyledRadioControl
                  name="role"
                  checked={values.role === ROLE_ORG_OWNER}
                  onChange={handleChange}
                  value={ROLE_ORG_OWNER}
                  control={<StyledRadio />}
                  label="Organisation Owner"
                />
                <RadioDescription variant="body2">
                  Can remove admins. Only one person per organisation can be the
                  owner.
                </RadioDescription>
              </StyledRadioOption>
            ) : (
              <StyledEmail
                id="email"
                name="email"
                label="Email address"
                type="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.email && !!errors.email}
                helperText={touched.email ? errors.email : ''}
                fullWidth
              />
            )}

            <StyledRadioOption>
              <StyledRadioControl
                name="role"
                checked={values.role === ROLE_ORG_ADMIN}
                onChange={handleChange}
                value={ROLE_ORG_ADMIN}
                control={<StyledRadio />}
                label="Organisation Admin"
              />
              {/* In edit mode, hide extra options until the admin type is selected */}
              {(!inEditMode || values.role === ROLE_ORG_ADMIN) && (
                <ButtonSwitchGroup>
                  <ButtonSwitch
                    size="small"
                    isActive={orgAdminPermission === ADMIN_PERMISSION_VIEW_ONLY}
                    onClick={() =>
                      setOrgAdminPermission(ADMIN_PERMISSION_VIEW_ONLY)
                    }>
                    can view
                  </ButtonSwitch>
                  <ButtonSwitch
                    size="small"
                    isActive={orgAdminPermission === ADMIN_PERMISSION_CAN_EDIT}
                    onClick={() =>
                      setOrgAdminPermission(ADMIN_PERMISSION_CAN_EDIT)
                    }>
                    can edit
                  </ButtonSwitch>
                </ButtonSwitchGroup>
              )}
              <RadioDescription variant="body2">
                {orgAdminPermission === ADMIN_PERMISSION_VIEW_ONLY
                  ? 'Can view-only all data in this organisation'
                  : 'Can view and edit all data in this organisation'}
              </RadioDescription>
            </StyledRadioOption>

            {groups.length ? (
              <StyledRadioOption>
                <StyledRadioControl
                  name="role"
                  checked={values.role === ROLE_GROUP_ADMIN}
                  onChange={handleChange}
                  value={ROLE_GROUP_ADMIN}
                  control={<StyledRadio />}
                  label="Group Admin"
                />
                {/* In edit mode, hide extra options until the admin type is selected */}
                {(!inEditMode || values.role === ROLE_GROUP_ADMIN) && (
                  <ButtonSwitchGroup>
                    <ButtonSwitch
                      size="small"
                      isActive={
                        groupAdminPermission === ADMIN_PERMISSION_VIEW_ONLY
                      }
                      onClick={() =>
                        setGroupAdminPermission(ADMIN_PERMISSION_VIEW_ONLY)
                      }>
                      can view
                    </ButtonSwitch>
                    <ButtonSwitch
                      size="small"
                      isActive={
                        groupAdminPermission === ADMIN_PERMISSION_CAN_EDIT
                      }
                      onClick={() =>
                        setGroupAdminPermission(ADMIN_PERMISSION_CAN_EDIT)
                      }>
                      can edit
                    </ButtonSwitch>
                  </ButtonSwitchGroup>
                )}
                <RadioDescription variant="body2">
                  {groupAdminPermission === ADMIN_PERMISSION_VIEW_ONLY
                    ? `Can view-only information for their assigned group. Cannot see
                journals.`
                    : `Can view and edit all data for their assigned group. Cannot see journals.`}
                </RadioDescription>
              </StyledRadioOption>
            ) : null}
          </StyledFields>
        </div>
      ) : (
        <div>
          <BackButton
            variant="text"
            color="primary"
            size="small"
            onClick={() => setIsAssigningToGroup(false)}>
            Back
          </BackButton>
          <Typography variant="h2">Assign to group/s</Typography>
          <StyledFields>
            <AllGroups>
              <StyledCheckboxControl
                label="All groups"
                onChange={toggleAllAssignedToGroupCheckbox}
                control={
                  <Checkbox
                    color="primary"
                    checked={values.assignedToGroups.length === groups.length}
                  />
                }
              />
            </AllGroups>

            <StyledGroups>
              {groups.map((group) => (
                <StyledCheckboxControl
                  key={group.value}
                  label={group.label}
                  name="assignedToGroups[]"
                  onChange={() => toggleAssignedToGroupCheckbox(group.value)}
                  value={group.value}
                  control={
                    <Checkbox
                      color="primary"
                      checked={values.assignedToGroups.includes(group.value)}
                    />
                  }
                />
              ))}
            </StyledGroups>
          </StyledFields>
        </div>
      )}

      {/* Group admins are a two step process. They need to be assigned to a group */}
      <Actions>
        <ActionButton
          variant="outlined"
          size="small"
          type="button"
          color="primary"
          onClick={onCancel}>
          Cancel
        </ActionButton>
        <ActionButton
          size="small"
          variant="contained"
          type="submit"
          color="primary">
          {values.role !== ROLE_GROUP_ADMIN || isAssigningToGroup
            ? inEditMode
              ? 'Update'
              : 'Send invite'
            : 'Next'}
        </ActionButton>
      </Actions>

      {inEditMode &&
        !isEditingOrgOwner &&
        canTransferOrgOwnership &&
        values.role === ROLE_ORG_OWNER && (
          <OrgOwnerWarning>
            <Typography variant="body2">
              {isOrgOwner
                ? `By making ${userName} the owner you will become an organisation
              admin.`
                : `By making ${userName} the owner. The old owner will be changed to an organisation admin`}
            </Typography>
          </OrgOwnerWarning>
        )}
    </StyledForm>
  )
}

InviteNewAdminForm.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  orgAdminPermission: PropTypes.string,
  groupAdminPermission: PropTypes.string,
  setOrgAdminPermission: PropTypes.func,
  setGroupAdminPermission: PropTypes.func,
  isAssigningToGroup: PropTypes.bool,
  setIsAssigningToGroup: PropTypes.func,
  toggleAllAssignedToGroupCheckbox: PropTypes.func,
  toggleAssignedToGroupCheckbox: PropTypes.func,
  groups: PropTypes.arrayOf(PropTypes.object),
  inEditMode: PropTypes.bool,
  onCancel: PropTypes.func,
  canTransferOrgOwnership: PropTypes.bool,
  isOrgOwner: PropTypes.bool,
  isEditingOrgOwner: PropTypes.bool,
  userName: PropTypes.string,
}

InviteNewAdminForm.defaultProps = {
  errors: {},
  values: {},
  touched: {},
  groups: [],
}

export default InviteNewAdminForm
