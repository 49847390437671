import { withFormik } from 'formik'
import { compose } from 'recompose'
import * as Yup from 'yup'

export default compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ group }) => ({ name: group ? group.name : '' }),
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Please enter a group name')
        .max(50, 'Must be less than 50 characters long'),
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
      setSubmitting(true)
      // If form has no error
      if (props.onSubmit) {
        props.onSubmit(values)
      }
    },
  }),
)
