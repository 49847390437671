import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import MenuItem from 'components/molecules/MenuItem'
import Select from 'components/molecules/Select'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { PartnersConstants } from '../../../constants/PartnersConstantsEnum'

export const StyledActions = styled.div`
  display: flex;
  justify-content: center;
`

export const ActionButton = styled(Button)`
  width: 50%;

  && {
    margin: 0 ${({ theme }) => theme.spacing.sm}px;
  }
`

export const StyledOrganisation = styled(Select)`
  width: 300px;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`

export const LinkOrganisationTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function LinkOrganisationToPartnerForm({
  className,
  handleSubmit,
  values,
  selectOrg,
  allOrganisations,
  // TODO: Only show partnerless when we have partner admins with limited view (currently they see all)
  // partnerlessOrganisations,
  onClose,
}) {
  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      <LinkOrganisationTitle variant="h2">
        Add existing org
      </LinkOrganisationTitle>
      <div>
        {allOrganisations ? (
          <StyledOrganisation
            name="organisations"
            value={values.orgUuid}
            label="Existing organisations"
            onChange={(e) => selectOrg(e.target.value)}>
            <MenuItem
              key={PartnersConstants.NO_ORGANISATION_SELECTED}
              value={PartnersConstants.NO_ORGANISATION_SELECTED}>
              Select an organisation
            </MenuItem>
            {allOrganisations
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((org) => (
                <MenuItem key={org.uuid} value={org.uuid}>
                  {org.name}
                </MenuItem>
              ))}
          </StyledOrganisation>
        ) : (
          'Loading...'
        )}
      </div>
      <StyledActions>
        <ActionButton
          variant="outlined"
          type="button"
          size="small"
          color="primary"
          onClick={onClose}>
          Cancel
        </ActionButton>
        <ActionButton
          variant="contained"
          type="submit"
          size="small"
          color="primary"
          disabled={
            values.orgUuid === PartnersConstants.NO_ORGANISATION_SELECTED
          }>
          Add
        </ActionButton>
      </StyledActions>
    </form>
  )
}

LinkOrganisationToPartnerForm.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  onClose: PropTypes.func,
  allOrganisations: PropTypes.arrayOf(PropTypes.object),
  partnerlessOrganisations: PropTypes.arrayOf(PropTypes.object),
  values: PropTypes.object,
  selectOrg: PropTypes.func,
}

LinkOrganisationToPartnerForm.defaultProps = {
  errors: {},
  touched: {},
  values: {},
}

export default LinkOrganisationToPartnerForm
