import * as authActions from 'modules/authentication/actions'
import { hasEditRights } from 'modules/authentication/selectors'
import * as dayParametersActions from 'modules/dayParameters/actions'
import {
  getDepartmentsList,
  getTypesOfDaysList,
  getRolesList,
} from 'modules/dayParameters/selectors'
import { connect } from 'react-redux'
import { compose, withHandlers, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  connect(
    // mapStateToProps
    (state) => ({
      departments: getDepartmentsList(state),
      typesOfDays: getTypesOfDaysList(state),
      roles: getRolesList(state),
      getDayParametersProgress: state.progress.getDayParameters,
      hasEditAccess: state.user.canEdit || hasEditRights(state),
      existingDepartmentIds: state.dayParameters.departments.orderedIds,
      existingDayTypesIds: state.dayParameters.typesOfDays.orderedIds,
      existingRoleIds: state.dayParameters.roles.orderedIds,
    }),
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...dayParametersActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  lifecycle({
    componentDidMount() {
      const { actions, match } = this.props
      actions.getDayParameters(match.params.orgUuid)
    },
  }),
  withHandlers({
    onUpdateDayParameters:
      ({
        actions,
        match,
        existingDayTypesIds,
        existingDepartmentIds,
        existingRoleIds,
      }) =>
      ({ organisationDepartments, typesOfDays, roles }) => {
        actions.updateDayParameters({
          orgUuid: match.params.orgUuid,
          dayTypes: typesOfDays.map((dayType) => {
            if (!existingDayTypesIds.includes(dayType.uuid)) {
              // remove uuid for new day types
              return {
                type: dayType.type,
              }
            }

            return dayType
          }),
          departments: organisationDepartments.map((department) => {
            if (!existingDepartmentIds.includes(department.uuid)) {
              // remove uuid for new departments
              return {
                department: department.department,
              }
            }

            return department
          }),
          roles: roles.map((role) => {
            if (!existingRoleIds.includes(role.uuid)) {
              // remove uuid for new departments
              return {
                title: role.title,
              }
            }

            return role
          }),
        })
      },
  }),
)
