import React from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function FaceHappy(props) {
  return (
    <svg width="21px" height="21px" viewBox="0 0 21 21" {...props}>
      <g
        id="Chnnl-admin-home"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="_Screens/Super-admin/selected-orgs"
          transform="translate(-468.000000, -338.000000)"
          stroke="currentColor"
          strokeWidth="1.092224">
          <g id="Group-10" transform="translate(469.000000, 339.000000)">
            <g id="Group-9">
              <path
                d="M9.51609412,18.9347294 C14.7176235,18.9347294 18.9342824,14.7174 18.9342824,9.51587059 C18.9342824,4.31456471 14.7176235,0.0979058824 9.51609412,0.0979058824 C4.31456471,0.0979058824 0.0979058824,4.31456471 0.0979058824,9.51587059 C0.0979058824,14.7174 4.31456471,18.9347294 9.51609412,18.9347294 Z"
                id="Stroke-1"
                fill="transparent"
              />
              <path
                d="M7.26202353,15.7856694 C7.97329412,16.0733518 8.75050588,16.2313871 9.56482353,16.2313871 C11.8705294,16.2313871 13.8793882,14.9612929 14.9304235,13.0827518"
                id="Stroke-3"
              />
              <path
                d="M15.6367318,10.3445612 C15.6367318,8.98974941 14.5387553,7.89177294 13.1839435,7.89177294 C11.8298024,7.89177294 10.7318259,8.98974941 10.7318259,10.3445612"
                id="Stroke-5"
              />
              <path
                d="M8.26026118,10.3445612 C8.26026118,8.98974941 7.16228471,7.89177294 5.80747294,7.89177294 C4.45333176,7.89177294 3.35535529,8.98974941 3.35535529,10.3445612"
                id="Stroke-7"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default FaceHappy
