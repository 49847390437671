import ContentContainer from 'components/atoms/ContentContainer'
import SurveyQuestionsForm from 'components/organisms/SurveyQuestionsForm'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import AppLayout from 'layouts/AppLayout'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Survey({
  departments,
  getDayParametersProgress,
  hasEditAccess,
  onUpdateDayParameters,
  roles,
  typesOfDays,
}) {
  const initialValues = useMemo(
    () => ({
      departments,
      typesOfDays,
      roles,
    }),
    [departments, typesOfDays, roles],
  )

  return (
    <AdminDashboardLayout noPadding>
      <AppLayout>
        <ContentContainer>
          <SurveyQuestionsForm
            disableEditing={!hasEditAccess}
            onSubmit={onUpdateDayParameters}
            progress={getDayParametersProgress}
            initialValues={initialValues}
          />
        </ContentContainer>
      </AppLayout>
    </AdminDashboardLayout>
  )
}

Survey.propTypes = {
  departments: PropTypes.arrayOf(PropTypes.object),
  getDayParametersProgress: PropTypes.object,
  hasEditAccess: PropTypes.bool,
  initialValues: PropTypes.object,
  onUpdateDayParameters: PropTypes.func,
  roles: PropTypes.arrayOf(PropTypes.object),
  typesOfDays: PropTypes.arrayOf(PropTypes.object),
}

export default Survey
