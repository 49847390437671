import { withRouter } from 'react-router-dom'
import { compose, mapProps } from 'recompose'

export default compose(
  withRouter,
  mapProps((props) => ({
    ...props,
    orgUuid: props.match.params.orgUuid,
  })),
)
