import { PASSWORD_MIN_LENGTH } from 'config'
import { withFormik } from 'formik'
import { compose } from 'recompose'
import * as Yup from 'yup'

export default compose(
  withFormik({
    mapPropsToValues: () => ({ firstName: '', lastName: '', password: '' }),
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required('Please enter your first name')
        .max(50, 'Must be less than 50 characters long'),
      lastName: Yup.string()
        .required('Please enter your last name')
        .max(50, 'Must be less than 50 characters long'),
      password: Yup.string()
        .required('Please enter your password')
        .min(
          PASSWORD_MIN_LENGTH,
          `Your password must be at least ${PASSWORD_MIN_LENGTH} characters`,
        ),
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
      setSubmitting(true)
      // If form has no error
      if (props.onSubmit) {
        props.onSubmit(values)
      }
    },
  }),
)
