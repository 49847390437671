import { PAGINATION_LIMIT } from 'constants/pagination'
import { TOAST_TYPE_ERROR } from 'constants/ui'

import { useState, useMemo, useCallback } from 'react'
// import { addToast } from 'modules/toasts/actions'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const paginate = (array, index) =>
  array.slice((index - 1) * PAGINATION_LIMIT, index * PAGINATION_LIMIT)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useMembers({
  actions,
  members = [],
  handleCloseSingleResendModal,
  handleCloseMultipleResendModal,
  handleCloseMoveMembersModal,
  handleCloseDeleteMemberModal,
}) {
  const [searchFilter, setSearchFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('')
  const [selected, setSelected] = useState([])

  const handleChangeSearchFilter = useCallback((e) => {
    setSearchFilter(e.target.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeStatusFilter = useCallback((e) => {
    setStatusFilter(e.target.value)
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSelectAll = useCallback((e) => {
    if (e.target.checked) {
      const newSelecteds = members.map((n) => n.user.uuid)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  })

  const isSelected = (uuid) => selected.indexOf(uuid) !== -1
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSelection = useCallback((uuid) => {
    if (selected.indexOf(uuid) !== -1) {
      setSelected(selected.filter((e) => e !== uuid))
    } else {
      setSelected([...selected, uuid])
    }
  })

  const onResendSingleInvite = async ({ userUuid, groupUuid, orgUuid }) => {
    if (!userUuid) {
      actions.addToast({
        message: 'Group Member cannot be found.',
        type: TOAST_TYPE_ERROR,
      })
      return
    }
    const response = await actions.resendInvitation({
      groupUuid,
      orgUuid,
      userUuid,
    })
    // Close modal and open the success modal if the request succeeds
    if (response) {
      handleCloseSingleResendModal()
    }
  }

  const onResendMultipleInvites = async ({
    userUuids = [],
    groupUuid,
    orgUuid,
  }) => {
    if (userUuids.length === 0) {
      actions.addToast({
        message: 'Need at least one user to send invite.',
        type: TOAST_TYPE_ERROR,
      })
      return
    }
    const response = await actions.broadcastInvitations({
      groupUuid,
      orgUuid,
      userUuids,
    })
    // Close modal and open the success modal if the request succeeds
    if (response) {
      handleCloseMultipleResendModal()
    }
  }

  const onMoveMembers = async ({
    userUuids = [],
    sourceGroupUuid,
    targetGroupUuid,
    orgUuid,
  }) => {
    const response = await actions.moveMembers({
      orgUuid,
      userUuids,
      sourceGroupUuid,
      targetGroupUuid,
    })

    if (response) {
      setSelected([])
      handleCloseMoveMembersModal()

      actions.addUndo({
        callback: () => {
          actions.moveMembers({
            orgUuid,
            userUuids,
            sourceGroupUuid: targetGroupUuid,
            targetGroupUuid: sourceGroupUuid,
            undoing: true,
          })
        },
      })
    }
  }

  const onRemoveMultipleUsers = async ({
    userUuids = [],
    groupUuid,
    orgUuid,
  }) => {
    if (userUuids.length === 0) {
      actions.addToast({
        message: 'You need to select at least one user to remove.',
        type: TOAST_TYPE_ERROR,
      })
      return
    }

    const response = await actions.removeGroupMembers({
      groupUuid,
      orgUuid,
      userUuids,
    })

    if (response) {
      setSelected([])
      handleCloseDeleteMemberModal()
    }
  }

  const filteredMembers = useMemo(() => {
    if (members) {
      const statusFiltered = members.filter((member) => {
        if (statusFilter === '') return true
        return member.status === statusFilter
      })
      const filtered = statusFiltered.filter((member) => {
        const lcSearch = searchFilter.toLowerCase()
        const lcEmail = member.contacts.find(
          (contact) => contact.contactType === 'email',
        )
        const lcPhone = member.contacts.find(
          (contact) => contact.contactType === 'phone',
        )

        return (
          (lcEmail && lcEmail.value.startsWith(lcSearch)) ||
          (lcPhone && lcPhone.value.startsWith(lcSearch))
        )
      })
      return filtered
    }
    return members
  }, [members, searchFilter, statusFilter])

  return {
    searchFilter,
    statusFilter,
    handleChangeSearchFilter,
    handleChangeStatusFilter,
    filteredMembers,
    isSelected,
    selected,
    handleSelectAll,
    handleSelection,
    onResendSingleInvite,
    onResendMultipleInvites,
    onMoveMembers,
    onRemoveMultipleUsers,
  }
}

export default useMembers
