import {
  ADMIN_PERMISSION_VIEW_ONLY,
  ADMIN_PERMISSION_CAN_EDIT,
  ROLE_ORG_ADMIN,
  ROLE_GROUP_ADMIN,
} from 'constants/form'

import { withFormik } from 'formik'
import { compose, withState, withHandlers, defaultProps } from 'recompose'
import { required, validEmail, isEmpty } from 'utils/validation'

export default compose(
  defaultProps({
    initialValues: {},
  }),
  withState(
    'orgAdminPermission',
    'setOrgAdminPermission',
    ({ initialValues }) =>
      initialValues.editRights
        ? ADMIN_PERMISSION_CAN_EDIT
        : ADMIN_PERMISSION_VIEW_ONLY,
  ),
  withState(
    'groupAdminPermission',
    'setGroupAdminPermission',
    ({ initialValues }) =>
      initialValues.editRights
        ? ADMIN_PERMISSION_CAN_EDIT
        : ADMIN_PERMISSION_VIEW_ONLY,
  ),
  // for group admins there is a 2 step process so we need to keep track of which fields to show
  withState('isAssigningToGroup', 'setIsAssigningToGroup', false),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }) => ({
      email: initialValues.email || '',
      role: initialValues.role || ROLE_ORG_ADMIN,
      assignedToGroups: initialValues.assignedGroups
        ? initialValues.assignedGroups.map((group) => group.uuid)
        : [],
    }),
    validate: (
      { email, assignedToGroups },
      { inEditMode, isAssigningToGroup },
    ) => {
      const errors = {}

      if (!inEditMode && required(email)) {
        errors.email = 'Please enter an email address'
      } else if (!inEditMode && !validEmail(email)) {
        errors.email = 'Please enter a valid email address'
      }

      if (isAssigningToGroup && isEmpty(assignedToGroups)) {
        errors.assignedToGroups = 'Please assign the admin to a group'
      }

      return errors
    },
    handleSubmit: (
      values,
      {
        props: {
          onSubmit,
          orgOwnerPermission,
          orgAdminPermission,
          groupAdminPermission,
          setIsAssigningToGroup,
          isAssigningToGroup,
          inEditMode,
        },
        setSubmitting,
      },
    ) => {
      // If it is a group admin role - ask them to assign the admin to some groups first
      if (values.role === ROLE_GROUP_ADMIN && !isAssigningToGroup) {
        setIsAssigningToGroup(true)
      } else {
        setSubmitting(true)
        // pass values to our onSubmit prop
        if (onSubmit) {
          onSubmit({
            ...values,
            orgOwnerPermission,
            orgAdminPermission,
            groupAdminPermission,
            inEditMode,
          })
        }
      }
    },
  }),
  withHandlers({
    toggleAssignedToGroupCheckbox:
      ({ setFieldValue, values }) =>
      (groupId) => {
        if (values.assignedToGroups.includes(groupId)) {
          setFieldValue(
            'assignedToGroups',
            values.assignedToGroups.filter((id) => id !== groupId),
          )
        } else {
          setFieldValue('assignedToGroups', [
            ...values.assignedToGroups,
            groupId,
          ])
        }
      },
    toggleAllAssignedToGroupCheckbox:
      ({ setFieldValue, values, groups }) =>
      () => {
        if (values.assignedToGroups.length === groups.length) {
          setFieldValue('assignedToGroups', [])
        } else {
          setFieldValue(
            'assignedToGroups',
            groups.map((group) => group.value),
          )
        }
      },
  }),
)
