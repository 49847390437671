import styled from 'styled-components'

// Make sure to have `position: relative;` on the enclosing component
export const ClickArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin: 0;
  cursor: pointer;
`
