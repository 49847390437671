import {
  SORT_ALPHABETICAL,
  SORT_LEAST_MEMBERS,
  SORT_MOST_MEMBERS,
  SORT_LEAST_ADMINS,
  SORT_MOST_ADMINS,
  ROLE_SUPER_ADMIN,
  ROLE_ORG_OWNER,
  ROLE_ORG_ADMIN,
  ROLE_PARTNER_ADMIN,
} from 'constants/form'
import { GROUP_DASHBOARD } from 'constants/routes'

import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import ContentContainer from 'components/atoms/ContentContainer'
import ProgressLoader from 'components/atoms/ProgressLoader'
import SearchField from 'components/atoms/SearchField'
import authorisedRoles from 'components/hoc/authorisedRoles'
import DropdownFilter from 'components/molecules/DropdownFilter'
import EmptyState from 'components/molecules/EmptyState'
import EntityCard from 'components/molecules/EntityCard'
import MenuItem from 'components/molecules/MenuItem'
import { Modal } from 'components/molecules/Modal'
import DeleteGroupForm from 'components/organisms/DeleteGroupForm'
import NewGroupForm from 'components/organisms/NewGroupForm'
import useEntitiesSearch from 'hooks/useEntitiesSearch'
import useEntitiesSort from 'hooks/useEntitiesSort'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { replaceRouteParams } from 'utils/routes'

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`
export const StyledItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* Negative margin for items on the edges */
  margin: -${({ theme }) => theme.spacing.md}px;
  max-width: calc(100% + ${({ theme }) => theme.spacing.md * 2}px);
`

export const StyledSearch = styled(SearchField)`
  width: 300px;
  margin-left: ${({ theme }) => theme.spacing.lg}px;
`

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`
export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`

export const CreateGroupButton = styled(Button)`
  && {
    margin-left: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledNewGroupModal = styled(Modal)`
  width: 525px;
`

export const StyledDeleteGroupModal = styled(Modal)`
  width: 450px;
`

export const Group = styled(EntityCard)`
  width: 230px;
  margin: ${({ theme }) => theme.spacing.md}px;
`

export const NoAdminsText = styled.span`
  color: ${({ theme }) => theme.palette.error.main};
`

// Don't allow group admins to see the create group button
const ProtectedCreateGroupButton = authorisedRoles(
  [ROLE_SUPER_ADMIN, ROLE_ORG_OWNER, ROLE_ORG_ADMIN, ROLE_PARTNER_ADMIN],
  true,
)(CreateGroupButton)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Groups({
  isNewGroupModalOpen,
  setIsNewGroupModalOpen,
  onNewGroupFormSubmit,
  setIsDeleteGroupModalOpen,
  isDeleteGroupModalOpen,
  onDeleteGroupFormSubmit,
  setModalGroupId,
  groups,
  getGroupsProgress,
  match,
  admins,
  groupEntities,
  modalGroupId,
  hasEditAccess,
}) {
  const groupAdmins = useMemo(
    () =>
      admins.map((admin) => ({
        value: admin.uuid,
        label: admin.fullName || admin.email,
      })),
    [admins],
  )
  const { searchFilter, handleChangeSearchFilter, filteredEntities } =
    useEntitiesSearch(groups)

  const { sortField, handleChangeSortField, sortedEntities } =
    useEntitiesSort(filteredEntities)

  return (
    <AdminDashboardLayout>
      <StyledDeleteGroupModal
        open={isDeleteGroupModalOpen}
        onClose={() => setIsDeleteGroupModalOpen(false)}>
        <DeleteGroupForm
          onSubmit={onDeleteGroupFormSubmit}
          onCancel={() => setIsDeleteGroupModalOpen(false)}
          group={groupEntities[modalGroupId]}
        />
      </StyledDeleteGroupModal>
      <StyledNewGroupModal
        open={isNewGroupModalOpen}
        onClose={() => setIsNewGroupModalOpen(false)}
        hasCloseButton>
        <NewGroupForm
          onSubmit={onNewGroupFormSubmit}
          admins={groupAdmins}
          onCancel={() => setIsNewGroupModalOpen(false)}
        />
      </StyledNewGroupModal>
      <ContentContainer>
        <StyledHeader>
          <HeaderLeft>
            <Typography variant="h2">All groups</Typography>
            <StyledSearch
              placeholder="Search"
              value={searchFilter}
              onChange={handleChangeSearchFilter}
            />
          </HeaderLeft>
          <HeaderRight>
            <DropdownFilter
              label="Sort by:"
              value={sortField}
              onChange={handleChangeSortField}>
              <MenuItem color="primary" value={SORT_ALPHABETICAL}>
                Alphabetical
              </MenuItem>
              <MenuItem color="primary" value={SORT_LEAST_MEMBERS}>
                Least members
              </MenuItem>
              <MenuItem color="primary" value={SORT_MOST_MEMBERS}>
                Most members
              </MenuItem>
              <MenuItem color="primary" value={SORT_LEAST_ADMINS}>
                Least admins
              </MenuItem>
              <MenuItem color="primary" value={SORT_MOST_ADMINS}>
                Most admins
              </MenuItem>
            </DropdownFilter>
            <ProtectedCreateGroupButton
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setIsNewGroupModalOpen(true)}>
              Create group
            </ProtectedCreateGroupButton>
          </HeaderRight>
        </StyledHeader>

        {getGroupsProgress.inProgress ? (
          <ProgressLoader fullWidth />
        ) : sortedEntities.length ? (
          <StyledItems>
            {sortedEntities.map((group) => {
              const actions = []

              if (hasEditAccess) {
                actions.push({
                  label: 'Delete',
                  key: 'delete-group',
                  onClick: () => {
                    setIsDeleteGroupModalOpen(true)
                    setModalGroupId(group.uuid)
                  },
                })
              }

              const groupRoute = replaceRouteParams(GROUP_DASHBOARD, {
                groupUuid: group.uuid,
                orgUuid: match.params.orgUuid,
              })

              return (
                <Group
                  key={group.uuid}
                  to={groupRoute}
                  title={group.name}
                  subtitle={`${group.memberCount} members`}
                  footerText={
                    group.adminCount ? (
                      `${group.adminCount} admins`
                    ) : (
                      <NoAdminsText>No admin</NoAdminsText>
                    )
                  }
                  actions={actions}
                />
              )
            })}
          </StyledItems>
        ) : (
          <EmptyState
            title="Nothing to chnnl here yet."
            text="Get started by adding a new group."
          />
        )}
      </ContentContainer>
    </AdminDashboardLayout>
  )
}

Groups.propTypes = {
  setIsNewGroupModalOpen: PropTypes.func,
  isNewGroupModalOpen: PropTypes.bool,
  onNewGroupFormSubmit: PropTypes.func,
  isDeleteGroupModalOpen: PropTypes.bool,
  setIsDeleteGroupModalOpen: PropTypes.func,
  onDeleteGroupFormSubmit: PropTypes.func,
  setModalGroupId: PropTypes.func,
  groups: PropTypes.arrayOf(PropTypes.object),
  getGroupsProgress: PropTypes.object,
  match: PropTypes.object,
  admins: PropTypes.arrayOf(PropTypes.object),
  groupEntities: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
  modalGroupId: PropTypes.string,
  hasEditAccess: PropTypes.bool,
}

Groups.defaultProps = {
  getGroupsProgress: {},
  groups: [],
  admins: [],
  groupEntities: {},
}

export default Groups
