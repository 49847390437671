import { adminsActions } from 'modules/admins/actions'
import * as partnerAdminActions from 'modules/partnerAdmins/actions'
import { getPartnerAdminsList } from 'modules/partnerAdmins/selectors'
import { connect } from 'react-redux'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  connect(
    (state) => ({
      partnerAdmins: getPartnerAdminsList(state),
    }),
    (dispatch) => {
      const actions = {
        ...partnerAdminActions,
        ...adminsActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  withState(
    'isInviteNewPartnerAdminModalOpen',
    'setIsInviteNewPartnerAdminModalOpen',
    false,
  ),
  withState('isDeleteAdminModalOpen', 'setIsDeleteAdminModalOpen', false),
  withState('editAdminId', 'setEditAdminId', null),
  lifecycle({
    componentDidMount() {
      const { match } = this.props
      this.props.actions.getPartnerAdmins(match.params.partnerUuid)
    },
  }),
  withHandlers({
    onInviteNewPartnerAdminSubmit:
      ({ match, actions, setIsInviteNewPartnerAdminModalOpen }) =>
      (values) => {
        actions.createPartnerAdmin({
          partnerUuid: match.params.partnerUuid,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
        })
        setIsInviteNewPartnerAdminModalOpen(false)
      },
    onDeleteAdmin:
      ({ match, actions, editAdminId, setIsDeleteAdminModalOpen }) =>
      () => {
        actions.deletePartnerAdmin(match.params.partnerUuid, editAdminId)
        setIsDeleteAdminModalOpen(false)
      },
  }),
)
