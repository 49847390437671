import Button from '@weareroam/cake-ui-v1/Button'
import FormControlLabel from '@weareroam/cake-ui-v1/FormControlLabel'
import Typography from '@weareroam/cake-ui-v1/Typography'
import Radio from 'components/atoms/Radio'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledFields = styled.fieldset`
  margin: ${({ theme }) => theme.spacing.lg}px 0;
  text-align: left;
`

const StyledRadio = styled(Radio)`
  && {
    height: 35px;
  }
`

const StyledRadioOption = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const StyledRadioControl = styled(FormControlLabel)`
  flex-grow: 1;
  width: 300px;
`

const Actions = styled.footer`
  display: flex;
  justify-content: center;
`

const ActionButton = styled(Button)`
  width: 140px;
  && {
    margin: 0 ${({ theme }) => theme.spacing.sm}px;
  }
`

const StyledForm = styled.form`
  padding-bottom: ${({ theme }) => theme.spacing.lg}px;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function AddOrgCountryForm({
  className,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleBlur,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleChange,
  handleSubmit,
  onCancel,
  title,

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  errors,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  touched,
  values,

  countries,
  toggleNewCountryRadio,
}) {
  return (
    <StyledForm
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      <div>
        <Typography variant="h2">{title}</Typography>
        {countries.length ? null : (
          <Typography variant="h4">
            No more countries available for selection
          </Typography>
        )}
        <StyledFields>
          <StyledRadioOption>
            {countries.map((country) => (
              <StyledRadioControl
                key={'new-country-' + country.value}
                name="newCountry"
                checked={values.newCountryIsoCode === country.value}
                onChange={() => toggleNewCountryRadio(country.value)}
                value={country.value}
                control={<StyledRadio />}
                label={country.label}
              />
            ))}
          </StyledRadioOption>
        </StyledFields>
      </div>

      <Actions>
        <ActionButton
          variant="outlined"
          size="small"
          type="button"
          color="primary"
          onClick={onCancel}>
          Cancel
        </ActionButton>
        <ActionButton
          size="small"
          variant="contained"
          type="submit"
          color="primary"
          disabled={!values.newCountryIsoCode}>
          Add
        </ActionButton>
      </Actions>
    </StyledForm>
  )
}

AddOrgCountryForm.propTypes = {
  className: PropTypes.string,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,

  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,

  countries: PropTypes.arrayOf(PropTypes.object),
  toggleNewCountryRadio: PropTypes.func,
}

AddOrgCountryForm.defaultProps = {
  errors: {},
  touched: {},
  values: {},

  countries: [],
}

export default AddOrgCountryForm
