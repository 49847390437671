import { Formik } from 'formik'
import React from 'react'
import { useDebouncedCallback } from 'use-debounce'
import {
  getPeriodFromDates,
  getPeriodFromName,
  Period,
  PeriodBoundaries,
} from 'utils/date'
import { noop } from 'utils/functions'

import { DatePeriodForm, DatePeriodFormValues } from './DatePeriodForm'

interface Props {
  initialPeriod: Period
  onPeriodChange: (e: PeriodBoundaries) => void
}

export const DatePeriodSelector = ({
  initialPeriod,
  onPeriodChange,
}: Props): React.ReactElement => {
  const initialDates = getPeriodFromName(initialPeriod)

  const bubbleUp = useDebouncedCallback(
    (value) => {
      onPeriodChange(value)
    },
    600, // Allows to type over the dates in the field
    { maxWait: 3000 },
  )

  const validateAndBubble = ({ start, end }: DatePeriodFormValues) => {
    const errors: { start?: string; end?: string } = {}

    if (start.length === 0) {
      errors.start = 'Please enter correct start date'
    } else if (end.length === 0) {
      errors.end = 'Please enter correct end date'
    } else if (start > end) {
      errors.start = 'Start date should be earlier than end date'
    } else {
      bubbleUp(getPeriodFromDates(start, end))
    }
    return errors
  }

  return (
    <Formik
      initialValues={{
        period: initialPeriod,
        start: initialDates.start,
        end: initialDates.end,
      }}
      validate={validateAndBubble}
      onSubmit={noop}>
      {(props) => <DatePeriodForm {...props} />}
    </Formik>
  )
}
