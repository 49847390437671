import Select from '@weareroam/cake-ui-v1/Select'
import DropdownIcon from '@weareroam/cake-ui-v1-icons/ExpandMore'
import FilterLabel from 'components/atoms/FilterLabel'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledDropdownFilter = styled.div`
  display: inline-flex;
  align-items: center;
`

const selectMenuClass = 'dropdown-filter-select-menu'
const paperClass = 'menu-no-shadow' // in theme file. workaround since this appears in a modal
export const StyledSelect = styled(Select).attrs({
  classes: {
    selectMenu: selectMenuClass,
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  MenuProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      horizontal: 'center',
      vertical: 'top',
    },
    getContentAnchorEl: null,
    classes: {
      paper: paperClass,
    },
  },
})`
  border: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  border-radius: 4px;
  background: ${({ theme }) => theme.palette.secondary.main};

  &::after,
  &::before {
    display: none;
  }

  .${selectMenuClass} {
    color: ${({ theme }) => theme.palette.primary.main};
    padding-left: ${({ theme }) => theme.spacing.md}px;
    font-size: 14px;
    padding-right: ${({ theme }) => theme.spacing.xl}px;
  }
`

export const StyledLabel = styled(FilterLabel)`
  && {
    margin-right: ${({ theme }) => theme.spacing.sm}px;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function DropdownFilter({
  id,
  children,
  label,
  name,
  value,
  onChange,
  className,
}) {
  return (
    <StyledDropdownFilter>
      <StyledLabel>{label}</StyledLabel>
      <div id={id}>
        <StyledSelect
          IconComponent={DropdownIcon}
          value={value}
          onChange={onChange}
          displayEmpty={false}
          name={name}
          className={className}>
          {children}
        </StyledSelect>
      </div>
    </StyledDropdownFilter>
  )
}

DropdownFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.string,
}

export default DropdownFilter
