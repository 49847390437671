import { FeatureKeys } from 'constants/features'
import {
  ROLE_SUPER_ADMIN,
  ROLE_GROUP_ADMIN,
  ROLE_ORG_OWNER,
  ROLE_ORG_ADMIN,
  ROLE_PARTNER_ADMIN,
} from 'constants/form'
import {
  ORG_DASHBOARD_SETTINGS_ADMINS,
  ORG_DASHBOARD_SETTINGS_GENERAL,
  ORG_DASHBOARD_SETTINGS_PROFILE,
  ORG_DASHBOARD_SETTINGS_ENTITLEMENTS,
  // ORG_DASHBOARD_SETTINGS_NOTIFICATIONS,
} from 'constants/routes'

import { useFeature } from '@optimizely/react-sdk'
import authorisedRoles from 'components/hoc/authorisedRoles'
import NavItem from 'components/molecules/NavItem'
import Sidebar from 'components/molecules/Sidebar'
import PropTypes from 'prop-types'
import React from 'react'
import { replaceRouteParams } from 'utils/routes'

import { SideNavigationContainer } from '../SideNavigation/SideNavigationContainer'
import { SideNavigationContent } from '../SideNavigation/SideNavigationContent'

// Don't allow super admins to access the Org admin profile page.
// Super admins have their own page to update their profile
const ProtectedProfileNavItem = authorisedRoles([
  ROLE_PARTNER_ADMIN,
  ROLE_ORG_OWNER,
  ROLE_ORG_ADMIN,
  ROLE_GROUP_ADMIN,
])(NavItem)

// Don't allow group admins to access the admins page
const ProtectedNavItem = authorisedRoles([
  ROLE_PARTNER_ADMIN,
  ROLE_SUPER_ADMIN,
  ROLE_ORG_OWNER,
  ROLE_ORG_ADMIN,
])(NavItem)

// Only ROLE_SUPER_ADMIN has access top this nav item
const ProtectedSuperAdminNavItem = authorisedRoles([ROLE_SUPER_ADMIN])(NavItem)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function OrgSettingsLayout({ children, orgUuid }) {
  const [entitlementsEnabled] = useFeature(FeatureKeys.Entitlements)

  return (
    <SideNavigationContainer>
      <Sidebar>
        <ProtectedNavItem
          direction="vertical"
          to={replaceRouteParams(ORG_DASHBOARD_SETTINGS_GENERAL, { orgUuid })}
          text="General"
        />
        {entitlementsEnabled && (
          <ProtectedSuperAdminNavItem
            direction="vertical"
            to={replaceRouteParams(ORG_DASHBOARD_SETTINGS_ENTITLEMENTS, {
              orgUuid,
            })}
            text="Entitlements"
          />
        )}
        <ProtectedNavItem
          direction="vertical"
          to={replaceRouteParams(ORG_DASHBOARD_SETTINGS_ADMINS, { orgUuid })}
          text="Admins"
        />
        <ProtectedProfileNavItem
          direction="vertical"
          to={replaceRouteParams(ORG_DASHBOARD_SETTINGS_PROFILE, { orgUuid })}
          text="Profile"
        />
        {/* <ProtectedProfileNavItem
          direction="vertical"
          to={replaceRouteParams(ORG_DASHBOARD_SETTINGS_NOTIFICATIONS, {
            orgUuid,
          })}
          text="Notifications"
        /> */}
      </Sidebar>
      <SideNavigationContent>{children}</SideNavigationContent>
    </SideNavigationContainer>
  )
}

OrgSettingsLayout.propTypes = {
  children: PropTypes.node,
  orgUuid: PropTypes.string,
}

export default OrgSettingsLayout
