import Typography from '@weareroam/cake-ui-v1/Typography'
import Box from 'components/molecules/Box'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledBox = styled(Box)`
  border: none;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
`
export const StyledContent = styled.div`
  display: flex;
  flex-direction: row;
`
export const StyledSection = styled.div`
  flex-basis: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid
    ${({ theme, noLine }) =>
      noLine ? 'transparent' : theme.palette.tertiary.light};
  padding: ${({ theme }) => theme.spacing.md}px;
`
export const SectionLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};

  && {
    margin-bottom: ${({ theme }) => theme.spacing.sm}px;
  }
`
export const SectionValue = styled(Typography)`
  && {
    color: ${({ theme, colour }) => theme.palette[colour].main};
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function GroupMemberInfo({ data, className }) {
  return (
    <StyledBox className={className}>
      <StyledContent>
        <StyledSection>
          <SectionLabel>Total members</SectionLabel>
          <SectionValue variant="h2" colour="primary">
            {data.active}
          </SectionValue>
        </StyledSection>
        <StyledSection>
          <SectionLabel>Pending invitations</SectionLabel>
          <SectionValue variant="h2" colour="quaternary">
            {data.pending}
          </SectionValue>
        </StyledSection>
      </StyledContent>
    </StyledBox>
  )
}

GroupMemberInfo.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
}

GroupMemberInfo.defaultProps = {
  data: {},
}

export default GroupMemberInfo
