import React from 'react'

// eslint-disable-next-line react/display-name
export const EditCircle = React.memo(() => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="14" cy="14" r="13.5" stroke="#828797" />
    <path
      d="M20.7722 9.72369L18.2343 7.18577C18.1153 7.06682 17.954 7 17.7857 7C17.6175 7 17.4561 7.06682 17.3372 7.18577L15.5651 8.95787L19.0001 12.3929L20.7722 10.6208C20.8912 10.5019 20.958 10.3405 20.958 10.1723C20.958 10.004 20.8912 9.84267 20.7722 9.72369V9.72369Z"
      fill="#828797"
    />
    <path
      d="M14.6678 9.85498L8.45438 16.0684C8.38485 16.1382 8.3325 16.2231 8.30147 16.3165L7.03251 20.1234C7.00076 20.2187 6.9921 20.3203 7.00726 20.4196C7.02241 20.5189 7.06094 20.6132 7.11967 20.6948C7.1784 20.7763 7.25565 20.8427 7.34507 20.8885C7.43449 20.9344 7.53352 20.9583 7.634 20.9584C7.70218 20.9584 7.7699 20.9472 7.83449 20.9254L11.6414 19.6564C11.7348 19.6254 11.8197 19.5731 11.8895 19.5035L18.1029 13.2901L14.6678 9.85498Z"
      fill="#828797"
    />
  </svg>
))
