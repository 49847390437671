import { FeatureKeys } from 'constants/features'
import {
  ROLE_ORG_OWNER,
  ROLE_SUPER_ADMIN,
  ROLE_ORG_ADMIN,
  ROLE_PARTNER_ADMIN,
} from 'constants/form'

import { useFeature } from '@optimizely/react-sdk'
import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import ContentContainer from 'components/atoms/ContentContainer'
import ProgressLoader from 'components/atoms/ProgressLoader'
import authorisedRoles from 'components/hoc/authorisedRoles'
import Box from 'components/molecules/Box'
import DragDropList from 'components/molecules/DragDropList'
import { Modal } from 'components/molecules/Modal'
import AddOrgCountryForm from 'components/organisms/AddOrgCountryForm'
import { AutoReplyForm } from 'components/organisms/AutoReplyForm/AutoReplyForm'
import DeleteOrgCountryForm from 'components/organisms/DeleteOrgCountryForm'
import AdminDashboardLayout from 'layouts/AdminDashboardLayout'
import OrgSettingsLayout from 'layouts/OrgSettingsLayout'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

const StyledHeaderSecondary = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.xl}px;
`

const StyledHeaderCopy = styled.div``

const StyledHeaderRight = styled.div`
  display: flex;
  width: 180px;
  justify-content: space-between;
  align-items: center;
`

const AddCountryButton = styled(Button)`
  && {
    margin-left: ${({ theme }) => theme.spacing.md}px;
  }
`

const CountriesSubheaderText = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};

  && {
    margin: ${({ theme }) => theme.spacing.sm}px 0;
  }
`

const StyledAddOrgCountryModal = styled(Modal)`
  width: 520px;
`

const StyledDeleteOrgCountryModal = styled(Modal)`
  width: 460px;
`

const OrgCountries = styled.div`
  margin: ${({ theme }) => theme.spacing.md}px 0;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

const OrgCountryItem = styled.div`
  display: flex;
  justify-content: space-between;
`

const CountryNameTypography = styled(Typography)`
  padding-top: 0.25rem;
`

const ProtectedAddCountryButton = authorisedRoles(
  [ROLE_ORG_OWNER, ROLE_SUPER_ADMIN, ROLE_ORG_ADMIN, ROLE_PARTNER_ADMIN],
  true,
)(AddCountryButton)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function General({
  orgUuid,
  isOrgOwner,
  hasEditAccess,

  orgCountries,
  orgCountryEntities,
  getOrgCountriesProgress,
  availableCountries,
  currentOrgCountryId,
  setCurrentOrgCountryId,

  isAddOrgCountryModalOpen,
  setIsAddOrgCountryModalOpen,
  handleAddOrgCountry,
  isDeleteOrgCountryModalOpen,
  setIsDeleteOrgCountryModalOpen,
  handleDeleteOrgCountry,
  onOrgCountryDragEnd,
}) {
  const [autoReplyFormEnabled] = useFeature(FeatureKeys.AutoReply)

  return (
    <AdminDashboardLayout noPadding>
      <StyledAddOrgCountryModal
        hasCloseButton
        open={isAddOrgCountryModalOpen}
        onClose={() => {
          setIsAddOrgCountryModalOpen(false)
        }}>
        <AddOrgCountryForm
          title="Add new country"
          isOrgOwner={isOrgOwner}
          countries={availableCountries.map((country) => ({
            label: country.name,
            value: country.isoCode,
          }))}
          onSubmit={({ newCountryIsoCode }) => {
            handleAddOrgCountry({
              isoCode: newCountryIsoCode,
              preferenceOrder:
                1 +
                Math.max(...orgCountries.map((oC) => oC.preferenceOrder), 0),
            })
          }}
          onCancel={() => {
            setIsAddOrgCountryModalOpen(false)
          }}
        />
      </StyledAddOrgCountryModal>
      <StyledDeleteOrgCountryModal
        hasCloseButton
        open={isDeleteOrgCountryModalOpen}
        onClose={() => {
          setIsDeleteOrgCountryModalOpen(false)
        }}>
        <DeleteOrgCountryForm
          orgCountry={orgCountryEntities[currentOrgCountryId]}
          onSubmit={() => {
            handleDeleteOrgCountry(orgCountryEntities[currentOrgCountryId])
          }}
          onCancel={() => {
            setIsDeleteOrgCountryModalOpen(false)
          }}
        />
      </StyledDeleteOrgCountryModal>
      <OrgSettingsLayout>
        <ContentContainer>
          <StyledHeader>
            <StyledHeaderCopy>
              <Typography variant="h2">Countries</Typography>
              <CountriesSubheaderText variant="body1">
                Your app users will be able to select their country from the
                below list, this enables us to give them the most relevant
                experience.
              </CountriesSubheaderText>
            </StyledHeaderCopy>
            <StyledHeaderRight>
              <ProtectedAddCountryButton
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  setCurrentOrgCountryId(null)
                  setIsAddOrgCountryModalOpen(true)
                }}>
                Add new country
              </ProtectedAddCountryButton>
            </StyledHeaderRight>
          </StyledHeader>
          {getOrgCountriesProgress.inProgress ? (
            <ProgressLoader fullWidth />
          ) : null}
          <DragDropList
            onDragEnd={(dragResult) =>
              onOrgCountryDragEnd({
                allEntities: orgCountries,
                dragResult,
              })
            }>
            {(provided) => (
              <OrgCountries>
                {orgCountries
                  ? orgCountries.map((orgCountry, index) => (
                      <Box
                        key={orgCountry.country.isoCode}
                        draggableId={orgCountry.country.isoCode}
                        draggableIndex={index}
                        isDraggable={hasEditAccess}
                        headerContent={
                          <OrgCountryItem>
                            <CountryNameTypography variant="h4">
                              {orgCountry.country.name}
                            </CountryNameTypography>
                            {hasEditAccess && orgCountries.length > 1 ? (
                              <Button
                                type="button"
                                variant="text"
                                color="primary"
                                size="small"
                                onClick={() => {
                                  setCurrentOrgCountryId(orgCountry.id)
                                  setIsDeleteOrgCountryModalOpen(true)
                                }}>
                                Remove
                              </Button>
                            ) : null}
                          </OrgCountryItem>
                        }
                      />
                    ))
                  : null}
                {provided.placeholder}
              </OrgCountries>
            )}
          </DragDropList>
        </ContentContainer>
        {autoReplyFormEnabled && (
          <ContentContainer>
            <StyledHeaderSecondary>
              <StyledHeaderCopy>
                <Typography variant="h2">Journals</Typography>
                <CountriesSubheaderText variant="body1">
                  When auto-reply is enabled each red flagged journal will
                  trigger this notification.
                </CountriesSubheaderText>
              </StyledHeaderCopy>
            </StyledHeaderSecondary>
            <AutoReplyForm orgUuid={orgUuid} />
          </ContentContainer>
        )}
      </OrgSettingsLayout>
    </AdminDashboardLayout>
  )
}

General.propTypes = {
  orgUuid: PropTypes.string,
  isOrgOwner: PropTypes.bool,
  hasEditAccess: PropTypes.bool,

  orgCountries: PropTypes.arrayOf(PropTypes.object),
  orgCountryEntities: PropTypes.object,
  getOrgCountriesProgress: PropTypes.object,
  availableCountries: PropTypes.arrayOf(PropTypes.object),
  currentOrgCountryId: PropTypes.number,
  setCurrentOrgCountryId: PropTypes.func,

  isAddOrgCountryModalOpen: PropTypes.bool,
  setIsAddOrgCountryModalOpen: PropTypes.func,
  handleAddOrgCountry: PropTypes.func,
  isDeleteOrgCountryModalOpen: PropTypes.bool,
  setIsDeleteOrgCountryModalOpen: PropTypes.func,
  handleDeleteOrgCountry: PropTypes.func,
  onOrgCountryDragEnd: PropTypes.func,
}

General.defaultProps = {
  orgCountries: [],
  orgCountryEntities: {},
  getOrgCountriesProgress: {},
  availableCountries: [],
}

export default General
