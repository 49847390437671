import { HumanReadableDate, HumanReadableDatetime } from 'constants/date'

import { Typography } from '@weareroam/cake-ui-v1'
import { ClickableContainer } from 'components/atoms/ClickableContainer/ClickableContainer'
import { EditPen } from 'components/atoms/Icons/EditPen'
import { TrashCan } from 'components/atoms/Icons/TrashCan'
import { format } from 'date-fns'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { getThumbnailURL } from 'utils/videoThumbnail'

interface VideoUpdateCardProps {
  videoUrl?: string
  activeAt: Date
  title: string
  description?: string
  isEditable: boolean
  isDeletable: boolean
  onEdit: () => void
  onDelete: () => void
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const VIDEO_CARD_SIZE = 236

// Sizes in Figma don't align with "em"s from our current theme at all.
//  So you'll see a ton of px...
const StyledCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: ${VIDEO_CARD_SIZE}px;
  row-gap: ${({ theme }) => theme.spacing.xs}px;
`

const StyledImage = styled.div`
  width: ${VIDEO_CARD_SIZE}px;
  height: ${Math.round(VIDEO_CARD_SIZE * 0.627)}px;
  background-color: #c4c4c4;
  background-size: cover;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`

const EditContainer = styled.div`
  display: flex;
  background: rgba(128, 128, 128, 0.8);
  position: absolute;
  right: 0px;
  top: 11px;
  padding: 3px 10px;
  line-height: 0.7; // Otherwise too much bottom padding
  border-radius: 8px 0px 0px 8px;
  column-gap: 10px;
`

const DateTimeLabel = styled(Typography)`
  && {
    font-size: 10px;
    color: #828797;
  }
`

const Title = styled(Typography)`
  && {
    width: 100%; // force linebreaks
    font-size: 15px;
    font-weight: 700;
  }
  // Shorten the text if needed
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const Description = styled(Typography)`
  && {
    margin-top: 6px;
    font-size: 13px;
  }

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  // Webkit can do ellipsis after multiple lines, not just one
  @supports (-webkit-line-clamp: 3) {
    && {
      display: -webkit-box;
    }
    white-space: initial;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`

export function VideoUpdateCard({
  videoUrl,
  activeAt,
  title,
  description,
  isEditable,
  isDeletable,
  onEdit,
  onDelete,
}: VideoUpdateCardProps): React.ReactElement {
  function renderEditControls(): React.ReactNode {
    if (isEditable || isDeletable) {
      return (
        <EditContainer>
          {isEditable && (
            <ClickableContainer handler={onEdit}>
              <EditPen />
            </ClickableContainer>
          )}
          {isDeletable && (
            <ClickableContainer handler={onDelete}>
              <TrashCan />
            </ClickableContainer>
          )}
        </EditContainer>
      )
    }
    return null
  }

  // We don't show the time part for previous videos
  function formatDateTime(dt: Date): string {
    if (dt < new Date()) {
      return format(dt, HumanReadableDate)
    }
    return format(dt, HumanReadableDatetime)
  }

  const [imageUrl, setImageUrl] = React.useState(null)
  useEffect(() => {
    async function fetchThumbnailUrl() {
      const url = await getThumbnailURL(videoUrl)
      setImageUrl(url)
    }
    videoUrl && fetchThumbnailUrl()
  }, [videoUrl])

  return (
    <StyledCard>
      <StyledImage style={{ backgroundImage: `url("${imageUrl}")` }}>
        {renderEditControls()}
      </StyledImage>
      <DateTimeLabel>{formatDateTime(activeAt)}</DateTimeLabel>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </StyledCard>
  )
}
