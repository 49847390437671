import React from 'react'

// eslint-disable-next-line react/display-name
export const ApproveCircle = React.memo(() => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="14" cy="14" r="13.5" stroke="#2985FC" />
    <path
      d="M12.3124 18.6668C12.1893 18.6668 12.0674 18.6442 11.9536 18.6003C11.8398 18.5564 11.7364 18.4919 11.6493 18.4107L7.23682 14.2918L8.56244 13.0545L12.3124 16.5545L20.4374 8.97119L21.7631 10.2084L12.9756 18.4107C12.8885 18.4919 12.7851 18.5564 12.6713 18.6003C12.5575 18.6442 12.4356 18.6668 12.3124 18.6668Z"
      fill="#2985FC"
    />
  </svg>
))
