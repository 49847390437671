import { Button } from '@weareroam/cake-ui-v1'
import { LabelledCheckbox } from 'components/molecules/LabelledCheckbox/LabelledCheckbox'
import { ValidatedTextarea } from 'components/molecules/ValidatedTextarea/ValidatedTextarea'
import { Form, FormikProps } from 'formik'
import React from 'react'
import styled from 'styled-components'

import { AutoReplyFormValues } from './AutoReplyForm'

const AlignRight = styled.div`
  display: flex;
  justify-content: end;
`

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AutoReplyFormUIProps {}

export function AutoReplyFormUI({
  handleSubmit,

  isValid,
  isSubmitting,

  values,
}: AutoReplyFormUIProps &
  FormikProps<AutoReplyFormValues>): React.ReactElement {
  return (
    <Form onSubmit={handleSubmit}>
      <LabelledCheckbox
        name={'autoReply'}
        label={'Enable auto-reply'}
        disabled={isSubmitting}
      />
      <br />
      <ValidatedTextarea
        name="autoReplyText"
        disabled={isSubmitting || !values.autoReply}
      />
      <br />
      <AlignRight>
        <Button
          variant="outlined"
          type="submit"
          color="primary"
          size="small"
          disabled={isSubmitting || !isValid}>
          Save
        </Button>
      </AlignRight>
    </Form>
  )
}
