import { TOAST_TYPE_ERROR } from 'constants/ui'

import { withFormik } from 'formik'
import * as partnerActions from 'modules/partners/actions'
import { connect } from 'react-redux'
import { compose, withHandlers, withState } from 'recompose'
import { bindActionCreators } from 'redux'
import * as Yup from 'yup'

export default compose(
  connect(
    // mapStateToProps
    (state, { match }) => ({
      getPartnerProgress: state.progress.getPartner,
      partner: state.partners.entities[match.params.partnerUuid],
    }),
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...partnerActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  withState('uploadProgress', 'setUploadProgress', 0),
  withState('uploadTotal', 'setUploadTotal', 0),
  withState('isConfirmUpdateModalOpen', 'setIsConfirmUpdateModalOpen', false),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ partner }) => ({
      partnerTitle: partner ? partner.title : '',
      logo: partner ? partner.logo : null,
    }),
    validationSchema: Yup.object({
      partnerTitle: Yup.string()
        .required('Please enter a partner title')
        .max(50, 'Must be less than 50 characters long'),
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
      if (props.isConfirmUpdateModalOpen) {
        // If the confirmation modal is already open, submit the form
        setSubmitting(true)
        // If form has no error
        if (props.onSubmit) {
          props.onSubmit(values)
        }

        props.setIsConfirmUpdateModalOpen(false)
      } else {
        props.setIsConfirmUpdateModalOpen(true)
      }
    },
  }),
  withHandlers({
    onImageUploadError:
      ({ actions }) =>
      () => {
        actions.addToast({
          message: 'Sorry, we only support PNG/JPEG for organisation images',
          type: TOAST_TYPE_ERROR,
        })
      },
    onUpdatePartner:
      ({
        actions,
        match,
        setUploadProgress,
        setUploadTotal,
        values,
        partner,
      }) =>
      () => {
        if (values.logo !== null && typeof values.logo !== 'string') {
          actions.uploadPartnerLogo({
            uuid: match.params.partnerUuid,
            file: values.logo,
            onUploadProgress: (progressEvent) => {
              setUploadProgress(progressEvent.loaded)
              setUploadTotal(progressEvent.total)
            },
          })
        }
        if (partner.title !== values.partnerTitle) {
          actions.updatePartner(match.params.partnerUuid, values.partnerTitle)
        }
      },
  }),
)
