import { LOGIN } from 'constants/routes'

import * as routeModule from 'modules/route'
import { connect } from 'react-redux'
import { compose } from 'recompose'

export default compose(
  connect(
    // mapStateToProps
    () => ({}),
    // mapDispatchToProps
    (dispatch) => ({
      goToLogin: () => dispatch(routeModule.redirect(LOGIN)),
    }),
  ),
)
