import { Typography } from '@weareroam/cake-ui-v1'
import { Error } from '@weareroam/cake-ui-v1-icons'
import StatusTag from 'components/atoms/StatusTag'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledErrorMessage = styled(StatusTag)`
  && {
    display: flex;
    flex-direction: row;
    align-items:center;
    text-transform: none;
    background-color: white;
    border-left: ${({ theme }) => theme.spacing.sm}px solid ${({ theme }) =>
  theme.palette.error.main};
    color: ${({ theme }) => theme.palette.error.main};
    width: 100%
    padding: ${({ theme }) => theme.spacing.sm}px;
    margin-top: ${({ theme }) => theme.spacing.sm}px;
    margin-bottom: ${({ theme }) => theme.spacing.sm}px;
    visibility : ${({ show }) => (show ? 'visible' : 'hidden')}
    opacity: ${({ show }) => (show ? 1 : 0)};
    overflow-y: hidden;
    transition: visibility 0.1s linear, opacity 0.1s linear, transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
`

export const StyledError = styled(Error)`
  margin-right: ${({ theme }) => theme.spacing.md}px;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const InputErrorStatusTag = ({ error }) => (
  <StyledErrorMessage show={!!error}>
    <StyledError fontSize="small" />
    <Typography variant="body1" color="error">
      {error}
    </Typography>
  </StyledErrorMessage>
)

InputErrorStatusTag.propTypes = { error: PropTypes.string }

export default InputErrorStatusTag
