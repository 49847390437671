import FormControlLabel from '@weareroam/cake-ui-v1/FormControlLabel'
import Radio from 'components/atoms/Radio'
import { Field, Form, FormikProps } from 'formik'
import React from 'react'
import styled from 'styled-components'
import { getPeriodFromName, Period } from 'utils/date'

const StyledFields = styled.fieldset`
  text-align: left;
  font-size: 0.942rem;
`

const StyledRadio = styled(Radio)`
  && {
    height: 24px;
    padding: 8px;
  }
`

const StyledRadioOption = styled.div`
  align-items: center;
  padding-top: 10px;
`

const StyledRadioControl = styled(FormControlLabel)`
  && {
    margin-left: -9px;
  }
  flex-grow: 1;
  padding-inline-end: ${({ theme }) => theme.spacing.sm}px;
`

const StyledDateSelectorLine = styled.div`
  padding-top: 12px;
`

const StyledDateInput = styled(Field)`
  border: 1px solid #dae1ee;
  border-radius: 5px;
  margin-inline-end: ${({ theme }) => theme.spacing.md}px;
`

const StyledLabel = styled.label`
  color: #979797;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  flex-grow: 1;
  padding-inline-end: ${({ theme }) => theme.spacing.sm}px;
`

const StyledErrorLabel = styled(StyledLabel)`
  color: ${({ theme }) => theme.palette.error.main};
`

export interface DatePeriodFormValues {
  start: string
  end: string
  period: Period
}

const periods = [
  { id: 'last-thirty', description: 'Last 30 days' },
  { id: 'this-month', description: 'This month' },
  { id: 'last-month', description: 'Last month' },
  { id: 'custom', description: 'Custom' },
]

export const DatePeriodForm = ({
  errors,
  handleChange,
  setFieldValue,
  setValues,
  values,
}: FormikProps<DatePeriodFormValues>): React.ReactElement => {
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('period', 'custom')
    handleChange(event)
  }

  const handlePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPeriod = event.target.value as Period | 'custom'

    if (newPeriod === 'custom') {
      setFieldValue('period', newPeriod)
    } else {
      const boundaries = getPeriodFromName(newPeriod)
      setValues({
        period: newPeriod,
        start: boundaries.start,
        end: boundaries.end,
      })
    }
  }

  return (
    <Form>
      <StyledFields>
        <StyledLabel>View:</StyledLabel>
        <StyledRadioOption>
          {periods.map(({ id, description }) => (
            <StyledRadioControl
              key={'period-' + id}
              name="period"
              checked={values.period === id}
              onChange={handlePeriodChange as never}
              value={id}
              control={<StyledRadio />}
              label={description}
            />
          ))}
        </StyledRadioOption>

        {values.period === 'custom' ? (
          <StyledDateSelectorLine>
            <StyledLabel htmlFor="start">From:</StyledLabel>
            <StyledDateInput
              id="start"
              name="start"
              type="date"
              onChange={handleDateChange}
            />
            <StyledLabel>To:</StyledLabel>
            <StyledDateInput
              name="end"
              type="date"
              onChange={handleDateChange}
            />
          </StyledDateSelectorLine>
        ) : null}

        {errors.start ? (
          <StyledErrorLabel>{errors.start}</StyledErrorLabel>
        ) : null}
        {errors.end ? <StyledErrorLabel>{errors.end}</StyledErrorLabel> : null}
      </StyledFields>
    </Form>
  )
}
