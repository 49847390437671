import React from 'react'
import styled from 'styled-components'

interface Props {
  children: React.ReactNode
}

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing.md}px;
  padding-right: ${({ theme }) => theme.spacing.xs}px;
`

export function ListItemActions({ children }: Props): React.ReactElement {
  return <ActionsWrapper>{children}</ActionsWrapper>
}
