import React, { useCallback } from 'react'

interface ClickableContainerProps {
  children: React.ReactNode
  handler: () => void
}

export function ClickableContainer({
  children,
  handler,
}: ClickableContainerProps): React.ReactElement {
  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        handler()
      }
    },
    [handler],
  )

  return (
    <div
      onClick={handler}
      onKeyPress={handleKeyPress}
      role={'button'}
      tabIndex={0}>
      {children}
    </div>
  )
}
