import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styled from 'styled-components'

export const StyledTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.tertiary.dark};
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export const StyledForm = styled.form`
  width: 300px;
`

export const StyledActions = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledButton = styled(Button)`
  width: 50%;

  && {
    margin: 0 ${({ theme }) => theme.spacing.sm}px;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function NavigationPromptForm({ onLeave, onStay, className }) {
  const handleOnSubmit = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()

    if (onStay) {
      onStay()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledForm
      onSubmit={handleOnSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      <StyledTitle variant="h4">
        Are you sure you would like to leave without updating?
      </StyledTitle>
      <StyledText variant="body1">
        All changes you&apos;ve made will be discarded.
      </StyledText>
      <StyledActions>
        <StyledButton
          variant="outlined"
          type="button"
          size="small"
          color="primary"
          onClick={onLeave}>
          Leave
        </StyledButton>
        <StyledButton
          variant="contained"
          type="submit"
          size="small"
          color="primary">
          Stay
        </StyledButton>
      </StyledActions>
    </StyledForm>
  )
}

NavigationPromptForm.propTypes = {
  onLeave: PropTypes.func,
  onStay: PropTypes.func,
  className: PropTypes.string,
}

export default NavigationPromptForm
