import * as Sentry from '@sentry/react'
import axios from 'axios'
import { useState, useEffect, useCallback } from 'react'

import { SAUCE_API_URL } from '../config'
import { ReportErrorType } from '../constants/reports'
import { Group } from '../modules/groups/schema'

import { DataHook } from './types'

interface UseGroupsProps {
  orgUuid: string
}

export const useGroups = ({ orgUuid }: UseGroupsProps): DataHook<Group[]> => {
  const [data, setData] = useState<Group[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<ReportErrorType | undefined>(undefined)

  // TODO: Make use of the Redux store. These are shared between pages.
  const fetchGroups = useCallback(async () => {
    setIsLoading(true)
    setError(undefined)
    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${orgUuid}/group`,
        {
          params: {},
        },
      )
      const data = response.data.data
      setData(data)
    } catch (error: unknown) {
      Sentry.captureException(error)
      setError(ReportErrorType.GENERIC)
    }
    setIsLoading(false)
  }, [setData, setIsLoading, setError, orgUuid])

  useEffect(() => {
    fetchGroups()
  }, [fetchGroups])

  return {
    data,
    isLoading,
    error,
  }
}
