export enum SupportTriggerTypes {
  FIRST_SURVEY = 'firstSurvey',
  STANDARD_SURVEY = 'standardSurvey',
  /** Below 28 on WHO5 */
  LOW_SURVEY = 'lowSurvey',
  BULLYING_REFLECTION = 'bullyingReflection',
}

export const SupportTriggerNames: { [key in SupportTriggerTypes]: string } = {
  firstSurvey: 'First Check-in',
  standardSurvey: 'Standard Check-in',
  lowSurvey: 'Low-score Check-in',
  bullyingReflection: 'Bullying / Harassment Themed Check-in',
}
