import Button from '@weareroam/cake-ui-v1/Button'
import FormControlLabel from '@weareroam/cake-ui-v1/FormControlLabel'
import Typography from '@weareroam/cake-ui-v1/Typography'
import ProgressLoader from 'components/atoms/ProgressLoader'
import Radio from 'components/atoms/Radio'
import { useFormik } from 'formik'
import { Group } from 'modules/groups/schema'
import { RequestProgressState } from 'modules/progress'
import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'

const StyledFields = styled.fieldset`
  margin: ${({ theme }) => theme.spacing.lg}px 0;
  text-align: left;
`

const StyledRadio = styled(Radio)`
  && {
    height: 35px;
  }
`

const StyledRadioOption = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const StyledRadioControl = styled(FormControlLabel)`
  flex-grow: 1;
  width: 300px;
`

export const StyledActions = styled.div`
  display: flex;
  justify-content: center;
`

export const ActionButton = styled(Button)`
  width: 50%;

  && {
    margin: 0 ${({ theme }) => theme.spacing.sm}px;
  }
`

export const Title = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

const WarningFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.error.light};
  color: ${({ theme }) => theme.palette.error.main};
  padding: ${({ theme }) => theme.spacing.md}px;
  text-align: center;
`

const WarningSpacer = styled.div`
  height: ${({ theme }) => theme.spacing.xl}px;
`

export interface MoveMembersFormValues {
  newGroupUuid: string
}

interface Props {
  onCancel: () => void
  onSubmit: (formValues: MoveMembersFormValues) => void
  groups: Group[]
  numSelected: number
  progress: RequestProgressState
}

export function MoveMembersForm({
  onSubmit,
  onCancel,
  groups,
  numSelected,
  progress,
}: Props): React.ReactElement {
  const inProgress = progress.inProgress

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      newGroupUuid: '',
    },
    validationSchema: Yup.object({
      newGroupUuid: Yup.string().required('Please choose a group'),
    }),
    onSubmit: (values) => {
      onSubmit(values)
    },
  })

  const warnings = []
  if (!groups.length) {
    warnings.push(
      'There are no other groups. Please make sure to create them first.',
    )
  }
  if (numSelected === 0) {
    warnings.push('Please select at least one member to move.')
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Typography variant="h2">
          Select a group to move these members to
        </Typography>
        <StyledFields>
          <StyledRadioOption>
            {groups.map((group) => (
              <StyledRadioControl
                key={'group-' + group.uuid}
                name="newGroupUuid"
                checked={values.newGroupUuid === group.uuid}
                onChange={(e) =>
                  handleChange({
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    target: { name: 'newGroupUuid', value: e.target.value }, //cake-ui typings declare target as empty
                  })
                }
                value={group.uuid}
                control={<StyledRadio />}
                label={group.name}
              />
            ))}
          </StyledRadioOption>
        </StyledFields>
      </div>

      <Title variant="h4">
        {numSelected > 0 &&
          `Are you sure you would like to move ${numSelected} members from this group?`}
      </Title>
      <StyledActions>
        <ActionButton
          variant="outlined"
          type="button"
          size="small"
          color="primary"
          onClick={onCancel}>
          Cancel
        </ActionButton>
        <ActionButton
          variant="contained"
          size="small"
          color="primary"
          type="submit"
          disabled={numSelected === 0 || !values.newGroupUuid || inProgress}>
          {inProgress ? <ProgressLoader size={18} /> : 'Confirm'}
        </ActionButton>
      </StyledActions>

      {warnings.map((val, idx) => (
        <WarningSpacer key={idx}></WarningSpacer>
      ))}
      {warnings.length > 0 && (
        <WarningFooter>
          {warnings.map((text, idx) => (
            <p key={idx}>{text}</p>
          ))}
        </WarningFooter>
      )}
    </form>
  )
}
