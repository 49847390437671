import * as authActions from 'modules/authentication/actions'
import { canEditGroup, hasEditRights } from 'modules/authentication/selectors'
import * as boosterActions from 'modules/boosters/actions'
import {
  getGroupBoostersList,
  getOrganisationBoostersList,
} from 'modules/boosters/selectors'
import { connect } from 'react-redux'
import { compose, withHandlers, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  connect(
    // mapStateToProps
    (state, { match }) => ({
      groupBoosterCategories: getGroupBoostersList(
        state,
        match.params.groupUuid,
      ),
      organisationBoosterCategories: getOrganisationBoostersList(
        state,
        match.params.orgUuid,
      ),
      hasEditAccess:
        state.user.canEdit ||
        canEditGroup(state, { uuid: match.params.groupUuid }) ||
        hasEditRights(state),
      getBoostersProgress: state.progress.getGroupBoosters,
      updateBoostersProgress: state.progress.updateGroupBoosters,
    }),
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...boosterActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  lifecycle({
    componentDidMount() {
      const { actions, match } = this.props
      actions.getOrganisationBoosters(match.params.orgUuid)
      actions.getGroupBoosters({
        groupUuid: match.params.groupUuid,
        orgUuid: match.params.orgUuid,
      })
    },
  }),
  withHandlers({
    onUpdateBoosters:
      ({ match, actions }) =>
      async ({ boosterCategories }) => {
        await actions.updateGroupBoosters({
          groupUuid: match.params.groupUuid,
          orgUuid: match.params.orgUuid,
          boosters: boosterCategories,
        })
        actions.getGroupBoosters({
          groupUuid: match.params.groupUuid,
          orgUuid: match.params.orgUuid,
        })
      },
  }),
)
