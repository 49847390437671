import { withFormik } from 'formik'
import { compose, defaultProps, withState, withHandlers } from 'recompose'
import uuid from 'uuid/v4'
import * as Yup from 'yup'

export default compose(
  withState('isConfirmUpdateModalOpen', 'setIsConfirmUpdateModalOpen', false),
  defaultProps({
    initialValues: {},
  }),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }) => ({
      typesOfDays: initialValues.typesOfDays || [],
      organisationDepartments: initialValues.departments || [],
      roles: initialValues.roles || [],
    }),
    validationSchema: Yup.object({
      typesOfDays: Yup.array()
        .of(
          Yup.object({
            type: Yup.string().required('Please add a day type'),
          }),
        )
        .min(1, 'Please add a type of day'),
      organisationDepartments: Yup.array()
        .of(
          Yup.object({
            department: Yup.string().required('Please add a department name'),
          }),
        )
        .min(1, 'Please add an organisation department'),
      roles: Yup.array()
        .of(
          Yup.object({
            title: Yup.string().required('Please add a role title'),
          }),
        )
        .min(1, 'Please add a role'),
    }),
    handleSubmit: (
      values,
      {
        props: {
          onSubmit,
          isConfirmUpdateModalOpen,
          setIsConfirmUpdateModalOpen,
        },
        setSubmitting,
      },
    ) => {
      if (isConfirmUpdateModalOpen) {
        // If the confirmation modal is already open, submit the form
        setSubmitting(true)
        // If form has no error
        if (onSubmit) {
          onSubmit(values)
        }

        setIsConfirmUpdateModalOpen(false)
      } else {
        // Open confirmation modal before submitting the form
        setIsConfirmUpdateModalOpen(true)
      }
    },
  }),
  withHandlers({
    onAddDayType:
      ({ values, setFieldValue }) =>
      () => {
        setFieldValue('typesOfDays', [
          ...values.typesOfDays,
          {
            uuid: uuid(),
            type: '',
          },
        ])
      },
    onAddDepartment:
      ({ values, setFieldValue }) =>
      () => {
        setFieldValue('organisationDepartments', [
          ...values.organisationDepartments,
          {
            uuid: uuid(),
            department: '',
          },
        ])
      },
    onAddRole:
      ({ values, setFieldValue }) =>
      () => {
        setFieldValue('roles', [
          ...values.roles,
          {
            uuid: uuid(),
            title: '',
          },
        ])
      },
    onDeleteDayType:
      ({ values, setFieldValue }) =>
      (index) => {
        setFieldValue(
          'typesOfDays',
          values.typesOfDays.filter((d, i) => i !== index),
        )
      },
    onDeleteDepartment:
      ({ values, setFieldValue }) =>
      (index) => {
        setFieldValue(
          'organisationDepartments',
          values.organisationDepartments.filter((d, i) => i !== index),
        )
      },
    onDeleteRole:
      ({ values, setFieldValue }) =>
      (index) => {
        setFieldValue(
          'roles',
          values.roles.filter((d, i) => i !== index),
        )
      },
    onDragEnd:
      ({ values, setFieldValue }) =>
      (result, field) => {
        // dropped outside the list
        if (!result.destination) {
          return
        }

        // Return the reordered list
        const reorderedList = Array.from(values[field])
        const [removed] = reorderedList.splice(result.source.index, 1)
        reorderedList.splice(result.destination.index, 0, removed)

        setFieldValue(field, reorderedList)
      },
  }),
)
