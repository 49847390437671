import { FormikProps, withFormik } from 'formik'
import { Theme } from 'hooks/useThemes'
import { compose, defaultProps } from 'recompose'
import * as Yup from 'yup'

export interface InnerFormProps {
  className: string
  hasError: boolean
  inEditMode: boolean
  themes: Theme[]
  onSubmit: (values: FormValues) => void
  onCancel: () => void
  initialValues: {
    uuid?: string
    categoryName?: string
    themes?: Theme[]
  }
}

export interface FormValues {
  uuid?: string
  categoryName: string
  themes: Partial<Theme>[]
  bullyingThemeAssociated: boolean
}

export default compose<
  InnerFormProps & FormikProps<FormValues>,
  InnerFormProps
>(
  defaultProps({
    initialValues: {},
  }),
  withFormik<InnerFormProps, FormValues>({
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }) => ({
      categoryName: initialValues.categoryName || '',
      bullyingThemeAssociated: !!initialValues.themes?.length,
      themes: initialValues.themes || [],
      ...(initialValues.uuid ? { uuid: initialValues.uuid } : {}),
    }),
    validationSchema: Yup.object({
      categoryName: Yup.string().required('Please enter category name'),
    }),
    handleSubmit: (values, { props: { onSubmit, themes }, setSubmitting }) => {
      setSubmitting(true)
      const payload = toggleBullyingThemeOnPayload(values, themes)
      onSubmit(payload)
    },
  }),
)

export const toggleBullyingThemeOnPayload = (
  values: FormValues,
  themes: Theme[],
): FormValues => {
  const bullyingTheme = themes.find(
    (theme) => theme.key === 'BULLIED_OR_HARASSED',
  )
  if (!bullyingTheme) return values

  return {
    ...values,
    ...(values.bullyingThemeAssociated
      ? {
          themes: [bullyingTheme],
        }
      : { themes: [] }),
  }
}
