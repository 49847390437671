import React from 'react'

// eslint-disable-next-line react/display-name
export const TrashCan = React.memo(() => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.2577 12.3749C2.29059 12.8158 2.48858 13.228 2.81213 13.5293C3.13567 13.8305 3.56095 13.9987 4.00303 14.0001H9.99736C10.4394 13.9987 10.8647 13.8305 11.1883 13.5293C11.5118 13.228 11.7098 12.8158 11.7427 12.3749L12.2934 4.66675H1.70703L2.2577 12.3749Z"
      fill="#212121"
    />
    <path
      d="M13.4167 2.33333H9.91667V0.583333C9.91667 0.428624 9.85521 0.280251 9.74581 0.170854C9.63642 0.0614582 9.48804 0 9.33333 0L4.66667 0C4.51196 0 4.36358 0.0614582 4.25419 0.170854C4.14479 0.280251 4.08333 0.428624 4.08333 0.583333V2.33333H0.583333C0.428624 2.33333 0.280251 2.39479 0.170854 2.50419C0.0614582 2.61358 0 2.76196 0 2.91667C0 3.07138 0.0614582 3.21975 0.170854 3.32915C0.280251 3.43854 0.428624 3.5 0.583333 3.5H13.4167C13.5714 3.5 13.7197 3.43854 13.8291 3.32915C13.9385 3.21975 14 3.07138 14 2.91667C14 2.76196 13.9385 2.61358 13.8291 2.50419C13.7197 2.39479 13.5714 2.33333 13.4167 2.33333ZM5.25 1.16667H8.75V2.33333H5.25V1.16667Z"
      fill="#212121"
    />
  </svg>
))
