import { MOOD_OVERALL, Moods } from 'constants/form'

import axios from 'axios'
import { useState, useEffect } from 'react'

import { SAUCE_API_URL } from '../config'
import { ReportErrorType } from '../constants/reports'
import { ErrorPayload } from '../types/services'

// Backend is rather broken. Returns either of:
// {totalUsers: 0, averageMood: 0, referToMonth: '2021-12-01'}
// {totalusers: '1', averagemood: 5, relativedate: '2022-01-01T00:00:00.000Z'}
// TODO: Fix the BE! No real point of having this as union type.
export interface WellbeingLineItem {
  averageMood?: number
  averagemood?: number
  totalUsers?: number
  totalusers?: string
  referToMonth?: string
  relativedate?: string
}

export const useWellbeingSixMonthsReport = ({
  mood,
  orgUuid,
  groupUuid,
}: {
  mood: Moods
  orgUuid: string
  groupUuid?: string
}): {
  data?: WellbeingLineItem[]
  isLoading: boolean
  error?: ReportErrorType
} => {
  const [reportData, setReportData] = useState<WellbeingLineItem[] | undefined>(
    undefined,
  )
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<ReportErrorType | undefined>(undefined)

  useEffect(() => {
    const fetchWellbeingSixMonthsMood = async () => {
      setIsLoading(true)
      setError(undefined)
      try {
        const response = await axios.get(
          `${SAUCE_API_URL}/wellbeing/average-monthly`,
          {
            params: {
              orgs: [orgUuid],
              mood: mood === MOOD_OVERALL ? null : mood,
              ...(groupUuid ? { groupUuids: [groupUuid] } : {}),
            },
          },
        )
        const data = response.data.data
        setReportData(data)
      } catch (error: unknown) {
        const brandedError = error as ErrorPayload
        if (
          brandedError?.response?.data?.status === 403 &&
          brandedError?.response?.data?.message.includes('needs at least')
        ) {
          setError(ReportErrorType.MIN_SAMPLE_REQUIRED)
        } else {
          setError(ReportErrorType.GENERIC)
        }
      }
      setIsLoading(false)
    }
    fetchWellbeingSixMonthsMood()
  }, [setReportData, setIsLoading, setError, orgUuid, groupUuid, mood])

  return {
    data: reportData,
    isLoading,
    error,
  }
}
