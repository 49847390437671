import {
  Table,
  Typography,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Button,
} from '@weareroam/cake-ui-v1'
import SearchField from 'components/atoms/SearchField'
import EntityCard from 'components/molecules/EntityCard'
import { Modal } from 'components/molecules/Modal'
import MoreActions from 'components/molecules/MoreActions'
import DeleteAdminForm from 'components/organisms/DeleteAdminForm'
import InviteNewPartnerAdminForm from 'components/organisms/InviteNewPartnerAdminForm'
import { usePartnerAdminsSearch } from 'hooks/usePartnerAdminsSearch'
import PartnerInnerLayout from 'layouts/PartnerInnerLayout'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styled from 'styled-components'

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`
export const StyledSearch = styled(SearchField)`
  width: 400px;
`
export const StyledDeleteModal = styled(Modal)`
  width: 450px;
`
export const StyledItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* Negative margin for items on the edges */
  margin: -${({ theme }) => theme.spacing.md}px;
  max-width: calc(100% + ${({ theme }) => theme.spacing.md * 2}px);
`
export const Organisation = styled(EntityCard)`
  width: 230px;
  margin: ${({ theme }) => theme.spacing.md}px;
`
export const StyledHeaderButtons = styled.div`
  display: inline-block;
  float: right;
`
export const NewOrganisationButton = styled(Button)`
  && {
    margin-left: ${({ theme }) => theme.spacing.sm}px;
  }
`
export const HeaderLeft = styled.div`
  flex-grow: 1;
`
const StyledTable = styled(Table)`
  min-width: 900px;
  && {
    border-collapse: separate;
    border-spacing: 0 1rem;
  }
`
export const PendingText = styled(Typography)`
  font-style: italic;
  color: ${({ theme }) => theme.palette.tertiary.light};
  && {
    margin-right: ${({ theme }) => theme.spacing.sm}px;
  }
`
export const ActionsCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const InviteButton = styled(Button)`
  && {
    margin-left: ${({ theme }) => theme.spacing.md}px;
  }
`
export const HeaderRight = styled.div`
  display: flex;
  width: 420px;
  justify-content: space-between;
  align-items: center;
`

export const StyledInviteModal = styled(Modal)`
  width: 520px;
`
export const StyledTableHead = styled(TableHead)`
  color: #979797;
`
export const ConfirmUpdatesModal = styled(Modal)`
  width: 480px;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function PartnerAdmins({
  partnerAdmins,
  editAdminId,
  setEditAdminId,
  isDeleteAdminModalOpen,
  setIsDeleteAdminModalOpen,
  isInviteNewPartnerAdminModalOpen,
  setIsInviteNewPartnerAdminModalOpen,
  onInviteNewPartnerAdminSubmit,
  onDeleteAdmin,
}) {
  const { searchFilter, handleChangeSearchFilter, filteredAdmins } =
    usePartnerAdminsSearch(partnerAdmins)

  const handleCloseConfirmDeleteAdminModal = useCallback(() => {
    setIsDeleteAdminModalOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PartnerInnerLayout>
      <StyledInviteModal
        hasCloseButton
        open={isInviteNewPartnerAdminModalOpen}
        onClose={() => {
          setIsInviteNewPartnerAdminModalOpen(false)
        }}>
        <InviteNewPartnerAdminForm
          onSubmit={onInviteNewPartnerAdminSubmit}
          inEditMode={!!editAdminId}
          title="Invite new partner admin"
        />
      </StyledInviteModal>
      <ConfirmUpdatesModal
        open={isDeleteAdminModalOpen}
        onClose={handleCloseConfirmDeleteAdminModal}>
        <DeleteAdminForm
          onCancel={handleCloseConfirmDeleteAdminModal}
          onSubmit={onDeleteAdmin}
        />
      </ConfirmUpdatesModal>
      <StyledHeader>
        <HeaderLeft>
          <Typography variant="h2">Partner admins</Typography>
        </HeaderLeft>
        <HeaderRight>
          <SearchField
            placeholder="Search"
            value={searchFilter}
            onChange={handleChangeSearchFilter}
          />
          <InviteButton
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              setEditAdminId(null)
              setIsInviteNewPartnerAdminModalOpen(true)
            }}>
            Invite new admin
          </InviteButton>
        </HeaderRight>
      </StyledHeader>
      <StyledTable>
        <StyledTableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Assigned to</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {filteredAdmins &&
            filteredAdmins.map((partnerAdmin) => {
              const editPermissionActions = [
                // {
                //   key: `edit-permissions-${partnerAdmin.uuid}`,
                //   label: 'Edit permissions',
                //   onClick: () => {
                //     setEditAdminId(partnerAdmin.uuid)
                //   },
                // },
                {
                  key: `delete-admin-${partnerAdmin.uuid}`,
                  label: 'Delete admin',
                  onClick: () => {
                    setEditAdminId(partnerAdmin.uuid)
                    setIsDeleteAdminModalOpen(true)
                  },
                },
              ]

              return (
                <TableRow key={partnerAdmin.uuid}>
                  <TableCell>
                    {partnerAdmin.profile.firstName}{' '}
                    {partnerAdmin.profile.lastName}
                  </TableCell>
                  <TableCell>{partnerAdmin.email}</TableCell>
                  <TableCell>All</TableCell>
                  <TableCell>
                    <ActionsCell>
                      <MoreActions
                        color="primary"
                        actions={editPermissionActions}
                      />
                    </ActionsCell>
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </StyledTable>
    </PartnerInnerLayout>
  )
}

PartnerAdmins.propTypes = {
  partnerAdmins: PropTypes.arrayOf(PropTypes.object),
  editAdminId: PropTypes.string,
  setEditAdminId: PropTypes.func,
  isDeleteAdminModalOpen: PropTypes.bool,
  setIsDeleteAdminModalOpen: PropTypes.func,
  setIsInviteNewPartnerAdminModalOpen: PropTypes.func,
  onUpdatePartnerAdminPermissions: PropTypes.func,
  isInviteNewPartnerAdminModalOpen: PropTypes.bool,
  onInviteNewPartnerAdminSubmit: PropTypes.func,
  onDeleteAdmin: PropTypes.func,
}

PartnerAdmins.defaultProps = {}

export default PartnerAdmins
