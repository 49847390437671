import { ROLE_PARTNER_ADMIN } from 'constants/form'

import { useAuth0 } from '@auth0/auth0-react'
import OrgAdminHeader from 'components/organisms/OrgAdminHeader'
import ChnnlDashboardLayout from 'layouts/ChnnlDashboardLayout'
import { StyledContent as ChnnlContent } from 'layouts/ChnnlDashboardLayout/ChnnlDashboardLayout'
import PartnerAdminLayout from 'layouts/PartnerAdminLayout'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { getUserRole } from 'utils/authentication'

export const StyledLayout = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.main};
`

export const IEWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledContent = styled.div`
  padding: ${({ theme, noPadding }) => (noPadding ? 0 : theme.spacing.xl)}px
    ${({ theme, noPadding }) => (noPadding ? 0 : theme.spacing.lg)}px;
  flex-grow: 1;
  display: flex;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
`

export const StyledChnnlLayout = styled(ChnnlDashboardLayout)`
  ${ChnnlContent} {
    padding: 0;
  }
`

export const StyledPartnerAdminDashboardLayout = styled(PartnerAdminLayout)`
  position: absolute;
  left: 0;
  top: 65px;
  background-color: red !important;
  padding: 0;
  color: red;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function AdminDashboardLayout({
  children,
  isChnnlAdmin,
  className,
  noPadding,
  organisation,
}) {
  const { logout } = useAuth0()
  const role = getUserRole()

  const AdminLayout = (
    <IEWrapper>
      <StyledLayout className={className}>
        <OrgAdminHeader
          onLogout={logout}
          logoSrc={organisation ? organisation.logo : ''}
          organisationCountry={
            organisation && organisation.defaultCountry
              ? organisation.defaultCountry.isoCode
              : ''
          }
          organisationName={organisation ? organisation.name : ''}
          organisationInviteToken={organisation?.inviteTokens?.[0]?.token ?? ''}
        />
        <StyledContent noPadding={noPadding}>{children}</StyledContent>
      </StyledLayout>
    </IEWrapper>
  )

  if (role === ROLE_PARTNER_ADMIN) {
    return <PartnerAdminLayout>{AdminLayout}</PartnerAdminLayout>
  }

  // chnnl admins should see this dashboard layout nested within their own dashboard layout.
  if (isChnnlAdmin) {
    return (
      <StyledChnnlLayout noSidebar onLogout={logout}>
        {AdminLayout}
      </StyledChnnlLayout>
    )
  }

  return AdminLayout
}

AdminDashboardLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isChnnlAdmin: PropTypes.bool,
  noPadding: PropTypes.bool,
  organisation: PropTypes.object,
}

AdminDashboardLayout.defaultProps = {
  organisation: {},
}

export default AdminDashboardLayout
