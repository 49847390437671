import { ROLE_GROUP_ADMIN } from 'constants/form'
import { ORG_DASHBOARD_GROUPS } from 'constants/routes'

import * as adminsActions from 'modules/admins/actions'
import {
  getVerifiedGroupAdmins,
  getGroupAdminsList,
} from 'modules/admins/selectors'
import * as authActions from 'modules/authentication/actions'
import { canEditGroup, hasEditRights } from 'modules/authentication/selectors'
import * as groupsActions from 'modules/groups/actions'
import { redirect } from 'modules/route'
import { connect } from 'react-redux'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'
import { replaceRouteParams } from 'utils/routes'
import { validEmail } from 'utils/validation'

export default compose(
  connect(
    // mapStateToProps
    (state, { match }) => ({
      groupAdmins: getGroupAdminsList(state, match.params.groupUuid),
      eligibleGroupAdmins: getVerifiedGroupAdmins(state),
      hasEditAccess:
        state.user.canEdit ||
        canEditGroup(state, { uuid: match.params.groupUuid }) ||
        hasEditRights(state),
      getAdminsProgress: state.progress.getOrganisationAdmins,
      adminEntities: state.admins.entities,
      group: state.groups.entities[match.params.groupUuid],
      role: state.authentication.role,
    }),
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...adminsActions,
        ...groupsActions,
        redirect,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  lifecycle({
    componentDidMount() {
      const { actions, match, role } = this.props

      if (role !== ROLE_GROUP_ADMIN) {
        actions.getOrgAdmins(match.params.orgUuid)
      } else {
        actions.getGroupAdmins({ ...match.params })
      }

      actions.getGroups(match.params.orgUuid)
    },
  }),
  withState('isDeleteGroupModalOpen', 'setIsDeleteGroupModalOpen', false),
  withState('isRemoveAdminModalOpen', 'setIsRemoveAdminModalOpen', false),
  withState('modalAdminId', 'setModalAdminId', null),
  withHandlers({
    onUpdateNameSubmit:
      ({ actions, match }) =>
      ({ name }) => {
        actions.updateGroup({
          groupUuid: match.params.groupUuid,
          orgUuid: match.params.orgUuid,
          name,
        })
      },
    onDeleteGroupSubmit:
      ({ actions, setIsDeleteGroupModalOpen, match }) =>
      async (e) => {
        e.preventDefault()

        setIsDeleteGroupModalOpen(false)

        const success = await actions.deleteGroup({
          orgUuid: match.params.orgUuid,
          groupUuid: match.params.groupUuid,
        })

        // Redirect out of the group on successful deletion
        if (success) {
          actions.redirect(
            replaceRouteParams(ORG_DASHBOARD_GROUPS, {
              orgUuid: match.params.orgUuid,
            }),
          )
        }
      },
    onAddGroupAdminSubmit:
      ({ actions, setIsAddGroupAdminModalOpen, match, eligibleGroupAdmins }) =>
      ({ admins, textInput }) => {
        // New admins will be emails from the input. LEAVING HERE FOR POST MVP
        const newAdmins = admins.filter((admin) => validEmail(admin))

        if (textInput && validEmail(textInput)) {
          newAdmins.push(textInput)
        }
        // Existing admins will be names. Find the uuids
        const existingAdminNames = admins.filter((admin) => !validEmail(admin))
        const existingAdmins = existingAdminNames.map((name) => {
          const foundAdmin = eligibleGroupAdmins.find(
            (a) => a.fullName === name,
          )
          return foundAdmin.userUuid
        })
        actions.inviteGroupAdminsToGroup({
          orgUuid: match.params.orgUuid,
          groupUuid: match.params.groupUuid,
          emails: newAdmins,
          userUuids: existingAdmins,
        })

        setIsAddGroupAdminModalOpen(false)
      },
    onRemoveGroupAdminSubmit:
      ({ match, actions, setIsRemoveAdminModalOpen }) =>
      (e, admin) => {
        e.preventDefault()
        actions.removeAdminFromGroup({
          orgUuid: match.params.orgUuid,
          groupUuid: match.params.groupUuid,
          adminUuid: admin.uuid,
          userUuid: admin.userUuid,
        })
        setIsRemoveAdminModalOpen(false)
      },
  }),
)
