import { withFormik } from 'formik'
import { compose, defaultProps } from 'recompose'
import * as Yup from 'yup'

export default compose(
  defaultProps({
    initialValues: {},
  }),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: () => ({
      email: '',
      firstName: '',
      lastName: '',
    }),
    validationSchema: Yup.object({
      email: Yup.string().required('Email is required'),
      firstName: Yup.string().required('Please enter a first name'),
      lastName: Yup.string().required('Please enter a last name'),
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
      setSubmitting(true)
      // If form has no error
      if (props.onSubmit) {
        props.onSubmit(values)
      }
    },
  }),
)
