import { Editor } from '@tinymce/tinymce-react'
import { Typography } from '@weareroam/cake-ui-v1'
import Button from '@weareroam/cake-ui-v1/Button'
import MenuItemComponent from '@weareroam/cake-ui-v1/MenuItem'
import DropdownFilter from 'components/molecules/DropdownFilter'
import { useFormik } from 'formik'
import { Tip } from 'modules/tips/schema'
import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'

const Actions = styled.footer`
  margin-top: ${({ theme }) => theme.spacing.lg}px;
  display: flex;
  justify-content: space-between;
`

const StyledHeader = styled.header`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
`

const ActionsLeft = styled.div``
const ActionsRight = styled.div`
  display: flex;
  /* IE11 min-width */
  min-width: 320px;

  && {
    > * {
      margin: 0 ${({ theme }) => theme.spacing.sm}px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const CharCount = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.main};
  text-align: right;
`

export interface TipFormValues {
  body: string
  tags: string
}

const sizeLimit = 150

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function TipForm({
  className,
  tip,
  onCancel,
  onSubmit,
}: {
  tip?: Tip
  className?: string
  onCancel: () => void
  onSubmit: (formValues: TipFormValues) => void
}) {
  const [value, setValue] = React.useState((tip && tip.body) || '')
  const [length, setLength] = React.useState(0)

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      body: (tip && tip.body) || '',
      tags: (tip && tip.tags) || 'ACTIVE',
    },
    validationSchema: Yup.object({
      body: Yup.string().required('A tip must have content'),
      tags: Yup.string().required('A tip must have tags associated'),
    }),
    onSubmit: (values) => {
      onSubmit(values)
    },
  })

  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      <StyledHeader>
        <DropdownFilter
          label="Tag:"
          value={values.tags}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange({
              target: { name: 'tags', value: e.target.value },
            })
          }>
          <MenuItemComponent color="primary" value={'ACTIVE'}>
            ACTIVE
          </MenuItemComponent>
          <MenuItemComponent color="primary" value={'CALM'}>
            CALM
          </MenuItemComponent>
          <MenuItemComponent color="primary" value={'RESTFUL'}>
            RESTFUL
          </MenuItemComponent>
          <MenuItemComponent color="primary" value={'INTERESTED'}>
            INTERESTED
          </MenuItemComponent>
          <MenuItemComponent color="primary" value={'CHEERFUL'}>
            CHEERFUL
          </MenuItemComponent>
        </DropdownFilter>
      </StyledHeader>
      <Editor
        id="body"
        textareaName="body"
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        init={{
          width: 450,
          menubar: false,
          plugins: 'wordcount',
          toolbar: 'wordcount',
        }}
        toolbar="bold"
        initialValue={(tip && tip.body) || ''}
        value={value}
        // eslint-disable-next-line @typescript-eslint/naming-convention
        onInit={(_evt, editor) => {
          setLength(editor.getContent({ format: 'text' }).length)
        }}
        onEditorChange={(value, editor) => {
          const length = editor.getContent({ format: 'text' }).length || 0
          if (length <= sizeLimit) {
            setValue(value)
            setLength(length)
            handleChange({ target: { name: 'body', value } })
          }
        }}
      />
      <CharCount variant="body2">
        <i>{length} / 150</i>
      </CharCount>
      <Actions>
        <ActionsLeft />
        <ActionsRight>
          <Button
            variant="outlined"
            type="button"
            color="primary"
            fullWidth
            size="small"
            onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            color="primary"
            size="small">
            Save changes
          </Button>
        </ActionsRight>
      </Actions>
    </form>
  )
}

export default TipForm
