import * as authActions from 'modules/authentication/actions'
import { isChnnlAdmin } from 'modules/authentication/selectors'
import { getEntitlements } from 'modules/entitlements/actions'
import * as organisationActions from 'modules/organisations/actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, withHandlers, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'

export default compose(
  withRouter,
  connect(
    // mapStateToProps
    (state, { match }) => ({
      isChnnlAdmin: isChnnlAdmin(state),
      organisation: state.organisations.entities[match.params.orgUuid],
    }),
    // mapDispatchToProps
    (dispatch) => {
      const actions = {
        ...authActions,
        ...organisationActions,
        getEntitlements,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  lifecycle({
    componentDidMount() {
      const {
        actions,
        match: {
          params: { orgUuid },
        },
        organisation,
      } = this.props

      // prevent re-loading organisation if already in state, only load if it does not exist
      // if organisation data is changed, the update action reloads organisation state any way
      if (!organisation) {
        actions.getOrganisation(orgUuid)
        actions.getEntitlements({ orgUuid })
      }
    },
  }),
  withHandlers({
    onLogout:
      ({ actions }) =>
      () => {
        actions.logout()
      },
  }),
)
