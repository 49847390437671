import Typography from '@weareroam/cake-ui-v1/Typography'
import ChevronRight from '@weareroam/cake-ui-v1-icons/ChevronRight'
import PartnerAdminHeader from 'components/molecules/PartnerAdminHeader'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { SideNavigationContainer } from '../SideNavigation/SideNavigationContainer'
import { SideNavigationContent } from '../SideNavigation/SideNavigationContent'

export const Breadcrumbs = styled.nav`
  background: ${({ theme }) => theme.palette.secondary.main};
  border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  height: 65px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
`

export const Breadcrumb = styled.span`
  color: ${({ theme }) => theme.palette.tertiary.dark};
  padding: 0;
  font-size: 14px;
`

export const CurrentPage = styled(Typography)`
  && {
    font-weight: ${({ theme }) => theme.weights.regular};
  }
`
export const StyledChevron = styled(ChevronRight)`
  margin: 0 ${({ theme }) => theme.spacing.sm}px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  position: absolute;
  top: 60px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 125vw;
    height: 65px;
    transform: translateX(-25vw);
    background-color: ${({ theme }) => theme.palette.secondary.main};
    border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  }
`

const StyledSideNavigationContainer = styled(SideNavigationContainer)`
  height: 100vh;
  background-color: #fafbfd;
`
export const Main = styled.div`
  display: flex;
  flex-grow: 1;
`
export const IEWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`
export const StyledSideNavigationContent = styled(SideNavigationContent)`
  padding: 0;
  overflow: auto;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function PartnerAdminLayout({ user, children, onLogout }) {
  return (
    <IEWrapper>
      <PartnerAdminHeader user={user} onLogout={onLogout} />
      <Main>
        <ContentContainer>
          <Breadcrumbs>
            <Breadcrumb>Partner</Breadcrumb>
            <StyledChevron />
            <CurrentPage variant="subtitle1">
              {user ? user.partner.title : 'Loading...'}
            </CurrentPage>
          </Breadcrumbs>
          <StyledSideNavigationContainer>
            <StyledSideNavigationContent>
              {children}
            </StyledSideNavigationContent>
          </StyledSideNavigationContainer>
        </ContentContainer>
      </Main>
    </IEWrapper>
  )
}

PartnerAdminLayout.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
  onLogout: PropTypes.func,
}

PartnerAdminLayout.defaultProps = {
  user: { partner: { title: '', logo: '', uuid: '' } },
}

export default PartnerAdminLayout
