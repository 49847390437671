import { PASSWORD_MIN_LENGTH, PASSWORD_REGEX } from 'config'
import { withFormik } from 'formik'
import { compose } from 'recompose'
import * as Yup from 'yup'

export default compose(
  withFormik({
    mapPropsToValues: () => ({ password: '', confirmPassword: '' }),
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Please enter a new password')
        .min(
          PASSWORD_MIN_LENGTH,
          `Your password must be at least ${PASSWORD_MIN_LENGTH} characters`,
        )
        .matches(
          PASSWORD_REGEX,
          'Your password must contain one uppercase, lowercase and special character',
        ),

      confirmPassword: Yup.string()
        .required('Please repeat your password')
        .oneOf([Yup.ref('password')], 'Your passwords do not match'),
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
      setSubmitting(true)
      // If form has no error
      if (props.onSubmit) {
        props.onSubmit(values)
      }
    },
  }),
)
