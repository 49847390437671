import { Typography } from '@weareroam/cake-ui-v1'
import Button from '@weareroam/cake-ui-v1/Button'
import Menu from '@weareroam/cake-ui-v1/Menu'
import ExpandMore from '@weareroam/cake-ui-v1-icons/ExpandMore'
import React, { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'

import { SupportOptionTypes } from '../../../constants/SupportOptionTypesEnum'
import { SupportOption } from '../../../modules/supportOption/schema'
import { SupportTrigger } from '../../../modules/supportTrigger/schema'
import { AreaSection } from '../../molecules/AreaSection/AreaSection'
import { ListItem } from '../../molecules/ListItem/ListItem'
import { ListItemAction } from '../../molecules/ListItemAction/ListItemAction'
import { ListItemActions } from '../../molecules/ListItemActions/ListItemActions'
import MenuItem from '../../molecules/MenuItem'
import { SupportOptionUpdateModal } from '../SupportOptionUpdateModal/SupportOptionUpdateModal'

export const StyledItemDescription = styled(Typography)`
  && {
    padding-left: ${({ theme }) => theme.spacing.sm}px;
  }
`

interface SupportTriggerCardProps {
  trigger: SupportTrigger
  title: string
  description: string
  isEditable?: boolean
  onAddOption: (item: SupportOption) => void
  onUpdateOption: (item: SupportOption) => void
  onDeleteOption: (item: SupportOption) => void
}

const defaultText: {
  [key in SupportOptionTypes]: {
    primaryActionText?: string
    placeholder: string
  }
} = {
  [SupportOptionTypes.JOURNAL]: {
    primaryActionText: 'Journal about your experience',
    placeholder: 'Prompt to journal',
  },
  [SupportOptionTypes.RESOURCES]: {
    placeholder: 'Show wellbeing resources',
  },
  [SupportOptionTypes.RESOURCES_LINK]: {
    placeholder: 'Link to the resources page',
  },
}

export function SupportTriggerCard({
  trigger,
  title,
  description,
  isEditable,
  onAddOption,
  onUpdateOption,
  onDeleteOption,
}: SupportTriggerCardProps): React.ReactElement {
  // Dropdown menu for "+ Add" option action
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const menuRef = useRef(null)
  const handleToggleMenu = useCallback(() => {
    setIsMenuOpen(!isMenuOpen)
  }, [isMenuOpen])

  const hasOptionFor = useCallback(
    (type) => trigger && trigger.options.some((e) => e.type === type),
    [trigger],
  )

  const [toEdit, setToEdit] = useState<SupportOption | undefined>(undefined)

  const actionEdit = useCallback(
    async (supportOption: SupportOption) => {
      setToEdit(undefined)
      onUpdateOption(supportOption)
    },
    [onUpdateOption],
  )
  const cancelEdit = () => setToEdit(undefined)

  const renderSupportOptions = ():
    | Array<React.ReactElement<typeof ListItem>>
    | React.ReactElement<typeof Typography> => {
    if (!trigger || !trigger.options.length) {
      return <Typography variant="body1">No options selected yet</Typography>
    }
    return trigger.options.map((item) => (
      <ListItem
        key={item.uuid || item.type}
        variant={'flat'}
        verticallyCentered={true}
        columns={[
          {
            contents: (
              <StyledItemDescription variant="body1">
                {item.primaryActionText ||
                  item.title ||
                  item.message ||
                  defaultText[item.type].placeholder}
              </StyledItemDescription>
            ),
            width: 90,
          },
          {
            contents: !isEditable ? null : (
              <ListItemActions>
                <ListItemAction icon={'edit'} onClick={() => setToEdit(item)} />
                <ListItemAction
                  icon={'delete'}
                  onClick={() => onDeleteOption(item)}
                />
              </ListItemActions>
            ),
            alignment: 'flex-end',
            width: 10,
          },
        ]}
      />
    ))
  }

  return (
    <AreaSection
      heading={title}
      subHeading={description}
      headerActions={
        <>
          {isEditable === true && (
            <Button
              buttonRef={menuRef}
              type="button"
              variant="text"
              color="primary"
              onClick={handleToggleMenu}>
              + Add <ExpandMore />
            </Button>
          )}
          <Menu
            open={isMenuOpen}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            getContentAnchorEl={null}
            anchorEl={menuRef.current}
            onClose={handleToggleMenu}>
            <MenuItem
              onClick={() => {
                const item = {
                  uuid: window.crypto.randomUUID(),
                  type: SupportOptionTypes.JOURNAL,
                  primaryActionText:
                    defaultText[SupportOptionTypes.JOURNAL].primaryActionText,
                }
                onAddOption(item)
                setIsMenuOpen(false)
                setToEdit(item)
              }}
              disabled={hasOptionFor(SupportOptionTypes.JOURNAL)}
              className={null}
              color="quinary"
              value={null}>
              Prompt to journal
            </MenuItem>
            <MenuItem
              onClick={() => {
                const item = {
                  uuid: window.crypto.randomUUID(),
                  type: SupportOptionTypes.RESOURCES,
                  primaryActionText:
                    defaultText[SupportOptionTypes.RESOURCES].primaryActionText,
                }
                onAddOption(item)
                setIsMenuOpen(false)
                setToEdit(item)
              }}
              disabled={hasOptionFor(SupportOptionTypes.RESOURCES)}
              className={null}
              color="quinary"
              value={null}>
              Selection of resources
            </MenuItem>
            <MenuItem
              onClick={() => {
                const item = {
                  uuid: window.crypto.randomUUID(),
                  type: SupportOptionTypes.RESOURCES_LINK,
                  primaryActionText:
                    defaultText[SupportOptionTypes.RESOURCES_LINK]
                      .primaryActionText,
                }
                onAddOption(item)
                setIsMenuOpen(false)
                setToEdit(item)
              }}
              disabled={hasOptionFor(SupportOptionTypes.RESOURCES_LINK)}
              className={null}
              color="quinary"
              value={null}>
              Resources page
            </MenuItem>
          </Menu>
        </>
      }>
      {renderSupportOptions()}
      {/* TODO: Render app functions first at the top of the list. Add reordering to booster resources. */}
      {/*<DragDropList onDragEnd={onDragEnd}>*/}
      {/*  {(provided) => <>{provided.placeholder}</>}*/}
      {/*</DragDropList>*/}
      {toEdit && (
        <SupportOptionUpdateModal
          open={true}
          triggerType={trigger.type}
          supportOption={toEdit}
          onCancel={() => cancelEdit()}
          onSubmit={(supportOption) => actionEdit(supportOption)}
        />
      )}
    </AreaSection>
  )
}
