// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: Types for DropdownButton are broken badly. Need to convert them to TS.

import {
  MOOD_OVERALL,
  MOOD_CHEERFUL,
  MOOD_CALM,
  MOOD_ACTIVE,
  MOOD_RESTED,
  MOOD_INTERESTED,
  Moods,
} from 'constants/form'

import Typography from '@weareroam/cake-ui-v1/Typography'
import Box from 'components/molecules/Box'
import DropdownButton from 'components/molecules/DropdownButton'
import MenuItem from 'components/molecules/MenuItem'
import WellbeingBarChart from 'components/organisms/WellbeingBarChart'
import WellbeingLineChart from 'components/organisms/WellbeingLineChart'
import { format } from 'date-fns'
import React from 'react'
import styled from 'styled-components'

import { ReportErrorType } from '../../../constants/reports'
import { WellbeingBarItem } from '../../../hooks/useWellbeingReport'
import { WellbeingLineItem } from '../../../hooks/useWellbeingSixMonthsReport'

export const GraphBoxTitle = styled(Typography)`
  && {
    display: flex;
    align-items: center;
  }
`
export const DateText = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const Graphs = styled(Box)`
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`

export const GraphBoxContent = styled.div`
  display: flex;
`

export const LeftGraphBox = styled.div`
  border-right: 1px solid ${({ theme }) => theme.palette.tertiary.light};
  width: 50%;
  padding: ${({ theme }) => theme.spacing.lg}px;
`
export const LeftText = styled.div`
  display: flex;
  align-items: center;
`
export const RightGraphBox = styled.div`
  width: 50%;
  padding: ${({ theme }) => theme.spacing.lg}px;
`

export const GraphText = styled(Typography)`
  && {
    display: inline;
    color: ${({ theme }) => theme.palette.tertiary.dark};
    font-weight: ${({ theme }) => theme.weights.bold};
  }
`

export const MoodText = styled.span`
  color: ${({ theme }) => theme.palette.text.primary};
`

export const StyledLineChart = styled(WellbeingLineChart)`
  margin-top: ${({ theme }) => theme.spacing.xl}px;
  margin-left: -${({ theme }) => theme.spacing.lg}px;
`

export const StyledBarChart = styled(WellbeingBarChart)`
  margin-top: ${({ theme }) => theme.spacing.xl}px;
  margin-left: -${({ theme }) => theme.spacing.lg}px;
`

export const StyledMoodSelect = styled(DropdownButton)`
  margin: 0 ${({ theme }) => theme.spacing.sm}px;
`

interface Props {
  title: string
  periodDescription: string
  data: {
    period?: WellbeingBarItem[]
    sixMonths?: WellbeingLineItem[]
  }
  loading: {
    period: boolean
    sixMonths: boolean
  }
  errors: {
    period?: ReportErrorType
    sixMonths?: ReportErrorType
  }
  mood: Moods
  onChangeMood: (val: Moods) => void
}

export function WellbeingReport({
  title,
  periodDescription,
  data,
  mood,
  onChangeMood,
}: Props): React.ReactElement {
  return (
    <Graphs
      headerContent={
        <GraphBoxTitle variant="h4" component="div">
          {title}
          <DateText variant="h4" component="span">
            &nbsp;for {periodDescription}
          </DateText>
        </GraphBoxTitle>
      }>
      <GraphBoxContent>
        <LeftGraphBox>
          <LeftText>
            <GraphText variant="body1">Individual</GraphText>
            <StyledMoodSelect
              name="mood"
              value={mood}
              onChange={(event) => {
                onChangeMood(event.target.value)
              }}
              size="small"
              multiple={false}
              variant="outlined">
              <MenuItem color="primary" value={MOOD_OVERALL}>
                {MOOD_OVERALL}
              </MenuItem>
              <MenuItem color="primary" value={MOOD_CHEERFUL}>
                {MOOD_CHEERFUL}
              </MenuItem>
              <MenuItem color="primary" value={MOOD_CALM}>
                {MOOD_CALM}
              </MenuItem>
              <MenuItem color="primary" value={MOOD_ACTIVE}>
                {MOOD_ACTIVE}
              </MenuItem>
              <MenuItem color="primary" value={MOOD_RESTED}>
                {MOOD_RESTED}
              </MenuItem>
              <MenuItem color="primary" value={MOOD_INTERESTED}>
                {MOOD_INTERESTED}
              </MenuItem>
            </StyledMoodSelect>
            <GraphText variant="body1">score for the period</GraphText>
          </LeftText>

          {data.period?.length > 0 && (
            <StyledBarChart
              xAxisLabel="Wellbeing score"
              yAxisLabel="People"
              data={data.period.map((point) => ({
                name: point.label,
                value: point.totalUsers,
              }))}
              className
            />
          )}
        </LeftGraphBox>
        <RightGraphBox>
          <GraphText variant="body1">
            Monthly average <MoodText>{mood}</MoodText> score over last 6 months
          </GraphText>
          {data.sixMonths?.length > 0 && (
            <StyledLineChart
              xAxisLabel="Month"
              yAxisLabel="Wellbeing score"
              data={data.sixMonths.map((point) => ({
                name: format(point.referToMonth || point.relativedate, 'MMM'),
                value:
                  point.averageMood !== undefined
                    ? point.averageMood
                    : point.averagemood,
                members:
                  point.totalUsers !== undefined
                    ? point.totalUsers
                    : point.totalusers,
              }))}
            />
          )}
        </RightGraphBox>
      </GraphBoxContent>
    </Graphs>
  )
}
