import Button from '@weareroam/cake-ui-v1/Button'
import TextField from '@weareroam/cake-ui-v1/TextField'
import Typography from '@weareroam/cake-ui-v1/Typography'
import Radio from 'components/atoms/Radio'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledFields = styled.fieldset`
  margin: 32px 0 16px;
  text-align: left;
`
export const StyledEmail = styled(TextField)`
  && {
    margin-bottom: 18px;
  }
`

export const StyledFirstName = styled(TextField)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.xl}px;
    width: 160px;
  }
`
export const StyledLastName = styled(TextField)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.xl}px;
    width: 160px;
    margin-left: 40px;
  }
`

export const StyledRadio = styled(Radio)`
  && {
    height: 35px;
  }
`

export const StyledFieldRow = styled.div`
  width: 100%;
`

export const Actions = styled.footer`
  display: flex;
  justify-content: center;
`

export const ActionButton = styled(Button)`
  width: 140px;
  && {
    margin: 0 ${({ theme }) => theme.spacing.sm}px;
  }
`

export const BackButton = styled(Button)`
  && {
    position: absolute;
    top: ${({ theme }) => theme.spacing.md}px;
    left: ${({ theme }) => theme.spacing.sm}px;
  }
`

export const StyledForm = styled.form`
  padding-bottom: 0px;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function InviteNewPartnerAdminForm({
  handleSubmit,
  className,
  values,
  errors,
  handleBlur,
  handleChange,
  title,
  touched,
  onCancel,
}) {
  return (
    <StyledForm
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      <div>
        <Typography variant="h2">{title}</Typography>
        <StyledFields>
          <StyledEmail
            id="email"
            name="email"
            label="Email address"
            type="email"
            value={values.email}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.email && !!errors.email}
            helperText={touched.email ? errors.email : ''}
            fullWidth
          />
          <StyledFieldRow>
            <StyledFirstName
              id="firstName"
              name="firstName"
              label="First name"
              type="text"
              value={values.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.firstName && !!errors.firstName}
              helperText={touched.firstName ? errors.firstName : ''}
            />
            <StyledLastName
              id="lastName"
              name="lastName"
              label="Last name"
              type="text"
              value={values.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.lastName && !!errors.lastName}
              helperText={touched.lastName ? errors.lastName : ''}
            />
          </StyledFieldRow>
        </StyledFields>
      </div>

      <Actions>
        <ActionButton
          variant="outlined"
          size="small"
          type="button"
          color="primary"
          onClick={onCancel}>
          Cancel
        </ActionButton>
        <ActionButton
          size="small"
          variant="contained"
          type="submit"
          color="primary">
          Send invite
        </ActionButton>
      </Actions>
    </StyledForm>
  )
}

InviteNewPartnerAdminForm.propTypes = {
  handleSubmit: PropTypes.func,
  className: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  title: PropTypes.string,
  touched: PropTypes.object,
  onCancel: PropTypes.func,
}

InviteNewPartnerAdminForm.defaultProps = {
  errors: {},
  values: {},
  touched: {},
  groups: [],
}

export default InviteNewPartnerAdminForm
