import FaceHappy from 'components/atoms/Icons/FaceHappy'
import FaceNeutral from 'components/atoms/Icons/FaceNeutral'
import FaceUpset from 'components/atoms/Icons/FaceUpset'
import CustomTooltip from 'components/molecules/Tooltip'
import TooltipItem from 'components/molecules/TooltipItem'
import idx from 'idx'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  ResponsiveContainer,
  Cell,
  CartesianGrid,
} from 'recharts'
import styled from 'styled-components'
import theme from 'styles/theme'

export const StyledContainer = styled(ResponsiveContainer)`
  svg {
    overflow: visible;
  }
`
export const Faces = styled.div`
  position: absolute;
  bottom: 102.5%;
  left: 0;
  padding-left: 70px;
  padding-right: 30px;
  width: 100%;
  display: flex;
  align-items: center;

  > * {
    width: 20%;
  }
`
export const Face = styled.div`
  text-align: center;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.2)};
`

export const StyledRoot = styled.div`
  position: relative;
`

export const BarColors = [
  theme.palette.quaternary.xlight,
  theme.palette.quaternary.light,
  theme.palette.quaternary.main,
  theme.palette.quaternary.dark,
  theme.palette.quaternary.xdark,
]

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function WellbeingBarChart({ xAxisLabel, yAxisLabel, data, className }) {
  const [activeFaceIndex, setActiveFaceIndex] = useState(null)

  return (
    <StyledRoot>
      <Faces>
        {/* Disabling faces as they don't align with buckets.*/}
        <Face isActive={activeFaceIndex === -1}>
          <FaceUpset />
        </Face>
        <Face isActive={activeFaceIndex === -1}>
          <FaceUpset />
        </Face>
        <Face isActive={activeFaceIndex === -1}>
          <FaceNeutral />
        </Face>
        <Face isActive={activeFaceIndex === -1}>
          <FaceHappy />
        </Face>
        <Face isActive={activeFaceIndex === -1}>
          <FaceHappy />
        </Face>
      </Faces>
      <StyledContainer width="100%" height={300} className={className}>
        <BarChart data={data} margin={{ left: 0 }} barCategoryGap={2}>
          <CartesianGrid horizontal={false} strokeWidth={0.2} />
          <XAxis
            stroke={theme.palette.tertiary.main}
            dataKey="name"
            tickSize={0}
            tickMargin={10}
            height={50}>
            <Label
              fill={theme.palette.tertiary.main}
              value={xAxisLabel}
              offset={0}
              position="insideBottomLeft"
            />
          </XAxis>

          <YAxis
            stroke={theme.palette.tertiary.main}
            tickSize={0}
            tickFormatter={(val) =>
              Math.floor(val) === 0 ? '' : Math.floor(val)
            }
            tickMargin={10}
            width={110}>
            <Label
              value={yAxisLabel}
              offset={30}
              position="insideTop"
              angle={-90}
              fill={theme.palette.tertiary.main}
            />
          </YAxis>

          <Tooltip
            cursor={{ fill: 'transparent' }}
            content={(values) => {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              const members = idx(values, (_) => _.payload[0].value)

              return (
                <CustomTooltip>
                  <TooltipItem value={members} suffix="member(s)" />
                </CustomTooltip>
              )
            }}
          />

          <Bar
            type="monotone"
            dataKey="value"
            fill={theme.palette.quaternary.main}>
            {data.map((entry, index) => (
              <Cell
                key={index}
                onMouseOver={() => setActiveFaceIndex(index)}
                onMouseLeave={() => setActiveFaceIndex(null)}
                fill={BarColors[Math.min(index, BarColors.length - 1)]}
              />
            ))}
          </Bar>
        </BarChart>
      </StyledContainer>
    </StyledRoot>
  )
}

WellbeingBarChart.propTypes = {
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
}

WellbeingBarChart.defaultProps = {
  data: [],
}

export default WellbeingBarChart
