import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import ProgressLoader from 'components/atoms/ProgressLoader'
import RowButton from 'components/atoms/RowButton'
import Box from 'components/molecules/Box'
import { Modal } from 'components/molecules/Modal'
import PathwayContent from 'components/molecules/PathwayContent'
import PathwayHeader from 'components/molecules/PathwayHeader'
import PathwayItem from 'components/molecules/PathwayItem'
import ConfirmUpdatesForm from 'components/organisms/ConfirmUpdatesForm'
import EditPathwayForm from 'components/organisms/EditPathwayForm'
import NavigationPromptForm from 'components/organisms/NavigationPromptForm'
import PathwayItemForm from 'components/organisms/PathwayItemForm'
import { deepEqual } from 'fast-equals'
import idx from 'idx'
import PropTypes from 'prop-types'
import React, { useCallback, useState, useMemo } from 'react'
import NavigationPrompt from 'react-router-navigation-prompt'
import styled from 'styled-components'

export const StyledForm = styled.form``

export const UpdateButton = styled(Button)`
  width: 140px;
`

export const ConfirmUpdatesModal = styled(Modal)`
  width: 480px;
`

export const StyledHeader = styled.header`
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`

export const HeaderLeft = styled.div`
  flex-grow: 1;
`

export const DescriptionText = styled(Typography)`
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

export const EditPathwayModal = styled(Modal)`
  width: 500px;
`

export const PathwayItemModal = styled(Modal)`
  width: 500px;
`

export const PathwayBox = styled(Box)`
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
`

export const AddButton = styled(RowButton)`
  border: 0;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function PathwaysForm({
  handleSubmit,
  className,
  touched,
  errors,
  values,
  disableEditting,
  setIsConfirmUpdateModalOpen,
  isConfirmUpdateModalOpen,
  progress,
  onResetPathway,
  setFieldValue,
  pathways,
}) {
  const handleCloseConfirmationModal = useCallback(
    () => setIsConfirmUpdateModalOpen(false),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const [isEditPathwayModalOpen, setIsEditPathwayModalOpen] = useState(false)
  const [isPathwayItemModalOpen, setIsPathwayItemModalOpen] = useState(false)
  // These indexes are used to track which pathway is being modified when using modals
  const [editPathwayIndex, setEditPathwayIndex] = useState(null)
  const [editPathwayItemIndex, setEditPathwayItemIndex] = useState(null)

  const handleCloseEditPathwayModal = useCallback(() => {
    setIsEditPathwayModalOpen(false)
    setEditPathwayIndex(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpenEditPathwayModal = useCallback((pathwayIndex) => {
    setIsEditPathwayModalOpen(true)
    setEditPathwayIndex(pathwayIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClosePathwayItemModal = useCallback(() => {
    setIsPathwayItemModalOpen(false)
    setEditPathwayIndex(null)
    setEditPathwayItemIndex(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpenPathwayItemModal = useCallback(
    ({ pathwayIndex, pathwayItemIndex }) => {
      setIsPathwayItemModalOpen(true)
      setEditPathwayIndex(pathwayIndex)
      setEditPathwayItemIndex(pathwayItemIndex)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  // This edits an existing pathway
  const handleEditPathway = useCallback(
    (form) => {
      setFieldValue('pathways', [
        ...values.pathways.slice(0, editPathwayIndex),
        {
          ...values.pathways[editPathwayIndex],
          ...form,
        },
        ...values.pathways.slice(editPathwayIndex + 1),
      ])

      handleCloseEditPathwayModal()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editPathwayIndex, values],
  )

  // This deletes a pathway item
  const handleDeletePathwayItem = useCallback(
    ({ pathwayIndex, pathwayItemIndex }) => {
      setFieldValue(
        'pathways',
        values.pathways.map((pathway, index) => {
          if (index === pathwayIndex) {
            return {
              ...pathway,
              items: pathway.items.filter(
                (item, itemIndex) => itemIndex !== pathwayItemIndex,
              ),
            }
          }

          return pathway
        }),
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values],
  )

  // This adds a new pathway item
  const handleAddPathwayItem = useCallback(
    (form) => {
      setFieldValue('pathways', [
        ...values.pathways.slice(0, editPathwayIndex),
        {
          ...values.pathways[editPathwayIndex],
          items: [
            ...values.pathways[editPathwayIndex].items,
            {
              ...form,
            },
          ],
        },
        ...values.pathways.slice(editPathwayIndex + 1),
      ])

      handleClosePathwayItemModal()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values, editPathwayIndex],
  )

  // This edits a pathway item
  const handleEditPathwayItem = useCallback(
    (form) => {
      setFieldValue(
        'pathways',
        values.pathways.map((pathway, pathwayIndex) => {
          if (pathwayIndex === editPathwayIndex) {
            return {
              ...pathway,
              items: pathway.items.map((item, itemIndex) => {
                if (itemIndex === editPathwayItemIndex) {
                  return {
                    ...item,
                    ...form,
                  }
                }

                return item
              }),
            }
          }

          return pathway
        }),
      )

      handleClosePathwayItemModal()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editPathwayIndex, editPathwayItemIndex, values],
  )

  // This checks if the user is adding a new item or editing an existing one and passes to the corresponding callback
  const handlePathwayItemSubmit = useCallback(
    (form) => {
      if (Number.isInteger(editPathwayItemIndex)) {
        handleEditPathwayItem(form)
      } else {
        handleAddPathwayItem(form)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editPathwayItemIndex],
  )

  const hasUnsavedChanges = useMemo(
    () => !deepEqual(values.pathways, pathways),
    [values.pathways, pathways],
  )

  return (
    <StyledForm
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      <NavigationPrompt when={hasUnsavedChanges}>
        {({ onConfirm, onCancel }) => (
          <Modal open>
            <NavigationPromptForm onLeave={onConfirm} onStay={onCancel} />
          </Modal>
        )}
      </NavigationPrompt>
      <ConfirmUpdatesModal
        open={isConfirmUpdateModalOpen}
        onClose={handleCloseConfirmationModal}>
        <ConfirmUpdatesForm
          onCancel={handleCloseConfirmationModal}
          onSubmit={handleSubmit}
          title="Confirm pathway updates"
          content={
            <React.Fragment>
              All pathway changes will be <br /> updated and visible in the app.
            </React.Fragment>
          }
        />
      </ConfirmUpdatesModal>

      <EditPathwayModal
        open={isEditPathwayModalOpen}
        onClose={handleCloseEditPathwayModal}
        hasCloseButton>
        <EditPathwayForm
          // eslint-disable-next-line @typescript-eslint/naming-convention
          initialValues={idx(values, (_) => _.pathways[editPathwayIndex])}
          onSubmit={handleEditPathway}
          onCancel={handleCloseEditPathwayModal}
        />
      </EditPathwayModal>

      <PathwayItemModal
        open={isPathwayItemModalOpen}
        onClose={handleClosePathwayItemModal}
        hasCloseButton>
        <PathwayItemForm
          initialValues={idx(
            values,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            (_) => _.pathways[editPathwayIndex].items[editPathwayItemIndex],
          )}
          inEditMode={Number.isInteger(editPathwayItemIndex)}
          onSubmit={handlePathwayItemSubmit}
          onCancel={handleClosePathwayItemModal}
        />
      </PathwayItemModal>

      <StyledHeader>
        <HeaderLeft>
          <Typography variant="h2">Pathways</Typography>
          <DescriptionText variant="body1">
            Pathways are messages that will appear in the app in <br />
            response to your employee&apos;s selected W.H.O 5 score.
          </DescriptionText>
        </HeaderLeft>
        {!disableEditting && (
          <UpdateButton
            variant="contained"
            type="submit"
            color="primary"
            size="small"
            disabled={!hasUnsavedChanges}>
            Update
          </UpdateButton>
        )}
      </StyledHeader>

      {progress.inProgress ? (
        <ProgressLoader fullWidth />
      ) : (
        values.pathways &&
        values.pathways.map((pathway, pathwayIndex) => {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const isTouched = idx(touched, (_) => _.pathways[pathwayIndex])
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const pathwayErrors = idx(errors, (_) => _.pathways[pathwayIndex])

          return (
            <PathwayBox
              key={pathway.uuid}
              footerContent={
                disableEditting ? null : (
                  <AddButton
                    onClick={handleOpenPathwayItemModal}
                    value={{ pathwayIndex }}>
                    + Add item
                  </AddButton>
                )
              }
              errors={
                isTouched && pathwayErrors ? Object.values(pathwayErrors) : []
              }>
              <PathwayHeader
                variant={pathway.heading}
                title={pathway.heading}
                message={pathway.subheading}
                onReset={disableEditting ? null : onResetPathway}
                value={pathway.uuid}
              />
              <PathwayContent
                title={pathway.title}
                message={pathway.message}
                onEdit={disableEditting ? null : handleOpenEditPathwayModal}
                value={pathwayIndex}
                disabled={disableEditting}
              />
              {pathway.items.map((item, pathwayItemIndex) => (
                <PathwayItem
                  key={item.uuid}
                  fullName={item.fullName}
                  position={item.position}
                  contactNumber={item.contactNumber}
                  extension={item.extension}
                  email={item.email}
                  website={item.website}
                  description={item.description}
                  value={{
                    pathwayIndex,
                    pathwayItemIndex,
                  }}
                  disabled={disableEditting}
                  onEdit={handleOpenPathwayItemModal}
                  onDelete={handleDeletePathwayItem}
                />
              ))}
            </PathwayBox>
          )
        })
      )}
    </StyledForm>
  )
}

PathwaysForm.propTypes = {
  handleSubmit: PropTypes.func,
  onResetPathway: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  className: PropTypes.string,
  progress: PropTypes.object,
  disableEditting: PropTypes.bool,
  setIsConfirmUpdateModalOpen: PropTypes.func,
  isConfirmUpdateModalOpen: PropTypes.bool,
  setFieldValue: PropTypes.func,
  pathways: PropTypes.arrayOf(PropTypes.object),
}

PathwaysForm.defaultProps = {
  values: {},
  errors: {},
  touched: {},
  progress: {},
}

export default PathwaysForm
