import { withFormik } from 'formik'
import { compose, withState, withHandlers, defaultProps } from 'recompose'
import { required } from 'utils/validation'

export default compose(
  defaultProps({
    initialValues: {},
  }),
  withState('newCountryIsoCode', 'setNewCountryIsoCode', null),
  withFormik({
    enableReinitialize: true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    mapPropsToValues: ({ initialValues }) => ({}),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    validate: ({ newCountryIsoCode }, { countries }) => {
      const errors = {}

      if (required(newCountryIsoCode)) {
        errors.newCountry = 'Please select a country'
      }

      return errors
    },
    handleSubmit: (values, { props: { onSubmit }, setSubmitting }) => {
      setSubmitting(true)
      // pass values to our onSubmit prop
      if (onSubmit) {
        onSubmit({
          ...values,
        })
      }
    },
  }),
  withHandlers({
    toggleNewCountryRadio:
      ({ setFieldValue }) =>
      (newCountryIsoCode) => {
        setFieldValue('newCountryIsoCode', newCountryIsoCode)
      },
  }),
)
