import React from 'react'
import styled from 'styled-components'

interface RoundedPanelProps {
  children?: React.ReactNode
}

const StyledPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: white;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing.mdlg}px;
`

export function RoundedPanel({
  children,
}: RoundedPanelProps): React.ReactElement {
  return <StyledPanel>{children}</StyledPanel>
}
