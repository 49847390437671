import Button from '@weareroam/cake-ui-v1/Button'
import TextField from '@weareroam/cake-ui-v1/TextField'
import Typography from '@weareroam/cake-ui-v1/Typography'
import Dropzone from 'components/molecules/Dropzone'
import FilePreview from 'components/molecules/FilePreview'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledForm = styled.form`
  && {
    text-align: left;
  }
`

export const StyledName = styled(TextField)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.xl}px;
  }
`

export const StyledUploadTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`

export const StyledDropzone = styled(Dropzone)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

export const StyledFilePreview = styled(FilePreview)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function OrganisationOnboardingForm({
  className,
  handleSubmit,
  errors,
  values,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  uploadTotal,
  uploadProgress,
  onImageUploadError,
}) {
  return (
    <StyledForm
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      <StyledName
        id="organisationName"
        name="organisationName"
        label="Organisation name"
        type="text"
        value={values.organisationName}
        onBlur={handleBlur}
        onChange={handleChange}
        error={touched.organisationName && !!errors.organisationName}
        helperText={touched.organisationName ? errors.organisationName : ''}
        fullWidth
      />
      <StyledUploadTitle variant="body1">
        Upload organisation logo
      </StyledUploadTitle>

      <StyledDropzone
        accept={['image/png', 'image/jpeg']}
        onDrop={(drop) => {
          if (drop[0]) {
            setFieldValue('logo', drop[0])
          } else {
            onImageUploadError()
          }
        }}
        name="logo"
      />

      {values.logo && (
        <StyledFilePreview
          name={values.logo.name}
          total={uploadTotal}
          progress={uploadProgress}
        />
      )}

      <Button
        variant="contained"
        type="submit"
        fullWidth
        color="primary"
        size="large"
        id="continue-to-dashboard-button">
        Continue to dashboard
      </Button>
    </StyledForm>
  )
}

OrganisationOnboardingForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  className: PropTypes.string,
  uploadTotal: PropTypes.number,
  uploadProgress: PropTypes.number,
  onImageUploadError: PropTypes.func,
}

OrganisationOnboardingForm.defaultProps = {
  errors: {},
  values: {},
  touched: {},
}

export default OrganisationOnboardingForm
