import Button from '@weareroam/cake-ui-v1/Button'
import Typography from '@weareroam/cake-ui-v1/Typography'
import ProgressLoader from 'components/atoms/ProgressLoader'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledActions = styled.div`
  display: flex;
  justify-content: center;
`

export const ActionButton = styled(Button)`
  width: 50%;

  && {
    margin: 0 ${({ theme }) => theme.spacing.sm}px;
  }
`

export const ResendTitle = styled(Typography)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ResendMultiMemberInvitationsForm({
  onSubmit,
  onCancel,
  numSelected,
  progress,
}) {
  const inProgress = progress.inProgress
  return (
    <>
      <ResendTitle variant="h4">
        {numSelected === 0
          ? 'Please select at least one member to invite'
          : `Are you sure you would like to resend invitations to ${numSelected}
          member${numSelected > 1 ? 's' : ''} of this group?`}
      </ResendTitle>
      <StyledActions>
        <ActionButton
          variant="outlined"
          type="button"
          size="small"
          color="primary"
          onClick={onCancel}>
          Cancel
        </ActionButton>
        <ActionButton
          variant="contained"
          size="small"
          color="primary"
          onClick={onSubmit}
          disabled={numSelected === 0 || inProgress}>
          {inProgress ? <ProgressLoader size={18} /> : 'Confirm'}
        </ActionButton>
      </StyledActions>
    </>
  )
}

ResendMultiMemberInvitationsForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  progress: PropTypes.object,
  numSelected: PropTypes.number,
}

export default ResendMultiMemberInvitationsForm
