import axios from 'axios'
import { useState, useEffect, useCallback } from 'react'

import { SAUCE_API_URL } from '../config'
import { ReportErrorType } from '../constants/reports'
import { TeamNotice } from '../modules/teamNotice/schema'

export const useTeamNotices = ({
  orgUuid,
}: {
  orgUuid: string
}): {
  data: TeamNotice[]
  isLoading: boolean
  error?: ReportErrorType
  handlers: {
    save: (notice: Partial<TeamNotice>) => Promise<void>
    remove: (noticeUuid: string) => Promise<void>
  }
} => {
  const [data, setData] = useState<TeamNotice[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<ReportErrorType | undefined>(undefined)

  const fetchTeamNotices = useCallback(async () => {
    setIsLoading(true)
    setError(undefined)
    try {
      const response = await axios.get(
        `${SAUCE_API_URL}/organisation/${orgUuid}/notice`,
        {
          params: {},
        },
      )
      const data = response.data.data[0].output
      setData(data)
    } catch (error: unknown) {
      setError(ReportErrorType.GENERIC)
    }
    setIsLoading(false)
  }, [setData, setIsLoading, setError, orgUuid])

  useEffect(() => {
    fetchTeamNotices()
  }, [fetchTeamNotices])

  const save = async (notice: Partial<TeamNotice>) => {
    setIsLoading(true)
    setError(undefined)

    const toSave = { ...notice, groupUuids: notice.groups?.map((e) => e.uuid) }
    delete toSave.groups
    try {
      if (notice.uuid) {
        await axios.put(`${SAUCE_API_URL}/notice/${notice.uuid}`, toSave)
      } else {
        await axios.post(
          `${SAUCE_API_URL}/organisation/${orgUuid}/notice/`,
          toSave,
        )
      }
      await fetchTeamNotices()
    } catch (error: unknown) {
      setError(ReportErrorType.GENERIC)
    }
    setIsLoading(false)
  }

  const remove = async (noticeUuid: string) => {
    setIsLoading(true)
    setError(undefined)
    try {
      await axios.delete(`${SAUCE_API_URL}/notice/${noticeUuid}`, {})
      await fetchTeamNotices()
    } catch (error: unknown) {
      setError(ReportErrorType.GENERIC)
    }
    setIsLoading(false)
  }

  return {
    data,
    isLoading,
    error,
    handlers: {
      save,
      remove,
    },
  }
}
