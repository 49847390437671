import Button from '@weareroam/cake-ui-v1/Button'
import TextField from '@weareroam/cake-ui-v1/TextField'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const StyledForm = styled.form`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin: ${({ theme }) => theme.spacing.lg}px 0
    ${({ theme }) => theme.spacing.xl}px;
`

export const StyledName = styled(TextField)`
  width: 320px;
`

export const StyledButton = styled(Button)`
  width: 140px;

  && {
    margin-left: ${({ theme }) => theme.spacing.md}px;
  }
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function EditGroupNameForm({
  className,
  handleSubmit,
  values,
  errors,
  touched,
  disabled,
  handleChange,
  handleBlur,
}) {
  return (
    <StyledForm
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className={className}>
      <StyledName
        id="name"
        name="name"
        label="Group name"
        type="text"
        disabled={disabled}
        value={values.name || ''}
        onBlur={handleBlur}
        onChange={handleChange}
        error={touched.name && !!errors.name}
        helperText={touched.name ? errors.name : ''}
      />
      {!disabled && (
        <StyledButton
          variant="contained"
          type="submit"
          size="small"
          color="primary">
          Update
        </StyledButton>
      )}
    </StyledForm>
  )
}

EditGroupNameForm.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  disabled: PropTypes.bool,
}

EditGroupNameForm.defaultProps = {
  errors: {},
  values: {},
  touched: {},
}

export default EditGroupNameForm
