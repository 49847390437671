import * as authActions from 'modules/authentication/actions'
import * as organisationsActions from 'modules/organisations/actions'
import { getOrganisationsList } from 'modules/organisations/selectors'
import * as partnerOrganisationsActions from 'modules/partnerOrganisations/actions'
import {
  getOrganisationsLinkedToPartnerList,
  getPartnerlessOrganisations,
} from 'modules/partnerOrganisations/selectors'
import * as partnersActions from 'modules/partners/actions'
import { connect } from 'react-redux'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'

import { PartnersConstants } from '../../../../constants/PartnersConstantsEnum'

export default compose(
  connect(
    (state, { match }) => ({
      partnerOrganisations: getOrganisationsLinkedToPartnerList(
        state,
        match.params.partnerUuid,
      ),
      partnerOrganisationEntities: state.partnerOrganisations.entities,
      getPartnerOrganisationProgress: state.progress.partnerOrganisations,
      partners: state.partners.entities,
      allOrganisations: getOrganisationsList(state),
      partnerlessOrganisations: getPartnerlessOrganisations(state),
    }),
    (dispatch) => {
      const actions = {
        ...authActions,
        ...partnerOrganisationsActions,
        ...organisationsActions,
        ...partnersActions,
      }

      return {
        actions: bindActionCreators(actions, dispatch),
      }
    },
  ),
  withState(
    'isNewOrganisationModalOpen',
    'setIsNewOrganisationModalOpen',
    false,
  ),
  withState(
    'isLinkExistingOrganisationToPartnerModalOpen',
    'setIsLinkExistingOrganisationToPartnerModalOpen',
    false,
  ),
  withState(
    'isUnlinkOrganisationFromPartnerModalOpen',
    'setIsUnlinkOrganisationFromPartnerModalOpen',
    false,
  ),
  withState(
    'isAddNewOrganisationToPartnerModalOpen',
    'setIsAddNewOrganisationToPartnerModalOpen',
    false,
  ),
  withState(
    'modalPartnerOrganisationId',
    'setModalPartnerOrganisationId',
    null,
  ),
  lifecycle({
    componentDidMount() {
      const { match } = this.props
      // getPartners() is required to form a reliable list of organisations not linked to a partner
      this.props.actions.getPartners()
      this.props.actions.getPartnerOrganisations(match.params.partnerUuid)
      this.props.actions.getOrganisations()
    },
  }),
  withHandlers({
    onCreateNewPartnerOrganisation:
      ({ actions, setIsAddNewOrganisationToPartnerModalOpen }) =>
      ({
        name,
        email,
        incidentsContactName,
        incidentsContactEmail,
        numberOfSeats,
        industry,
        country,
        partnerUuid,
      }) => {
        const selectedPartner =
          partnerUuid === PartnersConstants.NO_PARTNER_SELECTED
            ? null
            : partnerUuid

        actions.createOrganisation({
          name,
          orgOwnerEmail: email,
          incidentsContactName,
          incidentsContactEmail,
          seats: numberOfSeats,
          industry,
          country,
          partnerUuid: selectedPartner,
        })

        setIsAddNewOrganisationToPartnerModalOpen(false)
      },
    onUnlinkOrganisationFromPartner:
      ({
        actions,
        match,
        modalPartnerOrganisationId,
        setIsUnlinkOrganisationFromPartnerModalOpen,
      }) =>
      (e) => {
        e.preventDefault()
        actions.unlinkOrganisationFromPartner(
          match.params.partnerUuid,
          modalPartnerOrganisationId,
        )
        setIsUnlinkOrganisationFromPartnerModalOpen(false)
      },
    onLinkOrganisationToPartner:
      ({ actions, match, setIsLinkExistingOrganisationToPartnerModalOpen }) =>
      (values) => {
        actions.linkOrganisationToPartner(
          match.params.partnerUuid,
          values.orgUuid,
        )
        setIsLinkExistingOrganisationToPartnerModalOpen(false)
      },
  }),
)
